/**
 * CMS General Layout
 */
$sidebar-expanded-width: 253px;
.cms-wrapper {
  background-color: #fff;
  display: flex;
  .cms-sidebar {
    width: 60px;
    flex-shrink: 0;
    border-right: 1px solid $color-basic-300;
    overflow-x: hidden;
    transition: all 0.1s ease;
    padding-top: 14px;
    padding-bottom: 14px;
    >div {
      .cms-menu-title {
        position: relative;
        padding-right: 30px;
        cursor: pointer;
        font-size: 17px;
        font-family: $font-regular;
        color: #374050;
        display: flex;
        align-items: flex-start;
        padding: 10px 18px;
        width: $sidebar-expanded-width;
        .mr-3 {
          flex-shrink: 0;
          svg {
            margin-top: 3px;
          }
        }
        div {
          flex-grow: 1;
          text-align: left;
          margin-top: 2px;
          opacity: 0;
          transition: all 0.1s ease;
        }
        .icon-thick-down-caret {
          position: absolute;
          right: 16px;
          top: 55%;
          transform: translateY(-50%);
        }
        &.is-selected {
          svg {
            color: #007BE2;
          }
          div {
            color: #007BE2;
          }
          a {
            color: #007BE2;
          }
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 4px;
            background: #0094F3;
            border-radius: 0px 4px 4px 0px;
          }
        }
        > a {
          opacity: 0;
          transition: all 0.1s ease;
          text-decoration: none !important;
          font-size: 17px;
          font-family: $font-regular;
          color: #374050;
          margin-top: 2px;
        }
      }
      .cms-sub-menu {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        padding: 8px 0 8px 30px;
        margin-top: -6px;
        opacity: 0;
        width: $sidebar-expanded-width;
        transition: all 0.1s ease;
        display: none;
        li {
          padding: 0;
          a {
            padding-left: 36px;
            text-decoration: none;
            color: #576071;
            padding-bottom: 10px;
            padding-top: 10px;
            margin-right: 10px;
            font-size: 18px;
            line-height: 1.2;
            &.active {
              color: #374050;
              font-family: $font-medium;
              background: #F6F9FC;
              border-radius: 8px;
            }
          }
        }
      }
      .cms-menu-title.is-expanded + .cms-sub-menu {
        display: block;
      }
    }
    &:hover {
      transition-delay: 1s;
      width: $sidebar-expanded-width;
      .cms-menu-title {
        div {
          transition-delay: 1s;
          opacity: 1;
        }
        > a {
          transition-delay: 1s;
          opacity: 1;
        }
      }
      .cms-sub-menu {
        transition-delay: 1s;
        opacity: 1;
      }
    }

  }
  .cms-content {
    flex-grow: 1;
    padding: 10px 10px;
    overflow-y: auto;
    overflow-x: hidden;
    &.with-sidebar {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;

      >div {
        margin-top: 0 !important;
        .middle-content {
          margin-top: 50px;
          margin-bottom: 30px;
          width: calc( 100% - 320px );
          max-width: calc( 100% - 320px );
          flex: 0 0 calc( 100% - 320px );
        }
        .sidebar-compo {
          background-color: #fff;
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
          padding: 30px 40px 30px 30px;
          position: fixed;
          right: 0;
          top: 51px;
          width: 300px;
          height: calc(100vh - 51px);
          .cright-nav.opened {
            .content {
              top: 51px;
              height: calc(100vh - 51px);
              .scroll-wrp {
                margin-left: -24px;
                margin-right: -24px;
                padding-left: 24px;
                padding-right: 24px;
              }
            }
          }
        }
      }
    }
  }
}