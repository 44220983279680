.match-mentorlist {
  .match-card-header {
    position: relative;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E9EFF5;
    background-color: #FAFCFF;

    .level-lbl {
      border: 2px solid #95A0B0;
      border-radius: 6px;
      width: 24px;
      height: 24px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 16px;
      line-height: 1;
      color: #95A0B0;
      font-family: $font-medium;
      background-color: #FAFCFF;
    }

  }

  .match-card-info {
    padding: 10px 20px;
  }

  .sel-match-card {
    border: 1px solid #ebebeb;
    margin-bottom: 10px;
    position: relative;
    counter-increment: step-counter;
    background-color: #fff;
    border-radius: 5px;
    .match-card-header {
      .btn.btn-primary {
        border: 0;
        background: transparent;;
        padding: 0;
        color: #768091;
      }
    }

  }

}

.new-match-card.not-selected-card {
  margin-bottom: 24px;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid #E9EFF5;
  border-radius: 4px;
  .match-card-footer {
    padding: 16px 20px;
    border-top: 1px solid #E9EFF5;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .single-number {
      font-size: 16px;
      font-family: $font-regular;
      color: #576071;
      display: flex;
      align-items: center;
      line-height: 1 !important;

      i {
        font-size: 15px;
        color: #576071;
        margin-right: 5px;
      }

      i.color-red {
        color: #e2534B;
      }
    }
  }
}

