.outreach-left-sidebar-menu .menu-item-wrp.cursor{
  position: relative;
}
.outreach-left-sidebar-menu .icon {
  position: absolute;
  right: 16px;
  top: 55%;
  transform: translateY(-50%);
}

.outreach-left-sidebar-menu .sub-menu li a {
  padding-left: 36px;
  text-decoration: none;
  color: #576071;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-right: 10px;
  font-size: 16px;
  line-height: 1.2;
}