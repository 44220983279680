.cop-ct-columns.p-6 {
  .competency-domain-compo {
    padding: 0;

    .scrollable-columns {
      margin-bottom: 0;

      .inner-wrp .each-column {
        margin-bottom: 24px;
      }
    }
  }
}

.competency-domain-compo {
  padding: 0;

  .gray-bd-box.is-selected {
    background-color: #f9fcfe;
  }

  .increased-by {
    font-size: 21px;
    color: #374050;
    font-family: $font-medium;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 1;
    margin-top: 3px;

    i {
      color: #88c04e;
      margin-right: 6px;
      font-size: 12px;

      &.is-decreased {
        color: #e52c40;
        transform: rotate(180deg);
      }
    }
  }

  .inner-box {
    position: relative;
    padding-left: 16px;
    padding-bottom: 2px;

    .left-color-bar {
      position: absolute;
      left: 0;
      top: 0;
      width: 3px;
      height: 100%;
      border-radius: 10px;
      background-color: #95a0b0;
    }

    .feature-tag {
      height: 19px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: $font-medium;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 16px;
      background-color: #ebf6ff;
      color: #0094f3;
      flex-shrink: 0;
    }
  }

  .circle-count {
    height: 24px;
    min-width: 24px;
    padding-right: 6px;
    padding-left: 6px;
    font-size: 14px;
    border-radius: 12px;
    font-family: $font-medium;
    background-color: #f6f9fc;
    color: #a8b6c7;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: -1px;

    &.is-valid {
      background-color: #ebf6ff;
      color: #0094f3;
    }
  }
}

.domain-tasks-entry {
  margin: 2px -20px;
  border-radius: 4px;
  padding: 8px 12px !important;

  &:hover {
    background-color: #f6f9fc !important;
  }

  &[aria-selected='true'] {
    background-color: #f6f9fc !important;
  }

  .slash-command-container {
    .slash-command-name {
      .icon-wrp {
        border: 1px solid #e9eff5;
        border-radius: 8px;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.determine-emoji {
  @media (max-width: 1599px) {
    .fz-40 {
      font-size: 24px !important;
    }

    .fzs-30 {
      font-size: 20px !important;
      line-height: 24px !important;
    }
  }
}