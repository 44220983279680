.Profile-Container {
  .snapshot-comp-wrp {
    // overflow: auto;
    height: auto;
    min-height: 100vh;
    background-color: transparent;
    padding: 0;
    box-shadow: 0px 2px 6px rgba(23, 24, 26, 0.1);

    .es-call-trigger {
      width: 40px;
      height: 40px;
      background: #0094F3;
      padding: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px !important;
      border: 0;
      transition: all ease 0.3s;
      z-index: 99;

      &:hover {
        opacity: 0.9;
      }

      i {
        font-size: 18px;
        color: #fff;
      }
    }

    .inner-col {
      background-color: #fff;
      padding: 18px 24px 0 24px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.015);
      position: relative;
      height: 100%;

      .es-call-trigger {
        top: 70px;
      }
    }

    .menu-wrp {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 0;

      >div {
        display: flex;

        >span {
          display: flex;
          cursor: pointer;
          transition: all ease 0.3s;
        }
      }

      span i {
        font-size: 17px;
        color: #768091;
        cursor: pointer;
        cursor: pointer;
        transition: none;
        transform: translateX(-10px) rotate(180deg);
      }
    }

    .member-info {
      margin-top: 20px;

      .mentee-name {
        text-align: left;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 8px;


        .avatar.member-initial {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          background-color: #AB02B6;
          color: #fff;
          font-size: 16px;
          font-family: $font-regular;
          line-height: 42px;
          text-align: center;
          margin-right: 5px;
        }

        .name-wrp {
          display: flex;
          align-items: flex-start;
          margin-bottom: 0;

          .edit-modal-btn {
            border: 0;
            background-color: transparent;
            margin: 0;
            padding: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            padding: 0;
            width: 30px;

            i {
              font-size: 16px;
              transform: scale(0.3);
            }
          }
        }

        .mentee-status {
          margin-bottom: 12px;

          .round-tag {
            font-size: 13px;
            font-family: $font-regular;
            background: #F1F5F9;
            border: 1px solid #F1F5F9;
            border-radius: 4px;
            margin-right: 8px;
            color: #576071;

            b {
              font-family: $font-medium;
              margin-right: 5px;
            }

            &.bd-only {
              background-color: #fff;
              border-color: #E9EFF5;
            }
          }
        }
      }

      p {
        font-size: 16px;
        color: #4A4A4A;
        font-family: $font-regular;
        margin-bottom: 0;
      }

      .assigned-mentor-wrp {
        background: #F6F9FB;
        box-sizing: border-box;
        border-radius: 3px;
        margin-bottom: 16px;
        padding: 11px 9px;
        color: #768091;
        display: flex;

        >.fz-12 {
          line-height: 1 !important;
        }

        h4 {
          color: #768091;
        }

        span {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          color: #768091;
          text-decoration: none !important;
          font-size: 14px;
          letter-spacing: 0.1px;
          font-family: $font-regular;
          line-height: 1;
          border: 0;
        }
      }
    }

    .upcoming-recentcalls-wrp {
      padding-top: 16px;
      background-color: #fff;
      padding-bottom: 16px;
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
      border-bottom: 1px solid #F1F5F9;
    }



    .enrollment-wrp {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .icon-caret-down-solid {
      transform: rotate(-90deg);
      transition: all ease 0.3s;
      color: #95A0B0;

      &.expanded {
        transform: rotate(0);
      }
    }

    .snapshot-mentee-availability-wrp {
      padding: 16px;
      border: 1px solid #E9EFF5;
      box-sizing: border-box;
      border-radius: 8px;
      .icon-thick-down-caret {
        font-size: 12px;
        color: #95A0B0;
        &.expanded {
          transform: rotate(180deg);
        }
      }
      .snapshot-mentee-availability {
        padding: 0;

        >ul {
          margin-bottom: 8px;
          margin-top: 8px;
          display: flex;
          flex-wrap: wrap;

          >li {
            list-style: none;
            margin-bottom: 16px;
            padding-bottom: 0 !important;
            width: 100%;
            &:last-child {
              margin-bottom: 0;
            }
            b {
              display: block;
              width: 100%;
              font-family: $font-medium;
              color: #374050;
              font-size: 14px;
              font-weight: 400 !important;
            }

            br {
              display: none;
            }

            ul {
              list-style: none;

              li {
                font-size: 16px;
                font-family: $font-regular;
                color: #768091;
                margin-left: 0 !important;
                hr {
                  display: none;
                }
              }

              >li:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
              }
            }
          }
        }
      }

    }


    .pending-activation-wrp {
      margin-left: -24px;
      margin-right: -24px;
      padding: 9px 24px;
      border-top: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
      background-color: #223c88;
      border: 0;

      i {
        font-size: 25px;
        margin-right: 10px;
        color: #fff;
      }

      * {
        color: #fff;
      }
    }

    .alerts-col.col-lg-5 {
      transition: all ease 0.1s;
    }

    &.collapsed {
      width: 70px;
      flex: 0 0 70px;
      // overflow-x: hidden;
      position: relative;
      padding: 0;
      transition: all ease 0.1s;

      .inner-col {
        border-left: 0;
        color: #fff;
        background-color: #17202E;
        padding: 20px 7px 0 7px;
        min-height: 100vh;
        height: 100%;
      }

      .menu-wrp {
        justify-content: flex-start;

        span {
          cursor: pointer;
          transition: all ease 0.3s;
        }

        span i {
          color: #fff;
          cursor: pointer;
          transform: none;
          transition: none;
        }
      }

      .member-info {
        padding: 0;
        position: relative;
        margin-top: 70px;
        margin-bottom: 25px;

        h1 {
          font-size: 17px;
          text-align: center;
          flex-direction: column;
          margin: 0 0 15px 0;
        }

        .mentee-name {
          display: flex;
          flex-direction: column;
          margin: 0;
          padding-right: 0;

          >* {
            width: 100%;
          }
        }

        .name-wrp {
          margin-bottom: 9px;
          position: relative;
          display: flex;
          align-items: center;

          &::after {
            content: '';
            position: absolute;
            left: -7px;
            right: -7px;
            bottom: -16px;
            height: 1px;
            background-color: #fff;
            opacity: 0.1;
          }
        }
      }

      .es-call-trigger.btn.btn-primary {
        left: 8px;
        top: 45px;
        right: auto;
        transform: none;
      }

      .upcoming-recentcalls-wrp {
        padding: 0;
        display: none;

      }

      .materials-sent-to {
        text-align: center;
        padding: 10px;
      }

      .enrollment-wrp {
        text-align: left;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 15px;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: -7px;
          right: -7px;
          bottom: 0;
          height: 1px;
          background-color: #576071;
        }

        .fz-13.ff-bold.mb-2\.5 {
          margin-bottom: 0 !important;
          font-family: $font-regular !important;
        }

        .each-recent-call {
          margin-bottom: 0;
        }

      }


      .mini-desc {
        text-align: left;

        img {
          margin-right: 6px;
          margin-top: -2px;
          width: 12px;
          height: 12px;
        }
      }


      .app-download-wrp {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 14px;
        border-top: 1px solid #ebebeb;
        width: 100%;

        .nobd {
          padding: 0;

          i {
            margin-bottom: 13px;
          }
        }
      }
    }
  }

}

.es-call-popover-inner {
  border: 1px solid #fff;

  .es-call-option {
    border: 0;
    text-align: left;
    border-bottom: 1px solid #ececec;
    display: block;
    width: 100%;
    cursor: pointer;
    width: 200px;
    background-color: #fff;
    padding: 0;

    .fz-15.fc-black.ff-reg {
      line-height: 1 !important;
      margin-left: -1px;
    }
  }

  >button:last-child {
    border-bottom: 0;
    margin-bottom: -0.5rem;
  }
}