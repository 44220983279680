.step-tabs-compo {
	.steplets-nav-wrapper {
		.nav.nav-tabs {
			display: flex;
			justify-content: flex-start;
			border-bottom: none;
			margin-left: 0;
			width: calc(100% - 50px);
			flex-wrap: nowrap;

			.nav-item {
				padding: 0 12px;
				border: 0;

				@media(max-width: 1440px) {
					padding: 0 7px;
				}

				a {
					padding: 0;
					font-size: 21px;
					text-decoration: none !important;
					border: 0;
					font-family: $font-regular;
					padding-bottom: 3px;
					color: #768091;

					@media(max-width: 1368px) {
						font-size: 18px;
					}
				}

				&.current {
					a {
						color: #374050;
						border-bottom: 2px solid $secondary-blue;
					}
				}

				.nav-link {
					display: inline-flex;
					align-items: center;
					justify-content: flex-start;
				}

				.circle-count {
					width: 1.5rem;
					height: 1.5rem;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					line-height: 1;
					font-size: 14px;
					font-family: $font-medium;
					background-color: #EBF6FF;
					margin-left: 5px;
					color: $secondary-blue;
					padding-bottom: 1px;

					&.has-unread {
						background-color: #ff4e59;
						color: #fff;
					}
				}
			}

			>div:first-child {
				padding-left: 0;
			}
		}
	}

	.step-tab-content {
		background-color: #fff;
		padding: 0.75rem 0;
		min-height: 300px;

		.pull-left {
			float: none !important;
		}
	}

	.nav-tabs .nav-link.disabled {
		opacity: 0.3;
		cursor: not-allowed !important;
	}

	.cursor {
		.disabled {
			cursor: not-allowed !important;
		}
	}
}

body {
	.step-status-badge {
		font-family: $font-medium;

		&.is-active {
			background: #EBF4FF;
			color: #006DEB !important;
		}

		&.is-blocked {
			background: #FEF1F2;
			color: #F5000F !important;

		}

		&.is-resolved {
			background: #F0FAF1;
			color: #30883B !important;

		}

		&.is-notexploredyet {
			background: #FFF7EB;
			color: #E08700 !important;
		}
	}

	.step-other-status-badge {
		font-size: 14px;
		color: #768091;
		border: 1px solid #E0E8F0;
		border-radius: 6px;
		padding: 1px 8px 3px 8px;
		margin-right: 4px;
		margin-bottom: 4px;
	}

}

.guider-info-item {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	margin-bottom: 4px;
	flex-wrap: wrap;
	.guider-status-info {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		border: 2px solid #95A0B0;
		margin-right: 8px;
		margin-top: 6px;
		flex-shrink: 0;
		&.is-valid {
			border-color: $brand-primary;
		}
	}
	.guider-count {
		background-color: #3392FF;
		border-radius: 32px;
		padding: 0 6px;
		font-size: 14px;
		font-family: $font-medium;
		color: #fff;
		margin-left: 8px;
		min-width: 22px;
    text-align: center;
	}
}

.steplet-guider-wrapper {
	.steplet-guider-inner-content {
		max-height: 115px;
		overflow-y: hidden;
		transition: max-height 0.3s ease;
		position: relative;
		z-index: 1;
		&.is-expanded {
			max-height: 6000px;
			transition: max-height 0.3s ease-in-out;
		}
	}
	.steplet-guider-toggle-btn-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		z-index: 2;
		margin-top: 16px;
		&.show-shadow-bg {
			padding-top: 48px;
			margin-top: -48px;
			background: rgb(255,255,255);
			background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%);
		}
		svg {
			margin-right: 4px;
			width: 18px;
			height: 18px;
		}
	}
}