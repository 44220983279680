.site-nav {
  background-color: #212d52 !important;
}

.mentor-nav {
  background-color: #212d52 !important;
}

.site-nav,
.mentor-nav {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  height: 51px;
  padding-left: 0;
  padding-right: 0;

  &.is-sticky {
    position: fixed;
    left: 0;
    width: 100vw;
    top: 0;
    z-index: 999;

    &+div:not(.is-modal) {
      margin-top: 51px;
      height: calc(100vh - 51px);
      overflow-y: auto;
    }

    &+div.is-modal+div {
      margin-top: 51px;
      height: calc(100vh - 51px);
      overflow-y: auto;
    }
  }

  .MuiToolbar-root {
    padding-left: 0;
    padding-right: 0;
    min-height: unset;

    .main-nav-left-hamburger {
      height: 51px;
      width: 54px;
      border-radius: 0;
      border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
  }


  .tools-org-selectors {
    display: flex;
    height: 51px;

    .each-selector {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      border-right: 1px solid rgba(255, 255, 255, 0.2);

      select {
        -webkit-appearance: none;
        background-color: transparent;
        border: none !important;
        color: #b3cfe5;
        padding: 9px 37px 9px 19px;
        outline: 0 !important;
        font-size: 16px;
        font-family: $font-medium;
        line-height: 1;
        height: auto;
        box-shadow: none !important;
        text-overflow: ellipsis;
        // margin-right: -25px;
        padding-right: 25px;
        width: auto;
        cursor: pointer;
        -webkit-user-select: all;
        /* Chrome 49+ */
        -moz-user-select: all;
        /* Firefox 43+ */
        -ms-user-select: all;
        /* No support yet */
        user-select: all;

        option {
          color: #404040;
        }

        @media (max-width: 960px) {
          font-size: 13px;
          width: 120px;
        }

        &::-ms-expand {
          display: none;
        }

        @media (max-width: 1440px) {
          width: 200px;
          padding-left: 10px;
          padding-right: 24px;
        }
      }

      &::after {
        position: absolute;
        right: 9px;
        top: 54%;
        pointer-events: none;
        transform: translateY(-50%);
        background-image: url(../new-theme/images/bottom-triangle.svg);
        width: 12px;
        height: 6px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        content: '';
        pointer-events: none;
        opacity: 0.2;
      }

      .form-control:disabled {
        cursor: not-allowed !important;
      }
    }
  }

  .ivr-wrp {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    font-family: $font-medium;
    display: flex;
    align-items: center;
    height: 100%;

    .item {
      >label {
        font-size: 16px;
        color: #b3cfe5;
        line-height: 1 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        @media (max-width: 960px) {
          font-size: 12px;
        }

        @media (max-width: 1305px) {
          font-size: 14px;
          margin-left: 5px;
          margin-right: 5px;
        }

        i {
          font-size: 23px;
          position: relative;
          z-index: 2;
        }

        >button {
          border-radius: 3px;
          margin-left: 14px !important;
          padding: 0;
          border: 0;
          box-shadow: none;
          font-size: 16px;
          font-family: $font-medium;
          padding-top: 3px;
          line-height: 1;
          padding-bottom: 4px;
          padding-right: 7px;
          padding-left: 7px;
          border-radius: 8px;

          @media (max-width: 1305px) {
            margin-left: 5px !important;
          }
        }

        >button.btn-danger {
          background-color: #425c9d;
          color: #fff;
        }

        >button.btn-warning {
          background-color: #425c9d !important;
          color: #fff;
        }
      }
    }
  }

  .nav-selects {
    padding-left: 16px;
    padding-right: 16px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    >.item {
      display: flex;
      align-items: center;
      margin-top: -3px;

      .icon-wrp {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        i {
          font-size: 16px;
          margin-left: 0;
          cursor: pointer;
        }
      }

      >button {
        font-size: 16px;
        font-family: $font-medium;
        color: #fff;
        box-shadow: none;
        background-color: #dc7272;
        border: 1px solid #dc7272;
        width: 120px;
        text-align: center;
        padding-top: 3px;
        padding-bottom: 3px;
        line-height: 1 !important;

        &.btn-success {
          background-color: #88c04e;
          border: 1px solid #88c04e;
        }
      }

      .icon {
        font-size: 24px;
        margin-left: 10px;

        &.color-red {
          color: #dc7272 !important;
        }

        &.color-green {
          color: #88c04e !important;
        }
      }
    }
  }

  .outreach-search-inline {
    flex-grow: 1;
    padding-left: 15px;
    padding-right: 15px;

    .form-group {
      width: 100%;
      display: block;

      .input-group>div {
        width: 100%;
        padding-right: 30px;
        position: relative;

        input {
          width: 100%;
          font-size: 14px;
          line-height: 1.2;
          color: #fff !important;
          font-family: $font-regular;
          background-color: transparent;
          box-shadow: none !important;
          border: 0 !important;
          outline: none !important;
        }

        input::placeholder {
          opacity: 0.8;
        }

        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          width: 18px;
          height: 18px;
          // width: 100%;
          // height: 100%;
          background-image: url(../new-theme/images/thin-search.svg);
          transform: translateY(-50%);
        }
      }
    }
  }

  .navs-main-search {
    width: 300px !important;
    border: 1px solid #b9e0ff;
    border-radius: 50px;
    height: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding: 0 0 0 4px;
    flex-direction: row;

    .nav-search-fields-dropdown {
      padding: 0 12px;
      margin-inline: 0;
      border-right: 1px solid #b9e0ff;
      flex-grow: 0;
      flex-shrink: 0;
      min-width: unset;
    }

    .nav-search-box-wrapper {
      flex-grow: 1;
      position: relative;
      width: auto;
      padding-inline: 12px;
    }

    .MuiInput-root {
      padding-inline: 0;

      &::before,
      &::after {
        content: none;
      }
    }

    .top-magnify-glass-btn {
      flex-grow: 0;
      flex-shrink: 0;
      background-color: transparent !important;
      border-radius: 0 50px 50px 0;
      border-width: 0 0 0 1px !important;
      border-color: #b9e0ff;
      border-style: solid;
      padding-top: 0;
      padding-bottom: 1px;
      color: #fff;
      min-width: unset;
      padding-inline: 8px;
      height: 100%;
      box-shadow: none !important;
      margin-inline: 0;
    }
  }
}

.mentor-nav {
  .right-menus button.dropdown-toggle {
    background-color: $secondary-blue;
  }
}

.right-menus {
  height: 50px;
  margin-left: auto;
  padding-right: 0;
  padding-left: 18px;
  position: relative;

  .dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-btn-with-unread-cnt {
    margin-left: 10px;
    margin-right: 10px;

    .toggle-notification {
      min-width: unset;
      max-width: unset;
      padding-right: 12px;
      padding-left: 12px;
      height: 48px;

      .network-count-badge {
        background-color: transparent;
        border: 2px solid white;
        border-radius: 4px;
        padding: 0 4px;
        color: white;
        min-width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }

      .MuiBadge-root {
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        i {
          font-size: 20px;

          &.icon-notification-bell {
            // color: #b9e0ff;
            font-size: 24px;
          }
        }

        .MuiBadge-badge {
          color: white !important;
          height: 16px;
          width: max-content !important;
          min-width: 18px !important;
          padding: 2px 4px 3px 4px !important;
          font-size: 14px;
        }

        .MuiBadge-badge {
          top: 4px;
          right: 4px;
          position: absolute;
        }
      }
    }
  }

  .dropdown-menu {
    right: 0;
    left: auto;
    width: 230px;
  }

  .dropdown-menu {
    top: 51px;
    border-radius: 0;
    border: 1px solid #e0e8f0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

    .dropdown-item {
      padding: 8px 20px;
      font-size: 16px !important;
      color: #374050;

      &.active,
      &:active {
        background-color: transparent;
        color: $secondary-blue;
      }
    }
  }

  .btn-right-profile-menu {
    position: relative;

    .badge-on-badge {
      position: absolute;
      background-color: #fff;
      padding: 2px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      padding: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

}

.mui-right-menu {
  .MuiPaper-root {
    // padding: 8px !important;
    width: 230px;
    right: 5px;
    margin-top: 9px;
    left: auto !important;
    right: 16px !important;
    padding: 0 8px;
    border-radius: 0;
    font-weight: 200 !important;
  }

  .MuiMenuItem-root {
    padding: 2px 16px;
    width: 100%;
    height: 35px;
    font-size: 16px;
    text-transform: none;
    border-radius: 4px;
    font-family: "Calibre";
    font-size: 16px;

    span {
      font-family: $font-medium;
      font-size: 16px;
    }

    i {
      font-size: 16px;
    }

    svg {
      width: 19px;
    }

    .MuiListItemIcon-root {
      min-width: unset;
      margin-right: 12px;
    }

    &:hover {
      color: #fff;
      background-color: $secondary-blue;

      svg {
        color: #fff;
      }
    }
  }

  hr {
    border-color: rgb(235, 235, 235);
  }

}

.nav-selects {
  float: left;
}

.item {
  float: left;

  label {
    color: #fff;
    line-height: 45px !important;
    margin: 0 1em;
  }

  select {
    color: #000;
    background: #bababa;
    display: inline-block;
    width: auto;
  }
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a:focus {
  color: #d3d3d3;
}

.navbar {
  .custom-placeholder-input {
    border: 1px solid rgb(185, 224, 255);

    .border-right {
      border-right: 1px solid rgb(185, 224, 255) !important;
    }

    .mentor-height {
      height: 30px !important;
    }

    ::placeholder {
      color: #fff;
    }

    >input {
      box-sizing: border-box;
    }
  }

  .dropright {
    padding-top: 5px;
    padding-bottom: 5px;

    &:hover {
      background: #f6f9fb;
    }
  }
}

.site-nav .tools-org-selectors .each-selector select .form-control:disabled {
  cursor: not-allowed;
}

.outreach-search-field {
  margin-left: 15px;
}

.dropdown-menu {
  border: 1px solid #a0a0a0;
  margin-top: 0;
}

.dropdown-menu>.dropright {
  button.btn.btn-primary {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    outline: 0 !important;
    box-shadow: none !important;
    position: relative;
    font-weight: 400;
    font-family: $font-regular;

    &:after {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.5;
    }
  }

  &.show {
    .dropdown-menu {
      margin-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  line-height: 1;
  margin-top: 5px;
}

/**
 * Humburget style
 */
.hamburger:hover {
  opacity: 0.7;
}

.dropdown-toggle.nav-link[aria-expanded='true'] .hamburger:hover {
  opacity: 0.7;
}

.dropdown-toggle.nav-link[aria-expanded='true'] .hamburger .hamburger-inner,
.dropdown-toggle.nav-link[aria-expanded='true'] .hamburger .hamburger-inner::before,
.dropdown-toggle.nav-link[aria-expanded='true'] .hamburger .hamburger-inner::after {
  background-color: #fff;
}

.hamburger-box {
  width: 28px;
  height: 18px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 28px;
  height: 3px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--slider .hamburger-inner {
  top: 2px;
}

.hamburger--slider .hamburger-inner::before {
  top: 7px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider .hamburger-inner::after {
  top: 14px;
}

.hamburger--slider.is-open .hamburger-inner {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}

.hamburger--slider.is-open .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider.is-open .hamburger-inner::after {
  transform: translate3d(0, -14px, 0) rotate(-90deg);
}

/**
 * Main Dropdown Style
 */

.main-nav-menu {
  height: 100%;
  padding-left: 0;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);

  .top-level-menu {
    font-family: $font-medium !important;
    color: #fff !important;

    .menu-icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;
      color: #fff;
      margin-left: -4px;
    }
  }

  .dropdown.nav-item {
    margin-left: -16px;
    padding-left: 16px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    >a {
      margin-top: 0;
    }
  }

  .dropdown-toggle.nav-link {
    margin-top: 3px;

    &:after {
      content: none;
    }
  }

  i {
    font-size: 16px;
    color: #fff;
  }

  .dropdown-menu {
    border-radius: 0;
    padding: 32px 16px;
    width: 300px;
    background-color: #17202e;
    border-radius: 0;
    border: 0;
    padding: 32px 16px;
    width: 300px;
    z-index: 999;
    max-height: calc(100vh - 51px);
    overflow-y: auto;

    .dropdown-item {
      color: #ccd6e2;
      font-size: 16px;
      line-height: 24px;
      padding: 6px 24px 6px 16px;
      border-radius: 0;
      font-family: $font-regular;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 4px;

      &:hover,
      &:active {
        background-color: #007be2;
        color: #fafcff;
        font-family: $font-medium;
      }
    }

    .sub-menu-trigger {
      width: 100%;
      padding: 6px 16px 6px 16px;
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-family: $font-medium;
      color: #fff;
      background-color: transparent;
      border: 0;
      position: relative;
      display: block;
      // margin-bottom: 10px;

      &:after {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        font-family: 'Mentor1to1';
        display: inline-block;
        font-size: inherit;
        font-weight: normal;
        font-style: normal;
        line-height: 1;
        text-rendering: auto;
        content: '\f10d';
        border: 0 !important;
      }

      &:hover,
      &.focus-visible {
        background-color: rgba(0, 0, 0, 0.2);
        color: #fff;
        border-radius: 4px;
      }

      &.expanded {
        &:after {
          transform: rotate(180deg) translateY(50%);
        }
      }
    }

    div>.dropdown-item {
      position: relative;
      padding-left: 62px;
      white-space: normal;

      i.icon-menu-submenu-before {
        color: #c4c4c4;
        font-size: 16px;
        transform: scale(0.4);
        position: absolute;
        left: 42px;
        top: 9px;
      }
    }
  }

  .sub-menu-trigger,
  .top-level-menu {
    >i {
      font-size: 16px;
      color: #fff;
      margin-right: 16px;
    }
  }

  &.senior-mentor-menu {
    .dropdown-menu {
      border-radius: 0;
      border: 1px solid #e0e8f0;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
      background-color: #fff;
      padding: 32px 16px;
      width: 300px;
      z-index: 9999;

      .dropdown-item {
        color: #374050;
        // color:  #CCD6E2;
        font-size: 16px;
        line-height: 24px;
        padding: 6px 16px 6px 16px;
        border-radius: 0;
        font-family: $font-regular;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:hover {
          background-color: #f8f9fa;
          border-radius: 4px;
        }

        &.active {
          color: $primary-blue;
        }
      }

      .sub-menu-trigger {
        width: 100%;
        padding: 6px 16px 6px 16px;
        text-align: left;
        font-size: 16px;
        line-height: 24px;
        font-family: $font-medium;
        color: #374050;
        background-color: transparent;
        border: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        >span.mr-2 {
          display: inline-block;
        }

        // margin-bottom: 10px;

        &:after {
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);

          font-family: 'Mentor1to1';
          display: inline-block;
          font-size: inherit;
          font-weight: normal;
          font-style: normal;
          line-height: 1;
          text-rendering: auto;
          content: '\f10d';
          border: 0 !important;
        }

        &:hover,
        &.focus-visible {
          background-color: #f8f9fa;
          color: #16181b;
          border-radius: 4px;
        }

        &.expanded {
          &:after {
            transform: rotate(180deg) translateY(50%);
          }
        }
      }

      div>.dropdown-item {
        position: relative;
        padding-left: 62px;
        white-space: normal;

        i.icon-menu-submenu-before {
          color: #c4c4c4;
          font-size: 16px;
          transform: scale(0.4);
          position: absolute;
          left: 42px;
          top: 9px;
        }
      }
    }
  }
}

.site-nav {
  .input-group-append {
    button {
      border: none;
      color: #fff;
      font-size: 14px;
    }

    button:hover {
      background: transparent;
    }

    .dropdown-toggle::after {
      vertical-align: middle;
    }

    input:focus,
    button:focus,
    .form-control:focus,
    .btn:focus,
    .btn-secondary:focus,
    .btn-secondary {
      outline: 0;
      box-shadow: none;
    }
  }

  .btn:focus {
    outline: none !important;
    outline-offset: none !important;
  }

  .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .btn-outline-secondary:not(:disabled):not(.disabled):active,
  .show>.btn-outline-secondary.dropdown-toggle {
    background: transparent;
  }

  .custom-placeholder-input>input {
    padding: 3px 11px !important;
    height: 30px;
    flex-grow: 1;
  }
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

.stop-icon {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background: #ff4e59;

  .icon {
    font-size: 6px;
    color: #fff;
  }
}

.grey-circle {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background: #a8b6c7;

  .icon {
    font-size: 6px;
  }
}

#input-group-dropdown-2 {
  background-color: transparent;
  width: auto;
  padding: 0 10px 0 10px;
}


.outreach-search-field {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}



/**
 * New mui styles
 */

.mui-main-menu {
  .MuiPaper-root {
    background-color: $dropdown-menu-bg;
    color: #fff;
    // padding: 8px !important;
    width: 320px;
    left: 7px !important;
    padding: 0 8px;
    border-radius: 10px;
    transform-origin: none !important;
    margin-top: 6px;
    top: 51px !important;

    .MuiListItemButton-root {
      padding: 2px 16px;
      width: 100%;
      color: #fff;
      font-size: 16px;
      text-transform: none;
      border-radius: 4px;
      font-family: $font-medium;
      font-size: 16px;

      span {
        font-family: $font-medium;
        font-size: 16px;
      }

      i {
        color: #fff;
        font-size: 16px;
      }

      svg {
        width: 19px;
        color: #fff;
        margin-left: -3px;
      }

      .MuiListItemIcon-root {
        min-width: unset;
        margin-right: 12px;
        width: 16px;
      }

      &:hover {
        background-color: $secondary-blue;
      }
    }

    .MuiCollapse-root {
      .MuiList-root {
        margin-left: 14px;

        .MuiListItemIcon-root {
          i {
            font-size: 10px;
            margin-top: -4px;
            color: #ffffffa0;
          }
        }
      }
    }
  }
}


// Notifications
.MuiAlert-root.flash-notification-message {
  top: 0;
  position: fixed !important;
  width: 100vw;
  z-index: 1050;
  background-color: $dark-blue;
  padding: 14px 0;
  position: relative;
  border-radius: 0;

  .MuiAlert-message {
    width: 100%;
    padding: 0;
    margin: 0 60px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }

  .MuiAlert-action {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;

    svg {
      font-size: 16px;
    }
  }

  &+header {
    top: 48px;

    &+div {
      margin-top: 99px !important;
      max-height: calc(100vh - 99px) !important;
    }

    @media(max-width: 953px) {
      top: 68px;

      &+div {
        margin-top: 119px !important;
        max-height: calc(100vh - 119px) !important;
      }
    }

    @media(max-width: 814px) {
      top: 88px;

      &+div {
        margin-top: 139px !important;
        max-height: calc(100vh - 139px) !important;
      }
    }
  }
}

.alert-in-main-menu {
  margin-left: -8px;
  margin-right: -8px;
  margin-top: -8px;
  background-color: $light-blue;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  font-size: 16px;
  font-family: $font-medium;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}