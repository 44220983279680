/**
 *  Define scss variables here.
 *
 *  Available options for Bootstrap:
 *  http://getbootstrap.com/customize/
 *
 */

// Custom Colors
$cyan: #33e0ff;
$humility: #777;

// Bootstrap Variables
$brand-primary: darken(#428bca, 6.5%);
$brand-secondary: #e25139;
$brand-success: #5cb85c;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;
$brand-info: #5bc0de;

$text-color: #333;
$blue-color: #007BE2;
$red-color: #e2534B;
$light-grey-color: #cecece;
$super-light-grey-color: #f9f9f9;
$green-color: #88C04E;
$light-green-color: #a2d47a;
$grey-color: #464646;
$text-grey-color: #7b7b7b;
$yellow-color: #f4e8a7;

$font-size-base: 16px;
$font-size-small : 15px;
$roboto-font: Roboto, Helvetica, sans-serif;

$font-family-base: $roboto-font !default;
$montserrat-font: 'Montserrat', sans-serif;

$second-font : 'montserratregular', arial;
$second-font-bold : 'montserratsemi_bold', arial;

$navbar-height: 71px !default;

$navbar-default-link-color: #FFF;
$navbar-margin-bottom: 0;

$chart-bar-width: 50px;

$green: #88C04E;




/**
 * New Fonts Variables
 */
$font-regular: 'Calibre';
$font-bold: 'Calibre-Medium';
$font-semibold: 'Calibre-Semibold';
$font-medium: 'Calibre-Medium';
$font-light: 'Calibre-Light';



$blue: #007BE2;
$gray-wrp-bg: #E2E4E8;
$gray-bg: #F2F3F6;
$dark-gray-bg: #e2e3e4;
$gray-text: #7F7F7F;
$gray-text1: #979797;
$green-bg: #88C04E;
$gray-border: #ebebeb;
$gray-bg1: #f7f7f7;


$snapshot_blue: #007BE2;



$green-500: #88C04E;
$green-600: #88C04E;
$blue-500: #007BE2;
$blue-600: #007BE2;
$yellow-500: #FFCC6C;
$yellow-600: #FFBD47;
$stone-500: #212D52;
$stone-600: #162143;
$orange-500: #FF9A2D;
$orange-600: #FF8521;
$red-500: #FF416E;
$red-600: #FF4E59;
$grey-400: #F6F9FB;
$grey-500: #F1F5F9;
$grey-600: #E9EFF5;
$grey-700: #DAE2EB;
$grey-800: #CCD6E2;
$charcoal-400: #95A0B0;
$charcoal-500: #768091;
$charcoal-600: #374050;
$charcoal-700: #374050;
$charcoal-800: #17202E;

$dark-blue: #0094F3;
$primary-blue: #007BE2;
$secondary-blue: #0094F3;
$tertiary-blue: #212d52;

:root, html {
  font-size: 16px;
  @media(max-width: 1024px) {
    font-size: 14px;
  }
}
p {
  font-size: 16px;
  @media(max-width: 1024px) {
    font-size: 14px;
  }
}