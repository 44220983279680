.issue-state-modal-wrp {
  .cright-nav.opened .content {
    height: 100vh;
    background-color: #fff;
    // box-shadow: 10px 0 74px rgba(0, 0, 0, 0.2);
    padding: 0;
    border-radius: 0;
    overflow: auto;

    .modal-inner {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-bottom: 150px;
      padding: 29px 43px 100px 43px;
      overflow: scroll;

      .modal-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .text-btn {
          font-size: 15px;
          color: #000;
          font-family: 'Calibre-Bold';
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          img {
            width: 16px;
            margin-right: 6px;
          }
        }
      }

      .kanban-card.cursor {
        .kanban-card-body {
          padding-bottom: 15px;
        }

        .kanban-card-footer {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.mentee-issue-detail {
  display: flex;
  flex-direction: column;
  .issues-details-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
  }
  >.tab-content {
    flex-grow: 1;
    padding: 24px 24px 0 24px;
    overflow-y: auto;

    >.tab-pane {
      height: 100%;
      >.comments-wrp {
        >.comments-compo-wrp {
          display: flex;
          flex-direction: column;
          .comments-list {
            flex-grow: 1;
            overflow-y: auto;
          }
        }
      }
    }
  }
  .mentee-profile-wrp {
    .mentee-status-info {
      .gray-box {
        padding: 0;

        .issue-brief {
          display: flex;

          .each-info {
            padding: 24px;
            flex-grow: 1;
            border-right: 1px solid #E9EFF5;
          }

          >div:last-child {
            border-right: 0 !important;
          }
        }


      }

    }

    .other-info-wrp {
      .issue-meta-info {
        padding-right: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fit, 280px);
        gap: 16px;
        margin-bottom: 24px;
      }

    }
    .editable.editable-click.btn.btn-link {
      padding: 0 !important;
      font-family: $font-medium;
      font-size: 16px;
      color: $primary-blue;
    }
  }
  .issue-details {
    .gray-box.add-tag-box {
      padding: 24px;
      .custom-placeholder-input {
        >input {
          background-color: #fff;
          border: 1px solid #DAE2EB;
          border-radius: 4px;
          box-shadow: none !important;
          outline: none !important;
        }
      }
    }
  }
  .recent-activities-compo {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
  }
}



.box-select-style {
  &.is-status {
    >button.btn.btn-primary, >div {
      background-color: transparent;
      border: none;
      padding: 0;
      display: block;
      width: 100% !important;
      >div {
        border-radius: 8px;
        padding: 12px 16px;
        text-align: left;
        justify-content: space-between;
        display: flex;
        i {
          margin-top: 1px;
        }
      }
    }
    >div.is-round {
      padding: 12px 16px;
      line-height: 1;
      border-radius: 8px;
    }
  }
  &.is-assignee {
    >div {
      border: 1px solid #E0E8F0;
      border-radius: 8px;
      padding: 12px 16px;
      justify-content: space-between;
      display: flex;
      line-height: 1;
      font-size: 16px;
      color: #17202e;
      cursor: pointer;
      i {
        margin-top: 1px;
      }
    }
  }
}
.mentee-status-box {
  float: right;
  width: 300px;
  border-radius: 8px;
  padding: 24px;
  margin-left: 40px;
  margin-bottom: 40px;
  background-color: #fff;
  &.MuiContainer-root {
    @media (min-width: 936px) {
      max-width: 300px !important;
    }
  }
}

.mentee-issue-discovery-compo {
  .issue-area-wrp {
    .area-title-wrp {
      position: relative;
    }
  }
}