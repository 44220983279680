/*
.sticky-note {
  display: flex;
  flex-flow: column;
  margin: 0px 0px 10px;

  .note-header {
    flex: 1 100%;
    text-align: center;
    padding: 5px;
  }

  .note-body {
    border-bottom: 1px solid #EBEBEB;
    padding: 16px 16px 16px 0px;
    margin-left: 26px;
    .note-meta {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      i.fa-star {
        position: absolute;
        font-size: 15px;
        left: -25px;
        top: 35%;
        transform: translateY(-50%);
        color: #ffc63d;
      }
      .n-date {
        font-size: 15px;
        font-family: $font-bold;
      }
      .n-author {
        font-size: 15px;
        font-family: $font-regular;
      }
      i.fa-trash-o {
        cursor: pointer;
      }
    }

    .note-main {
      flex: 3 0px;
      padding-top: 6px;
      font-size: 14px;
      font-family: $font-regular;
      color: #333333;
      margin-bottom: 10px;
      * {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.3px;
        font-family: $font-regular;
        color: #333;
      }

    }
    .main-topic {
      text-align: left;
      background-color: #FFFCF6;
      color: #000;
      font-size: 15px;
      line-height: 18px;
      font-family: $font-bold;
      border-left: 2px solid #0094F3;
      padding: 10px 15px 8px 15px;
      margin-bottom: 3px;
    }
    .note-button {
      text-align: left;
      margin-top: 10px;
      margin-bottom: 10px;
      button {
        border: none;
        background-color: transparent;
      }
    }
    .expand-btn-wrp {
      text-align: left;
      button {
        color: #cacaca;
        padding: 0;
      }
    }
    .ql-editor {
      padding-left: 0 !important;
    }
  }

  Button {
    background-color: inherit;
    border: none;
    &:hover {
      background-color: #E6E686;
    }
    &:focus {
      background-color: #F2F287;
    }
  }

}
*/
.quill {
  padding: 0px !important
}

.ql-editor {
  padding: 0px 0px 0px 10px !important;

  ul {
    padding-left: 0 !important;
    li {
      padding-left: 16px;
    }
  }

  &.ql-blank::before {
    font-style: normal;
  }
}

.ql-tooltip {
  z-index: 999;
}

.notes {
  text-align: center;
  // height: 100vh;
  overflow: auto;
  padding-bottom: 20px;
  padding-left: 15px;
}
.notes-input-holder {
  position: fixed;
  right: 0;
  bottom: 0;
  width: calc( 25% - 18px );
  @media(max-width: 991px) {
    position: relative;
    width: 100%;
  }
  ul {
    list-style: disc !important;
    margin-left: 15px;
  }
}

.notes .note-body {
  .note-sender {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .note-created-date {
    font-size: 14px !important;
    line-height: 20px !important;
    margin-bottom: 6px;
  }
  .note-main {
    font-size: 16px !important;
    line-height: 21px !important;
  }
}

.notepad-compo.is-copilot {
  .tp_notepad {
    .icon-angle-down-fa {
      padding: 4px;
    }
    .tp_notepad_text {
      max-height: 24px;
      overflow-y: hidden;
      @media(max-width: 1599px) {
        max-height: 16px !important;
      }
    }
    .created_at {
      display: none;
    }
    &.is-expanded {
      .tp_notepad_text {
        max-height: unset;
        overflow-y: hidden;
      }
      .created_at {
        display: block;
      }
      .icon-angle-down-fa {
        transform: rotate(180deg);
      }
    }
  }
  .custom-input-compo .custom-note-editor .main-editor .DraftEditor-editorContainer {
    padding: 16px;
  }
  .custom-input-compo .public-DraftEditorPlaceholder-inner {
    padding: 16px;
  }
}