.outreach-list-wrp {
	.left-nav-listing-header-col {
		padding-right: 0;
	}
	.main-content-col {
		padding-left: 24px;
		padding-right: 32px;
		padding-top: 24px;
		padding-bottom: 24px;
		.filters-wrp {
			margin-bottom: 24px;
		}
	}
	.filter-label {
		display: block;
	}
	.round-tag {
		border: 1px solid #E9EFF5 !important;
		border-radius: 8px !important;
		background-color: transparent;
		font-size: 12px;
		text-align: center;
		font-family: $font-regular;
		color: #768091;
		margin-right: 7px;
		margin-bottom: 7px;
		opacity: 1 !important;
	}
	.latest-notes-title {
		display: none !important;
	}
	&.is-outreach-mentee-list {
		padding: 24px;
		background-color: #fff;
		min-height: calc(100vh - 51px);
		.white-box, .gray-bd-box {
			box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);
			border-radius: 16px;
		}

		.crcw.cr_Table > div > div:last-child table button.btn.btn-primary, .crcw.cr_TableWithBrowserPagination > section table button.btn.btn-primary {
			background-color: $secondary-blue;
			border-color: $secondary-blue;
			border-radius: 8px;
			color: #fff;
			font-size: 16px;
		}
		.crcw.cr_TableWithBrowserPagination {
			border-radius: 8px;
			overflow: hidden;
		}
	}
}