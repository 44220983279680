.weekdaytable{
  background-color: #FFF;
  width: 100%;
  font-size: 20px;
  td{
    padding: 10px;
    text-align: center;
    span.color-green{
      margin-right: 20px;
    }
  }
  .headers{
    span{
      display: inline-block;
      width: 50px;
      text-align: center;
    }
    td{
      padding-bottom: 40px;
    }
    &.__bottom{
      td{
        padding-bottom: 10px;
        padding-top: 40px;
      }
    }
  }
}

.custom-barchart{
  height:50px;
  .chart-bar{
    width: $chart-bar-width;
    height: 100%;
    float: left;
    position: relative;
    background-color: #a1a9ac;
    &.highcolor{
      background-color: $blue-color;
    }
  }
  .bar-mask{
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    background-color: #FFF;
  }
  border-bottom: 3px solid #e4e4e4;
  &.__reverse{
    border-top: 3px solid #e4e4e4;
    border-bottom: none;
    .chart-bar{
      height: 50px;
    }
  }
  & > div{
    height: 100%;
  }
}

.chartaxis{
  font-size: 16px;
  font-weight: bold;
  & > div{
    width: 2*$chart-bar-width;
    text-align: center;
  }
}

.google-visualization-table-tr-head {
  height: 40px;
}
.google-visualization-table-td-number {
  text-align: left !important;
}