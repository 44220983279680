.reschedule-panel{
	// width: 355px;
	padding: 15px;
	.reschedule-action{
		background-color: #f1f1f1;
		padding: 12px 16px;
		font-size: 17px;
		.second-font{
			font-size: 12px;
			color: $text-grey-color;
		}
		span.date{
			margin-right: 5px;
		}
		span.time{
			display: inline-block;
		    font-size: 15px;
		    line-height: 23px;
		    vertical-align: top;
		}
		div.pull-left{
			margin-top: 7px;
		}
		.btn{
			margin-top: 6px;
    		padding: 7px 9px;
		}
	}
}

.next-call-box{
	.date{
		font-size: 23px;
	}
	.time{
		font-size: 17px;
	}
}

.time-slots{
	li{
		background-color: #f0f0f0;
	    border-radius: 2px;
	    float: left;
	    font-size: 15px;
	    margin-bottom: 7px;
	    margin-right: 7px;
	    padding: 8px 0;
	    text-align: center;
	    width: 54px;
	    cursor:pointer;
	    &.available {
	    	background-color: #c6dcc8;
	    }
	    &.selected {
	    	background-color: #00BB81;
	    	color: #ffffff;
	    }
	    &:last-child{
	    	margin-right: 0;
	    }
	    &.not-available {
	    	background: #ffffff;
	    	width:100px !important;
	    }
	}
}

.simple-month-calendar{
	max-width: 325px;
	.header{
		text-align: center;
		position: relative;
		background: none;
		padding: 8px;
		.btn-prev, .btn-next{
			cursor: pointer;
			font-size: 17px;
			padding: 5px;
			position: absolute;
			top: 5px;
		}
		.btn-prev{
			left: 10px;
		}
		.btn-next{
			right: 10px;
		}
	}
	.week{
		.day{
			border-top: 1px solid #E3E3E3;
			border-left: 1px solid #E3E3E3;
			float: left;
			height: 40px;
			line-height: 40px;
			margin-left: -1px;
			position: relative;
			text-align: left;
			width: 47px;
			background: #F4F4F4;
			padding: 0 8px;
			&:after{
				content: '\f026';
				display: inline-block;
				font: normal normal normal 14px/1 'Mentor1to1';
				text-rendering: auto;
				font-size: 15px;
				vertical-align: top;
				line-height: 39px;
			}
			&:first-child{
				@include border-radius(2px 0 0 2px);
			}
			&:last-child{
				@include border-radius(0 2px 2px 0);
				border-right: 1px solid #E3E3E3;
			}
			&.available{
				background-color: #ffffff;
				&:after{
					color: #FFCD00;
				}
			}
			&.preferred{
				background-color: #ffffff;
				position: relative;
				&:after{
					color: #00BB81;
				}
			}
			&.different-month{
				background-color: #F4F4F4;
				color: rgba(191, 191, 191, 0);
				&:after{
					content: none;
				}
			}
			&.selected {
				background-color: #00BB81;
				color: #FFFFFF;
				&:after{
					content: none;
				}
			}
			&.passed-date{
				background-color: #F4F4F4;
				color: rgba(74, 74, 74, 0.5);
				&:after{
					color: rgba(74, 74, 74, 0.3);
				}
			}
		}
		&.names{
			.day{
				border: none;
				height: 36px;
				line-height: 36px;
				color: #9b9b9b;
				text-align: center;
				background: none;
				&:after{
					content: none;
				}
			}
		}
		&:last-child{
			.day{
				border-bottom: 1px solid #cccccc;
			}
		}
	}
	.time-slots{
		padding: 10px 12px;
		li:nth-child(5n){
			margin-right: 0;
		}
	}
}
.backbtn{
	cursor: pointer;
	&:hover{
		text-decoration: none;
	}
}