.upcoming-call-compo {
  margin-left: -24px;
  margin-right: -24px;
  background-color: #F6F9FB;
  padding: 12px 24px 20px 24px;

  .btn.btn-success {
    background-color: $blue;
    color: #fff;
    padding-left: 30px;
    padding-right: 30px;
    height: 40px;
    border-radius: 40px;
    text-align: center;
    font-family: $font-bold;
    font-size: 15px;
    border: none;
    letter-spacing: -0.08px;
    line-height: 21px;
    transition: all ease 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }


  .reschedule-btn {
    font-size: 16px;
    color: $snapshot_blue;
    font-family: $font-medium;
    border: 0;
    padding: 0;
    background-color: transparent;
  }

  .reschedule-wrp {
    .Select {
      .Select-control {
        border-color: $primary-blue;

        .Select-input {
          outline: 0;
        }
      }
    }
  }

  .resc-footer {
    .col-2 {
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      padding-bottom: 10px;

      button {
        padding: 0;
        margin: 0;

        img {
          width: 12px;
          height: 12px;
          object-fit: contain;
          object-position: center center;
        }
      }
    }

    .btn.btn-success {
      margin-top: 24px;
      background: $primary-blue;
      border-radius: 4px;
      color: #fff;
      font-size: 15px;
    }


  }


}


.phase-selector {
  position: relative;
  line-height: 1;

  span.custom-btn {
    font-size: 16px;
    color: $secondary-blue;
    font-family: $font-regular;
    text-decoration: none;
    padding: 0 14px 0 0;
    cursor: pointer;
  }

  i.icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-40%) translateX(2px) scale(0.8);
    font-size: 16px;
    color: $secondary-blue;
    pointer-events: none;
  }
}

.datepickers-wrp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  input.form-control {
    border: 1px solid #E9EFF5;
    border-radius: 4px;
    padding: 2px 10px 3px 10px;
    height: 32px !important;
    font-size: 16px !important;
    font-family: $font-regular !important;
    color: #374050;
    cursor: pointer;
  }



  .resc-date-selector {
    width: calc(50% - 8px);
    margin-right: 16px;
  }

  .resc-time-selector {
    width: calc(50% - 8px);
    input.form-control, select.form-control {
      border: 1px solid #E9EFF5;
      border-radius: 4px;
      padding: 2px 10px 3px 10px;
      height: 32px !important;
      font-size: 16px !important;
      font-family: $font-regular !important;
      color: #374050;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      &::-ms-expand {
          display: none;
      }
    }
  }
}

.icon-btn-with-unread-cnt {
  .btn-primary.each-icon-btn {
    padding: 4px;
    bottom: 0;
    background-color: transparent !important;
    color: #576071 !important;
    position: relative;
    border: none;
    width: 36px;
    height: 36px;

    svg {
      color: #576071;
    }
    .badge {
      position: absolute;
      right: -2px;
      top: -2px;
      margin: 0 !important;
      left: auto;
      font-size: 12px;
      padding: 2px 3px 4px 3px;
      height: auto;
      border-radius: 12px;
      line-height: 1;
      min-width: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1 !important;
    }
    .badge-danger {
      background-color: #FF4E59;
    }
    .badge-secondary {
      background-color: #fff;
    }
  }
}