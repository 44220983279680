.mentor-account-signup-compo {
  height: calc(100vh - 52px);
  background-color: #fff;
  display: flex;
  justify-content: flex-start;

  .left-nav-listing-header {
    width: 320px;
    flex-shrink: 0;
    height: 100%;
    overflow-y: auto;
  }

  .main-content-wrp {
    height: 100%;
    overflow-y: auto;
    flex-grow: 1;
    padding: 0 24px 24px 24px;

    .step-title-wrp {
      margin-left: -24px;
      margin-right: -24px;
      padding: 24px 24px 24px 24px;
      border-bottom: 1px solid #E9EFF5;
      margin-bottom: 24px;
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 2;
    }

    .step-content {
      .MuiOutlinedInput-root {
        padding: 0 !important;
        border-radius: 4px !important;

        input {
          padding: 4px 8px !important;
          background-color: transparent !important;
        }
      }

      .MuiPaper-root {
        box-shadow: none;
        outline: none;
      }

    }

    &.account-settings-wrp {
      background-color: #E9EFF5;
      padding: 24px;

      .custom-styled-tabs.nav.nav-tabs {
        padding: 24px 24px 0 24px;
        background-color: #fff;

        &+.tab-content {
          background-color: transparent;

          .tab-pane>div {
            >.bg-white {
              background-color: transparent !important;

              >div {
                border-radius: 16px;
                overflow: hidden;
              }

              &.voice-memo-compo {
                >div {
                  border-top-left-radius: 0;
                }
              }
            }

            .crcw.cr_Textarea>div textarea {
              padding: 14px 16px;
            }
          }
        }
      }
    }
  }

  /**
   * Bootstrap overrides for our design standard
   * enabled it for signup for now, since it would break in other parts
   */
  .form-label {
    font-size: 14px;
    font-family: $font-regular;
    color: #576071;
    margin-bottom: 4px;
  }

  input.form-control {
    border-radius: 4px !important;
    border: 1px solid #CCD6E2 !important;
    font-size: 16px !important;

    &.is-red-bordered {
      border-color: #FF4E59 !important;
    }
  }

  select.is-red-bordered {
    border-color: #FF4E59 !important;
  }

  input[type='date'].form-control {
    padding: 2px 10px !important;
  }

  .voice-memo-compo {
    border-radius: 8px;

    .record-btns-wrapper {
      padding: 16px 16px 0 16px;
    }

    .inq-table-w-pagination {
      margin-left: 16px;
      margin-right: 16px;
    }
  }

  .mentor-availability-compo {
    padding: 0;
    .MuiCardContent-root {
      padding: 0 !important;
    }
  }
  .confirm-weekly-availability-compo {
    padding: 0;
    .MuiCardHeader-root {
      padding-left: 0;
      padding-right: 0;
    }
    .MuiCardContent-root {
      padding: 0 !important;
    }
  }
}

.step-content,
.bgchk-step-content {
  .custom-checkbox.square {
    &.red-box {
      .custom-checkbox-mark {
        border-color: #FF4E59;
      }
    }

    .custom-checkbox-mark {
      margin-top: 2.5px !important;
    }
  }
}

.background-check-modal-compo {
  .step-indicator {
    width: 480px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    >div:last-child {
      &:before {
        content: none;
      }
    }

    .each-step {
      position: relative;
      padding-top: 36px;
      width: 33.33%;
      text-align: center !important;

      &:before {
        content: '';
        position: absolute;
        top: 20px;
        left: 50%;
        height: 3px;
        width: 100%;
        background-color: #DAE2EB;
      }

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 8px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #CCD6E2;
        transform: translateX(-50%);
      }

      .step-label {
        font-size: 20px;
        font-family: $font-regular;
        color: #768091;
      }

      &.is-current {
        .step-label {
          font-family: $font-medium;
          color: #374050;
        }

        &:after {
          background-color: $secondary-blue;
        }
      }
    }
  }
}

.background-check-modal-compo {
  .modal-content {
    .modal-body {
      padding: 24px;
    }
  }
}

.bgchk-step-content {
  input.form-control {
    border-radius: 4px !important;
    border: 1px solid #CCD6E2 !important;
    font-size: 16px !important;

    &.is-red-bordered {
      border-color: #FF4E59 !important;
    }
  }

  .fcra-rights {
    border: 1px solid #CCD6E2 !important;
    border-radius: 8px;
    padding: 24px;
    font-size: 14px;
    font-family: $font-regular;
    height: 240px;
    overflow-y: auto;

    ul,
    ol {
      margin-left: 16px;
    }
  }
}

.voice-memo-compo {
  background-color: #fff;
}


.progress-circular-completed {
  svg * {
    stroke: #88C04E !important;
  }

  h1 {
    color: #88C04E !important;
  }
}

.progress-circular-processing {
  svg * {
    stroke: $secondary-blue !important;
  }

  h1 {
    color: $secondary-blue !important;
  }
}

.progress-circular-start {
  svg * {
    stroke: #007BE2 !important;
  }

  h1 {
    color: #007BE2 !important;
  }
}

.circular-progress-bar-wrp {
  position: relative;
  margin: 0 auto;
  width: 150px;

  .circular-progress-label {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 28px;
    color: #007BE2
  }

  &.is-completed {
    .circular-progress-label {
      color: #88C04E
    }
  }

  &.is-processing {
    .circular-progress-label {
      color: #0094F3
    }
  }
}