// Cosmetic
// ========

@mixin cosmetic($module, $property, $map: false) {

  @if not $map {
    $map: $property;
    $property: $module;
  }

  @each $key, $value in $map {
    .#{$module}-#{$key} {
      #{$property}: if($value, $value, $key) !important;
    }
  }
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin custom_scrollbar {
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    padding-left: 12px;
    background-color: rgba(122, 122, 122, 0.1)
    // padding-right: 12px;
  }

  &::-webkit-scrollbar-thumb {
    // height: 6px;
    background-clip: padding-box;
    -webkit-border-radius: 0;
    background-color: rgba(122, 122, 122, 0.3);
    border-color: rgba(122, 122, 122, 0.3);

  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

