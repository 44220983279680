.tagbox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;

    .mentor_status_box {
        display: inline-flex;
        align-items: center;
        padding-right: 20px;
        position: relative;
        margin-bottom: 3px;
        min-height: 45px;

        i {
            font-size: 30px;
        }

        .right {
            margin-left: 5px;

            >span {
                margin-left: 5px;
                display: block;
                font-size: 14px;
            }
        }

        &::after {
            content: '';
            position: absolute;
            width: 2px;
            right: 9px;
            text-align: center;
            background-color: #7b7b7b;
            height: 100%;
        }
    }

    >div:last-child::after {
        content: none;
    }
}

.btn-add-filters {
    button.dropdown-toggle {
        padding-left: 0;
        padding-right: 0;
        box-shadow: none;
        border: 0;
        background-color: transparent !important;
        color: $secondary-blue  !important;
        font-size: 18px;
        font-family: $font-medium;

        &:after {
            content: none;
        }
    }

    .dropdown-menu {
        top: 0 !important;
        border: none;
        background: #FFFFFF;
        box-shadow: 0px 4px 12px rgba(23, 32, 46, 0.1);
        border-radius: 4px;

        .dropdown-item {
            background-color: #fff;
            font-size: 16px;
            color: #374050;
            padding-top: 8px;
            padding-bottom: 8px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.02);
            }
        }
    }
}

.single-select-filter,
.multi-select-filter {
    .dropdown-toggle {
        display: block;
        padding: 8px 32px 8px 12px;
        background: #EBF6FF !important;
        border: 1px solid $secondary-blue  !important;
        border-radius: 8px;
        color: $secondary-blue  !important;
        font-family: $font-medium;
        font-size: 16px;
        position: relative;

        &:after {
            content: none;
        }

        >span {
            position: absolute;
            right: 12px;
            top: 50%;
            color: $secondary-blue;
            font-size: 12px;
            transform: translateY(-50%) scale(0.5);
        }
    }

    &.show {
        .dropdown-toggle {
            color: $secondary-blue;
        }

        .dropdown-menu {
            top: 0 !important;
            border: none;
            background: #FFFFFF;
            box-shadow: 0px 4px 12px rgba(23, 32, 46, 0.1);
            border-radius: 4px;
            padding: 16px;
            width: 240px;
            z-index: 99;

            .dropdown-item {
                background-color: #fff;
                font-size: 16px;
                color: #374050;
                padding-top: 8px;
                padding-bottom: 8px;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.02);
                }
            }

            .custom-checkbox {
                margin-bottom: 16px;

                .check-desc {
                    font-size: 16px;
                    color: #374050;
                }
            }
        }
    }

    a.btn-dropdown-close {
        width: 100%;
        border: 1px solid $secondary-blue  !important;
        border-radius: 8px;
        text-align: center;
        background-color: transparent;
        font-size: 16px;
        font-family: $font-medium;
        color: $secondary-blue  !important;
    }
}