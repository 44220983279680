.status-manager-compo {
  margin-bottom: 20px;

  .each-value {
    margin-bottom: 16px;

    .editable-click {
      color: $primary_blue;
      font-size: 16px;
      font-family: $font-medium;
      border-bottom: 1px dotted $primary_blue;
      padding: 0;
      margin: 0;
      border-radius: 0;
      line-height: 1 !important;

    }
    .named-entity {
      max-width: 100%;
      display: inline-block; 
      .editable-click {
        max-width: 100%; 
        text-align: left; 
      }
    }
  }

  &.mentees {
  	margin-bottom: 0;
    .edit-status-wrp {
    	margin-top: -1px;
    	margin-bottom: 15px;
      margin-left: -24px;
      margin-right: -24px;
      padding: 16px 24px 20px 24px;
      background-color: #F6F9FC;
      border-top: 1px solid #E9EFF5;
      border-bottom: 1px solid #E9EFF5;
    }
  }
}

.update-settings-wrp {
  width: 400px;
}