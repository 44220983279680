.notes-unread {
  background: #e2534B;
}

.notes-component{
    .note-item{
        border-bottom: 1px solid #dadada;
        padding: 25px;
    }
}

.notes-header{
    background: rgba(255, 255, 255, 0.5);
    position: relative;
}


.custom-styled__suggestions {
    border: 1px solid #E9EFF5;
    border-radius: 5px;
    overflow: hidden;
}

.custom-ql-editor-wrp {
    border: 1px solid #E9EFF5;
    border-radius: 12px;
    &.is-readonly {
        border: 0;
    }
    .custom-ql-editor {
        margin-bottom: 0;
        .ql-editor {
            height: 160px;
            padding: 16px !important;
            p {
                font-size: 16px;
                color: #374050;
            }
        }
        .ql-toolbar {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-bottom: 1px solid #E9EFF5;
        }
        .ql-container {
            border: 0;
        }
    }

    .custom-ql-editor-unfocus {
        margin-bottom: 0;
        .ql-editor {
            border: 0;
            height: fit-content;
            overflow-y: auto;
            padding: 16px !important;
            p {
                font-size: 16px;
                color: #374050;
            }
        }
        .ql-toolbar {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-bottom: 1px solid #E9EFF5;
            display: none;
        }
        .ql-container {
            border: 0;
        }
    }

    .custom-ql-editor-readonly {
        margin-bottom: 0;
        .ql-editor {
            border: 0;
            height: fit-content;
            overflow-y: auto;
            padding: 4px 0 !important;
            p {
                font-size: 16px;
                color: #374050;
            }
        }
        .ql-toolbar {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-bottom: 1px solid #E9EFF5;
            display: none;
        }
        .ql-container {
            border: 0;
        }
    }

    .custom-ql-editor-hide-toolbar {
        margin-bottom: 0;
        #editor {
            min-height: 32px;
        }
        .ql-editor {
            border: 0;
            height: fit-content;
            overflow-y: auto;
            padding: 16px !important;
            p {
                font-size: 16px;
                color: #374050;
            }
        }
        .ql-toolbar {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-bottom: 1px solid #E9EFF5;
            display: none;
        }
        .ql-container {
            border: 0;
        }
    }
}

.steplet-guider {
    .custom-ql-editor-wrp {
        .custom-ql-editor-hide-toolbar {
                margin-bottom: 0;
                .ql-editor {
                    max-height: 100%;
                }
        }
    }
}
.ql-editor.ql-blank::before {
    font-style: normal !important;
}

.ql-mention-list-container .ql-mention-list {
    padding-top: 6px;
    padding-bottom: 6px;
    .ql-mention-list-item {
        font-size: 16px;
        line-height: 1;
        padding: 6px 12px;
    }
}

.ql-editor {
    font-family: $font-regular;
    span.mention {
        &[data-denotation-char='#'] {
            color: $orange-400;
            background-color: transparent;
            font-family: $font-medium;
        }
        &[data-denotation-char='@'] {
            &[data-id='admin-all'] {
                color: $orange-400 !important;
                background-color: #f9edd2 !important;
            }
            color: #3D4FA9;
            background-color: #D2E6F9;
            font-family: $font-medium;
        }
    }
}

.note-detail span.mention {
    &[data-denotation-char='@'] {
        &[data-id='admin-all'] {
            color: $orange-400 !important;
            background-color: #f9edd2 !important;
        }
        font-family: $font-medium;
    }
}