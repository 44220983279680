/**
 * Fix bootstrap responsive issue in safari
 */
#content {
  overflow-x: hidden;
}
body {
  font-family: $font-regular;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal !important;
}

.row:before,
.row:after {
  content: none;
}

/**
 * some core classes
 */
.por {
  position: relative;
}

span > svg {
  display: block !important;
}

.tac {
  text-align: center;
}

.tds-dashed {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px dotted #0094f3;
  }
}
.is-border-radius0  {
  border-radius: 0 !important;
}
.tdn {
  text-decoration: none !important;
}

.faic,
.aic {
  align-items: center;
}

.aici {
  align-items: center !important;
}

.aifs {
  align-items: flex-start;
}
.aifsi {
  align-items: flex-start !important;
}

.aife {
  align-items: flex-end;
}

.fsi {
  font-style: italic;
}

.fww {
  flex-wrap: wrap;
}

.aie {
  align-items: flex-end;
}

.aife {
  align-items: flex-end;
}

.ais {
  align-items: flex-start;
}

.df {
  display: flex !important;
}

.fb0 {
  flex-basis: 0 !important;
}
.dif {
  display: inline-flex !important;
}

.fg1 {
  flex-grow: 1 !important;
}

.fs0 {
  flex-shrink: 0 !important;
}

.db {
  display: block !important;
}

.jcsb {
  justify-content: space-between;
}

.jcsbi {
  justify-content: space-between !important;
}

.jcfs {
  justify-content: flex-start;
}

.jcfe {
  justify-content: flex-end;
}

.jcc {
  justify-content: center;
}

.fdc {
  flex-direction: column;
}

.fw {
  flex-wrap: wrap;
}

.ff-bold {
  font-family: $font-bold !important;
  font-weight: 400 !important;
}

.ff-semibold {
  font-family: $font-medium !important;
  font-weight: 400 !important;
}

.font-medium {
  font-family: $font-medium !important;
  font-weight: 400 !important;
}

.ff-reg {
  font-family: $font-regular !important;
  font-weight: 400 !important;
}

.ff-light {
  font-family: $font-light !important;
  font-weight: 400 !important;
}

.bg-lt-gray {
  background-color: #f9f9f9;
}

.wbbw {
  word-break: break-word;
}

.w40px {
  width: 40px;
}
.w47p {
  width: 47%;
}
.mb0 {
  margin-bottom: 0 !important;
}

.mtn01 {
  margin-top: -1px;
}
.mt0 {
  margin-top: 0 !important;
}
.mb02 {
  margin-bottom: 2px !important;
}
.mt01 {
  margin-top: 1px !important;
}
.mt015 {
  margin-top: 1.5px !important;
}
.mt02 {
  margin-top: 2px !important;
}
.mt03 {
  margin-top: 3px !important;
}
.mt04 {
  margin-top: 4px !important;
}
.mt05 {
  margin-top: 5px !important;
}

.mb1i {
  margin-bottom: 1px !important;
}

.mb2i {
  margin-bottom: 2px !important;
}

.mt--3 {
  margin-top: -3px !important;
}

.w100p {
  width: 100%;
}

.h100p {
  height: 100%;
}

.w100pi {
  width: 100% !important;
}

.w50pi {
  width: 50% !important;
}

.sc02 {
  transform: scale(0.2);
}

.sc04 {
  transform: scale(0.4);
}

.sc06 {
  transform: scale(0.6);
}

.sc08 {
  transform: scale(0.8);
}

.pb0i {
  padding-bottom: 0 !important;
}
.pb01 {
  padding-bottom: 1.5px !important;
}
.fw600 {
  font-weight: 600;
}

@for $i from 6 to 50 {
  .fz-#{$i} {
    font-size: #{$i + 2}px !important;
    line-height: 1.3;
  }
}

// @media(max-width: 1599px) {
//   @for $i from 6 to 50 {
//     .fz-#{$i} {
//       font-size: #{$i}px !important;
//       line-height: 1.3;
//     }
//   }
// }

@for $i from 6 to 50 {
  .fzs-#{$i} {
    font-size: #{$i}px !important;
  }
}
.mr0 {
  margin-right: 0 !important;
}
.mt--1 {
  margin-top: -1px;
}

.pb0i {
  padding-bottom: 0 !important;
}

.ml--2 {
  margin-left: 2px !important;
}


.w171px {
  width: 171px;
}
.tli {
  text-align: left !important;
}

.op-07 {
  opacity: 0.7;
}

.op-06 {
  opacity: 0.6;
}

.op-05 {
  opacity: 0.5;
}

.op-04 {
  opacity: 0.4;
}

.op-02 {
  opacity: 0.2;
}

button.nobd {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  cursor: pointer;
}

.nopd {
  padding: 0;
}

.lh1 {
  line-height: 1 !important;
}

.lh14 {
  line-height: 1.4 !important;
}
.lh16 {
  line-height: 1.6 !important;
}

.lh11 {
  line-height: 1.1 !important;
}
.lh12 {
  line-height: 1.2 !important;
}

.lh20 {
  line-height: 20px !important;
}

.pla-95A0B0 {
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #768091;
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #768091;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #768091;
  }
}
.fc-A5737C {
  color: #A5737C !important;
}
.fc-5B65DF {
  color: #5B65DF !important;
}
.fc-CCD6E2 {
  color: #ccd6e2 !important;
}
.fc-FFBD47 {
  color: #ffbd47 !important;
}
.fc-orange {
  color: #ff8521;
}
.fc-red2 {
  color: #ff4e59;
}
.fc-95A0B0 {
  color: #95a0b0 !important;
}
.fc-FF9A2D {
  color: #ff9a2d !important;
}

.fc-gr1 {
  color: $gray-text1 !important;
}

.fc-gr {
  color: $gray-text !important;
}

.fc-gr2 {
  color: #626262 !important;
}

.fc-gr3 {
  color: #777 !important;
}

.fc-gr4 {
  color: #4a4a4a !important;
}

.fc-gr5 {
  color: #7f7f7f !important;
}

.fc-gr5 {
  color: #4a4a4a !important;
}

.fc-gr6 {
  color: #c0c0c0 !important;
}

.fc-gr404040 {
  color: #404040 !important;
}

.fc-A8B6C7 {
  color: #a8b6c7 !important;
}

.fc-6B6B6B {
  color: #6b6b6b !important;
}

.fc-gr606060 {
  color: #606060 !important;
}

.fc-7d7d7d {
  color: #7d7d7d !important;
}

.fc-CCD6E2 {
  color: #ccd6e2 !important;
}

.fc-5AC9F1 {
  color: #5AC9F1 !important;
}

.fc-EC6254 {
  color: #EC6254 !important;
}

.fc-F6BF5E {
  color: #F6BF5E !important;
}

.fc-D36042 {
  color: #D36042 !important;
}

.fc-7979E6 {
  color: #7979E6 !important;
}

.fc-D1A41B {
  color: #D1A41B !important;
}

.fc-8ABE57 {
  color: #8ABE57 !important;
}

.fc-374050 {
  color: #374050 !important;
}
.fc-E0E8F0 {
  color: #e0e8f0 !important;
}

.fc-212D52 {
  color: #212d52 !important;
}

.fc-576071 {
  color: #576071 !important;
}

.fc-768091 {
  color: #768091 !important;
}

.fc-17202E {
  color: #17202e !important;
}

.fc-c2c2c2 {
  color: #c2c2c2 !important;
}

.fc-secondary-gray {
  color: #768091 !important;
}

.fc-00CBF6 {
  color: #00cbf6 !important;
}

.fc-0094F3 {
  color: $secondary-blue !important;
}

.fc-007BE2 {
  color: #007be2 !important;
}

.fc-white {
  color: #fff !important;
}

.fc-e52c40,
.fc-red {
  color: #e52c40 !important;
}

.fc-FF4E59 {
  color: #ff4e59 !important;
}

.fc-black {
  color: #000 !important;
}

.ls002e {
  letter-spacing: 0.02em;
}

.fc-659E48 {
  color: #659e48;
}

.fc-999 {
  color: #999;
}

.fc-808080 {
  color: #808080;
}

.fc-red {
  color: #ee1616 !important;
}

.fc-ff3822 {
  color: #ff3822;
}

.fc-F0AD4E {
  color: #f0ad4e !important;
}

.fc-blue {
  color: $primary-blue !important;
}

.fc-green {
  color: #88c04e !important;
}
.fc-blue-light {
  color: $secondary-blue !important;
}

.fc-charcoal-400 {
  color: #95a0b0 !important;
}

.fc-charcoal-500 {
  color: #768091 !important;
}

.fc-charcoal-600 {
  color: #576071 !important;
}

.fc-charcoal-700 {
  color: #374050 !important;
}

.fc-charcoal-800 {
  color: #17202e !important;
}

.fc-charcoal-900 {
  color: #071422 !important;
}

.fc-green {
  color: #88c04e !important;
}

.bd-bt-gr {
  border-bottom: 1px solid $gray-border;
}

.gray-border-box {
  border: 1px solid #ccd6e2;
  padding: 0.6875rem 1.25rem;
  border-radius: 4px;
}

.remove-left-radius {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.col-inner-wrp {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px $dark-gray-bg;
}

h4.col-title {
  display: inline-block;
  padding-left: 6px;
  font-family: $font-bold;
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 5px;
  line-height: 20px;
}

.round-button {
  background-color: $blue;
  border-color: $blue;
  color: #fff;
  min-width: 140px;
  height: 40px;
  border-radius: 40px;
  text-align: center;
  font-family: $font-bold;
  font-size: 15px;
  border: none;
  margin-top: 16px;
  margin-bottom: 16px;
  letter-spacing: -0.08px;
  line-height: 31px;
  cursor: pointer;

  &.second-button {
    background-color: #fff;
    color: $blue;
  }

  &.small {
    min-width: 90px;
    height: 36px;
    line-height: 1.4;
  }
}

.gray-divider {
  margin-bottom: 24px;
  margin-left: -24px;
  margin-right: -24px;
  height: 1px;
  background-color: #e9eff5;
}

.gray-dot-divider {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #a8b6c7;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  &.is-4px {
    width: 4px;
    height: 4px;
    margin-left: 0;
  }
}

.gray-btm-bd {
  border-bottom: 1px solid #e0e8f0;
}

.gray-bd-top {
  border-top: 1px solid #e0e8f0;
}
.gray-bd-btm {
  border-bottom: 1px solid #e0e8f0;
}
.gray-bd-btm-list {
  > div {
    border-bottom: 1px solid #e0e8f0;
    &:last-child {
      border-bottom: 0;
    }
  }
}

.tag-lbl {
  font-size: 14px;
  font-family: $font-bold;
  color: #fff;
  text-transform: uppercase;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.3);
  line-height: 1;
  display: inline-block;
}

.blue-anchor {
  font-size: 16px !important;
  font-family: $font-medium !important;
  color: $primary-blue !important;
  text-decoration: none !important;
  margin-top: 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

/**
 * BS override css for badges
 */

.badge-primary {
  background-color: #47acc9;
}

.badge-success {
  background-color: #7ac143;
}

.badge-danger {
  background-color: #d8534f;
}

.badge-warning {
  background-color: #ffc63d;
}

.round-player-wrp {
  background-color: #f6f6f6;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 115px;
  padding: 9px 12px;

  i {
    font-size: 23px;
    color: #979797;
  }

  span {
    line-height: 1 !important;
    padding-top: 5px;
  }
}

// end of BS4 badge override

.round-pane {
  padding: 18px 15px 0px 0px;
  border-radius: 5px;
  /*box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);*/
  margin-bottom: 20px;
  overflow: hidden;
}

.cr-po {
  cursor: pointer;
}

.Profile-Container {
  background-color: #e9eff5;
  min-height: 100vh;
  position: relative;

  /**
   * BS override css for buttons
   * * this will only work for engaged support mentee pages
   */

  .btn.btn-link {
    font-size: 16px;
    font-family: $font-medium;
    line-height: 18px;
    padding-left: 0;
    padding-right: 0;
    color: $primary-blue;
    user-select: none;
    text-decoration: none !important;
  }

  /*.Profile-Middle {*/

  .col-inner-wrp {
    h5 {
      font-size: 15px;
      line-height: 20px;
      font-family: $font-regular;
      margin-bottom: 14px;
    }

    h6 {
      font-size: 15px;
      line-height: 18px;
      color: $gray-text;
    }
  }

  /*}*/

  &.oncall-now {
    .tracks-survey-wrp .nav.nav-tabs {
      margin-top: 0 !important;
    }
  }

  &.es-mentee-profile {
    .steps-wrp
      .each-step
      .single-step-wrp
      .step-inner-content
      .steplet-content {
      background-color: transparent;
    }

    .steps-wrp
      .each-step
      .single-step-wrp
      .step-inner-content
      .step-actions-wrp
      .custom-react-select
      .custom__control {
      background-color: transparent !important;
    }

    .step-tabs-compo .step-tab-content {
      background-color: transparent;
    }

    .steps-wrp.steps-tab .each-step.step-wrp-steps-tab .step-inner-content {
      padding-left: 1.5625rem;
    }
  }
}

.modal-inner-wrp {
  height: 100%;
  // background-color: #fff;
  // box-shadow: 0px 2px 4px 0px $dark-gray-bg;
  // overflow-y: auto;

  .modal-inner-header {
    display: flex;
    justify-content: flex-start;
    padding-left: 40px;
    padding-right: 40px;
    height: 60px;
    flex-direction: row;
    align-items: flex-start;

    > .ff-bold {
      margin-left: 26px;
      margin-top: 5px;
    }

    i.fa.fa-arrow-left {
      cursor: pointer;
    }

    .nav.nav-tabs {
      border-bottom: none;
      display: flex;
      align-items: center;
      margin-left: 26px !important;

      .nav-item {
        width: auto;
        padding-right: 50px;
        padding-top: 3px;
      }
    }
  }

  .modal-inner-content {
    padding: 30px 40px;
    height: calc(100vh - 100px);
    overflow: scroll;

    .issues-wrp {
      background-color: #fff;

      .each-issue {
        background-color: #f6f6f6;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        margin-bottom: 16px;
        width: 600px;

        .each-active-track {
          background-color: #fff;
        }

        .track-actions {
          .left {
            justify-content: flex-start;
          }

          .right {
            justify-content: flex-start;
          }
        }
      }
    }

    .steps-wrp {
      .each-step {
        .single-step-wrp {
          width: 600px;
          margin-bottom: 16px;
          background-color: #f6f6f6;
        }
      }
    }

    .tracks-wrp {
      .each-track .step {
        margin-left: 5px;
      }

      .each-track .steps-wrp > p {
        margin-left: 5px;
      }

      .each-track {
        width: 600px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: #f6f6f6;

        .track-step-actions {
          margin-left: 5px;
        }

        .steps-wrp {
          .each-step {
            margin-left: 5px;

            .single-step-wrp {
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  .scroll-wrp {
    margin-inline: 0 !important;
    max-height: unset !important;
    padding: 0 !important;
  }
}

.alerts-col {
  .col-inner-wrp {
    background-color: transparent;

    .tracks-survey-wrp {
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0px 2px 4px 0px #e2e3e4;
      padding-bottom: 0;
      overflow-x: hidden;

      .talking-points-wrp {
        max-height: 200vh;
        overflow: auto;
        min-height: 150px;
      }

      .sidebar-compo .sidebar-header .nav.nav-tabs {
        width: 100%;
      }

      .lang-selector {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 0 !important;

        .selector-wrp {
          padding-bottom: 5px;
          margin-top: -12px;
        }
      }

      .listing-completed {
        .list-group-item {
          * {
            text-decoration: line-through;
          }

          i {
            text-decoration: none !important;
          }
        }
      }
    }
  }
}

#xeditable_select_popover {
  .form-control {
    border: 1px solid #dae2eb;
  }

  .editable-buttons.btn-toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;

    .btn-okay {
      margin-right: 16px;
      background-color: $primary-blue !important;
      padding: 6px 12px 8px 12px !important;
      border-radius: 4px !important;
      font-size: 16px;
      font-family: $font-medium;
      line-height: 1 !important;
    }

    .btn-cancel {
      font-family: $font-regular;
      font-size: 16px;
      color: #374050;
      padding: 6px 12px 8px 12px !important;
      line-height: 1 !important;
    }
  }
}

.editable-textfield-wrp {
  input {
    width: 100%;
    display: block;
    font-size: 15px;
    font-family: $font-regular;
  }

  .editable-buttons.btn-toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 8px;

    button {
      padding: 0;
      border: 0;
      margin-left: 5px;
      margin-right: 5px;
      border-radius: 50%;
      width: 30px;
      height: 30px;

      i {
        font-size: 20px;
      }
    }
  }
}

.react-bootstrap-table {
  border-radius: 5px;
  overflow: hidden;
  min-width: 1200px;
  width: 100%;

  .table {
    background-color: #fff;

    thead {
      background-color: #444;

      th {
        font-family: $font-bold;
        border: 0;
        font-size: 14px;
        color: #fff;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #d1d1d1;

        th {
          font-family: $font-bold;
          border: 0;
          font-size: 14px;
          color: #fff;
        }

        td {
          border: 0;
          font-size: 15px;
          font-family: $font-regular;

          // span {
          //   font-size: 15px;
          //   font-family: $font-regular;
          // }
          b {
            font-weight: normal;
            font-family: $font-bold;
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid #d1d1d1;

            th {
              font-family: $font-bold;
              border: 0;
              font-size: 14px;
              color: #fff;
            }

            td {
              border: 0;
              font-size: 15px;
              font-family: $font-regular;

              // span {
              //   font-size: 15px;
              //   font-family: $font-regular;
              // }
              b {
                font-weight: normal;
                font-family: $font-bold;
              }

              .tag.status-tag {
                font-size: 15px;
                font-family: $font-bold;
                border-right: 5px;
                line-height: 1;
                padding-top: 3px;
                padding-bottom: 3px;
                padding-left: 10px;
                padding-right: 10px;
                width: auto;
              }
            }

            > td:first-child {
              font-family: $font-bold;
            }

            .tag.status-tag {
              font-size: 13px;
              font-family: $font-bold;
              border-right: 5px;
              line-height: 1;
              padding-top: 3px;
              padding-bottom: 3px;
              padding-left: 10px;
              padding-right: 10px;
              width: auto;
            }
          }

          > td:first-child {
            font-family: $font-bold;
          }
        }
      }
    }
  }

  .react-bs-table-sizePerPage-dropdown {
    font-size: 15px;
    font-family: $font-bold;
  }

  .react-bootstrap-table-page-btns-ul.pagination {
    font-size: 15px;
    font-family: $font-bold;

    a {
      box-shadow: none;
    }
  }
}

.react-bs-table-container.custom-styled {
  @media (max-width: 1200px) {
    overflow-x: auto;
  }

  .react-bs-table {
    border-radius: 5px;
    overflow: hidden;
    min-width: 1200px;
  }

  .react-bs-table-bordered {
    border: 0 !important;
  }

  .react-bs-container-header,
  .react-bs-container-body {
    background-color: #fff;

    a {
      color: $dark-blue;
    }

    thead {
      background: #f6f9fc;
      border-radius: 4px;

      th {
        font-family: $font-medium;
        border: 0;
        font-size: 16px;
        color: #374050;

        div {
          display: inline-block;
        }

        input {
          font-size: 15px;
          font-family: $font-regular;
          line-height: 1;
          height: auto;
        }
      }

      th:last-child {
        text-align: center;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #e9eff5;

        td {
          border: 0;
          font-size: 16px;
          font-family: $font-regular;
          vertical-align: middle;
          color: #576071;

          // span {
          //   font-size: 15px;
          //   font-family: $font-regular;
          // }
          b {
            font-weight: normal;
            font-family: $font-medium;
          }

          .icon.icon-ellipse3 {
            font-size: 16px;
            transform: scale(0.4);
            margin-top: 2px;
          }

          .tag.status-tag {
            font-size: 15px;
            font-family: $font-medium;
            border-right: 5px;
            line-height: 1;
            padding-top: 5px;
            padding-bottom: 3px;
            padding-left: 10px;
            padding-right: 10px;
            width: auto;
            max-width: 130px;
          }

          input[type='text'],
          select {
            padding: 3px 5px 1px 5px;
            display: block;
            font-size: 14px;
            font-family: $font-regular;
            height: 22px;
            border-radius: 3px;
            border: 1px solid #ececec;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.015);
            outline: 0;
          }

          button {
            font-size: inherit;
            font-family: inherit;
            color: inherit;
            text-align: left;
            line-height: 1 !important;
          }
        }

        > td:first-child {
          font-family: $font-medium;
        }
      }
    }
  }

  .react-bs-table-pagination {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
}

.react-bootstrap-table-pagination.row {
  margin-left: 0;
  margin-right: 0;
}

.oncall-wrp {
  padding: 16px 30px;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  vertical-align: center;
  justify-content: space-between;

  .callinfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    i {
      font-size: 25px;
      color: #7ac143;
      margin-right: 24px;
    }

    > span {
      line-height: 1 !important;
      position: relative;

      .vertical-divider {
        width: 1px;
        background-color: #ebebeb;
        position: absolute;
        right: -19px;
        bottom: -7px;
        top: -29px;
      }
    }
  }

  .btns-wrp {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .schedule-btn {
      font-size: 15px;
      font-family: $font-bold;
      color: $primary-blue;
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 14px;
    }

    .round-button {
      background-color: #ff2b19;
      padding: 11px 20px 9px 11px;
      border-radius: 40px;
      color: #fff;
      font-size: 15px;
      font-family: $font-bold;
      line-height: 1;
      margin: 0;
    }
  }
}

/**
 * Bootstrap table custom styles
 * will be moved to separated file when outreach and es mentee profile branches merged
 */

.react-bs-table-container.border-bottom-table {
  .react-bs-table {
    border: 0;

    .table {
      border: 0;

      thead {
        tr {
          border-bottom: 1px solid #ebebeb;

          th {
            font-size: 15px;
            font-family: $font-medium;
            border: 0;
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid #ebebeb;

          td {
            border: 0;
            font-size: 15px;
            font-family: $font-regular;
          }
        }
      }
    }
  }

  .react-bs-table-sizePerPage-dropdown {
    font-size: 15px;
    font-family: $font-medium;
    line-height: 1;

    * {
      font-size: 15px;
      font-family: $font-medium;
      line-height: 1;
    }
  }

  .react-bootstrap-table-page-btns-ul.pagination {
    font-size: 15px;
    font-family: $font-medium;
    line-height: 1;

    * {
      font-size: 15px;
      font-family: $font-medium;
      line-height: 1;
    }

    a {
      box-shadow: none;
    }
  }
}

.table.custom-styled {
  background-color: #fff;

  a {
    color: $dark-blue;
  }

  thead {
    background: #f6f9fc;
    border-radius: 4px;

    th {
      font-family: $font-medium;
      border: 0;
      font-size: 16px;
      color: #374050;

      div {
        display: inline-block;
      }

      input {
        font-size: 15px;
        font-family: $font-regular;
        line-height: 1;
        height: auto;
      }
    }

    th:last-child {
      text-align: center;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #e9eff5;

      td {
        border: 0;
        font-size: 16px;
        font-family: $font-regular;
        vertical-align: middle;
        color: #576071;

        // span {
        //   font-size: 15px;
        //   font-family: $font-regular;
        // }
        b {
          font-weight: normal;
          font-family: $font-medium;
        }

        .icon.icon-ellipse3 {
          font-size: 16px;
          transform: scale(0.4);
          margin-top: 2px;
        }

        .tag.status-tag {
          font-size: 15px;
          font-family: $font-medium;
          border-right: 5px;
          line-height: 1;
          padding-top: 5px;
          padding-bottom: 3px;
          padding-left: 10px;
          padding-right: 10px;
          width: auto;
          max-width: 130px;
        }

        input[type='text'],
        select {
          padding: 3px 5px 1px 5px;
          display: block;
          font-size: 14px;
          font-family: $font-regular;
          height: 22px;
          border-radius: 3px;
          border: 1px solid #ececec;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.015);
          outline: 0;
        }

        // button {
        //   font-size: inherit;
        //   font-family: inherit;
        //   color: inherit;
        //   text-align: left;
        //   line-height: 1 !important;
        // }
      }

      > td:first-child {
        font-family: $font-medium;
      }
    }
  }

  &.white-head {
    thead {
      background-color: #fff;

      th {
        font-family: $font-medium;
        border: 0;
        font-size: 14px;
        color: #000;

        div {
          display: inline-block;
        }

        input {
          font-size: 15px;
          font-family: $font-regular;
          line-height: 1;
          height: auto;
        }
      }

      th:last-child {
        text-align: left !important;
      }
    }

    tbody {
      tr a {
        color: #000;
        text-decoration: none;
      }
    }
  }

  &.normal-font-first-col {
    tbody tr > td:first-child {
      font-family: $font-regular;
    }
  }

  &.no-padding {
    tr td {
      padding-left: 0 !important;
    }
  }

  &.remove-last-border {
    tbody {
      >tr:last-child {
        border-bottom: 0;
      }
    }
  }

  &.vertical-align-top {
    tbody {
      tr {
        td {
          vertical-align: top !important;
        }
      }
    }
  }
}

.es-quick-schedule-toolbar {
  .btn-toolbar {
    justify-content: center;
    margin-bottom: 15px;
  }

  .upcoming-call-compo .editable-click.btn-link {
    font-size: 15px;
    padding: 0;
    border: 0;
    text-decoration: none;
  }
}

.react-confirm-alert-body {
  padding: 24px !important;
  color: #576071 !important;
  font-size: 16px !important;
  font-family: $font-regular !important;
  background: #ffffff !important;
  box-shadow: 0px 2px 12px rgba(23, 32, 46, 0.1) !important;
  border-radius: 4px !important;

  h1 {
    font-size: 24px !important;
    font-family: $font-medium !important;
    color: #374050 !important;
    margin-bottom: 4px !important;
  }

  h1.sequence-overlap {
    color: #d9534f !important;
  }

  .react-confirm-alert-button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-left: 12px;
      margin-right: 0;
      font-size: 16px;
      font-family: $font-medium;
      padding: 3px 10px;
      border-radius: 4px;
      text-align: center;
      background: transparent;
      color: #768091;
      border: 1px solid #ccd6e2 !important;
    }

    button:last-child {
    }

    button:first-child {
      color: #768091;
      border: 1px solid #ccd6e2 !important;
      background-color: #fff;
    }
  }
}


.selector-wrp.custom-styled {
  position: relative;
  border: 1px solid #dae2eb;
  border-radius: 4px;

  &::after {
    position: absolute;
    right: 10px;
    top: 43%;
    content: '';
    pointer-events: none;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    display: inline-block;
    height: 0;
    width: 0;
  }

  select {
    background: transparent;
    outline: none !important;
    cursor: pointer;
    appearance: none;
    position: relative;
    width: 100%;
    background-color: transparent;
    border: 0;
    font-size: 14px;
    margin: 0 9px;
    font-family: $font-regular;
    height: 32px;
  }
}

.search-input.search-input-float {
  width: 100%;

  input {
    width: 100%;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    height: 32px;
    box-shadow: 0;
    background-color: transparent;
    font-size: 14px;
    font-family: $font-regular;
    padding: 0 9px;
    outline: 0;
  }
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 130px;
}

.buttons-group.btn-group {
  button {
    font-size: 14px;
    font-family: $font-regular;
    padding: 5px 6px 3px 6px;
    line-height: 1;
  }
}

.styled.nav.nav-tabs {
  a {
    font-size: 15px;
    font-family: $font-regular;
    color: #000;
  }
}

.styled.nav.nav-tabs + .tab-content {
  padding-top: 0;

  .tab-pane {
    // border: 1px solid #dee2e6;
    border-top: 0;
    // border-top-left-radius: 0;
    // border-top-right-radius: 0;
    padding: 30px 15px;
  }
}

.xedit-btn-with-icon-wrp {
  position: relative;
  display: inline-block;

  button {
    z-index: 2;
    padding-right: 12px !important;
  }

  i {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.no-content-gray-box {
  padding: 10px;
  background-color: #f3f3f3;
  font-size: 14px;
  font-family: $font-regular;
  border-radius: 3px;
  margin-top: 8px;
  margin-bottom: 8px;
  display: block;
}

.gray-box {
  background: #f6f9fc;
  border-radius: 8px;
  padding: 8px 16px;

  .inner-wrp {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .each-part {
      width: 25%;
    }
  }

  &.bd-only {
    background-color: transparent;
    border: 1px solid #e9eff5;
  }
  &.is-border-radius12 {
    border-radius: 12px;
  }
}

/*
.gray-box.with-border {
  background: #F6F9FC;
  border-radius: 4px;
  padding: 12px;
  border: 1px solid #E9EFF5;
  &.highlight {
    animation: fadeout 2s ease-in;
  }
}
*/
@keyframes fadeout {
  from {
    background: rgba(255, 204, 108, 0.05);
    border-color: rgba(255, 189, 71, 0.15);
    color: #c8912f;
  }

  to {
    background: #f6f9fc;
    border-color: #e9eff5;
  }
}

.short-bar {
  display: block;
  width: 100%;
  height: 3px;
  position: relative;
  margin-top: 8px;
  margin-bottom: 21px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 36px;
    height: 3px;
    background-color: #c2c2c2;
    opacity: 0.4;
  }
}

.thin-bar {
  height: 1px;
  background-color: #e6e6e6;
  margin-left: -24px;
  margin-right: -24px;
}

.collapsed .thin-bar {
  margin-left: -7px;
  margin-right: -7px;
  background-color: #fff;
  opacity: 0.1;
}

.green-select {
  width: 200px;

  .Select-control {
    border: 0;
  }

  .Select-placeholder {
    background-color: $primary-blue;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 0;

    i {
      font-size: 20px;
      color: #fff;
    }
  }

  .Select-arrow-zone {
    width: 36px;
    border-left: 1px solid #fff;
    opacity: 0.5;

    span.Select-arrow {
      border-color: #fff transparent transparent;
    }
  }
}

.with-new-red {
  position: relative;

  &:after {
    position: absolute;
    right: 0;
    top: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #ff6623;
    content: '';
  }
}

.with-new-red-8 {
  position: relative;

  // &:after {
  //   position: absolute;
  //   right: -2px;
  //   top: -2px;
  //   width: 8px;
  //   height: 8px;
  //   border-radius: 50%;
  //   background-color: #FF6623;
  //   content: '';
  // }
}

.table thead tr th {
  label input.form-control {
    height: auto;
    width: 120px;
  }
}

.selector-wrp {
  position: relative;

  .icon.icon-caret-down-solid {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    pointer-events: none;
  }

  // &::after {
  //   position: absolute;
  //   right: 0;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   background-image: url(../new-theme/images/down_arrow.svg);
  //   width: 12px;
  //   height: 6px;
  //   background-position: center center;
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   content: '';
  //   pointer-events: none;
  // }
}

select.custom-styled {
  border: none;
  background: transparent;
  // width: 70px;
  display: inline-block;
  outline: none !important;
  font-size: 16px;
  cursor: pointer;
  appearance: none;
  position: relative;
  color: #768091 !important;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #ccd6e2 !important;
  border-radius: 4px;
  min-width: 80px;
  height: 32px;
  padding-top: 4px;
  padding-bottom: 4px;
  outline: 0 !important;
  box-shadow: none !important;
  line-height: 1;

  &.white-text {
    color: #fff;
  }
}

.yellow-box {
  border: 1px dashed #d6c06c;
  background-color: #fffdf0;
  border-radius: 5px;
  padding: 8px 10px;
  line-height: 1.2;
  margin-bottom: 8px;
  margin-top: 8px;
  white-space: normal;
  display: block;
  width: 100%;

  b {
    font-weight: 400 !important;
    font-family: $font-medium;
  }
}

.btn-with-eclipse {
  padding-left: 13px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #3db9ff;
    font-size: 11px;
  }
}

.btn-primary {
  background-color: $primary-blue;
  border-color: $primary-blue;
}

.fz-12.lh15i {
  line-height: 15px !important;
}
.fz-14-5 {
  .fz-14 {
    font-size: 17px !important;
    line-height: 1 !important;
  }
  svg {
    margin-top: 1.5px;
  }
}

.embed-audio-dropdown {
  line-height: 1;

  .dropdown-toggle {
    background-color: transparent !important;
    color: #95a0b0 !important;
    border: 0 !important;
    padding: 0 !important;
    width: 40px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    i.icon-three-dots-horizontal {
      transform: scale(0.4);
      font-size: 10px !important;
      width: 40px !important;
    }

    &:after {
      content: none !important;
    }
  }

  .dropdown-menu.show {
    background: #ffffff;
    border: 0 !important;
    box-shadow: 0px 4px 12px rgba(23, 32, 46, 0.1);
    border-radius: 4px;
    position: fixed !important;

    .dropdown-item {
      font-size: 16px;
      font-family: $font-regular;
      color: #374050;
      line-height: 1.4;
    }
  }

  .dropdown-item {
    cursor: pointer;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #374050;
    text-decoration: none;
    background-color: transparent;
  }
}

.row > .right-bd {
  border-right: 1px solid #e9eff5;
}

.dot-status {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  font-family: $font-medium;
  line-height: 1;

  .icon.icon-ellipse3 {
    transform: scale(0.375);
    margin-right: 0;
    margin-top: 0px;
  }
}

.blue-text-btn {
  border: 0 !important;
  background-color: transparent !important;
  color: $secondary-blue !important;
  padding: 0 !important;

  &:disabled,
  &[disabled] {
    color: #95a0b0 !important;
    opacity: 1 !important;
  }

  &.with-left-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 15px;
      transform: scale(0.5);
      margin-right: 2px;
    }
  }
}

.checkmark {
  display: flex;
  width: 24px;
  height: 24px;
  margin-top: -2px;
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.remove-row-padding {
  .row {
    margin-left: 0;
    margin-right: 0;

    > div {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.es-mentor-view-btn {
  background-color: transparent !important;
  color: $primary-blue !important;
  font-size: 16px !important;
  font-family: $font-medium !important;
  border: 0 !important;
  margin-right: 30px;
  padding: 0 !important;

  > span {
    white-space: nowrap;
  }

  i {
    font-size: 16px;
    margin-right: 12px;
  }
}

.custom-dropdown.dropdown-toggle {
  padding-right: 0 !important;

  &:after {
    content: none !important;
  }

  &[aria-expanded='true'] {
    i.icon-thick-down-caret {
      transform: rotate(180deg);
    }
  }
}

.general-center-modal {
  width: 480px;
  background-color: #fff;
  padding: 28px 24px;
  background: #ffffff;
  border-radius: 16px;
}

.full-width-btn {
  width: 100%;
  display: block;
  background-color: $secondary-blue;
  color: #fff;
  font-size: 16px;
  font-family: $font-medium;
  border-radius: 4px;
  border: 0;
  box-shadow: none !important;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.is-border-radius8 {
    border-radius: 8px;
  }
}

.w-right-sidebar {
  width: 100%;
  display: flex;

  .main-col {
    width: calc(100% - 400px);
  }

  &.sidebar-hidden .main-col {
    width: 100%;
  }

  .sidebar-col {
    width: 400px;
    border-left: 1px solid #e9eff5;
  }
}

.custom-file-upload {
  border: 1px solid #dae2eb;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;

  label {
    flex-grow: 1;
    padding: 12px 18px;
    margin-bottom: 0;
    // width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span.fz-12 {
      min-height: 16px;
    }
  }

  > i.icon {
    padding: 0 18px;
  }

  input[type='file'] {
    display: none;
  }
}

.filepond--wrapper {
  .filepond--credits {
    display: none;
  }
  .filepond--root {
    border: 1px solid #dae2eb;
    border-radius: 4px;

    * {
      background-color: transparent;
      font-size: 14px;
      font-family: $font-regular;
    }

    .filepond--file {
      background-color: #768091;
      .filepond--file-info-main {
        font-size: unset !important;
      }
    }

    .filepond--file-action-button.filepond--action-remove-item,
    .filepond--file-action-button.filepond--action-abort-item-load {
      span {
        display: none;
      }
    }
  }
}

.gray-bd-box {
  border: 1px solid #e9eff5;
  border-radius: 4px;
  padding: 18px 20px;
  &.is-red {
    border-color: #fdeced;
  }
  &.is-blue {
    border-color: $primary-blue;
  }
  &.is-round {
    border: 1px solid #e0e8f0;
    border-radius: 8px;
  }

  &.is-border-dark {
    border: 1px solid #a8b6c7;
  }
  &.is-padding16 {
    padding: 16px;
  }
  &.is-padding8-14 {
    padding: 8px 14px;
  }
  &.is-padding16-14 {
    padding: 16px 14px;
  }

  &.is-padding14 {
    padding: 14px;
  }

  &.is-padding12 {
    padding: 12px;
  }
  &.is-padding10-20 {
    padding: 10px 20px;
  }
  &.is-padding14-24 {
    padding: 14px 24px;
  }
  &.is-padding16-20 {
    padding: 16px 20px;
  }

  &.is-padding14-20 {
    padding: 14px 20px;
  }
  &.is-padding11-20 {
    padding: 11px 20px;
  }
  &.is-padding12-16 {
    padding: 12px 16px;
  }
  &.is-padding16-32 {
    padding: 16px 32px;
  }
  &.is-border-radius8 {
    border-radius: 8px;
  }
  &.is-border-radius12 {
    border-radius: 12px !important;
  }
  &.is-border-radius16 {
    border-radius: 16px;
  }
  &.is-selected {
    background-color: #f9fcfe;
  }
  &.inline {
    display: inline-block;
    width: 360px;
  }
  &.is-border-light {
    border: 1px solid #e9eff5;
  }
  &.is-border-dark {
    border: 1px solid #a8b6c7;
  }
  &.is-blue-border {
    border-color: #0094f3;
  }
  &.is-width200 {
    width: 200px;
  }
  &.is-selected-blue {
    background-color: $secondary-blue !important;
    border-color: $secondary-blue !important;
    * {
      color: #fff !important;
    }
    .ff-reg {
      font-family: $font-medium !important;
    }
    .loading-gradient {
      color: transparent !important;
    }
  }
  &.is-white {
    background-color: #fff;
  }
  .row {
    margin-left: -8px;
    margin-right: -8px;
    > div {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  &.is-orange-border {
    border: 1px solid #ff9a2d;
  }
}

.selectable-round-box {
  border-radius: 8px;
  padding: 16px;
  &.is-overflown {
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  &.is-selected {
    background-color: #f6f9fc;
  }
}

.is-padding16 {
  padding: 16px;
}

.is-padding12 {
  padding: 12px;
}

.is-padding14-24 {
  padding: 14px 24px;
}

.is-padding14-20 {
  padding: 14px 20px;
}

.is-padding12-16 {
  padding: 12px 16px;
}

.is-border-radius8 {
  border-radius: 8px;
}
#content .btn.btn-warning {
  color: #fff;
  background-color: #ffbd47;
}

.modal-close-btn {
  padding: 0;
  line-height: 0;
  color: #768091;
  margin: 0;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}
.modal-inner-wrp {
  .por {
    .modal-close-btn {
      top: 50%;
      right: 0;
      padding: 0;
    }
  }
}
.terms-modal {
  .MuiPaper-root {
    // width: 600px;
    overflow-x: hidden;
    .MuiPaper-root {
      padding: 0 24px !important;
    }
    .MuiDialogContent-root {
      padding: 0 24px !important;
      // margin-right: -24px !important;
    }
  }
  .modal-close-btn {
    position: absolute;
    right: 0;
    top: 15px;
    padding: 8px 24px;
    border: 0;
    margin: 0;
    line-height: 1 !important;
    background-color: transparent;
    z-index: 1001;

    i {
      font-size: 12px;
      color: #768091;
    }
  }
}
.es-modal {
  .modal-dialog {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translateX(-50%) translateY(-50%) !important;
    margin-top: 0 !important;
  }

  .modal-content {
    border-radius: 8px;
  }

  .modal-header {
    padding: 20px 20px 16px 20px;
    border-bottom: 0;
    position: relative;

    .close {
      padding: 0;
      line-height: 0;
      color: #768091;
      margin: 0;
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .modal-title {
    font-size: 20px;
    font-family: $font-medium;
    color: #374050;
  }

  .modal-body {
    padding: 0 24px 24px 24px;
    border-bottom: 0;

    .custom-react-select {
      .custom__control {
        border: 1px solid #0094f3;
      }
    }
  }

  .modal-footer {
    padding: 0 20px 20px 20px;
    border-top: 0;

    .btn.btn-primary {
      outline: 0;
      box-shadow: none;
      line-height: 1;
      background-color: $secondary-blue;
      border-color: $secondary-blue;
      color: #fff;
      border-radius: 8px;
      padding-left: 12px;
      padding-right: 12px;
      font-size: 16px;
      font-family: $font-regular;
      box-shadow: none !important;
    }

    .btn.btn-primary.gray-text-btn {
      color: #95a0b0 !important;
    }
  }
}

.badge.badge-pill {
  border-radius: 16px;
  font-size: 14px;
  padding: 3px 8px 4px 8px;
  font-family: $font-medium;
  letter-spacing: 0;
  color: #fff;
  flex-shrink: 0;
  letter-spacing: -0.4px;
  background-color: #fff6e0;
  font-weight: 400 !important;
  line-height: 1 !important;

  &.is-filter {
    background-color: #fff6e0;
    color: #f59300;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1 !important;
    padding: 2px 8px 4px 8px;
    border-radius: 16px;

    .icon-times-btb {
      margin-top: 2px;
      font-size: 16px;
      transform: scale(0.6);
      color: #ffcc6c;
    }
  }

  &.badge-warning {
    background-color: #ffaa00;
  }

  &.badge-primary {
    background-color: #0094f3;
  }

  &.badge-success {
    background-color: #9bce63;
  }

  &.is-outline {
    &.badge-warning {
      border: 1px solid #ffaa00;
      border-radius: 6px;
      color: #ffaa00;
      background-color: transparent;
    }

    &.badge-primary {
      border: 1px solid #0094f3;
      border-radius: 6px;
      color: #0094f3;
      background-color: transparent;
    }

    &.badge-success {
      border: 1px solid #9bce63;
      border-radius: 6px;
      color: #9bce63;
      background-color: transparent;
    }
  }

  &.circle-count {
    height: 24px;
    text-align: center;
    border-radius: 12px;
    font-size: 14px;
    line-height: 1;
    font-family: $font-medium;
    min-width: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 6px;
    letter-spacing: 0 !important;

    &.is-gray {
      color: #a8b6c7 !important;
      background-color: #f6f9fc !important;
    }

    /*background-color: #F6F9FC;
    color: #95A0B0; */

    &.is-red {
      color: #fdeced !important;
      background-color: #ff4e59;
    }
    &.is-blue {
      background-color: #ebf6ff;
      color: $secondary-blue;
    }
  }
}

.gray-datepicker-wrp {
  input {
    border: 1px solid #e9eff5 !important;
    border-radius: 4px;
  }

  &.is-full-width {
    input {
      width: 100%;
      display: block;
    }
  }
  &.dp-empty-button {
    .react-datepicker-wrapper {
      border: 0;
      .react-datepicker__input-container {
        input {
          background-color: #e9eff5;
          color: #374050;
          cursor: pointer;
          font-size: 16px !important;
          font-family: $font-medium !important;
          text-align: center;
          width: 96px;
          caret-color: transparent;
        }
        &:after {
          content: none;
        }
      }
    }
  }
  &.dp-text-only {
    .react-datepicker-wrapper {
      border: 0;
      .react-datepicker__input-container {
        input {
          color: #374050;
          cursor: pointer;
          font-size: 16px !important;
          font-family: $font-medium !important;
          text-align: center;
          caret-color: transparent;
          padding: 0;
          border: 0 !important;
          outline: none !important;
          width: 120px;
        }
        &:after {
          content: none;
        }
      }
    }
  }
}

.btm-btns-wrp {
  border-top: 1px solid #e0e8f0;
  margin-left: -24px;
  margin-right: -24px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
}

.Toastify__toast-container.Toastify__toast-container--top-right {
  .Toastify__toast {
    background: #ffffff;
    box-shadow: 0px 8px 24px rgba(23, 32, 46, 0.16);
    // box-shadow: 0px 4px 12px rgba(23, 32, 46, 0.1);
    border-radius: 8px;
    padding: 16px 16px 30px 48px;
    position: relative;
    min-height: unset;

    .Toastify__close-button {
      color: #768091;
    }

    .Toastify__progress-bar {
      background-color: #0094f3;
      left: 48px;
      right: 20px;
      width: auto;
      height: 2px;
      bottom: 18px;
      border-radius: 20px;
    }
    .icon-times-btb {
      color: #ccd6e2;
      font-size: 10px;
      position: absolute;
      top: 22px;
      right: 20px;
    }
    &:before {
      content: '';
      position: absolute;
      left: 48px;
      right: 20px;
      width: auto;
      height: 2px;
      bottom: 18px;
      border-radius: 20px;
      background-color: #e9eff5;
    }

    // &:before {
    //   content: none;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 4px;
    //   height: 100%;
    // }

    &:after {
      content: '';
      background-image: url(../new-theme/images/toast_success.svg);
      position: absolute;
      width: 24px;
      height: 24px;
      top: 17px;
      left: 16px;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .Toastify__close-button {
    }

    .Toastify__toast-body {
      color: #374050;
      font-size: 16px;
      font-family: $font-regular;
      line-height: 16px;
      padding: 0;
      margin: 0;
    }
    .Toastify__toast-icon.Toastify--animate-icon {
      display: none;
    }
    &.Toastify__toast--success {
      // &:before {
      //   background-color: #07bc0c;
      // }

      .Toastify__progress-bar {
        background-color: #07bc0c;
      }
    }

    &.Toastify__toast--error {
      // &:before {
      //   background-color: #e74c3c;
      // }

      .Toastify__progress-bar {
        background-color: #e74c3c;
      }

      &:after {
        background-image: url(../new-theme/images/toast_error.svg);
      }
    }

    &.Toastify__toast--info {
      // &:before {
      //   background-color: #e74c3c;
      // }

      .Toastify__progress-bar {
        background-color: #0094f3;
      }

      &:after {
        background-image: url(../new-theme/images/toast_info.svg);
      }
    }

    &.Toastify__toast--warning {
      // &:before {
      //   background-color: #f1c40f;
      // }

      .Toastify__progress-bar {
        background-color: #f1c40f;
      }

      &:after {
        background-image: url(../new-theme/images/toast_warning.svg);
      }
    }
  }
}

.alerts-overflow {
  height: calc(50vh - 160px);
}

.gray-bubble {
  color: #95a0b0;
  background-color: #f6f9fc;
  padding: 2px 12px;
  border-radius: 20px;
}

.cms-list-item.list-group-item {
  .input-group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .input-group-prepend {
      margin-right: 10px;

      span {
        border-radius: 4px !important;
      }
    }

    .input-group-append {
      margin-left: 10px;

      button {
        border-radius: 4px !important;
      }
    }

    .custom-react-select {
      margin-right: 10px;

      .custom__control {
        border-radius: 4px;
      }
    }
  }
}

.preview-note {
  .each-note {
    border-bottom: 1px solid #e9eff5 !important;
    display: block;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;

    .badge {
      font-size: 14px;
      font-family: $font-medium;
      border-radius: 4px;
      padding: 3px 10px 4px 10px;
      color: #fff;
      border: 0 !important;
      font-weight: 400;
      margin-right: 10px;
      &.badge-primary {
        background-color: #7ac143;
      }
      &.badge-success {
        background-color: $secondary-blue;
      }
      &.badge-warning {
        background-color: #9e3dff;
      }
    }
  }
}

ul.custom-bullets {
  list-style: none !important;
  padding-left: 0 !important;
  li {
    list-style: none !important;
    position: relative;
    padding-left: 12px;
    font-size: 16px;
    line-height: 1.2;
    color: #576071;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 8px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #768091;
    }
  }
}

.g-circle-count {
  height: 24px;
  min-width: 24px;
  padding-right: 6px;
  padding-left: 6px;
  font-size: 14px;
  border-radius: 12px;
  font-family: $font-medium;
  background-color: #f6f9fc;
  color: #a8b6c7;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &.is-valid {
    background-color: #ebf6ff;
    color: #0094f3;
  }
  &.is-small {
    height: 20px;
    font-size: 13px;
    padding-left: 4px;
    padding-right: 4px;
  }
}

.dot-divided-data {
  > span {
    max-width: 100%;
  }
  .gray-dot-divider {
    margin-top: 2px;
  }
}

.no-label-in-col {
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid rgb(224, 232, 240);
  border-bottom: 1px solid rgb(224, 232, 240);
}

.gray-line-divider {
  margin-left: 1rem;
  margin-right: 1rem;
  width: 1px;
  background-color: #95a0b0;
  display: block;
  &.mr-3 {
    margin-right: -12px;
  }
  &.ml-0 {
    margin-left: 0;
  }
}

.icon-wrp-mt--2 {
  svg {
    margin-top: -2px;
  }
}

.icon-wrp {
  &.w-12 {
    svg {
      width: 12px;
      height: 12px;
    }
  }
  &.w-13 {
    svg {
      width: 13px;
      height: 13px;
    }
  }
  &.w-14 {
    svg {
      width: 14px;
      height: 14px;
    }
  }
  &.w-15 {
    svg {
      width: 15px;
      height: 15px;
    }
  }
}
.icon-size-16 {
  svg {
    width: 18px;
    height: 18px;
  }
}


.white-bg-box {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 10px 20px;
}

.hover-gray {
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #f9f9f9;
  }
}

.pos-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-10 {
  top: 10px;
}
.icon-thick-down-caret {
  transition: all ease 0.3s;
  &[aria-reversed='true'] {
    transform: rotate(-180deg);
  }
}
