.cms-wrapper {
  .btn {
    padding: 8px 16px;
    border-radius: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    i {
      margin-right: 6px;
    }
  }

  .btn.btn-primary {
    padding: 8px 16px;
    border-radius: 32px;
    background-color: $color-primary-500;
    border-color: $color-primary-500;
    color: #fff;
    transition: all 0.3s ease;
    font-size: 16px;
    font-family: $font-medium;
    min-width: 60px;

    &.is-small {
      font-size: 14px;
      padding: 6px 12px;
    }

    &.is-disabled {
      pointer-events: none;
      background-color: #DAE2EB;
      border-color: #DAE2EB;
      color: #fff;
      opacity: 1;
    }
  }

  .btn.btn-link {
    color: $color-primary-500;
    font-family: $font-medium;
    font-size: 16px;
    text-decoration: none !important;

    &:disabled {
      color: $color-basic-600;
    }

    &.is-small {
      font-size: 14px;
      padding: 6px 12px;
    }
  }

  .btn.btn-secondary {
    padding: 8px 16px;
    border-radius: 32px;
    background-color: $color-success-500;
    border-color: $color-success-500;
    color: #fff;
    transition: all 0.3s ease;
    font-size: 16px;
    font-family: $font-medium;
    min-width: 60px;

    &.is-small {
      font-size: 14px;
      padding: 6px 12px;
    }
  }

  .btn.btn-outline-secondary {
    font-size: 16px;
    color: $color-success-500;
    border: 1px solid $color-success-500;
    padding: 8px 16px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;

    >span {
      margin-left: 10px;
    }

    &.is-small {
      font-size: 14px;
      padding: 6px 12px;
      min-width: 80px;
    }

    &:hover {
      background-color: rgb(235, 252, 248);
    }
  }

  .btn.btn-outline-primary {
    font-size: 16px;
    color: $color-primary-500;
    border: 1px solid $color-primary-500;
    padding: 8px 16px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;

    >span {
      margin-left: 10px;
    }

    &.is-small {
      font-size: 14px;
      padding: 6px 12px;
      min-width: 80px;
    }

    &:hover {
      background-color: rgb(231, 241, 254);
    }
  }

  .btn.btn-outline-success {
    font-size: 16px;
    color: $color-success-500;
    border: 1px solid $color-success-500;
    padding: 8px 16px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;

    >span {
      margin-left: 10px;
    }

    &.is-small {
      font-size: 14px;
      padding: 6px 12px;
      min-width: 80px;
    }

    &:hover {
      background-color: rgb(235, 252, 246);
    }
  }

  .btn.btn-success {
    font-size: 16px;
    color: $color-success-500;
    border: 1px solid $color-success-500;
    padding: 8px 16px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    background-color: transparent;
    ;

    &.is-small {
      font-size: 14px;
      padding: 6px 12px;
      min-width: 80px;
    }

    &:hover {
      background-color: rgb(235, 252, 246);
    }
  }

  .btn.btn-danger {
    font-size: 16px;
    color: $color-danger-500;
    border: 1px solid $color-danger-500;
    padding: 8px 16px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    background-color: transparent;
    ;

    &.is-small {
      font-size: 14px;
      padding: 6px 12px;
      min-width: 80px;
    }

    &:hover {
      background-color: rgb(255, 239, 244);
    }
  }

  .search-input {
    input {
      border: 1px solid $color-basic-400;
      padding: 12px;
      border-radius: 4px;
    }
  }

  .icon-size-34 {
    svg {
      width: 34px;
      height: 34px;
    }
  }

  .cms-form-compo {
    max-width: 800px;
    padding: 30px;
    border-radius: 4px;
    border: 1px solid $color-basic-400;
    background-color: #fff;
    form > div:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .form-group {
    .form-label {
      color: $color-basic-600;
      font-size: 16px;
    }
  }

  .list-group-item.active {
    background-color: $color-primary-500;
    border-color: $color-primary-500;
  }

  .custom-checkbox input[type='checkbox']:checked+.checkbox-lbl .custom-checkbox-mark {
    background-color: $color-primary-500;
  }

  .custom-checkbox.square .custom-checkbox-mark {
    border-color: $color-primary-500;
  }

  .MuiFormControl-root.MuiTextField-root .MuiInputBase-root{
    min-height: 32px;
    border: 1px solid #ccd6e2;
    border-radius: 4px !important;
    background-color: #fff;
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
}