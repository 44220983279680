// icons
.ic{
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: inline-block;
    line-height: 1;
    position: relative;
    top: -1px;
    vertical-align: middle;
}
.ic.__filter-age-inactive {
    background-image: url(../new-theme/images/icons/filter-age-inactive.png);
    height: 43px;
    width: 59px;
}
.ic.__filter-ethnicity-inactive {
    background-image: url(../new-theme/images/icons/filter-ethnicity-inactive.png);
    height: 46px;
    width: 46px;
}
.ic.__filter-gender-inactive {
    background-image: url(../new-theme/images/icons/filter-gender-inactive.png);
    height: 45px;
    width: 45px;
}
.ic.__filter-currentTreatments-inactive {
    background-image: url(../new-theme/images/icons/filter-currentTreatments-inactive.png);
    height: 45px;
    width: 45px;
}
.ic.__filter-languages-inactive {
    background-image: url(../new-theme/images/icons/filter-languages-inactive.png);
    height: 48px;
    width: 52px;
}
.ic.__filter-sexualOrientation-inactive {
    background-image: url(../new-theme/images/icons/filter-sexualOrientation-inactive.png);
    height: 48px;
    width: 52px;
}
.ic.__filter-educationLevel-inactive {
    background-image: url(../new-theme/images/icons/filter-educationLevel-inactive.png);
    height: 48px;
    width: 52px;
}
.ic.__filter-coInfection-inactive {
    background-image: url(../new-theme/images/icons/filter-coInfection-inactive.png);
    height: 48px;
    width: 52px;
}
.ic.__filter-age-active, .match-filters .inactive-filter:hover .ic.__filter-age-inactive {
    background-image: url(../new-theme/images/icons/filter-age-active.png);
    height: 43px;
    width: 59px;
}
.ic.__filter-ethnicity-active, .match-filters .inactive-filter:hover .ic.__filter-ethnicity-inactive {
    background-image: url(../new-theme/images/icons/filter-ethnicity-active.png);
    height: 46px;
    width: 46px;
}
.ic.__filter-gender-active, .match-filters .inactive-filter:hover .ic.__filter-gender-inactive {
    background-image: url(../new-theme/images/icons/filter-gender-active.png);
    height: 45px;
    width: 45px;
}
.ic.__filter-currentTreatments-active, .match-filters .inactive-filter:hover .ic.__filter-currentTreatments-inactive {
    background-image: url(../new-theme/images/icons/filter-currentTreatments-active.png);
    height: 45px;
    width: 45px;
}
.ic.__filter-languages-active, .match-filters .inactive-filter:hover .ic.__filter-languages-inactive {
    background-image: url(../new-theme/images/icons/filter-languages-active.png);
    height: 48px;
    width: 52px;
}
.ic.__filter-sexualOrientation-active {
    background-image: url(../new-theme/images/icons/filter-sexualOrientation-active.png);
    height: 48px;
    width: 52px;
}
.ic.__filter-educationLevel-active {
    background-image: url(../new-theme/images/icons/filter-educationLevel-active.png);
    height: 48px;
    width: 52px;
}
.ic.__filter-coInfection-active {
    background-image: url(../new-theme/images/icons/filter-coInfection-active.png);
    height: 48px;
    width: 52px;
}
.list_icon {
    width: 35px;
}

.custom-icon-call-status{
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    width: 20px;
    height: 20px;
    display: inline-block;
    &.completed{
        background-image:url(../new-theme/images/icons/call-answered.png);
    }
    &.missed{
        background-image:url(../new-theme/images/icons/call-unanswered.png);
    }
    &.attempted{
        background-image:url(../new-theme/images/icons/call-unknown.png);
    }
}
.fa-2 {
    font-size: 2em !important;
}
.fa-3 {
    font-size: 3em !important;
}

.icon-step-edit {
    font-size: 16px;
}