

.q-box {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 20px;

  .q-title {
    font-size: 14px;
    font-family: $font-regular;
    color: #576071;
    margin-bottom: 10px;
  }

  .q-subtitle {
    font-size: 12px;
    color: #576071;
    margin-bottom: 10px;
    margin-top: 0;
  }
	&.multi-select {
	  .options-list {
	    border: 1px solid #DAE2EB;
	    border-radius: 4px;
	    padding: 4px;
	    display: flex;
	    align-items: center;
	    justify-content: flex-start;
	    flex-wrap: wrap;

	    .each-option {
	      font-size: 14px;
	      border-radius: 4px;
	      line-height: 1;
	      padding: 4px 10px 3px 10px;
	      border: 1px solid $secondary-blue;
	      margin: 4px;

	      button {
	        padding: 0;
	        background-color: transparent;
	        line-height: 1 !important;
	        width: auto;
	        border: 0 !important;
					box-shadow: none !important;
					background-color: transparent !important;
					font-family: $font-regular !important;
	        * {
	          line-height: 1 !important;
	          border: 0 !important;
						box-shadow: none !important;
						background-color: transparent !important;
						color: #0094F3;
	        }
	      }
	      &.selected {
	      	background-color: $secondary-blue;
	      	* {
	      		color: #fff !important;
	      	}
	      }
	    }
	  }
	}
	&.single-select {
		.options-list {
			border: 1px solid #DAE2EB;
	    border-radius: 4px;
	    padding: 4px;
	    display: flex;
	    align-items: center;
	    justify-content: flex-start;
	    flex-wrap: wrap;
	    .each-option {
	      font-size: 14px;
	     	background-color: transparent;
	      border-radius: 4px;
	      padding: 4px 10px 4px 10px;
	      border: 1px solid #DAE2EB;
	      margin: 4px;
				line-height: 1;
	      button {
	        padding: 0;
	        background-color: transparent;
	        border: 0 !important;
					box-shadow: none !important;
					background-color: transparent !important;
					color: #95A0B0;
	      	line-height: 1 !important;
	      	font-family: $font-regular !important;
	      }
	      &.selected {
	      	border-color: $secondary-blue;
	      	background-color: $secondary-blue;
	      	button {
	      		color: #fff; 
	      	}
	      }
	    }
		}
	}

  .ppq-textinput.form-control {
    border: 1px solid #DAE2EB;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    box-shadow: none !important;
  }
}


.btn-toggle-week {
  position: absolute;
  left: 1%;
  @media(max-width: 1400px) {
    position: relative;
    margin-right: 30px;
  }
}