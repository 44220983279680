.Profile-Container {
  .snapshot-comp-wrp {
    .clinical-measure-compo {
      border-top: 1px solid #f1f5f9;
      padding: 16px 0;
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.015);
      background-color: #fff;
      h4 {
        font-size: 15px;
        line-height: 18px;
        font-family: $font-regular;
      }
      .graph-wrp {
        overflow: hidden;
        > div:nth-child(2) {
          margin-left: -40px;
          margin-top: -11px;
        }
      }
    }
    &.collapsed {
      .clinical-measure-compo {
        margin-left: -7px;
        margin-right: -7px;
        padding-left: 7px;
        padding-right: 7px;
        background: transparent !important;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }
}

.cm-chart-wrp {
  position: relative;
  .chart-overflow-wrp {
    width: 100%;
    overflow-x: overlay;
    overflow-y: hidden;
    padding-bottom: 8px;
  }
  .custom-y-ticks {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    background-color: #fff;
  }
  .chart-title {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-56px) translateY(80px) rotate(-90deg);
    background-color: #fff;
    z-index: 3;
  }
}

.modal-with-close-btn {
	.cm-chart-wrp {
		.chart-title {
			transform: translateX(-56px) translateY(140px) rotate(-90deg);
		}
	}
}