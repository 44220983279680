.performance-metrics-compo {
  padding: 24px;

  &.is-in-dashboard {
    padding: 0;

    // .inner-compo {
    //   table.custom-styled {
    //     margin-bottom: 46px;

    //     thead {
    //       background-color: #fff;

    //       th {
    //         font-size: 21px;
    //         font-family: $font-regular;
    //         color: #374050;
    //         padding: 0;
    //       }
    //     }

    //     tbody {
    //       tr {
    //         td {
    //           padding: 10px;
    //           color: #374050;
    //           font-size: 18px !important;

    //           &:first-child {
    //             font-family: $font-regular !important;
    //             padding-left: 0;
    //             max-width: 300px;
    //           }

    //           .graph-wrp {
    //             border: 1px solid #E0E8F0;
    //             border-radius: 8px;
    //             padding: 8px;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

  &.is-in-profile {
    padding: 0;
  }
}

.mentee-statistic-table.custom-styled {
  table-layout: fixed;
  width: 100%;
  thead {
    background-color: #fff !important;

    tr {
      th {
        font-size: 21px;
        color: #374050;
        font-weight: 400;
        font-family: $font-regular;
        padding: 15px 10px;
      }
    }
  }

  tbody {
    tr {
      td {
        font-family: $font-regular !important;
        padding: 15px 0;
        text-align: center;
        vertical-align: center !important;

        &:first-child {
          color: #374050;
          width: 70%;
          text-align: left;
        }
        &:last-child{
          text-align: right;
          min-width: 60px;
          width: 15%;
        }
        &.is-neutral {
          color: #768091 !important;
        }
      }
      .is-increased {
        color: #E52C40;
        font-size: 16px;
        font-family: $font-medium;

        i {
          color: #E52C40;
          font-size: 12px;
          margin-right: 6px;
          transform:  rotate(-180deg) scale(0.6);
        }
      }

      .is-decreased {
        color: #88C04E;
        font-size: 16px;
        font-family: $font-medium;

        i {
          color: #88C04E;
          font-size: 12px;
          transform:  scale(0.6);
          margin-right: 6px;
        }
      }

      .is-neutral {
        color: #E0E8F0;
        font-size: 16px;
        font-family: "Calibre-Medium";

        i {
          color: #E0E8F0;
          font-size: 12px;
          margin-right: 6px;
          transform:  rotate(-180deg) scale(0.6);
          display: none !important;
        }
      }

      .is-increased-positive {
        color: #88C04E;
        font-size: 16px;
        font-family: "Calibre-Medium";

        i {
          color: #88C04E;
          font-size: 12px;
          margin-right: 6px;
          transform:  rotate(-180deg);
        }
      }

      .is-increased-negative {
        color: #E52C40;
        font-size: 16px;
        font-family: "Calibre-Medium";

        i {
          color: #E52C40;
          margin-right: 6px;
          font-size: 12px;
          transform:  rotate(-180deg) scale(0.6);
        }
      }

      .is-decreased-positive {
        color: #E52C40;
        font-size: 16px;
        font-family: "Calibre-Medium";

        i {
          color: #E52C40;
          font-size: 12px;
          margin-right: 6px;
          transform: scale(0.6);
        }
      }

      .is-decreased-negative {
        color: #88C04E;
        font-size: 16px;
        font-family: "Calibre-Medium";

        i {
          color: #88C04E;
          font-size: 12px;
          margin-right: 6px;
          transform:  scale(0.6);
        }
      }

      &.is-selected {
        background-color: #F6F9FC;
        border-radius: 8px;

        &:first-child {
          font-family: $font-medium !important;
        }
      }

      &.row-group-title {
        border-bottom: 0;
        td {
          padding-bottom: 5px;
        }
      }
      &.row-in-group {
        td {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
      &.bottom-in-group {
        td {
          padding-top: 5px;
          padding-bottom: 15px;
        }
      }
    }
  }
  &.td-pt-2\.5 {
    thead tr th, tbody tr td {
      padding-top: 10px;
    }
  }
  &.td-pb-10 {
    thead tr th, tbody tr td {
      padding-bottom: 10px;
    }
  }
}
.mentor-outcomes-compo {
  .mentee-statistic-table {
    thead {
      background-color: #fff;

      tr {
        th {
          font-size: 21px;
          color: #374050;
          font-weight: 400;
          font-family: $font-regular;
          padding: 15px 6px !important;
        }
      }
    }

    tbody {
      tr {
        td {
          font-family: $font-regular !important;
          padding: 15px 0;
          * {
            font-family: $font-regular !important;
          }

          &:first-child {
            color: #374050;
          }
        }

        &.is-selected {
          background-color: #F6F9FC;
          border-radius: 8px;

          &:first-child {
            font-family: $font-medium !important;
          }
        }
      }
    }
  }
}
.mentee-statistic-table.remove-side-padding {
  thead tr {
    background-color: #fff !important;
    th {
      padding: 0 !important
    }
  }
  tbody tr td {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}