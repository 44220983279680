.mentor-detail-tabs {
  .tab-content {
    background-color: #fff;
  }
}

.mentor-detail-wrp {
  background-color: #ebebeb;

  .mentor-detail-main {
    background-color: #fff;

    .mentor-detail-sticky-header {
      background-color: #fff;

      .sticky-inner-wrapper {
        background-color: #fff;
      }

      .inner-wrp {
        display: flex;
        justify-content: space-between;
        padding: 0 17px;
        border-bottom: 1px solid #E9EFF5;

        .avatar.member-initial {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #AB02B6;
          color: #fff;
          font-size: 17px;
          font-family: $font-regular;
          line-height: 40px;
        }

        .person {
          padding-top: 12px;
          padding-bottom: 12px;

          .mentor-info {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-right: 30px;

            i.icon-ellipse3 {
              font-size: 16px;
              margin-left: 3px;
              margin-right: 3px;
              margin-top: 0;
              transform: scale(0.3);
              // margin-top: 2px;
            }
          }

          .mentor-calls {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .each-call-option {
              margin-right: 10px;

              >span {
                display: block;

                button {
                  padding: 3px 7px;
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  background-color: $primary-blue;
                  border-color: $primary-blue;
                  transition: all ease 0.3s;

                  i {
                    font-size: 18px;
                    color: #fff;
                  }

                  &:hover {
                    opacity: 0.8;
                  }
                }
              }

              &.secondary-call {
                >span button {
                  background-color: #00DE4F;
                  border-color: #00DE4F;
                }
              }
            }
          }
        }

        .mentor-status-wrp {
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;

          .mentor_status_box {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-left: 1px solid #E8E8E8;
            padding: 8px 17px;

            .fz-14 {
              line-height: 1 !important;
            }

            i {
              line-height: 1 !important;
              margin-top: -3px;
            }
          }
        }
      }
    }

    .mentor-detail-tabs {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;

      .mentor-profile-tabs.nav.nav-tabs {
        background-color: #fff;
        margin-left: 0;
        margin-right: 0;
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 1px solid #E9EFF5;

        a {
          text-decoration: none;
          border: none;
          font-size: 16px;
          font-family: $font-medium;
          color: #576071;
          line-height: 24px;
          padding-top: 16px;
          padding-bottom: 5px;
          padding-left: 0;
          padding-right: 0;
          transition: all ease 0.3s;
          border-radius: 0;
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: -1px !important;

          &:hover,
          &.active {
            color: $primary-blue;
            border-bottom: 2px solid $primary-blue;

          }

        }
      }
    }


    padding-left: 0;
    padding-right: 0;
    flex-basis: calc(100% - 400px);
    max-width: calc(100% - 400px);

    &.col-lg-11 {
      flex-basis: calc(100% - 70px);
      max-width: calc(100% - 70px);
    }

  }

  .mentee-detail-sidebar {
    padding-top: 0;
    background-color: #fff;
    overflow-x: hidden;
    margin-top: 0 !important;

    &.col-lg-1 {
      flex: 0 0 70px;
      max-width: 70px;

      .sticky-mentor-detail-sidebar .sticky-inner-wrapper .sidebar-header {
        padding-top: 9px;
      }
    }

    &.col-lg-3 {
      flex: 0 0 400px;
      max-width: 400px;
    }

    .notes-header-filters {
      margin-left: 0;
      margin-right: 0;
    }
  }

  /*
  .mentordetail-sidebar-content {
    .notes-header  {
      padding: 15px;
      margin-left: -15px;
      margin-right: -15px;
      .notes-header-filters {
        margin-left: -15px;
        margin-right: -15px;
        border-top: 0;
        border-bottom: 1px solid #E9EFF5;
      }
    }
    .notes {
      // height: calc(100vh - 116px);
      // overflow-y: auto;
    }
  }
  */
  .sidebar-compo {
    width: 400px;

    .sticky-mentor-detail-sidebar {
      background-color: #fff;
      border-left: 2px solid #ebebeb;

      .sticky-inner-wrapper {
        background-color: #fff;

        .sidebar-header {
          padding-top: 23px;
          margin-left: -17px;
          padding-left: 18px;
          background-color: #fff;

          .menu-btn {
            color: #768091;
          }
        }
      }

      &.acitve {
        .sticky-inner-wrapper {
          .sidebar-header {
            padding-top: 17px;
            margin-bottom: 0;
          }
        }
      }
    }

    &.menu-closed {
      background-color: #17202E;
      height: calc(100vh - 51px);

      .sticky-mentor-detail-sidebar {
        .sticky-inner-wrapper {
          .sidebar-header {
            background-color: #17202e;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 23px;
            border-bottom: 1px solid #ebebeb;
            width: 70px;

            .btn-wrp {
              width: 28px !important;
              padding-top: 14px;
              padding-bottom: 13px;
              margin-left: 10px;

              .menu-btn {
                transform: rotate(-180deg);

                i {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  .sidebar-compo .sidebar-header.menu-closed .menu-btn {
    padding-bottom: 16px;
  }
  .sidebar-compo .sidebar-header .nav.nav-tabs {
    overflow: hidden;
  }
  */
}

.quality-score-wrp,
.admin-call-attempts-wrp {
  padding-left: 0;
  background-color: #fff;

  .inner-wrp {
    background-color: #fff;
    min-height: 500px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    padding: 24px 24px;
  }
}

.admin-call-attempts-wrp {
  padding: 0;

  .inner-wrp {
    padding-top: 0;
    padding: 24px;
  }
}

.availability-wrp {
  padding: 24px;


  .inner-wrp {
    background-color: #fff;
    font-family: $font-regular;
    padding: 10px;

    .round-button {
      .pb-button {
        padding-top: 5px;
      }
    }
  }
}

.notes-to-admin-compo {
  .col-12 {
    padding-left: 0;
    padding-right: 0;
  }

  .title-wrp {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 1px solid #E9EFF5;
    padding-bottom: 20px;
  }

  .each-note {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 1px solid #E9EFF5;
  }

  .new-note-form-row {
    margin-left: 0;
    margin-right: 0;
    background-color: #F6F9FC;
    padding: 24px;
    border-radius: 4px;
    margin-bottom: 16px;

    .textarea {
      border: 1px solid #E9EFF5 !important;
      box-sizing: border-box !important;
      font-size: 16px;
    }
  }
}

.header-select-wrp-i-tabs {
  margin-bottom: 16px;
  width: 300px;

  .custom-react-select {
    width: 100%;

    .custom__control {
      border: 0 !important;

      &:hover {
        box-shadow: none;
      }

      .Select-value {
        padding: 0;
        // line-height: 1 !important;
      }

      .custom__single-value {
        color: #374050 !important;
        font-size: 24px !important;
        font-family: $font-medium !important;
      }

      .custom__indicators {
        // display: none !important;
      }
    }
  }
}

.mentor-profile-wrp {
  padding: 24px !important;

}

.mentor-profile-wrp {
  .card {
    border: 0 !important;
    box-shadow: none !important;
    padding-bottom: 30px;
    margin-bottom: 30px;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 1px solid #E9EFF5 !important;

    .card-body {
      padding: 0;
      border: 0;
    }
  }
}


.mentoring-progress {
  .phase-table {
    .rainbow-table_cell-content {
      margin: 12px 0;

      a {
        text-decoration: none !important;
        font-family: $font-medium;
        font-size: 16px;
        color: $primary-blue;
      }
    }

    .percent-bar {
      height: 2px;
      background-color: #E9EFF5;
      position: relative;

      .completed {
        position: absolute;
        left: 0;
        top: 0;
        height: 2px;
        background-color: #0094F3;
      }
    }
  }
}

.mentor-pmu {
  .header-select-wrp-i-tabs {
    padding-left: 24px;
    padding-top: 24px;
    margin-bottom: 20px;
  }

  .collapse-list {
    border-top: 1px solid #E9EFF5;

    .crcw.cr_VerticalNavigation>nav>div>button {
      padding: 18px 24px;

      &[aria-expanded="true"] {
        .fz-16.text-charcoal-700.ff-reg {
          font-family: $font-medium !important;
        }
      }
    }

    .crcw.cr_VerticalNavigation>nav>div>button>svg {
      margin-top: 0.55rem;
    }
  }
}

.compensation-table {
  .remove-btn-padding {
    button {
      padding: 0 !important;
      text-align: left !important;
    }
  }
}











/**
 * New style
 */
.scroll-wrp {
  overflow-y: auto;

  .scroll-wrp {
    max-height: unset !important;
  }

  &.show-scrollbar {
    overflow-y: scroll;
  }
}

.admin-mentor-profile {
  background-color: #E9EFF5;

  .profile-status-bar {
    .inner-wrp {
      min-width: 900px;
      width: 100%;

      >div {
        width: 100%;

        .menu-list {
          .each-menu {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-right: 1.2rem;

            .icon-wrp {
              svg {
                margin-right: 12px;
              }
            }
          }

          >div:last-child {
            border-right: 0 !important;
          }
        }
      }
    }
  }

  .mentor-pmu {
    .header-select-wrp-i-tabs {
      padding-left: 0;
      padding-top: 0;
      margin-bottom: 16px;
    }

    >.pl-6.pr-6 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .cop-content-wrp {
    height: 100%;

    .profile-main-wrp {
      height: 100%;
    }
  }
}

.mentor-logistics-compo {
  .row {
    margin-left: 0;
    margin-right: 0;

    >div:first-child {
      padding-right: 15px;
      padding-left: 0;
    }

    >div:last-child {
      padding-left: 15px;
      padding-right: 0;
    }
  }
}

.graph-wrp {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  min-width: 220px;

  .graph-inner-item {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
  }
}

.row-with-graph {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .graph-desc {
    flex-grow: 1;
  }
}

.mentor-sop-list,
.mentor-payment-requests-compo,
.mentor-goal-scores-compo,
.mentor-quality-scores-compo {
  .scroll-wrp {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .bottom-btn-wrp {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 24px;
    border-top: 1px solid #E0E8F0;
  }
}

.sops-list {
  .each-sop-info {
    position: relative;
    padding: 16px 16px;
    margin-left: -16px;
    margin-right: -16px;

    &.active {
      background-color: #F6F9FC;
      border-radius: 8px;
    }

    .icon-wrp {
      padding-top: 1px;
    }
  }
}

.rounded-panel {
  background-color: #fff;
  padding: 24px;
  border-radius: 16px;
  overflow: hidden;
}

.notes-to-admin-compo {
  padding-left: 0;
  padding-right: 0;
}

.mentor-overview-panel {
  position: relative;

  .tab-content {
    min-height: 355px;
    display: flex;
    align-items: center;

    >div {
      width: 100%;
    }
  }

  .three-dots-on-right {
    position: absolute;
    right: 24px;
    top: 28px;
    z-index: 2;
  }
}






.sop-timeline {
  position: relative;
  padding-left: 26px;

  .copilot-note {
    border-bottom: 0;
  }

  .each-item {
    position: relative;

    .item-title {
      position: relative;

      &:before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        left: -25px;
        top: 6px;
        background-color: #fff;
        border: 2px solid #CCD6E2;
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: -20px;
      top: 20px;
      width: 2px;
      height: calc(100% + 14px);
      background-color: #CCD6E2;
    }

    &.completed {


      &:before {
        content: '';
        position: absolute;
        left: -20px;
        top: 20px;
        width: 2px;
        height: calc(100% + 14px);
        background-color: #9BCE63;
      }

      &:after {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        left: -25px;
        top: 6px;
        background-color: #9BCE63;
        border: 2px solid #CCD6E2;
      }

      .item-title {
        &:before {
          border: 2px solid #9BCE63;
          background-color: #fff;
          z-index: 1;
        }

        &:after {
          content: "\f0db";
          color: #9BCE63;
          position: absolute;
          left: -27px;
          top: 4px;
          transform: scale(0.4);
          font-family: 'Mentor1to1';
          display: inline-block;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          line-height: 1;
          text-rendering: auto;
          pointer-events: none;
          z-index: 2;
        }
      }
    }

    &.active {
      .item-title:before {
        border: 2px solid #0094F3;
        background-color: #fff;
      }
    }

    &.pending {
      .item-title:before {
        border: 0;
        background-color: transparent;
        background-image: url(../new-theme/images/sequence_pending.svg);
        width: 16px;
        height: 16px;
        top: 6px;
        left: -24px;
      }
    }
  }

  >div:last-child {
    &:before {
      content: none;
      // height: calc(100% - 12px);
    }
  }
}


.blue-pg-bar {
  border-radius: 30px;
  background-color: #F6F9FC;
  height: 8px;
  position: relative;

  .blue-bar {
    border-radius: 30px;
    height: 8px;
    background-color: #0094F3;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.payments-tab {
  >.tab-content {
    height: calc(100vh - 161px);
    // overflow-y: auto;
    margin-left: -24px;
    margin-right: -24px;
    padding: 24px !important;

    .scrollable-columns {
      margin-bottom: 0;
      max-height: unset;

      .inner-wrp {
        min-height: unset;

        .each-column .inner-column {
          box-shadow: none;
          border: 1px solid rgba(55, 64, 80, 0.1);
        }
      }
    }
  }
}


.sop-overview-compo-in-modal {
  margin-top: 24px;

  .mentor-sop-list {
    .ob-wrp {
      width: 100%;
      padding-right: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;

      .inner-col {
        width: auto;
        margin: 0;
        .fz-24.font-medium {
          font-size: 22px !important;
        }
        .icon.icon-times-btb {
          transform: scale(0.8);
        }

        .scroll-view-compo {
          height: 500px !important;
        }
        .bottom-btn-wrp {
          margin-inline: 0;
          padding-inline: 0;
        }
      }
    }

    .sop-timeline .each-item .item-title {
      font-size: 15px !important;
      word-break: break-word;
    }

    .sop-timeline .each-item .item-title:before {
      top: 3px;
    }
    .sop-timeline .each-item.completed .item-title:after {
      top: 2px;
    }
    .sop-timeline .each-item.completed:after {
      top: 3px;
    }
    .sop-timeline .each-item.completed:before {
      top: 8px;
    }
    .sop-timeline .each-item:before {
      top: 8px;
      height: calc(100% + 5px);
    }

    .item-schedule-date {
      word-break: break-word;
      width: 80px;
    }
  }
}