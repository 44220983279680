.diet-suggestions-component {
  margin-left: -24px;
  margin-right: -24px;
  .btn.btn-primary {
    background-color: $secondary-blue;
    border: 1px solid $secondary-blue;
  }

  textarea.form-control {
    border-color: #E9EFF5;
    box-shadow: none !important;
    font-size: 16px;
    color: #374050;
    font-family: $font-regular;
  }

  .gray-box {
    padding: 20px 24px;
  }

  .suggestions-list {
    .number-badge {
      padding: 3px 8px 4px 8px !important;
      background-color: $secondary-blue;
    }

    .each-suggestion {
      padding: 16px 24px 0 24px;
      border-top: 1px solid #E9EFF5;

      .btn-title {
        margin-bottom: 16px;
      }
    }

    >div:last-child {
      border-bottom: 1px solid #E9EFF5;
    }

    .gray-box {
      padding: 24px 24px;

    }
  }
}

.two-columns-modal {
  .modal-body {
    padding: 0 !important;
  }

  .inq-modal-header {
    margin-left: -15px;
    margin-right: -15px;
    padding: 20px 30px;
    border-bottom: 1px solid #E9EFF5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .inq-modal-close-btn {
      padding: 10px;
      i {
        transform: scale(0.6);
        color: #768091;
        font-size: 14px;
      }
    }
  }

  .content-row {
    .sidebar-col {
      padding: 24px 0;
      border-right: 1px solid #E9EFF5;

      .border-bottom {
        padding-bottom: 15px;
        padding-left: 30px;
        padding-right: 30px;
        border-bottom: 1px solid #E9EFF5 !important;
      }

      .guider-content {
        margin-left: -2px;
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    .main-content-col {
      .dietary-feedback-form-compo {
        .title-with-space.expanded {
          border: 0;
          padding-bottom: 20px;
          background-color: transparent;
          .steplet-detail {
            background-color: transparent;
            padding: 24px 0;

            .border-bottom {
              padding-bottom: 15px;
              padding-left: 24px;
              padding-right: 24px;
              border-bottom: 1px solid #E9EFF5 !important;
              margin-bottom: 20px;

              .form-control {
                margin-bottom: 0;
              }
            }
          }
          input.form-control {
            border: 1px solid #E0E8F0 !important;
            border-radius: 8px !important;
            font-size: 16px;
          }
        }
      }
      .react-datepicker-wrapper {
        margin-bottom: 0 !important;
      }
      .react-datepicker__input-container {
        input {
          padding: 5px 10px !important;
          font-size: 16px !important;
          color: #374050 !important;
          // border: 1px solid #CCD6E2 !important;
          // border-radius: 4px !important;
          height: auto !important;
          line-height: 1;
          min-width: 80px !important;
          width: 80px !important;
          font-family: $font-regular !important;
          &:empty {
            width: 150px !important;
          }
        }
        .MuiOutlinedInput-root {
          padding: 0 !important;
        }
      }

      .custom_rb-vertical_navigation {
        margin-left: -24px;
        margin-right: -24px;
      }
    }
  }
}