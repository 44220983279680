.rbc-calendar.is-fixed-height {
  .rbc-time-view {
    .rbc-time-content {
      max-height: 560px;
      overflow-y: auto;

      .rbc-time-gutter {
        width: 80px;
      }
    }

    .rbc-time-header {
      padding-right: 12px;
    }
  }
}

.mentee-availability-compo {
  // .rbc-time-view {
  //   .rbc-time-header-cell {
  //     padding: 0 !important;
  //   }
  // }

  .admin-mentoring-availability-wrp {
    padding: 0;

    article {
      padding: 0 !important;
      margin: 8px -12px -12px 0 !important;
      box-shadow: none !important;
      background-color: transparent !important;
      box-shadow: none !important;
      outline: none !important;
      border: 0 !important;

      >div {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
}

// .MuiCard-root {
//   .rbc-time-view {
//     .rbc-time-header-cell {
//       padding: 0 !important;
//     }
//   }
// }