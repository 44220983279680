.search-its-compo {
  position: relative;
  z-index: 10;

  &.is-reduced {
    .search-wrp .search-input input {
      width: 180px !important;
    }
  }

  .search-wrp {
    .icon-wrp {
      left: 12px;
    }

    .search-input {
      margin-bottom: 0;

      input {
        border-radius: 8px;
        width: 360px;
        padding-left: 32px;
      }
    }

    .hotkey-indicator {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #F1F5F9;
      color: #576071;
      border-radius: 6px;
      padding: 2px 4px;
      font-size: 12px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      text-transform: uppercase;
      font-family: $font-medium;
    }
  }

  .search-results {
    position: absolute;
    top: calc(100% + 4px);
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(23, 32, 46, 0.1);
    border-radius: 8px;
    width: 360px;
    overflow-y: auto;
    padding: 0;

    .each-issue,
    .each-steplet,
    .each-track,
    .each-step {
      cursor: pointer;
      padding: 4px 8px;
      transition: all ease 0.3s;
      margin-bottom: 12px;
      border-radius: 8px;

      &:hover,
      &.is-search-item-active {
        background-color: #F6F9FC;
      }

      .gray-dot-divider {
        width: 4px;
        height: 4px;
      }
    }

    .each-issue {
      margin-bottom: 8px;
    }

    .issues-list,
    .steplets-list {
      >div:last-child {
        margin-bottom: 0;
      }
    }

    .search-results-inner-wrapper {
      max-height: calc(100vh - 150px);
      overflow-y: auto;
      padding: 12px;
      margin-bottom: 36px;
    }
  }
}

.mui-search-its-compo {
  .MuiFormLabel-root {
    transform: none;
    top: 50%;
    left: 32px;
    transform: translateY(-50%);

    &.MuiFormLabel-filled {
      display: none;
    }
  }

  .MuiInputBase-input {
    margin-left: 6px;
    line-height: 1;
    font-size: 16px;
  }

  &.Mui-focused {
    .MuiFormLabel-root {
      display: none;
    }

  }
}