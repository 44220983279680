.full-row-col {
  max-width: 1412px;
  @media (max-width: 1599px) {
    width: 1196px;
  }
}
.ob-wrp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 24px;
  width: fit-content;

  >div {
    width: 460px;
    margin-right: 16px;
    flex-shrink: 0;

    @media (max-width: 1599px) {
      width: 388px;
    }
  }

  .two-thirds-col {
    width: 936px;
    margin-right: 16px;
    flex-shrink: 0;

    @media (max-width: 1599px) {
      width: 796px;
    }
  }

  .w-660px {
    width: 660px;
  }

  .half-col {
    width: 560px;
    margin-right: 16px;
    flex-shrink: 0;

    @media (max-width: 1599px) {
      width: 592px;
    }
  }

  .inner-col {
    max-width: 100%;

    .scroll-wrp {
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
      overflow-x: hidden;
    }
  }

  >div:last-child {
    margin-right: 0;
  }

  .full-col {
    width: 1412px;
    margin-right: 0;
    flex-shrink: 0;

    @media (max-width: 1599px) {
      width: 1200px;
    }
  }
}

.profile-main-wrp {
  display: flex;

  .cop-ct-columns.is-mentee-profile {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    height: auto;
    padding-bottom: 0;
    overflow-x: hidden;
    flex-grow: 1;

    .cols-wrp {
      padding: 24px 24px;
      overflow-y: auto;
      overflow-x: auto;
    }

    .with-expand-btn {
      .inner-col {
        overflow-y: hidden;
        position: relative;
        max-height: unset;
        height: unset;
      }

      .btn-toggle-wrp-expansion {
        background-color: #f6f9fc;
        cursor: pointer;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        line-height: 1;
        text-align: center;
        border-top: 1px solid #e0e8f0;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;

        i {
          font-size: 16px;
          color: #768091;
        }
      }
    }

    .inner-col {
      overflow-y: auto;
    }
  }

  .sidebar-compo {
    height: calc(100vh - 51px);
    overflow-y: auto;
    background-color: #fff;
    padding-left: 0;
    padding-right: 0;

    .inner-col {
      min-height: unset;

      .sidebar-header {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.col-lg-4 {
      width: 380px;
      max-width: 380px;
    }

    &.col-xl-1 {
      .inner-col {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &.is-sidebar-open {
    .cop-ct-columns.is-mentee-profile .cols-wrp {
      .ob-wrp {
        flex-wrap: wrap;
      }
    }
  }
}

.profile-status-bar {
  border-bottom: 1px solid #e0e8f0;
  border-right: 1px solid #e0e8f0;
  background-color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100% !important;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  min-height: 51px;

  .inner-wrp {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 43px;
    min-width: 100%;

    >div:first-child {
      padding-left: 0 !important;
    }

    // min-width: 1200px;
  }

  .each-status-val {
    padding-left: 1rem;
    padding-right: 1rem;
    border-right: 1px solid #e0e8f0;
    height: 44px;
    padding-top: 3px;
    padding-bottom: 3px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .fz-12 {
      font-size: 14px !important;
    }



    &.with-timeline {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .blue-select-box-wrp {
        margin-top: 3px;
      }

      .fz-12 {
        line-height: 1 !important;
      }
    }
  }

  >div:last-child {
    border-right: 0;
  }

  .call-timer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;

    .hms {
      height: 48px;
      font-size: 26px;
      font-family: $font-medium;
      padding: 10px 20px;
      color: #374050;
      border-right: 1px solid #e0e8f0;
      line-height: 1;
    }

    .menu-list {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .each-menu {
        padding-left: 1rem;
        padding-right: 1rem;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        border-right: 1px solid #e0e8f0;
        height: 44px;
        white-space: nowrap;

        svg {
          margin-right: 14px;

          * {
            stroke: $dark-blue;
          }
        }
      }

      >div:first-child {
        padding-left: 0 !important;
      }
    }
  }
}

.content-with-slider-arrows {
  position: relative;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 10px;
  /*margin-top: 14px;*/

  .btn-prev {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    color: $secondary-blue;
    cursor: pointer;
    width: 32px;
    height: 32px;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-next {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    color: $secondary-blue;
    cursor: pointer;
    width: 32px;
    height: 32px;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.active-tracks-ind {
  .arrow {
    &:before {
      border-bottom-color: #f1f5f9;
    }
  }

  .tooltip-inner {
    background-color: #f1f5f9;
    color: #374050;
    font-size: 14px;
    padding: 6px 12px;
  }
}

.with-height-toggle-btn {
  position: relative;
  overflow-y: hidden !important;

  >div:first-child {
    // height: 100%;
    // overflow-y: auto;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .btn-toggle-expansion {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f6f9fc;
    text-align: center;
    border-top: 1px solid #e0e8f0;

    i {
      color: #768091;
    }
  }

  .tab-content {
    height: calc(50vh - 160px);
    overflow-y: auto;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
  }



}



body>.react-datepicker__tab-loop {
  position: fixed !important;
  z-index: 9999 !important;
  top: 0;
}

.content-with-bottom-nav {
  .bottom-nav {
    margin-top: 15px;
    border: 1px solid #e0e8f0;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dots-nav {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .dot-nav {
        margin-left: 2px;
        margin-right: 2px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: #ccd6e2;
        transition: all 0.3s ease;
        cursor: pointer;

        &.is-active {
          width: 16px;
          background-color: #0094f3;
          pointer-events: none;
        }
      }
    }

    .btn-prev {
      color: #0094f3;
      transform: rotate(90deg);
      cursor: pointer;
      margin-right: 6px;

      i {
        font-size: 16px;
      }

      &.is-disabled {
        color: #e9eff5;
        pointer-events: none;
      }
    }

    .btn-next {
      color: #0094f3;
      font-size: 18px;
      transform: rotate(-90deg);
      cursor: pointer;
      margin-left: 6px;

      i {
        font-size: 16px;
      }

      &.is-disabled {
        color: #e9eff5;
        pointer-events: none;
      }
    }
  }
}

.todo-list-compo {
  .MuiTabPanel-root {
    padding: 16px 0;
  }

  .each-todo {
    .meta-wrp {
      flex-shrink: 0;
      .MuiButtonBase-root.MuiButton-root.MuiButton-contained {
        padding: 4px 8px;
        min-width: unset;
      }
    }
  }
}