.waitlist-management-cont {
  padding: 24px;
  .filters-wrp {
    border: 1px solid #E0E8F0;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-color: transparent;
    position: relative;
    margin-bottom: 24px;
    .btn-close {
      position: absolute;
      z-index: 1;
      right: 12px;
      top: 12px;
      padding: 6px;
      cursor: pointer;
      display: flex;
      i {
        font-size: 14px;
        color: #95A0B0;
        transform: scale(0.8);
      }
    }
    .each-filter {
      width: 206px;
      margin: 12px;
      padding-right: 0;
      .custom-react-select {
        .custom__control {
          border: 1px solid #E0E8F0;
          border-radius: 4px;
          // height: 48px;
        }
      }
      input.form-control {
        border: 1px solid #E0E8F0;
        border-radius: 4px;
        height: 48px !important;
      }
      &.two-columns {
        width: 436px;
      }
    }
  }
}
.custom-tab-bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  >div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 28px;
    &.default {
      font-size: 21px;
      color: #95A0B0;
      cursor: pointer;
      .badge.is-blue {
        color: #95A0B0;
        background-color: #F6F9FC;
      }
    }
    &.active {
      color: #374050;
      font-size: 21px;
      .menu-title {
        border-bottom: 2px solid $secondary-blue;
      }
    }
    .menu-title {
      margin-right: 8px;
      border-bottom: 1px;
      border-bottom: 2px solid transparent;
    }
  }
}
.icon-thick-down-caret {
  font-size: 16px;
  &.is-reverse {
    transform: rotate(180deg);
  }
}