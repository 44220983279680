.custom-checkbox-container {
  position: relative;
  display: flex;
  margin-bottom: 0px;
  margin-left: 24px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~.custom-checkbox-element {
        background-color: $light-blue;
        border: 1px solid $light-blue;
        border-radius: 4px;
        opacity: 1;
        position: relative;

        &::after {
          position: absolute;
          content: "";
          left: 50%;
          top: 50%;
          background-image: url(../assets/images/white_check.svg);
          background-size: contain;
          background-position: center center;
          transform: translateX(-50%) translateY(-50%) scale(2);
          border: none;
          width: 16px;
          height: 16px;
        }
      }
    }

    &:disabled {
      ~.custom-checkbox-element {
        cursor: pointer;
        background: #F1F5F9;
        border: 1.5px solid #DAE2EB;
        border-radius: 4px;
      }

      &:disabled:checked {
        ~.custom-checkbox-element::after {
          border-color: #DAE2EB;
        }
      }
    }

  }

  .custom-checkbox-element {
    // margin-top: 2px;
    margin-top: 0;
    margin-right: 10px;
    display: inline-block;
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    border: 1.5px solid $light-blue;
    border-radius: 4px;
    cursor: pointer;
    flex-shrink: 0;

    &::after {
      position: absolute;
      left: 7px;
      top: 6px;
      width: 6px;
      height: 11px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  .red-box {
    border-color: #FF4E59;
  }
}


.custom-checkbox {
  max-width: 100%;
  margin-bottom: 0;
  cursor: pointer;
  line-height: 1;

  input[type='checkbox'] {
    display: none;
  }

  &.square input[type='checkbox'] {
    &:disabled+.checkbox-lbl {
      .custom-checkbox-mark {
        background-color: #E9EFF5 !important;
        border-color: #CCD6E2 !important;
      }
    }

    &:checked:disabled+.checkbox-lbl {
      .custom-checkbox-mark {
        color: transparent !important;
        background-color: $light-blue !important;
      }
    }
  }

  &.is-urgent {
    .custom-checkbox-mark {
      border-color: #FF4E59 !important;
    }
  }

  .custom-checkbox-mark {
    border-radius: 50%;
    border: 2px solid $light-blue;
    margin-right: 8px;
    flex-shrink: 0;
    margin-top: 0;
  }

  .checkbox-lbl {
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 19px;
    max-width: 100%;
    .custom-checkbox-mark {
      flex-shrink: 0;
    }
    .note-for-question {
      padding-left: 8px;
      position: relative;
      font-size: 14px;
      margin-top: 2px;
      margin-bottom: 8px;
      padding-top: 2px;
      padding-bottom: 2px;
      color: #374050;
      font-family: $font-medium;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 2px;
        height: 100%;
        border-radius: 4px;
        background-color:#A8B6C7;
      }
    }
  }

  input[type='checkbox']:checked+.checkbox-lbl {
    .custom-checkbox-mark {
      // border-color: $secondary-blue;
      background-color: $light-blue;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 80%;
        color: #fff;
        transform: translateX(2%) scale(0.6);
      }
    }
  }



  &.square {
    .custom-checkbox-mark {
      border-radius: 4px;
      border-color: $light-blue;
      border-width: 2px;
      margin-top: 4px;
    }
  }

  /**
   * Additional style for title
   */
  .check-desc {
    color: #768091;
    font-family: $font-regular;
    flex-grow: 1;
    line-height: 1.2;
    margin-top: 0;
    white-space: normal;
  }

  &.is-right {
    .checkbox-lbl {
      .custom-checkbox-mark {
        margin-right: 0;
        margin-left: 15px;
      }
    }
  }


  &.with-actions {
    align-items: flex-start;
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }

  20% {
    height: 0;
    width: 5px;
    opacity: 1;
  }

  40% {
    height: 9px;
    width: 5px;
    opacity: 1;
  }

  100% {
    height: 9px;
    width: 5px;
    opacity: 1;
  }
}