.outreach-dashboard-wrp {
  background-color: #fff; 
  .progress-bar-compo .pg-wrp.mb-2 {
    margin-bottom: 0 !important;
  }
}
.container-w-right-bar {
  display: flex;
  min-height: 100vh; 
  .middle-content {
    width: calc( 100% - 400px );
    padding: 24px;
    .gray-box {
      background-color: #F6F9FC; 
      padding: 24px;
      border-radius: 4px;
      margin-bottom: 12px; 
      .dashboard-card {
        margin-bottom: 0;
        background-color: transparent;
        box-shadow: none;
        >div {
          padding: 0;
        }
      }
    }
    .custom-styled-tabs.nav.nav-tabs {
      margin-left: -24px;
      margin-right: -24px;
      .nav-item {
        padding-bottom: 0 !important;
        .nav-link {
          padding-bottom: 3px;
        }
      }
      & + .tab-content {
        padding: 24px;
        margin-left: -24px;
        margin-right: -24px;
      }
    }
  }
  .right-sidebar {
    width: 400px;
    border-left: 1px solid #E9EFF5;
    height: auto;
    padding: 24px;
    .btn-toggle-right-sidebar {
      margin-bottom: 16px;
      border: 0; 
      padding: 0;
      background-color: transparent;
      color: #768091; 
      font-size: 16px; 
    }
    .dashboard-card {
      box-shadow:  none; 
      border: 0 ; 
      border-radius: 0; 
      .dashboard-card-header {
        background-color: transparent;
        border: 0; 
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        .sidebar-header {
          padding: 0;
          margin-left: 0;
          margin-right: 0;
          border-bottom: 1px solid #E9EFF5; 
          margin-left: -24px;
          margin-right: -24px;
          .nav.nav-tabs{
            margin-left: 0;
            margin-right: 0;
            padding-left: 24px;
            padding-right: 24px;
            .nav-item {
              margin-right: 15px;
              padding-left: 0;
              padding-right: 0;
            }
          } 
        }
      }
    }
  }
  &.sidebar-collapsed {
    .middle-content {
      width: calc( 100% - 70px); 
    }
    .right-sidebar {
      width: 70px;
      padding-left: 15px;
      padding-right: 15px;
      .btn-toggle-right-sidebar {
        transform: rotate(-180deg);
      }
    }
  }
}