.copilot-main-component-wrp {
  width: fit-content;
  height: calc(100vh - 60px - 51px);
  overflow-y: auto;

  .copilot-ct-columns {
    height: 100%;

    .overflow-wrp {
      display: flex;
      height: 100%;

      .col-wrp {
        width: 312px;

        &.is-level-one {
          height: 100%;

          .inner-col {
            height: 100%;
            display: flex;
            flex-direction: column;

            .cop-second {
              flex-grow: 1;
              height: 0;
              display: flex;
              flex-direction: column;

              .cop-list {
                flex-grow: 1;
                height: 0;

                >.por {
                  height: 100%;
                }

                .scroll-wrp {
                  height: 100%;
                }
              }
            }
          }
        }

        .inner-col {
          padding: 24px;
          width: 100%;
          height: 100%;
          overflow-y: auto;

          .scroll-wrp {
            height: calc(100vh - 200px);
          }
        }

        &.is-level-two {
          width: 464px;
          height: 100%;
          border-left: 1px solid #E9EFF5;

          .inner-col {
            height: 100%;

            .cop-second {
              height: 100%;
              display: flex;
              flex-direction: column;

              .scroll-wrp {
                height: 0;
                flex-grow: 1;
              }

              &.issues-main-wrp {
                >.por {
                  flex-grow: 1;
                  height: 0;
                  display: flex;
                  flex-direction: column;

                  .scroll-wrp {
                    flex-grow: 1;
                    height: 0;
                    max-height: unset;
                  }
                }
              }

              &.add-new-issues-list {
                height: 100%;
                display: flex;
                flex-direction: column;

                >.por {
                  flex-grow: 1;
                  height: 0;
                  display: flex;
                  flex-direction: column;

                  .scroll-wrp {
                    flex-grow: 1;
                    height: 0;
                    max-height: unset;
                  }
                }
              }
            }
          }

          &.with-level-three {
            width: 464px;
          }
        }

        &.is-level-three {
          width: 312px;
          height: 100%;
          border-left: 1px solid #E9EFF5;

          .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root {
            font-size: 16px;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }

  &.with-topbar {
    height: calc(100vh - 60px - 51px - 52px);

    .copilot-ct-columns .overflow-wrp .col-wrp .inner-col .scroll-wrp {
      height: calc(100vh - 250px);
    }
  }

  &.is-column-one-hidden {
    .copilot-ct-columns {
      .col-wrp {
        &.is-level-two {
          width: 464px;
        }
      }
    }
  }
}

.copilot-cont {
  &.is-right-sidebar-open {
    .copilot-main-component-wrp .copilot-ct-columns .overflow-wrp .col-wrp.is-level-three {
      min-width: 312px;
      max-width: 375px;
      width: calc(100vw - 420px - 1055px);
    }
  }

  &.is-right-sidebar-closed {
    .copilot-main-component-wrp .copilot-ct-columns .overflow-wrp .col-wrp.is-level-three {
      min-width: 312px;
      max-width: 375px;
      width: calc(100vw - 94px - 1055px);
    }
  }

  &.sidebar-collapsed {
    &.is-right-sidebar-open {
      .copilot-main-component-wrp .copilot-ct-columns .overflow-wrp .col-wrp.is-level-three {
        min-width: 312px;
        max-width: 375px;
        width: calc(100vw - 420px - 861px);
      }
    }

    &.is-right-sidebar-closed {
      .copilot-main-component-wrp .copilot-ct-columns .overflow-wrp .col-wrp.is-level-three {
        max-width: 375px;
        min-width: 312px;
        width: calc(100vw - 94px - 861px);
      }
    }
  }
}

.progress-bar-compo {
  .pg-wrp {
    border-radius: 24px;
    height: 4px;
    width: 100%;
    position: relative;
    display: block;
    background-color: #E0E8F0;

    .active-percent {
      background-color: #0094F3;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: 0;
    }

    >div:first-child {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }

    >div:last-child {
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }

  &.is-thin {
    .pg-wrp {
      height: 2px;
    }
  }
}


.cop-issue-card {
  border: 1px solid #CCD6E2;
  padding: 12px;
  margin-top: 4px;
  margin-bottom: 12px;
  border-radius: 12px;
  position: relative;
  cursor: pointer;

  .cop-issue-status {
    flex-shrink: 0;
    font-weight: 400;
    border-radius: 16px;

    .MuiChip-label {
      font-size: 14px;
      font-family: $font-medium;
    }

    &.is-active {
      background-color: #EBF4FF !important;
      color: #006DEB !important;
    }

    &.is-blocked {
      background-color: #FEF1F2 !important;
      color: #F5000F !important;

    }

    &.is-resolved {
      background-color: #F0FAF1 !important;
      color: #30883B !important;

    }

    &.is-notexploredyet {
      background: #FFF7EB;
      color: #E08700 !important;
    }
  }
}

.MuiChip-root.badge-status {
  font-size: 14px;
  font-family: $font-medium;

  &.MuiChip-colorDefault {
    background-color: #EBF4FF !important;
    color: #006DEB !important;
  }

  &.MuiChip-colorWarning {
    background-color: #F1F5F9 !important;
    color: #95A0B0 !important;
  }
}




.copilot-sticky-notes {
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(55, 64, 80, 0.16);
  border-radius: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 24px;
  z-index: 11;

  .tab-icon-btn {
    margin-left: 5px;
    transform: all ease 0.3s;

    &.is-border-radius8 {
      border-radius: 8px;
    }

    &.is-open {
      transform: rotate(180deg);
    }

    &.is-active {
      background-color: #EBF4FF;

      svg {
        color: $dark-blue;
      }
    }

    &.is-toggle {
      width: 24px;
      height: 24px;
      padding: 0;
    }
  }

  .sticky-notes-list-wrp {
    height: 290px;
    overflow-y: auto;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;

    .bottom-bd-box.mb-2 {
      padding-bottom: 8px;
    }
  }
}

.scroll-wrp {
  .search-its-compo {
    z-index: unset;
  }
}


body {
  .btn-guider-notes {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(55, 64, 80, 0.16) !important;
    border-radius: 16px;
    border: 0 !important;
    color: #768091 !important;
    position: absolute;
    min-width: 60px !important;
    right: -12px;
    top: -5px;
    padding: 5px 8px 5px 6px;
    min-width: unset;
    z-index: 5;


    .MuiButton-startIcon {
      margin-right: 4px;
      margin-left: 0;
    }

    &:hover {
      background-color: #fff;
      color: $dark-blue !important;

      >* {
        color: $dark-blue;
      }
    }
  }

  .talking-points-list.is-global-talkingpoints {
    .btn-guider-notes {
      top: -7px;
    }
  }

  .btn-toggle-collapse {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1.5px solid #768091;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #768091;

    svg {
      font-size: 14px;
    }

    .MuiTouchRipple-root {
      display: none;
    }

    &.border-none {
      border: none;

      svg {
        font-size: 16px;
      }
    }
  }

  .goal-tabs-content.is-in-issue.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root .BaseBadge-root {
    font-size: 16px;
  }
}

.cg-search-results {
  margin-bottom: 8px;

  mark {
    background-color: #ffe7c2;
    padding: 1px;
  }
}

.issue-guider-history.is-expanded {
  >.gray-bd-box {
    background-color: #F9FCFE;
  }
}

.conversation-guide-item-check {
  position: absolute;
  right: 0;
  bottom: 0;
}


// .inq-shadow-card {
//   .copilot-ct-columns {
//     height: 100%;

//     .overflow-wrp {
//       height: 100%;

//       .col-wrp {
//         height: 100%;

//         .cop-second {
//           height: 100%;
//           display: flex;
//           flex-direction: column;

//           .column-title {
//             flex-shrink: 0;
//           }

//           .cop-list {
//             flex-grow: 1;
//             border: 1px solid #f00;
//           }

//           div:not(.scroll-wrp) {
//             flex-shrink: 0;
//           }

//           .scroll-wrp {
//             flex-grow: 1;
//             max-height: unset !important;
//             height: auto !important;
//           }
//         }
//       }
//     }
//   }
// }

// Transcription Component
.css-1c35hjw-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}