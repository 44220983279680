.call-note-compo {
  .note-edit {
    border-bottom: 2px solid #E9EFF5;
  }

  .form-control {
    border: 1px solid #E9EFF5;
    border-radius: 8px;
  }

  .btn-play {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $secondary-blue;
    margin-left: 8px;
    margin-top: 20px;
    transition: all 0.3s ease;
    flex-shrink: 0;

    i {
      font-size: 14px;
      transform: scale(0.6);
      color: #fff;
    }

    svg {
      width: 16px;
    }

    &.is-disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.custom-bar-chart {
  .pg-wrp {
    background: #F6F9FC;
    border-radius: 10px;
    height: 8px;
    width: 100%;
    position: relative;

    .pg-active {
      position: absolute;
      left: 0;
      top: 0;
      height: 8px;
      border-radius: 10px;
    }
  }

  &.is-single-line {
    .pg-wrp {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      >div {
        border-radius: 0;
      }

      >div:first-child {
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
      }

      >div:last-child {
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }
    }

    .pg-active {
      position: relative;

    }
  }
}

.tooltip-white-bg {
  background-color: #fff !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 8px !important;
}

.inq-tooltip-box {
  padding: 6px;
}

.role-info-list {
  &-item {
    margin-top: 4px;
    margin-bottom: 4px;
    .font-medium {
      font-family: $font-regular !important;
    }
    .text-charcoal-700 {
      color: #576071 !important;
    }
  }
}