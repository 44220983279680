.add-compensation-wrp {
  background-color: #fff;
  padding: 0 1px;
  margin-left: -2px;
  margin-right: -2px;
	overflow-x: scroll !important;
  .new-row-wrp {
  	padding: 24px;
  	background-color: #F6F9FC;
  	border-radius: 4px; 
  	margin-top: 16px;
  	margin-bottom: 24px;
  	.row {
  		// margin: 0;
  		>div {
  			padding-left: 16px;
  			padding-right: 16px;
  		}
  	}
  	.react-datepicker-wrapper {
  		width: 100%;
  		display: block;
  	}
  	input {
  		width: 100%;
  		display: block;
  		border: 1px solid #DAE2EB; 
  		border-radius: 4px; 
  		background-color: #fff; 
			height: 32px;
			margin-bottom: 16px;
			outline: 0 !important;
			box-shadow: none !important;
			padding: 3px;
  	}
  }
}