.swMain {
    box-sizing: border-box;
    background: #0c69cd;
    background: -moz-linear-gradient(left, #0c69cd 0%, #4daa71 100%, #458f50 100%);
    background: -webkit-linear-gradient(left, #0c69cd 0%, #4daa71 100%, #458f50 100%);
    background: linear-gradient(to right, #0c69cd 0%, #4daa71 100%, #458f50 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0c69cd', endColorstr='#458f50', GradientType=1);
    margin-left: -30px;
    margin-right: -30px;
    padding: 20px 120px 20px 79px;
    position: relative;

    .back-btn {
        position: absolute;
        left: 36px;
        bottom: 13px;
        cursor: pointer;

        i {
            font-size: 22px;
            color: #fff;
        }
    }

    .step-list {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: -26px;

        li {
            margin-right: 30px;
        }

        .selected {
            padding: 10px 16px;
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 3px 3px 0 0;
        }
    }

    .program-select {
        display: flex;
        align-items: center;

        .btn {
            padding-right: 20px
        }

        &::after {
            position: relative;
            top: 5px;
            right: 10px;
            pointer-events: none;
            transform: translateY(-50%);
            background-image: url(../new-theme/images/bottom-triangle.svg);
            width: 12px;
            height: 6px;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            content: '';
            pointer-events: none;
            opacity: 0.2;
        }
    }
}


.enroll-container-wrp {
    .left-nav-listing-header-col {
        padding: 0;
        border-right: 1px solid #E9EFF5;

        .left-nav-listing-header {
            height: auto;
            min-height: auto;
            border-right: 0;
        }
    }

    .program-selector {
        position: relative;
        padding: 24px 0 24px 16px;
        color: $secondary-blue;

        .df {
            width: 100%;
        }

        .icon-step-check {
            font-size: 12px;
        }

        span.editable-click {
            font-size: 18px;
            font-family: $font-medium;
            width: 100%;
            padding-right: 50px;
        }

        .icon-three-dots-horizontal {
            position: absolute;
            right: -5px;
            top: 50%;
            transform: translateY(-50%) scale(0.4);
            color: #576071;
            pointer-events: none;
        }
    }

    .left-nav-listing-header .detail-filters-wrp .card h4 {
        color: #95A0B0
    }

    .left-nav-listing-header .detail-filters-wrp .card.active h4 {
        color: #374050;
    }

    .listing-container-with-sidebar {
        .main-content-col {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}