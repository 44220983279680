body {
  color: $text-color;
  .form-control:focus {
    border-color: #3392FF;
  }
}

ul {
  list-style: none;
  padding-left: 0;
}



.form-control {
  font-size: 14px;
  padding: 3px;
  font-family: $font-regular;
}

.form-label {
  font-size: 14px;
  font-family: $font-regular;
  color: #576071;
  margin-bottom: 5px;
}

.form-group {

  input[type='text'],
  textarea {
    border: 1px solid #E9EFF5;
    border-radius: 8px;
    min-height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: none !important;
    min-width: 150px;

    &.with-valid-ind {
      background-color: rgba(255, 78, 89, 0.15);
      border-color: #ff4e59;

      &.is-valid {
        background-color: transparent;
        border-color: #E9EFF5;
      }
    }
  }
}

.navbar-brand {
  position: relative;
  padding-left: 70px;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
  color: #33e0ff;
  background-color: transparent;
}


.container {
  width: 100%;

  .main-content {
    padding: 30px 0;
  }

}

.container-fluid.remove-padding-margin {
  padding-left: 0;
  padding-right: 0;
  // >div {
  //  margin-left: 0;
  //  margin-right: 0;
  // }
}

.navbar-default {
  background-color: #464646;
  border: none;
}

.navbar-default .navbar-brand {
  color: #FFF;
}

.input-group {
  .form-control {
    height: 33px;
  }

  .input-group-prepend {
    height: 33px;
  }

  .custom-react-select {
    .custom__control {
      min-height: 33px;
    }
  }

  .input-group-append {
    button {
      height: 33px !important;
      line-height: 1 !important;
    }
  }

}

/*  Custom CSS */
h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  margin-top: 0;
  margin-bottom: 0;
}



select.form-control {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #CCD6E2;
  font-size: 16px;
  box-shadow: none;
  outline: none;
  height: 32px;
  padding: 3px 10px;
}

a,
button {
  outline: 0 !important;
}

// Navs CSS Classes
.right-nav {
  background: #000;
  color: rgba(255, 255, 255, 0.7);
}

.right-nav li.dropdown>a {
  color: rgba(255, 255, 255, 0.7);
  padding: 17.5px 30px;

  &:hover {
    color: #FFF;
  }
}

.cright-nav {
  >.click-overlay {
    z-index: 990;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: -100%;
    opacity: 0;
    transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    background: rgba(0, 0, 0, 0.541176);

  }

  &.opened {
    overflow: hidden;

    >.click-overlay {
      opacity: 1;
      right: 0px;
    }

    >.content {
      transform: translate3d(0px, 0px, 0px);
      overflow-x: hidden;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

      @media screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
        .bottom-btn {
          width: 25%;
        }

        @media(max-width: 1280px) {
          width: 50% !important;

          .bottom-btn {
            width: 50%;
          }
        }
      }
    }
  }

  .modal-close-btn {
    position: absolute;
    right: 0;
    top: 10px;
    padding: 16px;
    border: 0;
    margin: 0;
    line-height: 1 !important;
    background-color: transparent;
    z-index: 1001;

    i {
      font-size: 12px;
      color: #768091;
    }
  }

  >.content {
    >.modal-close-btn {
      transform: none;
    }

    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: fixed;
    top: 0;
    right: 0;
    transform: translate3d(-410px, 0px, 0px);
    // box-shadow: rgba(0, 0, 0, 0.156863) 0px 3px 10px, rgba(0, 0, 0, 0.227451) 0px 3px 10px;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
    z-index: 1001;
    background-color: #FFF;

    .card-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .ff-bold.fz-30 {
        margin-left: 20px;
      }
    }
  }
}

.left-nav {
  .click-overlay {
    z-index: 1200;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: -100%;
    opacity: 0;
    transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    background: rgba(0, 0, 0, 0.541176);
  }

  &.opened {
    .click-overlay {
      opacity: 1;
      left: 0px;
    }

    .content {
      transform: translate3d(0px, 0px, 0px);
    }
  }

  .content {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(-410px, 0px, 0px);
    box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    overflow: hidden;
    width: 400px;
    height: 100%;
    z-index: 1201;
    background-color: #FFF;
    background-image: url(../elements/LeftNav/left-nav-bg.png);
  }
}

/*
.timeline{
    margin-top: 20px;
    .labels{
        text-transform: uppercase;
        position: relative;
        li{
            float: left;
            width: 8.325%;
            text-align: right;
            &:first-child{
                position: absolute;
                text-align: left;
            }
        }
    }
    .background{
        float: left;
        width: 100%;
        background-image: url(../new-theme/images/timeline-bg.png);
        height: 30px;
        background-size: 8.325% 30px;
        position: relative;
    }
    .object{
        background: rgb(255, 255, 255) none repeat scroll 0 0;
        border-radius: 20px;
        color: #000;
        height: 21px;
        line-height: 20px;
        position: absolute;
        text-align: center;
        top: 5px;
        width: 21px;
        font-family: $second-font;
        font-size: 15px;
    }
} */
.open-tickets {
  .note-item {
    padding: 15px 25px;
    border-bottom: 1px solid #c9c9c9;
    position: relative;

    &:after {
      content: ">";
      position: absolute;
      right: 25px;
      top: 14px;
      font-size: 20px;
      color: #898989;
    }
  }
}

.open-ticket-header {
  padding: 20px 25px;
  border-bottom: 1px solid #c9c9c9;
  line-height: 32px;
}

/*  Dashboard Page */

.board-title {
  margin: 0;

  .board-icon {
    display: inline-block;
    color: $light-grey-color;
    font-size: 25px;
    margin-right: 10px;
  }

  .title {
    display: inline-block;
    font-size: 20px;
    margin: 0;
  }
}

.board-content {
  background: #EEE;
  padding-top: 15px;
  padding-bottom: 15px;
}

.call-count-box {
  display: flex;
  background: $super-light-grey-color;
  margin-bottom: 20px;

  .person {
    padding: 20px;

    .value {
      font-size: 50px;
      font-weight: 300;
      margin-right: 15px;
      line-height: 50px;
    }

    &>div {
      font-family: $montserrat-font;
      padding-top: 5px;
    }
  }
}

.delta-icon {
  display: inline-block;
  height: 14px;
  position: relative;
  width: 14px;
  margin: 0 5px;

  &.up {
    border-top: 1px solid red;
    position: relative;
    top: 3px;

    &:before {
      border-bottom: 4px solid white;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      content: "";
      height: 0;
      left: 3px;
      position: absolute;
      top: 5px;
      width: 0;
      z-index: 100;
    }

    &:after {
      content: "";
      border-bottom: 6px solid red;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      height: 0;
      position: absolute;
      width: 0;
      z-index: 99;
      top: 4px;
      left: 1px;
    }

    &.green {
      border-top: 1px solid $green-color;

      &:after {
        border-bottom: 6px solid $green-color;
      }
    }

    &.red {
      border-top: 1px solid $red-color;

      &:after {
        border-bottom: 6px solid $red-color;
      }
    }
  }

  &.down {
    border-bottom: 1px solid;

    &:before {
      border-top: 4px solid white;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      content: "";
      height: 0;
      left: 3px;
      position: absolute;
      top: 5px;
      width: 0;
      z-index: 100;
    }

    &:after {
      content: "";
      border-top: 6px solid red;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      height: 0;
      position: absolute;
      width: 0;
      z-index: 99;
      top: 4px;
      left: 1px;
    }

    &.green {
      border-bottom: 1px solid $green-color;

      &:after {
        border-top: 6px solid $green-color;
      }
    }

    &.red {
      border-bottom: 1px solid $red-color;

      &:after {
        border-top: 6px solid $red-color;
      }
    }
  }
}

.stats-section {
  &>div {
    margin-bottom: 15px;
  }

  h3 {
    margin: 0;
  }
}

.stats-box {
  .stats-chart-area {
    border-top: 1px solid #e5e5e5;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .box-title {
    color: #464646;
  }
}

.stats-chart-area {
  margin-left: -20px;
  margin-right: -20px;
  background: #EEE;
  padding: 20px;
}

.icon-grey {
  color: #cecece;
}

.fa.box-icon {
  font-size: 25px;
}

.stats-item {
  h2 {
    font-size: 45px;
  }

  .status-text {
    font-size: 15px;
    max-width: 140px;
  }
}

.chart-stats {
  td {
    vertical-align: top;
    padding: 5px 0 5px 0 !important;
  }

  h4 {
    margin: 0;
    padding-top: 5px;
    font-size: 12px;
  }
}

.mini-modulue-stats {
  color: #63ddff;

  .value {
    font-size: 45px;

    &.highlight {
      font-weight: bold;
      color: #FFF;
    }
  }

  .percentage {
    font-weight: 300;
    margin-top: 15px;
    font-size: 22px;
  }
}

.right-stick-section {
  margin-top: -14px;
  margin-right: -15px;
}

.snapshot-box {
  padding: 30px 20px 20px;
  background: #f8f8f8;
}

.snapshot {
  .person {
    width: calc(100% - 90px);
    min-width: 350px;

    .title {
      font-size: 20px;
    }

    &>div {
      width: 100%;
    }
  }
}

.side-nav {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  padding-top: 12px;
  float: left;
  width: 100%;
  margin-bottom: 0;

  li {
    border-right: 1px solid #e1e1e1;
    float: left;
    padding: 0 5px 6px 5px;
    text-align: center;
    cursor: pointer;

    button {
      border: none;
      background: transparent;
      padding: 6px 7px;
      border-radius: 2px;
      height: 30px;

      &:hover {
        border-radius: 0px;
        border-bottom: 1px solid #9d9d9d;
      }
    }

    .fa {
      margin-right: 8px;
      color: #777777;
      vertical-align: bottom;
      font-size: 18px;
    }

    &.active {
      button {
        background: #7e7e7e;
        color: #FFFFFF;

        &:hover {
          border-radius: 2px;
          border-bottom: none;
        }
      }

      .icon {
        color: #FFFFFF;
      }
    }
  }
}

.timelines {
  position: relative;
  z-index: 0;

  label.date {
    padding: 8px;
    background-color: #FDFDFD;
  }

  &:before {
    background-color: #d5d5d5;
    content: "";
    left: 4.5px;
    position: absolute;
    top: 24px;
    width: 1px;
    z-index: 0;
    bottom: -12px;
  }

  ul {
    margin-left: 30px;
    margin-bottom: 10px;

    li {
      position: relative;
      padding: 6px 0 6px 30px;
      float: left;
      width: 100%;

      .main-icon {
        font-size: 20px;
        width: 20px;
        float: left;
        line-height: 20px;
        position: absolute;
        left: 0;
      }

      span {
        float: left;
        line-height: 22px;
        font-size: 18px;
      }

      .msg-box {
        background: #f1f1f1;
        padding: 10px;
        font-size: 14px;

        p {
          text-transform: none;
        }
      }

      td.duration {
        .embed-audio-player {
          margin-left: 20px;
        }
      }
    }
  }

  .date-line {
    position: relative;
    z-index: 1;
    width: 100%;
    display: inline-block;

    label.date {
      display: inline-block;
      width: 100%;
    }
  }

  &.middleline {
    width: 100%;
    max-width: 800px;
    z-index: 0;

    &:before {
      left: 50%;
    }

    label.date {
      background: transparent;
      border-bottom: 1px solid #AAA;
      width: 125px;
    }

    .date-line {
      width: 50%;
      margin-left: 50%;

      ul {
        position: relative;

        &:before {
          content: "";
          width: 125px;
          position: absolute;
          height: 0;
          top: 0px;
          left: -9px;
        }

        margin-left: 10px;

        li {
          float: right;
        }
      }

      &.left {
        label.date {
          float: right;
        }
      }

      @media screen and (min-width: 992px) {
        &.left {
          label.date {
            text-align: right;
          }

          margin-left: 0;

          ul {
            margin-left: 0;
            margin-right: 10px;

            &:before {
              left: auto;
              right: -9px;
            }

            li {
              padding: 6px 30px 6px 0;

              .main-icon {
                left: auto;
                right: 0;
              }
            }
          }
        }
      }
    }

    .avatar-contain {
      min-height: 35px;
      padding-left: 50px;
      font-size: 14px;

      .avatar {
        width: 35px;
        height: 35px;
        line-height: 35px;
        left: 0;
      }
    }

    @media screen and (max-width: 991) {
      &:before {
        left: 0;
      }

      .date-line {
        max-width: 400px;
        width: 100%;
        margin-left: 0;

        ul {
          li {
            float: left;
          }
        }
      }
    }
  }

  .call-table {
    border-left: 1px solid #BBB;
    border-top: 1px solid #BBB;

    th {
      font-weight: normal;
      color: #000;
    }

    td {
      font-weight: 300;
    }

    td,
    th {
      border-bottom: 1px solid #BBB;
      border-right: 1px solid #BBB;

      &:first-child {
        width: 34%;
      }
    }
  }
}

// Messages
/*
.chat-messages-list {
  font-size: 15px;
  margin-top: 10px;

  li {
    margin-bottom: 8px;
    width: 100%;
    float: left;
    position: relative;

    .second-font {
      font-size: 12px;
      color: $grey-color;
    }

    .name {
      text-transform: uppercase;
      font-size: 10px;
      display: block;
      margin-bottom: 4px;
      color: #474747;

      b {
        color: #000000;
        font-weight: 400;
      }
    }

    .system_name {
      padding: 10px;
      margin-bottom: 0px;
    }

    .message_holder {
      width: 300px;
      margin-bottom: 12px;
    }

    .mentee_message_box {
      background-color: #e5f6fd;
      padding: 10px;
      border-radius: 2px;
    }

    .mentee_up_arrow {
      display: block;
      margin-left: 30px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #e5f6fd;
    }

    .mentor_message_box {
      background-color: #f1f1f1;
      padding: 10px;
      border-radius: 2px;
      box-shadow: 0px 0px 2px #f1f1f1;
    }

    .mentor_up_arrow {
      display: block;
      position: relative;
      left: 88%;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #f1f1f1;
    }

    .system_message_box {
      background-color: #fcedc2;
      padding: 10px;
      border-radius: 2px;
      font-style: italic;
      color: #474747;
    }

    .padding-bottom-20 {
      padding-bottom: 20px;
    }
  }
}
*/
.chat-messages-list {
  img {
    max-width: 100%;
  }
}

.row.is-padding-8 {
  margin-left: -8px;
  margin-right: -8px;

  >div {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media(max-width: 1599px) {
    &.full-col-in-md {
      >div {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

.row.is-padding-0 {
  margin-left: 0;
  margin-right: 0;

  >div {
    padding-left: 0;
    padding-right: 0;
  }
}

.call-status {
  position: relative;

  >.container-fluid {
    >.row {
      >div {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .right-select {
    position: absolute;
    right: 0;
    top: 0;
    width: 150px;
  }

  .right-select-second {
    margin: 0 auto;
    width: 150px;
    position: absolute;
    right: 140px;
    top: -351px;
  }
}

.center-select {
  margin: 0 auto;
  width: 150px;
}

.status-text2 {
  font-size: 15px;
  text-align: center;
}

a {
  outline: none;
}

@import "./react-big-calendar.scss";

.call-complete-mentee-list {
  display: inline-block;
  min-width: 250px;
}

.checkbox-custom {
  margin: 0;
  margin-left: 15px;

  .checkbox {
    margin: 0px;
  }
}

.rbc-gutter-cell,
.rbc-allday-cell {
  display: none;
}



.mentoring-progress {
  box-shadow: 0 -10px 10px -10px rgba(0, 0, 0, 0.3) inset;
  padding: 12px 15px;

  h2 {
    font-size: 15px;
    line-height: 1;
  }

  .title-desc {
    opacity: 0.7;
  }
}

.DraftEditor-editorContainer ul {
  list-style: disc !important;
  padding-left: 10px;
}



.table td,
.table th {
  vertical-align: middle !important;
}

.react-bs-table-sizePerPage-dropdown .btn.btn-secondary.dropdown-toggle {
  outline: 0;
  box-shadow: none;
  line-height: 1;
  padding: 7px 20px;
  border-radius: 4px;
  background-color: $primary-blue;
  border-color: $primary-blue;
  color: #fff;
  font-size: 16px;
  font-family: $font-medium;

  &:hover {
    background-color: $secondary_blue;
    background-color: $secondary-blue;
    border-color: $secondary-blue;
  }

  &:active:focus {
    box-shadow: none !important;
  }
}

body .btn {
  outline: 0;
  box-shadow: none;
  line-height: 1;
  padding: 6px 20px;
  border-radius: 4px;
  font-size: 16px;
  font-family: $font-medium;
  box-shadow: none !important;

  i {
    font-size: 15px;
    line-height: 1;
  }
}


.btn.btn-link {
  text-align: left;
  text-decoration: none !important;
  font-size: 16px;
  color: $secondary-blue;

  &.nopd {
    padding: 0 !important;
  }

  .nobd {
    border: 0 !important
  }
}

.btn.btn-primary {
  outline: 0;
  box-shadow: none;
  line-height: 1;
  padding: 6px 20px;
  border-radius: 8px;
  background-color: $secondary-blue;
  border-color: $secondary-blue;
  color: #fff;
  font-size: 16px;
  font-family: $font-regular;
  box-shadow: none !important;

  @media(max-width: 1599px) {
    font-size: 13px;
    padding: 6px;
    height: auto;
  }

  &:hover {
    background-color: $primary-blue;
    border-color: $primary-blue;
  }

  &:active:focus {
    box-shadow: none !important;
  }

  &.bordered-button {
    min-width: 48px;
    text-align: center;
    padding: 6px 6px 7px 6px;
    font-size: 13px !important;
    font-family: $font-medium  !important;
    background-color: #fff;
    border: 1px solid $secondary-blue;
    color: $secondary-blue;

    &.is-selected {
      color: #fff;
      background-color: $secondary-blue;
    }

    &.is-disabled {
      pointer-events: none;
      background-color: #E9EFF5;
      border-color: #E9EFF5;
      color: #fff;
    }

    &.is-medium {
      font-size: 16px !important;
      font-family: $font-regular  !important;
      height: unset !important;
    }

    &.is-full {
      width: 100%;
    }

    &.is-big {
      height: 40px;
      font-size: 16px !important;
      font-family: $font-medium  !important;
      padding-left: 20px;
      padding-right: 20px;
    }

    &.is-bordered-disable-btn {
      &.is-disabled {
        color: #CCD6E2;
        border-color: #CCD6E2;
        background-color: #fff;
      }
    }

    &.is-full-width {
      padding: 9px 20px 10px 20px !important;
      height: auto !important;
      font-family: $font-regular  !important;
      width: 100% !important;
      display: block;
    }

    // &:active {
    //   color: $secondary-blue !important;
    //   border: 1px solid $secondary-blue !important;
    // }
  }

  &.is-bordered {
    background-color: transparent;
    border: 1px solid $secondary-blue  !important;
    color: $secondary_blue  !important;
    padding: 6px 12px !important;
  }

  &.blue-disabled {
    background-color: $secondary-blue  !important;
    color: #fff !important;
    border-color: $secondary-blue  !important;
    padding: 6px 12px 6px 8px !important;
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;

    i {
      transform: scale(0.4) !important;
    }
  }

  &:disabled {
    pointer-events: none;
    background-color: #DAE2EB;
    border-color: #DAE2EB;
    color: #fff;
    opacity: 1;
  }

  &.full-width {
    height: 32px;
  }

  &.is-medium {
    height: 36px;
    font-family: $font-medium  !important;
  }

  &.is-small {
    font-size: 14px;
    padding: 6px 16px;
  }

  &.is-red {
    background-color: #FF4E59;
    color: #fff;
    border-color: #FF4E59;
  }

  &.nopd {
    padding: 0 !important;
    background-color: transparent !important;
  }

  &.nobd {
    border: 0 !important;
  }
}

.btn.btn-primary.btn-full-width {
  width: 100%;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  i {
    font-size: 16px;
    margin-right: 10px;
  }

  span {
    color: #fff;
    font-size: 16px;
    font-family: $font-medium;
  }
}

.btn.btn-warning {
  background-color: #ffcc6c !important;
  border-color: #ffcc6c !important;
}

.btn.btn-primary.gray-text-btn {
  border-color: transparent !important;
  background-color: transparent !important;
  color: #768091;
  padding-left: 0 !important;
  padding-right: 0 !important;
  outline: 0;
  box-shadow: none !important;
  font-family: $font-regular;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

a {
  color: $dark-blue;
}

.rbc-calendar {
  background-color: #fff;

  .rbc-time-view {
    border: 0;
  }

  .rbc-time-header {
    background-color: #fff;

    .rbc-header {
      padding-top: 8px;
      padding-bottom: 8px;
      border-left: 1px dashed #CCD6E2;
      border-bottom: 1px dashed #CCD6E2;

      span {
        font-size: 14px;
        line-height: 20px;
        font-family: $font-medium;
        color: #768091;

      }
    }
  }

  .rbc-timeslot-group {
    border-bottom: 1px dashed #CCD6E2;
  }

  .rbc-timeslot-group .rbc-label {
    font-size: 14px;
    font-family: $font-regular;
    color: #768091;
  }

  .rbc-time-slot {
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .rbc-time-content>*+* {
    border-left: 1px dashed #CCD6E2;
  }

  .rbc-time-slot+.rbc-time-slot {

    border-top: 1px dashed #CCD6E2;
  }

  .rbc-timeslot-group .rbc-label {
    border-top: 0;
  }

  .rbc-event {
    background: rgba(0, 148, 243, 0.1) !important;
    border: 2px solid #0094F3;
  }

  .rbc-day-slot .rbc-event-label {
    color: $primary_blue;
    font-family: $font-medium;
    font-size: 12px;
    text-align: center;
    width: 100%;
  }

  .unconfirmed-slot {
    border-color: #768091;

    &.rbc-event {
      background-color: rgba(118, 128, 145, 0.1) !important;
    }

    .rbc-event-label {
      color: #768091;
    }

    .rbc-event-content {
      color: #768091;
    }
  }

  .ooo-slot {
    border-color: #A6B0C1;

    &.rbc-event {
      background-color: rgba(166, 176, 193, 0.1) !important;
    }

    .rbc-event-label {
      color: #A6B0C1;
    }

    .rbc-event-content {
      color: #A6B0C1;
    }
  }

  .confirmed-slot {
    border-color: #9BCE63;

    &.rbc-event {
      background-color: rgba(155, 206, 99, 0.1) !important;
    }

    .rbc-event-label {
      color: #9BCE63;
    }

    .rbc-event-content {
      color: #9BCE63;
    }
  }

  .selected-slot {
    border-color: #0094F3;

    &.rbc-event {
      background-color: rgba(0, 148, 243, 0.1) !important;
    }

    .rbc-event-label {
      color: #0094F3;
    }

    .rbc-event-content {
      color: #0094F3;
    }
  }
}

#content .react-bootstrap-table {
  .table {
    background-color: #fff;
    border: 0 !important;

    thead {
      background: #F6F9FC;
      border-radius: 4px;

      tr {
        th {
          padding: 10px 16px;
          font-family: $font-medium;
          font-size: 16px;
          color: #374050;
          line-height: 18px !important;
          outline: 0 !important;

          label {
            display: none;
          }

          &:active,
          &:focus,
          &:hover {
            label {
              display: block;

              input {
                padding: 4px 8px;
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #E9EFF5;

        td {
          font-size: 16px;
          font-family: $font-regular;
          color: #576071;

          span {
            .custom-styled.table {
              tbody tr {
                border-bottom: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}


.white-bg-modal {
  background-color: #fff;
  padding: 24px;
  height: 100%;

  &.with-fixed-footer {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    >div:first-child {
      flex-shrink: 0;
      flex-grow: 0;
    }

    .modal-fixed-footer {
      flex-shrink: 0;
      flex-grow: 0;
      border-top: 1px solid #E9EFF5;
      padding: 24px 24px 0 24px;
      margin-left: -24px;
      margin-right: -24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    >.scroll-wrp {
      flex-grow: 1;
      // height: calc(100vh - 200px);
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

input.form-control {
  border: 1px solid #CCD6E2;
  box-shadow: none !important;
  border-radius: 4px;
  padding: 3px 10px;
  font-size: 16px !important;
  font-family: "Calibre" !important;
  height: auto !important;

  &.light-gray-bordered {
    border: 1px solid #E9EFF5 !important;
  }

  &.is-red-bordered {
    border: 1px solid #ff4e59 !important;
  }
}

textarea.form-control {
  border: 1px solid #E9EFF5;
  border-radius: 4px;
  background: #FFFFFF;
  font-size: 16px;
  box-shadow: none;
  outline: none !important;
}

.custom-styled-tabs.nav.nav-tabs {
  display: flex;
  justify-content: flex-start;
  border-bottom: none;
  margin-left: 0;
  padding: 0 24px 0 24px;
  flex-wrap: nowrap;
  border-bottom: 1px solid #E9EFF5;
  flex-shrink: 0;

  &.with-horizontal-scroll {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 1px;
    overflow-y: hidden;

    >.nav-item {
      flex-shrink: 0;
    }
  }

  .nav-item {
    padding: 0;
    font-size: 16px;
    color: #768091;
    text-decoration: none !important;
    font-family: $font-regular;
    border: 0;
    padding-bottom: 3px;
    margin-right: 12px;
    background-color: transparent;

    @media(max-width: 1599px) {
      margin-right: 0.6rem;
    }

    @media(max-width: 1599px) {
      margin-right: 12px;
    }

    &.active {
      color: $secondary-blue;
      border-bottom: 2px solid $secondary-blue;
      background: transparent;
      font-family: $font-medium;

    }

    .with-left-btn {
      padding-right: 24px;
      position: relative;

      .close-btn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #CCD6E2;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        i {
          color: #CCD6E2;
          font-size: 16px;
          transform: scale(0.4);
        }
      }
    }



    .nav-link {
      padding: 0;
      border: 0;
      background-color: transparent;
      color: inherit !important;

      &.active {
        color: $primary-blue  !important;
        border-bottom: 2px solid $primary-blue;
      }
    }

    .circle-count {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 14px;
      font-family: $font-medium;
      background-color: #EBF6FF;
      margin-left: 5px;
      color: $secondary-blue;
      padding-bottom: 1px;

      &.has-unread {
        background-color: #ff4e59;
        color: #fff;
      }
    }
  }

  >a:first-child {
    padding-left: 0;
  }

  &.is-big-text {
    .nav-item {
      font-size: 24px;
      color: #95A0B0;

      span {
        font-size: 24px;
        color: #95A0B0;
      }

      &.active {
        color: #374050;
        font-family: $font-medium  !important;

        span {
          color: #374050;
          font-family: $font-medium  !important;
        }
      }
    }
  }

  &.is-no-bottom-line {
    border-bottom: 0 !important;
  }

  &+.tab-content {
    .inner-tab {
      padding: 24px;
    }
  }

  &.copilot {
    padding: 0;

    .nav-item {
      color: #768091;
      font-size: 21px;
      font-family: $font-regular;

      &.active {
        // color: #374050;
        color: $secondary-blue;
        font-family: $font-medium;
      }
    }

    +.tab-content {
      padding-top: 16px;
      padding-bottom: 12px;
    }
  }

  &.have-circle-counts {
    .nav-item {
      padding-bottom: 0;

      &.active {
        border-bottom: 0;

        .custom-tab-link {
          border-bottom: 2px solid $secondary_blue;

          &.with-circle-count {
            .circle-count {
              color: #0094F3;
              background-color: #EBF6FF;
            }
          }
        }
      }

      .custom-tab-link {
        font-size: 21px;
        display: block;
        padding-bottom: 2px;

        @media (max-width: 1599px) {
          font-size: 16px;
        }

        &.with-circle-count {
          margin-right: 30px;
          position: relative;
          padding-bottom: 0;

          .circle-count {
            position: absolute;
            right: -30px;
            top: 50%;
            transform: translateY(-48%);
            margin-left: 0;
            color: #95A0B0;
            background-color: #F6F9FC;
            padding-bottom: 2px;

            &.is-small {
              font-size: 14px;
              font-family: $font-medium;
            }

            &.is-alert {
              color: #FF4E59;
              background-color: #FDECED;
            }
          }

          @media (max-width: 1599px) {
            font-size: 16px;
            margin-right: 21px;

            .circle-count {
              font-size: 12px;
              width: 20px;
              height: 20px;
              right: -24px;
            }
          }
        }
      }
    }
  }

  &.is-small {
    padding: 0;

    .nav-link {
      font-size: 18px;
      color: #768091;
      font-family: $font-regular;
      padding-bottom: 6px;

      &.active {
        font-family: $font-medium;
        color: #374050;
      }
    }

    &+.tab-content {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  &.remove-side-padding {
    padding-left: 0;
    padding-right: 0;
  }

  &.with-side-padding {
    padding-left: 24px;
    padding-right: 24px;
  }
}



.btn.btn-outline-dark {
  font-size: 14px;
  font-family: $font-medium;
  color: #768091 !important;
  padding: 4px 10px !important;
  border: 1px solid #CCD6E2 !important;
  border-radius: 4px !important;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
    opacity: 0.7;
    outline: none !important;
    box-shadow: none !important;
  }
}


.container-true {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

.modal.custom-styled {

  .modal-dialog {
    border-radius: 16px;
    overflow: hidden;

    @media(min-width: 767px) {
      max-width: 680px;
    }
  }

  &.is-width-520 {
    .modal-dialog {
      max-width: 520px;
    }
  }

  &.is-centered {
    margin-top: 20vh;

    .escalate-modal-inner {
      height: 60vh;

      .escalate-scroll-wrp {
        height: calc(60vh - 146px);
      }
    }
  }

  .modal-body {
    padding: 32px 40px;

    .generic-text-area-comp {
      padding: 0;
      background-color: transparent;
    }
  }
}

.modal.is-default {
  display: flex !important;
  align-items: center;
  justify-content: center;

  // .modal-dialog {

  //   @media(min-width: 767px) {
  //     width: 680px;
  //   }
  // }
  .modal-content {
    width: 600px;
  }

  .modal-body {
    padding: 24px;
    border-radius: 16px;
    max-width: 600px;

    .scroll-wrp {
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
      overflow-y: auto;
      height: 64vh;
    }
  }
}

.select-btns-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  label {
    display: inline-block !important;
    border-radius: 40px !important;
    background-color: #fff !important;
    border-radius: 4px !important;
    color: #576071 !important;
    border: 1px solid #95A0B0 !important;
    font-family: $font-medium  !important;
    font-size: 16px !important;
    text-align: left !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
    padding: 6px 35px !important;
    line-height: 1 !important;
    box-shadow: none !important;
    user-select: none !important;
    flex-grow: 0;

    &.active {
      border: 1px solid $secondary-blue  !important;
      background-color: $secondary-blue  !important;
      color: #fff !important;
    }
  }
}

.toggle-button-group {
  display: flex;
  align-items: center;
  justify-content: center;

  &.jcfs {
    justify-content: flex-start;

    label {
      margin: 0 8px 8px 0 !important;
    }
  }

  label {
    border: 1px solid #0094F3;
    border-radius: 8px !important;
    color: $secondary-blue;
    padding: 6px 12px;
    font-size: 16px;
    margin: 4px 8px !important;
    background-color: transparent;
    display: inline-block;
    flex-grow: 0 !important;
    font-family: $font-regular;
    min-width: 84px;

    &:active:not(.without-hover),
    &:hover:not(.without-hover) {
      background-color: $secondary-blue  !important;
      border-color: $secondary-blue  !important;
      color: #fff !important;
      font-family: $font-medium;
    }

    &.without-hover {
      background-color: #fff !important;
      border-color: $secondary-blue  !important;
      color: $secondary-blue  !important;
    }
  }
}



.card {
  .card-header {
    font-size: 20px;
    font-family: $font-medium;
    color: #374050;
    border-radius: 0 !important;
    background-color: transparent !important;
    border-bottom: 1px solid #E9EFF5;

    >* {
      margin-bottom: 0;
      text-transform: none;
    }
  }
}

.account-settings {
  .form-group {
    .form-label {
      font-size: 14px;
      color: #576071;
      font-family: $font-regular;
      margin-bottom: 0.25rem;
      display: block;
      width: 100%;
    }

    .btn.btn-link {
      padding: 0 !important;
      border-bottom: 1px dotted #007BE2;
    }
  }
}

.custom-html-select {
  position: relative;
  border: 1px solid #CCD6E2;
  border-radius: 8px;
  background-color: #fff;


  select {
    min-height: 30px;
    background-color: transparent;
    color: #374050;
    position: relative;
    width: 100%;
    -webkit-appearance: none;
    border: 0;
    padding: 0 30px 0 10px;
    cursor: pointer;

    option {
      font-family: $font-regular;
      font-size: 14px;
      color: #979797;
    }
  }

  &:after {
    content: "\f10d";
    color: #768091;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Mentor1to1';
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    text-rendering: auto;
    pointer-events: none;
  }
}

.custom-styled.btn-group.btn-group-toggle {
  width: 100%;
  border-radius: 8px;

  >label {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-medium;
    color: #768091;
    font-size: 16px;
    background-color: #fff;
    border: 1px solid #CCD6E2;

    &.active {
      background-color: #0094F3;
      color: #fff;
      border-color: #0094F3;
    }
  }
}

.tooltip {
  .tooltip-inner {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(23, 32, 46, 0.1);
    border-radius: 8px;
    border: 0;
    padding: 16px 24px;
    color: #576071;
    opacity: 1;
    font-size: 12px;
  }
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #fff !important;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #fff !important;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-right-color: #fff !important;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-right-color: #fff !important;
}

.nav.nav-tabs.no-borders {
  border: 0;

  .nav-link {
    border: 0;
    padding: 0;
    margin-right: 16px;
    font-size: 21px;
    color: #95A0B0;
    display: inline-block;

    &.active {
      border: 0;
      // font-family: $font-medium;
      // color: $secondary-blue;
      // margin-right: 31px;
      color: #374050;
      border-bottom: 2px solid $secondary-blue;
    }
  }
}

.bs-tooltip-left {
  .arrow {
    &:before {
      border-left-color: #fff !important;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      border-color: transparent;
      border-style: solid;
      border-left-color: rgba(23, 32, 46, 0.01);
      border-width: 0.5rem 0 0.5rem 0.5rem;
      top: -0.05rem;
      z-index: 1;
    }
  }
}

.badge.is-round {
  padding: 3px 8px 5px 8px;
  font-size: 14px;
  line-height: 1 !important;
  min-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: $font-medium;
  color: #0094F3;
  background-color: #EBF6FF;
  border-radius: 16px;
  font-weight: 400 !important;
  line-height: 1 !important;
  vertical-align: top;
  min-width: 24px;
  white-space: pre-wrap;

  &.is-invalid {
    color: #95A0B0;
    background-color: #F6F9FC;
  }

  &.is-regular {
    font-family: $font-regular;
  }

  &.is-border {
    border-radius: 12px;
    border: 1px solid #E9EFF5;
    padding: 2px 8px 3.3px 8px;
    font-family: $font-medium;
    background-color: transparent;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .icon-wrp {
      margin-right: 6px;
      margin-top: -0.8px;

      &.is-star {
        margin-top: -2.5px;
      }

      svg {
        width: 12px;
      }
    }

    &.is-blue {
      background-color: transparent;
      border-color: #0094F3;
      color: #0094F3;
    }
  }

  &.is-gray-border {
    border: 1px solid #E9EFF5;
    border-radius: 5px;
    background-color: transparent;
  }

  &.with-close-btn {
    .icon-remove-in-circle {
      opacity: 0;
      width: 0;
      transition: all ease 0.3s;
      margin-left: 0;
      color: #95A0B0;
      font-size: 11px;
      margin-top: 2px;
      cursor: pointer;
    }

    &:hover {
      .icon-remove-in-circle {
        opacity: 1;
        width: 10px;
        margin-left: 8px;
      }
    }
  }

  &.is-blue {
    color: #0094F3;
    background-color: #EBF6FF;
    border-radius: 12px;
  }

  &.is-red {
    background-color: #FF4E59;
    color: #fff;
    border-color: #FF4E59;
  }

  &.is-orange,
  &.badge-warning {
    color: #F59300;
    border-color: #F59300;
    background-color: #FFF6E0;
  }

  &.is-green {
    color: #88C04E;
    border-color: #88C04E;
  }

  &.is-brown {
    color: #85485F;
    border-color: #85485F;
  }

  &.is-blue-txt {
    padding: 2px 6px;
    height: 20px;
    background: #0094F3;
    border-radius: 12px;
    line-height: 1;
    color: #fff;
    font-size: 14px;
  }

  &.is-red-bubble {
    background: #FDECED;
    border-radius: 12px;
    color: #FF4E59;
    font-size: 12px;
    font-family: $font-medium;
    height: 24px;
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.badge.badge-primary.is-red {
  background-color: #FF4E59;
  color: #fff;
  border-color: #FF4E59;
}

.table.custom-styled {
  thead {
    border-bottom: 1px solid #E0E8F0;

    th {
      padding: 10px 8px;
      line-height: 1.2;

      &:first-child {
        padding-left: 24px;
      }

      &:last-child {
        padding-right: 24px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 24px 8px;
        line-height: 1.2;

        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
        }

        .editable-click {
          padding: 0;
        }
      }
    }
  }

  .table.custom-styled {
    tr {
      border-bottom: 0;
      border-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      th {
        border-top: 0;
        padding: 8px !important;
        background-color: transparent;
        text-align: center;

        &.w-3-1 {
          width: 33.3333%;
        }

        &.w-2-1 {
          width: 50%;
        }
      }

      td {
        padding: 8px !important;
        text-align: center;

        &.w-3-1 {
          width: 33.3333%;
        }

        &.w-2-1 {
          width: 50%;
        }
      }
    }
  }
}

.select-none.btn-outline-success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: $secondary-blue;
  ;
  border-color: $secondary-blue;

  >div.crcw.cr_Badge {
    margin-left: 4px;
  }

  &:hover {
    background-color: transparent !important;
  }

  &.is-blue {
    background-color: #EBF6FF;
    color: #0094F3;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.bs-popover-bottom {
  .editable-textfield-wrp {
    border: 0 !important;
    padding: 0;
  }
}



.custom-styled.modal {
  &.is-padding24 {
    .modal-header {
      padding: 24px;
      border-bottom: 0;
    }

    .modal-body {
      padding: 0 24px 24px 24px;
    }

    .modal-footer {
      padding: 24px;
    }
  }

  input.opentext-input {
    border-radius: 4px;
    padding: 8px 12px;
  }

  .opentext-input {
    textarea {
      border-radius: 4px;
      padding: 8px 12px;
    }
  }

  .custom-react-select {
    .custom__control {
      border: 1px solid #E9EFF5;
      height: 36px;
    }
  }
}

.btn {
  font-size: 14px;
  font-family: $font-regular;
}

.btn.btn-danger {
  color: #fff;
  background-color: #FF4E59;
  border-color: #FF4E59;
}

.btn.btn-success {
  color: #fff;
  background-color: #0094F3;
  border-color: #0094F3;
}

.btn.btn-primary.is-circle {
  background-color: #0094F3;
  border: 1px solid #0094F3;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  i.icon-phone-fill {
    color: #fff;
    font-size: 20px;
  }

  &.is-small {
    width: 20px;
    height: 20px;
    padding: 0;

    i {
      font-size: 10px !important;
    }

    i.fa-play {
      margin-left: 1px;
    }
  }
}

.btn.is-circle {
  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }

  svg {
    pointer-events: none;
  }

  &.btn-hangup {
    background-color: #FF4E59;

    svg {
      color: #fff;
    }
  }

  &.btn-call {
    background-color: #0094F3;

    svg {
      color: #fff;
    }
  }

  &.btn-mute {
    background-color: #768091;

    svg {
      color: #fff;
    }
  }

  &.btn-unmute {
    background-color: #A8B6C7;

    svg {
      color: #fff;
    }
  }

  &.btn-plus {
    background-color: #A8B6C7;

    svg {
      color: #fff;
    }
  }

  &.btn-minus {
    background-color: #A8B6C7;

    svg {
      color: #fff;
    }
  }

  &.btn-dial {
    background-color: #A8B6C7;

    svg {
      color: #fff;
    }
  }

  &.btn-disabled {
    background-color: #CCD6E2;

    svg {
      color: #fff;
    }
  }
}

.btn.btn-danger.is-circle {
  background-color: #FF4E59;
  border: 1px solid #FF4E59;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    color: #fff;
  }
}

.btn.btn-gray {
  background-color: #A8B6C7;
  border: 1px solid #A8B6C7;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  i.icon-plus {
    color: #fff;
    font-size: 20px;
  }
}

.circle-icon-wrp {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0094F3;
  border: 2px solid #0094F3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  svg {
    color: #fff;
  }
}

.modal-loading-ind.modal {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

#content {
  .switch {
    border: 1px solid #A8B6C7;
  }

  .switch.on {
    background-color: #0094F3;
  }
}

.badge.badge-pill {
  border-radius: 16px;
  font-size: 14px;
  padding: 3px 8px 4px 8px;
  font-family: $font-medium;
  letter-spacing: 0;
  color: #fff;
  flex-shrink: 0;
  letter-spacing: -0.4px;
  background-color: #fff6e0;
  font-weight: 400 !important;
  line-height: 1 !important;

  &.is-filter {
    background-color: #fff6e0;
    color: #f59300;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1 !important;
    padding: 2px 8px 4px 8px;
    border-radius: 16px;

    .icon-times-btb {
      margin-top: 2px;
      font-size: 16px;
      transform: scale(0.6);
      color: #ffcc6c;
    }
  }

  &.badge-warning {
    background-color: #ffaa00;
  }

  &.badge-primary {
    background-color: #0094f3;
  }

  &.badge-success {
    background-color: #9bce63;
  }

  &.is-outline {
    &.badge-warning {
      border: 1px solid #ffaa00;
      border-radius: 6px;
      color: #ffaa00;
      background-color: transparent;
    }

    &.badge-primary {
      border: 1px solid #0094f3;
      border-radius: 6px;
      color: #0094f3;
      background-color: transparent;
    }

    &.badge-success {
      border: 1px solid #9bce63;
      border-radius: 6px;
      color: #9bce63;
      background-color: transparent;
    }
  }

  &.circle-count {
    height: 24px;
    text-align: center;
    border-radius: 12px;
    font-size: 14px;
    line-height: 1;
    font-family: $font-medium;
    min-width: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 6px;
    letter-spacing: 0 !important;

    &.is-gray {
      color: #a8b6c7 !important;
      background-color: #f6f9fc !important;
    }

    /*background-color: #F6F9FC;
    color: #95A0B0; */

    &.is-red {
      color: #fdeced !important;
      background-color: #ff4e59;
    }

    &.is-blue {
      background-color: #ebf6ff;
      color: $secondary-blue;
    }
  }
}

.w-3-1 {
  width: 33.33% !important;
}

.w-2-1 {
  width: 50% !important;
}