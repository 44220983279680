.match-panel {
  float: left;
  width: 100%;
  min-height: 400px;
}

.cancel-filter {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 30px;
  z-index: 99;
  transition: all ease 0.3s;
  padding: 0;
  line-height: 1 !important;
  border: 0;
  background: transparent;
  ;

  &:hover {
    opacity: 0.7;
  }

}

.match_top_bar {
  // position: relative;
  // .new-match-filters {
  //   position: absolute;
  //   top: 100%;
  //   right: 0;
  //   width: 900px;
  //   box-shadow: 0 2px 31px rgba(0,0,0,0.15);
  //   z-index: 3;
  // }
  .filter-searchbox {
    margin-bottom: 0;

    .input-group.search-input-group {
      margin-top: 0;
    }
  }

  .search-input-group {
    margin-top: 8px;
  }
}

.filter-searchbox {
  margin-bottom: 15px;

  .pull-right {
    float: none;
  }

  .input-group.search-input-group {
    display: flex;

    input {
      font-size: 16px;
      font-family: $font-regular;
      color: #374050;
      height: 32px;
      box-shadow: none;
      background-color: #fff;
      border: 1px solid #CCD6E2;
      border-radius: 4px;
    }

    .input-group-btn {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      overflow: hidden;

      >button:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    button {
      height: 31px;
      text-align: center;
      border-radius: 0;
      padding-top: 0;
      padding-bottom: 0;

      i {
        line-height: 31px;
        color: #fff;
        font-size: 14px;
      }
    }
  }
}

.button-icon {
  font-size: 14px !important;
}

.match-filters {
  &.inactivefilters {
    float: right;
    height: 36px;
  }

  &>li {
    float: left;
  }

  .inactive-filter {
    color: #949eac;
    cursor: pointer;
    margin-right: 10px;
    padding: 10px 10px 10px 10px;

    @media(max-width: 820px) {
      padding: 5px;
    }

    &>.ic {
      margin-right: 10px;
      width: 23px !important;
      height: 23px !important;
      background-size: 100% 100% !important;
      background-position: 0 0 !important;

      &.__filter-age-inactive {
        width: 30px !important;
        height: 23px !important;
      }
    }

    &:hover {
      color: #5d22bf;
    }

    &.button-gender-filter:hover {
      color: #5d22bf;
    }

    &.button-age-filter:hover {
      color: #e74c3c;
    }

    &.button-ethnicity-filter:hover {
      color: #feaa19;
    }

    &.button-currentTreatments-filter:hover {
      color: #3498db;
    }

    &.button-languages-filter:hover {
      color: #1abc9c;
    }
  }

  .active-filter {
    color: #2b3d53;
    margin-right: 10px;
    padding: 10px 0;
    position: relative;
    width: 200px;

    .filter-header {
      border-bottom: 1px dashed #2b3d53;
      padding-bottom: 10px;
      width: 100%;
      font-size: 15px;
    }

    .filter-header>.ic {
      margin-right: 10px;
      width: 23px !important;
      height: 23px !important;
      background-size: 100% 100% !important;
      background-position: 0 0 !important;

      &.__filter-age-active {
        width: 30px !important;
        height: 23px !important;
      }
    }

    .match-number {
      color: #888f9b;
      margin-left: 10px;
    }
  }
}

.filter-content {
  height: 150px;
  width: 100%;

  .filter-values {
    max-height: 150px;
    overflow: auto;
    line-height: 24px;
    padding-top: 5px;
    padding-bottom: 5px;

    &>li {
      background-image: url(../new-theme/images/icons/filter-check.png);
      background-repeat: no-repeat;
      background-position: 1px 4px;
      position: relative;
      padding-left: 20px;
      cursor: pointer;
      margin-bottom: 5px;
      font-size: 14px;
      background-size: 14px;
      color: #768091;

      .icon-check {
        position: absolute;
        left: 1px;
        top: 4px;
        display: none;
      }

      &.checked {
        .icon-check {
          display: block;
        }
      }
    }
  }

  &.age {
    padding: 4px;

    .label {
      font-size: 16px;
      color: #2b3d53;
      font-weight: 400;
    }

    li.checked {
      color: #e74c3c;
    }
  }

  &.gender {
    li.checked {
      color: #5d22bf;
    }
  }

  &.ethnicity {
    li.checked {
      color: #ffa200;
    }
  }

  &.currentTreatments {
    li.checked {
      color: #3f91c6;
    }
  }

  &.languages {
    li.checked {
      color: #1ebd9e;
    }
  }

  &.educationLevel {
    li.checked {
      color: #278900;
    }
  }

  &.coInfection {
    li.checked {
      color: #5bb89d;
    }
  }

  &.sexualOrientation {
    li.checked {
      color: #bc00d8;
    }
  }
}

.customslider {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1) inset;
  height: 20px;

  .bar {
    height: 100%;
  }

  .bar-1 {
    background: #398AE9;
  }

  .handle {
    background: none;
    background-image: url(../new-theme/images/slider-handle.png);
    border: none;
    height: 39px;
    top: -10px;
    width: 22px;
    outline: none;
  }
}




/**
 * CSS For new design
 */
.match-with-mentors {
  padding: 0;

  .row {
    margin-left: 0;
    margin-right: 0;

    .col-8 {
      padding-left: 0;
      padding-right: 0;
      height: 100%;
      min-height: calc(100vh - 100px);
      border-right: 1px solid #E9EFF5;

    }

    .col-4 {
      padding: 24px 0 24px 24px;

      .cart-section {
        .cart-header {
          .cart-number {
            background: #0094F3;
            border-radius: 4px;
            color: #fff;
            width: 24px;
            height: 24px;
            line-height: 23px;
            text-align: center;
            font-size: $font-medium;
          }
        }

        .cart-content {}
      }
    }
  }

  .match_top_bar {
    .gray-box.filters-wrp {
      margin-right: 24px;
      width: auto;
      margin-bottom: 0 !important;
      padding: 24px;

      .filter-wrp.each-filter {
        width: 33%;
      }
    }
  }

  .cright-nav>.content {
    background-color: #fff !important;
  }
}

.match_top_bar.gray-box {
  padding: 0;
}

.new-match-filters.filters-wrp {
  padding: 0;
}

.new-match-filters {

  .Select--multi {
    .Select-control {
      background: #FFFFFF;
      border: 1px solid #DAE2EB;
      border-radius: 4px;
      box-shadow: none !important;
      position: relative;
      padding-right: 45px;
      height: 40px;

      .Select-multi-value-wrapper {
        padding: 8px 0 0 8px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;

        .Select-input {
          display: none !important;
        }

        .Select-value {
          border-radius: 4px;
          background-color: $secondary-blue;
          padding: 4px 18px 4px 8px;
          position: relative;
          line-height: 1 !important;
          margin-right: 8px;
          margin-bottom: 8px;
          margin-left: 0 !important;
          margin-top: 0 !important;
          overflow: hidden;

          .Select-value-icon {
            position: absolute;
            right: 0;
            top: 0;
            width: 16px;
            height: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            padding: 0;
          }

          .Select-value-label {
            padding: 0 !important;
          }
        }
      }

      .Select-arrow-zone {
        padding: 8px !important;
        width: auto !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;

        .Select-arrow {
          width: 24px;
          height: 24px;
          border-radius: 4px;
          background-color: #F1F5F9;
          border: 0 !important;
          position: relative;
          pointer-events: none;

          &:before {
            content: '+';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            color: #768091;
            font-size: 16px;
            transform: scale(1.5);
          }
        }
      }
    }

    .Select-value-icon:hover {
      background-color: $secondary-blue;
      opacity: 0.8;
    }
  }

  span.Select-aria-only {
    display: none !important;
  }

}

.filter-wrp {
  position: relative;
  margin-bottom: 12px;

  .filter-title {
    p {
      cursor: pointer;
      margin-bottom: 0;

      span {
        display: inline-block;
      }
    }
  }

  .filter-content.age {
    padding: 0;
    height: auto;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow: hidden;

    .age-input {
      font-size: 16px;
      font-family: $font-medium;
      text-align: center;
      box-shadow: none;
      outline: 0;
      width: 55px;
      border: 1px solid #DAE2EB;
      border-radius: 5px;
      text-align: center;
      width: 50%;
      padding-top: 3px;
      padding-bottom: 3px;
      height: 32px;
      background-color: #fff;
    }


    .age-slider-wrp {
      background-color: #ebebeb;
      padding: 15px;
    }
  }
}


.match-with-mentors .modal-dialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  max-width: none;
  margin: 0;

  .modal-content {
    max-width: 540px;

    * {
      font-size: 14px;
      font-family: $font-regular;
    }
  }
}

.match-wizard-wrp {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  & + div {
    padding-top: 72px;
  }
}


.peer-mentee-match-wrp {
  &.is-gray-out {
    > * {
      opacity: 0.6;
      filter: grayscale(100%);
      pointer-events: none !important;
    }
    & + .outreach-main-content-footer {
      .is-diabled-when-wizard-open {
        filter: grayscale(100%);
        pointer-events: none !important;
      }
    }
  }
}