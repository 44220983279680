.sidebar-compo.is-cms {
  background-color: #fff;
}

.sidebar-compo.col-xl-3>.inner-col {
  background-color: #fff;
  padding: 0 18px;
  margin-left: -15px;
  margin-right: -15px;
  transition: none;
  display: flex;
  flex-direction: column;

  .sidebar-content-main {
    flex-grow: 1;
    overflow-y: auto;
    margin-left: -18px;
    margin-right: -18px;

    // padding-left: 18px;
    // padding-right: 18px;
    .custom-input-compo {
      position: relative;
    }
  }
}

.sidebar-compo.col-xl-1>.inner-col {
  background-color: #eee;
  padding: 0 18px;
  margin-left: -15px;
  margin-right: -15px;
  transition: none;
  background-color: #17202e;
}

.sidebar-compo {

  .materials-sent-list {
    margin-left: 0;
    margin-right: 0;
  }


  >.inner-col {
    min-height: 100vh;
  }

  .sidebar-header {
    background-color: #fff;
    margin-left: 0;
    margin-right: 0;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid #e9eff5;
    padding: 17px 24px 0 24px;

    .btn-wrp {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 12px;

      .menu-btn {
        border: none;
        background-color: transparent;
        width: 21px;
        padding: 0;
        cursor: pointer;
        margin-top: -5px;

        i {
          font-size: 17px;
          color: #768091;
        }
      }
    }

    .nav.nav-tabs {
      display: flex;
      justify-content: flex-start;
      border-bottom: none;
      margin-left: 0;
      margin-left: -20px;
      margin-right: -20px;
      flex-wrap: nowrap;

      .nav-item {
        padding: 0 12px;
        border: 0;

        @media (max-width: 1399px) {
          padding: 0 7px;
        }

        a {
          padding: 0;
          font-size: 16px;
          color: #576071;
          text-decoration: none !important;
          font-weight: 500;
          border: 0;
          font-family: $font-medium;
          padding-bottom: 3px;

          span {
            white-space: nowrap;
          }
        }

        &.current {
          a {
            color: $secondary-blue;
            border-bottom: 2px solid $secondary-blue;

          }
        }
      }

      >div:first-child {
        padding-left: 0;
      }
    }
  }

  .recent-activities-compo {
    margin-left: 0;
    margin-right: 0;
  }
}



.generic-notepad-compo {
  .note-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .meta-wrp {
      i {
        color: #95a0b0;
        font-size: 14px;
        margin-left: 8px;
      }
    }
  }

  .notes {
    padding-left: 0;
    padding-right: 0;

    .sticky-note {
      background-color: #fff;
      // margin-left: -24px;
      // margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 16px;
      padding-top: 16px;
      border-bottom: 1px solid #e9eff5;
    }
  }

  .note-main {
    text-align: initial;
  }
}

.admin-mentee-notes {
  .generic-notepad-compo {
    .notes {
      margin-left: 24px;
      margin-right: 24px;
      padding-bottom: 0 !important;

      .sticky-note {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
      }

      .note-meta {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
}

.sidebar-compo,
.sidebar-content-main {
  .notes-header {
    // margin-left: -18px;
    // margin-right: -18px;
    background-color: #fff;
  }

  .notes {
    padding-left: 0;
    overflow: unset;

    .sticky-note {
      background-color: #fff;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 16px;
      padding-top: 16px;
      border-bottom: 1px solid #e9eff5;

      .note-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .meta-wrp {
          i {
            color: #95a0b0;
            font-size: 14px;
            margin-left: 8px;
          }
        }
      }

      .note-main {
        text-align: initial;
      }
    }

    .tag-area {
      margin-top: 5px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      .issue-tag {
        font-size: 16px;
        border-radius: 5px;
        padding: 1px 5px;
        cursor: pointer;
        margin: 0 10px 5px 0;
        color: #fff;
      }
    }
  }
}

.sidebar-content-main {


  .menu-closed .nav-item {
    border: none !important;
  }

  .mentee-notes-compo {
    padding: 24px;

    .gradient-loader-wrp {
      left: 24px !important;
      right: 24px !important;
      top: 16px !important;
      bottom: 16px !important;
    }
  }

  .recent-activities-compo {
    margin-left: 0;
    margin-right: 0;
    padding-top: 16px;
  }

  .quality-metrics-components-wrp {
    margin-left: 0;
    margin-right: 0;
  }
}

// Message Tab Style
.sidebar-messages-tab {
  height: 100%;
  position: relative;

  .recent-activities-compo,
  .cop-sidebar-materials {
    padding: 0 !important;
  }



  #messages-tab-accordion {
    border-bottom: 1px solid #e9eff5;
  }

  .MuiTabPanel-root {
    padding-left: 0;
    padding-right: 0;
  }

}

.messages-wrp {
  background: #FFFFFF;

  input.long-placeholder-input {
    font-family: $font-regular;
    font-size: 16px;
    flex-grow: 1;
    outline: none !important;
    border: 1px solid #e9eff5;
    border-radius: 8px;
    padding: 12px;
    width: 100%;
  }

  .messages-header {
    background-color: #fff;
    display: flex;
    justify-content: center;
    background: #ffffff;
    margin-bottom: 16px;

    button {
      display: none;
      /*
        border-radius: 0;
        font-family: $font-bold;
        font-size: 17px;
        text-transform: uppercase;
        line-height: 1;
        padding-top: 9px;
        */
    }
  }

  .chat-messages-list {
    .each-message {
      border-bottom: 1px solid #e0e8f0;
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
  }
}

.notes-header-filters {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  align-items: center;
  margin-left: 0;
  margin-right: 0;

  .filter-dropdown-trigger {
    padding: 0 !important;
    line-height: 1;

    .dropdown-toggle.nav-link {
      padding: 0;
      padding-right: 10px;
      text-align: right;
      margin-top: -1px;

      i {
        color: #576071 !important;
        font-size: 20px;
      }

      &::after {
        content: none;
      }
    }

    .icon.icon-filter {
      margin-top: 3px;
    }

    .dropdown-menu.show {
      padding: 14px 23px;
      padding: 14px 23px;
      width: 240px !important;

      .dropdown-header {
        padding: 0;
        text-align: left;
      }

      .nav-item {
        margin-top: 15.3px;
        font-size: 16px;
        font-family: $font-regular;
        color: #576071;
        cursor: pointer;
      }

      & div:first-child {
        margin-top: 0;
      }
    }
  }
}

.bot-message-input {
  border: 1px solid #e9eff5 !important;
  border-radius: 4px !important;
  padding: 8px !important;
  height: auto !important;
  font-family: $font-regular;
  font-size: 15px;
  flex-grow: 1;
  outline: none !important;
}

.sidebar-compo.col-lg-1 {
  flex: 0 0 70px;
  max-width: 70px;

  .sidebar-header.menu-closed {
    background-color: #17202e;
    padding-left: 6px;
    padding-right: 6px;

    .btn-wrp {
      text-align: center;

      .menu-btn {
        text-align: center;
        width: 100%;
        margin-left: -6px;

        i {
          color: #fff;
          transform: rotate(180deg);
        }
      }
    }

    >.nav.nav-tabs {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
      margin: 0;

      .nav-item {
        padding: 0;
        width: 100%;

        a {
          font-size: 12px;
          color: #fff;
          text-align: center;
          padding-bottom: 16px;
          display: block;
          width: 100%;
        }

        &.current {
          a {
            border-bottom: 0 !important;
          }
        }
      }
    }
  }
}



.logistical-support-compo.is-in-sidebar {
  .each-logistical-issue {
    border: 1px solid #e9eff5;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 16px;

    .badge {
      border-radius: 12px;
      color: #fff;
      font-size: 14px;
      font-family: $font-regular;
      padding: 2px 8px 4px 8px;
    }
    .mt-3 .step-tabs-compo .step-tab-content {
      min-height: unset;
    }
  }

  .steplet-container {
    .title-with-space {
      margin-bottom: 0;
      border: 1px solid #e9eff5 !important;
    }
  }
}

.copilot-right-sidebar-wrp {
  .block-question-compo {
    .block-table-header {
      font-size: 12px !important;
      line-height: 1.1 !important;

      >div:first-child {
        width: 30%;
      }
    }

    .checkmark-radio-group {
      >div:first-child {
        width: 30% !important;
        padding: 8px 0 !important;
        font-size: 12px !important;
        line-height: 1.1 !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      >div:last-child {
        width: 70% !important;

        .custom-checkbox-mark {
          margin-left: auto !important;
          margin-right: auto !important;
        }

        .talking-point-checkbox {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}