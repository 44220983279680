

.dial-pad {
  width: 100%;
  max-width: 450px;
  border-radius: 16px;
  background-color: #fff;
  padding: 60px;
  z-index: 99999 !important;
  .MuiPaper-root {
    margin-top: 16px !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25) !important;
  }
  .dial-inner {
    display: flex;
    flex-wrap: wrap;
    width: 320px;
    justify-content: space-between;
    .dial-number-btn {
      margin: 1%;
      cursor: pointer;
      border: 0;
      background-color: transparent;
      text-align: center;
      width: 31%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      transition: all 0.3s ease;
      border-radius: 4px;
      padding: 8px;
      .fz-46 {
        line-height: 1 !important;
      }
      &:hover {
        background-color: $super-light-grey-color;
      }
    }
  }
}

.hangup-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #FEF1F2;
  border-radius: 28px;
  padding: 0 16px 0 0;
  margin-right: 24px;
  .text-red2 {
    color: #F5000F;
  }
}