.inq-date-picker-range {
  border: 1px solid #E0E8F0;
  box-sizing: border-box;
  border-radius: 4px;
  width: 240px;
  padding: 4px 8px;
  font-size: 16px;
  color: #374050;
  height: 36px;
}

.pn-out-office-compo {
  height: 100%;

  .inq-card {
    height: 100%;
    max-width: 660px;

    .react-datepicker-wrapper {
      width: auto;
    }
  }
}

.pn-mentee-list-table-wrp {
  div.rainbow-table_header-container>span {
    color: #768091 !important;
  }
}

.hover-underline {
  transition: all 0.3s ease;

  &:hover {
    text-decoration: underline;
  }
}

.last-note-list {
  .each-note {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #E0E8F0;
  }
}


// .site-nav .outbound-calls-buttons-list {
//   button {
//     width: 32px;
//     height: 32px;
//     margin-right: 6px;
//   }
// }

.admin-pn-tasks-list-compo {
  max-width: 800px;
  margin-bottom: 24px;


  .todo-list-scroll-wrapper {
    overflow-x: hidden;
    margin-left: -16px;
    margin-right: -16px;
    padding-inline: 16px;
  }
}

#outbound-call-modal {
  position: fixed;
  top: 60px;
  left: calc(50vw - 420px);
  z-index: 99;
  @media(max-width: 1024px) {
    left: 5vw;
    width: 90vw;
  }

  #draggable-dialog-div {
    width: 100%;
  }
}

.outbound-calls-buttons-list {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  button.MuiIconButton-root {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin-right: 8px;
    &.grey-btn {
      background-color: #A8B6C7;
      color: #fff;
    }
    &.green-btn {
      background-color: $green-color;
      color: #fff;
    }
    &.red-btn {
      background-color: $red-color;
      color: #fff;
    }
  }
}