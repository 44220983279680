/**
 * Talking points
 */
.talking-points-wrp {
  position: relative;

  .Select {
    width: 90%;
    margin: 0 auto;
  }

  .Select .Select-control .Select-multi-value-wrapper .Select-placeholder {
    color: #fff !important;
    padding-left: 21px;
    padding-right: 21px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .Select-input>input {
    margin-top: 8px;
  }

  .Select-menu-outer {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-left: 12px;
    margin-right: 12px;
    width: 100%;
    width: calc(100% - 26px);
    border-bottom-left-radius: 19px !important;
    border-bottom-right-radius: 19px !important;
  }

  .Select-control {
    border-radius: 19px;
    padding: 0 21px;
    padding-right: 36px;
    margin-bottom: 18px;
    background-color: #007be2;
    border: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    color: #7f7f7f;
    line-height: 1;
    position: relative;
    height: 38px !important;

    .Select-multi-value-wrapper {
      height: 32px;
      max-width: 100%;

      .Select-value {
        color: #fff;
        display: flex;
        align-items: center;
        padding: 0 21px;
      }

      .Select-input {
        padding: 0;
        height: 32px;
        color: #fff;
        max-width: 100%;

        * {
          color: #fff;
          max-width: 100%;
          margin: 0;
        }
      }
    }

    span {
      line-height: 1;
      margin-right: 8px;
    }

    i {
      font-size: 16px;
      color: #fff;
      margin-right: 7px;
    }

    .fa.fa-caret-down {
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      margin-right: 0;
    }

    .Select-arrow-zone {
      position: absolute;
      right: 0px;
      color: #fff;
      top: 50%;
      height: 15px;
      transform: translateY(-50%);

      .Select-arrow {
        border-color: #fff transparent transparent;
      }
    }
  }

  &.is-issue-area {
    position: relative;
    margin-top: 8px;
    margin-bottom: 8px;

    >div {
      margin-bottom: 8px;
      position: relative;
      z-index: 1;
      padding-bottom: 2px;

      .custom-checkbox-mark {
        background-color: #fff;
      }

      .custom-checkbox .checkbox-lbl .check-desc {
        padding-right: 60px;
        width: 100%;
      }
    }
  }
}

.talking-points-listing {
  margin-right: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #fff;
  border-top: 1px solid #e1e1e1;

  >.row {
    margin-left: 0;
    margin-right: auto;

    >.col {
      padding-left: 0;
      padding-right: 0;
    }
  }

  >.row>.col {
    border-bottom: 1px solid #ebebeb;

    >.list-group-item {
      border: none;
      // border-bottom: 1px solid #EBEBEB;
      margin-bottom: 0;
      padding-left: 0;
      position: relative;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 0;
      padding-right: 30px;
      margin-left: 0;
      padding-left: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .icon {
        position: absolute;
        right: 18px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        color: #95a0b0;
        cursor: pointer;
        pointer-events: none;
      }

      .in-circle {
        margin-left: 18px;
        width: 18px;
        height: 18px;
        line-height: 18px;
        border-radius: 9px;
        background-color: #f6f9fc;
        color: #95a0b0;
        text-align: center;
        font-size: 12px;
        font-family: $font-medium;
        display: inline-block;
        text-decoration: none !important;
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.listing-collapsed {
        border-bottom: none;
      }
    }

    .list-group {
      margin-left: 24px;

      label {
        margin-bottom: 0;
      }

      .list-group-item {
        border: 0;
        background-color: #f6f9fc;
        padding: 12px 16px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        img {
          width: 13px;
          margin-right: 10px;
        }

        input[type='checkbox'] {
          margin-right: 7px;
        }

        .icon {
          color: #95a0b0;
        }
      }

      &.show {
        background-color: #f6f6f6;
        margin-bottom: 20px;
      }
    }
  }

  >div:last-child>.col>.list-group-item {
    border-bottom: none !important;
  }
}

.tp-status-progress-bar {
  position: fixed;
  top: 194px;
  left: 0;
  width: 90px;
  height: 320px;
  border-right: 1px solid #a8b6c7;

  .status-info-wrp {
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    border-right: 2px solid #0094f3;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}



.talking-points-list {
  >span {
    display: block;
  }

  .each-guider-item {
    padding-bottom: 12px;
    position: relative;
    padding-right: 44px;

    >div {
      margin-bottom: 0;
    }

    >div {
      z-index: 2;
      position: relative;
    }

    /*.checkbox-lbl .check-desc .font-medium {
      font-family: $font-regular !important;
    }*/

    &.is-checked {
      &:before {
        height: calc(100% - 16px);
      }

      >div {
        z-index: 2;
        position: relative;
      }
    }

    &.without-check {
      padding-left: 24px;
      padding-top: 0;

      &:before {
        height: calc(100% + 4px);
        top: 0;
      }

      .gray-bd-box {
        border-width: 1.5px;
      }
    }

    &.is-note-to-peer-box {
      padding-inline: 0;
    }

    &.is-sticky-note-box {
      padding-inline: 0;
    }

  }

  >div:last-of-type {
    &:before {
      content: none;
    }
  }

  .recipe-guider-wrp {
    border: 1px solid #E0E8F0;
    border-radius: 12px;
    padding: 12px;
    display: block;
    margin-bottom: 12px;
  }

  .recipe-issue-title-box {
    margin-bottom: 0;
  }

  &.is-animating {
    .checkbox-lbl .custom-checkbox-mark {
      position: relative;
      transition: all 0.3s ease;

      svg {
        display: none !important;
      }
    }

    input[type='checkbox']:checked+.checkbox-lbl .custom-checkbox-mark {
      border: 2px solid transparent;
      background-color: transparent;

      &:after {
        opacity: 1;
        height: 9px;
        width: 5px;
        border-radius: 1px;
        transform-origin: left top;
        border-right: 2px solid $light-blue;
        border-top: 2px solid $light-blue;
        content: '';
        left: 1px;
        top: 7px;
        position: absolute;


        animation-duration: 300ms;
        animation-timing-function: ease;
        animation-name: checkmark;
        transform: scaleX(-1) rotate(135deg);
      }
    }
  }

  &.is-expanded {
    .recipe-issue-title-box {
      margin-bottom: 16px;
    }

    .recipe-guiders {
      margin-bottom: 12px;

      >div:last-child {
        .each-guider-item {
          padding-bottom: 0;
        }
      }
    }

    .MuiCollapse-wrapper .MuiCollapse-wrapperInner {
      >div {
        margin-bottom: 0 !important;
      }

      .talking-points-list {
        >div:last-child {
          >div>div:last-child {
            margin-bottom: -4px;
          }
        }
      }
    }
  }


}


.talking-points-guider-compo {
  .talking-points-wrp {
    >div {
      padding-top: 8px;
      padding-bottom: 8px;
      margin-bottom: 0;

      >div {
        margin-bottom: 0 !important;

        .each-question {
          margin-bottom: 0 !important;

          .qa-wrp {
            margin-bottom: 4px;
          }

          div:empty {
            display: none !important;
          }
        }
      }
    }
  }
}

.reactflow-wrapper {
  .react-flow__nodes {
    .react-flow__node.react-flow__node-talking_point {
      .custom-ql-editor-wrp {
        padding: 0;
        color: #374050 !important;
        font-size: 16px;
        line-height: 18px;
        font-family: "Calibre";
        border: 1px solid #E9EFF5 !important;
        border-radius: 8px;
        box-shadow: none !important;
        background-color: #fff !important;
        max-width: 320px;

        &.is-readonly {
          padding: 12px 10px;
        }
      }
    }
  }
}

.ql-editor {
  ul {
    padding-left: 0 !important;

    li {
      padding-left: 16px !important;
      position: relative;

      &::before {
        position: absolute;
        left: -6px;
        top: -1px;
        margin: 0 !important;
        text-align: center !important;
      }
    }
  }
  ol {
    padding-left: 0 !important;

    li {
      padding-left: 20px !important;
      position: relative;

      &::before {
        position: absolute;
        left: 0;
        top: -1px;
        margin: 0 !important;
        text-align: left !important;
      }
    }
  }
}

.each-guider-item {
  .ql-editor {
    overflow: visible !important;
    li {
      margin-bottom: 8px;
      font-size: 16px;
      font-family: "Calibre";
      color: #374050;
    }

    ul li{
      margin-left: -16px !important;
    }
    ol li {
      margin-left: -20px !important;
    }
  }
}

.checkbox-lbl .check-desc .custom-ql-editor-wrp .custom-ql-editor-readonly .ql-container.ql-disabled .ql-editor {
  padding: 0 !important;
  margin-top: -1.5px;
  ul, ol {
    padding-left: 16px !important;
  }
}