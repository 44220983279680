.Profile-Container {
  >.row>.alerts-col.col-lg-5 {
    flex: 0 0 calc(50% - 70px);
    max-width: calc(50% - 70px);
    transition: all ease 0.1s;
  }

  >.row>.alerts-col.col-lg-4 {
    flex: 0 0 calc(41.66% - 70px);
    max-width: calc(41.66% - 70px);
    transition: all ease 0.1s;
  }

  >.row>.alerts-col.col-lg-3 {
    transition: all ease 0.1s;
    flex: 0 0 calc(50% - 380px);
    max-width: calc(50% - 380px);
  }
}

.Profile-Container .snapshot-comp-wrp.col-xl-3 {
  transition: max-width ease 0.1s;
  max-width: 380px;
  flex: 0 0 380px;
}


.Profile-Container .tracks-sl-com {
  padding-left: 0;

  &.col-lg-3 {
    flex: 0 0 calc(50% - 380px);
    max-width: calc(50% - 380px);
  }

  &.col-lg-5 {
    flex: 0 0 calc(50% - 50px);
    max-width: calc(50% - 50px);
  }

  &.col-lg-6 {
    flex: 0 0 calc(58.34% - 75px);
    max-width: calc(58.34% - 75px);
  }
}

// @media(max-width: 1559px) {
//   @for $i from 6 to 36 {
//     .fz-#{$i} {
//       font-size: #{$i}px !important;
//       line-height: #{$i+3}px !important;
//     }
//   }
// }

@media(max-width: 1439px) {
  .Profile-Container {
    >.row>.alerts-col.col-lg-5 {
      flex: 0 0 calc(100% - 70px);
      max-width: calc(100% - 70px);
      transition: all ease 0.1s;
    }

    // >.row>.alerts-col.col-lg-4 {
    //   flex: 0 0 calc(83.22% - 70px);
    //   max-width: calc(83.22% - 70px);
    //   transition: all ease 0.1s;
    // }

    >.row>.alerts-col.col-lg-3 {
      transition: all ease 0.1s;
      flex: 0 0 calc(100% - 380px);
      max-width: calc(100% - 380px);
    }
    .tracks-sl-com {
      &.col-lg-3 {
        flex: 0 0 calc(100% - 380px);
        max-width: calc(100% - 380px);
        padding-left: 15px;
      }

      &.col-lg-5 {
        flex: 0 0 calc(100% - 50px);
        max-width: calc(100% - 50px);
        padding-left: 15px;
      }

      // &.col-lg-6 {
      //   flex: 0 0 calc(58.34% - 75px);
      //   max-width: calc(58.34% - 75px);
      // }
    }
  }
}