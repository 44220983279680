.issue-area-header {
  padding: 12px 16px;
  margin-left: -16px;
  margin-right: -16px;
  transition: background-color 0.3s ease;


  .icon-wrp {
    svg {
      color: #576071;
    }
  }

  .btn-collapse {
    pointer-events: none;

    i {
      font-size: 12px;
      color: #95a0b0;
    }
  }

  &:not(.is-expanded):hover {
    border-radius: 8px;
    background-color: #F6F9FC;
  }

  .issues-list {
    padding-top: 16px;

    .issues-kind-list {
      >div {
        position: relative;
        padding-left: 0;

        .issue-title {
          margin-top: -0.25rem;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

          .red-dot {
            margin-left: 8px;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background-color: #ff4e59;
            display: inline-block;
            margin-top: 9px;
          }
        }

        span.empty-circle {
          width: 16px;
          height: 16px;
          border: 2px solid #a8b6c7;
          border-radius: 50%;
          display: inline-block;
        }

        span.filled-circle {
          width: 16px;
          height: 16px;
          background-color: #ccd6e2;
          border-radius: 50%;
          display: inline-block;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      .issue-status-badge {
        flex-shrink: 0;

        .custom-react-select {
          .custom__control {
            border: 0;
            min-width: unset;
            border-radius: 16px;
            height: 22px;
            min-height: 22px;

            .custom__value-container .custom__single-value {
              font-size: 14px !important;
              padding: 0 !important;
              font-family: $font-medium  !important;
            }
          }

          &.is-active {
            .custom__control {
              background-color: #EBF4FF !important;

              .custom__value-container .custom__single-value {
                color: #006DEB !important;
              }
            }
          }

          &.is-blocked {
            .custom__control {
              background-color: #FEF1F2 !important;

              .custom__value-container .custom__single-value {
                color: #F5000F !important;
              }
            }
          }

          &.is-resolved {
            .custom__control {
              background-color: #F0FAF1 !important;

              .custom__value-container .custom__single-value {
                color: #30883B !important;
              }
            }
          }
        }

        &.is-readonly {
          border-radius: 16px;
          height: 22px;
          min-height: 22px;
          font-size: 14px;
          padding: 0px 8px;
          font-family: $font-medium;
          flex-shrink: 0;

          &.is-active {
            background-color: #EBF4FF !important;
            color: #006DEB !important;
          }

          &.is-blocked,
          &.is-cancelled {
            background-color: #FEF1F2 !important;
            color: #F5000F !important;
          }

          &.is-resolved {
            background-color: #F0FAF1 !important;
            color: #30883B !important;
          }
        }
      }
    }
  }

  .gray-bd-box {
    margin-left: -12px;
    margin-right: -12px;
    cursor: pointer;

    &:hover {
      background-color: #F6F9FC;
    }
  }
}

.issue-area-alerts-wrp {
  background-color: #fff;
}

.issue-area-pg-wrp {
  display: flex;
  .progress-bar-compo .pg-wrp {
    height: 4px;
    border-radius: 2px;
    margin-bottom: 0 !important;
  }

  svg {
    margin-left: 2px;
    margin-right: 2px;
  }

  .pg-answered {
    width: 42%;
  }

  .pg-closed {
    width: 58%;
  }
}

.issue-area-card {
  border-radius: 12px;
  padding: 12px;
  margin-left: -12px;
  margin-right: -12px;
  transition: all ease 0.3s;
  &:hover, &.item-selected {
    background-color: #F6F9FC;
  }
  .btn-expand-detail {
    right: -3px;
    top: -13px;
    position: absolute;
    width: 18px;
    height: 18px;
    padding: 0;
  }
  &.is-bordered {
    border: 1px solid #e0e8f0;
    padding: 16px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 12px;
  }
}

.gray-notification {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #A8B6C7;
  border-radius: 4px;
  padding: 0 6px;
  .icon-wrp {
    flex-shrink: 0;
    color: #fff;
    margin-right: 6px;
  }
  .text-wrp {
    font-size: 14px;
    color: #fff;
  }
}