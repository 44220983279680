.custom-table-compo {
  background-color: #fff;

  &-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E0E8F0;

    &-col {
      font-size: 11px;
      color: #95A0B0;
      text-align: right;
      font-family: $font-medium;
      text-transform: uppercase;
      padding: 12px 8px;
    }
  }

  &-body {
    &-row {
      display: flex;
      justify-content: space-between;
      transition: all 0.3s ease;
      margin-top: 4px;
      margin-bottom: 4px;
      margin-left: -12px;
      margin-right: -12px;
      padding-right: 12px;
      padding-left: 12px;

      .expanding-toggle {
        width: 24px;
        display: block;
        cursor: pointer;

        i {
          font-size: 18px;
          color: #95A0B0;
          transition: all 0.3s ease;
          transform: rotate(-90deg);
        }
      }

      &.is-expanded {
        border-radius: 8px;
        background-color: #F1F5F9;

        .expanding-toggle {
          i {
            transform: none;
          }
        }
      }

      .table-header-search {
        .MuiOutlinedInput-root {
          background-color: #fff;
        }
      }

      >div:first-child {
        text-align: left;
      }

      &-col {
        font-size: 18px;
        color: #374050;
        text-align: right;
        font-family: $font-medium;
        text-transform: uppercase;
        padding: 12px 8px;
      }

      &.is-detail-row {
        border-bottom: 1px solid #F1F5F9;

        >div:first-child {
          color: #0094F3;
          text-transform: none;
          font-family: $font-medium;
        }

        .custom-table-compo-body-row-col {
          font-family: $font-regular;
          color: #576071;
        }
      }
    }

    &-expanding-area {
      transition: opacity ease 0.3s;
      opacity: 0;
      height: 0;
      overflow: hidden;

      &.is-expanded {
        height: auto;
        opacity: 1;
        pointer-events: auto;
        border-bottom: 1px solid #CCD6E2;
        max-height: 460px;
        overflow-y: auto;
        margin-right: -8px;
      }

      >div:last-child {
        border-bottom: none;
      }
    }
  }
}

.info-table-wrp {
  .mini-stats-table {
    width: 100%;

    thead {
      th {
        font-size: 16px;
      }
    }

    tbody {
      tr {
        td {
          font-size: 14px;
        }
      }
    }
  }
}