.voicemails-wrp {
  background-color: #fff;
	.voicemails-list {
		.each-voicemail {
			// padding-bottom: 16px;
			margin-bottom: 16px;
			border-bottom: 1px solid #E0E8F0;
		}
	}
}

.voicemails-inbox-compo {
	.copilot-audio-player {
		width: 300px;
	}
}

.title-w-red-dot {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	.red-new-ind {
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background-color: $red-500;
		margin-top: -3px;
		margin-left: 6px;
	}
}