.mentee-attribute-header {
  padding: 0 24px 30px 24px; 
}
.with-gray-dot {
  padding-left: 14px;
  position: relative;
  &:before {
    content: ''; 
    position: absolute;
    left: 0; 
    top: 8px; 
    width: 4px; 
    height: 4px; 
    border-radius: 50%; 
    background-color: #95A0B0;
  }
}