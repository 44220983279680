
.resizable-columns {
  width: 100%; 
  height: 100%; 
  display: flex;
  min-height: calc( 100vh - 168px ); 
  .columns-wrapper {
    position: relative;
    width: 100%; 
    .rz-single-column-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%; 
      &-content {
        width: 100%;
        position: relative;
        overflow-y: auto;
        height: 100%; 
      }
      .resize-handler {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 1px; 
        background-color: #E0E8F0;
        z-index: 3; 
        transform: none !important;
        &-label {
          font-size: 14px;
          color: #576071;
          display: inline-block;
          position: absolute;
          width: 40px;
          height: 30px;
          right: -20px; 
          top: 50%; 
          transform: translateY(-50%);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          cursor: pointer; 
        }
      }
    }
  }
}