.inq-shadow-card {
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(55, 64, 80, 0.16);
  border-radius: 16px;
}
.column-title {
  font-size: 24px;
  line-height: 28px;
  color: #17202E;
  font-family: $font-medium;
  font-weight: 400 !important;
}
.inq-h2 {
  font-size: 20px;
  line-height: 24px;
  color: #17202E;
  font-family: $font-medium;
  font-weight: 400 !important;
}

.inq-h3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400 !important;
  color: #17202E;
  font-family: $font-medium;
  margin-bottom: 10px;
}