.outreach-call-scheduler-wrp {
  padding: 11px 16px;
  .panel-heading {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    margin-top: 15px;
    h4 {
      margin-right: 15px;
      margin-bottom: 0;
    }

    .schedule-type-select {
      width: 130px;
      height: 30px;
      background-color: #fff;
    }
  }
  h6 {
    font-size: 15px;
    font-family: $font-bold;
    text-decoration: underline;
  }
  .weekday-selector {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .weekday-toggle {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 0;
      margin-left: -5px;
      margin-right: -5px;
      .btn.btn-primary {
        width: 46px;
        height: 32px;
        margin-left: 5px;
        margin-right: 5px;
        font-size: 15px;
        font-family: $font-medium;
        background-color: transparent;
        color: #768091;
        box-shadow: none;
        outline: 0 !important;
        border: 1px solid #CCD6E2;
        border-radius: 4px;
        padding: 0 3px;
        line-height: 29px;
        display: inline-block;
        &.active {
          background-color: $dark-blue;
          color: #fff;
        }
        &:hover {
          background-color: $dark-blue;
          color: #fff;
        }
      }
    }
    .bulk-selects {
      padding-left: 10px;
      margin-left: 6px;
      border-left: 1px solid #d3d3d3;

      >h6:last-child {
        margin-bottom: 0;
      }
    }
  }
}