.progress-lbl {
  margin-left: 6px;
  background-color: #f6f9fc;
  border-radius: 16px;
  color: #a8b6c7;
  font-size: 14px;
  min-width: 24px;
  height: 24px;
  padding-left: 4px;
  padding-right: 4px;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  &.is-red {
    background-color: #fdeced;
    color: #ff4e59;
  }
}

.search-wrp {
  position: relative;

  .icon-wrp {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    line-height: 1 !important;
  }

  .search-input {
    width: 100%;
    margin-bottom: 0;

    input {
      border: 1px solid #e0e8f0;
      border-radius: 40px;
      font-size: 16px;
      color: #374050;
      padding: 6px 16px 6px 40px;
      line-height: 1 !important;

      &::-webkit-input-placeholder {
        /* Edge */
        line-height: 1 !important;
        color: #95a0b0;
        padding-top: 2px;
      }

      &::-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        line-height: 1 !important;
        color: #95a0b0;
        padding-top: 2px;
      }

      &::placeholder {
        line-height: 1 !important;
        color: #95a0b0;
        padding-top: 2px;
      }
    }
  }

  &.is-square {
    .icon-wrp {
      left: 8px;
    }

    .search-input {
      input {
        padding: 6px 8px 6px 28px;
        border-radius: 4px;
      }
    }
  }

  &.is-border-radius8 {
    .search-input {
      input {
        border-radius: 8px;
      }
    }
  }

  &.is-height-40px {
    .search-input {
      height: 40px !important;

      input {
        height: 100%;
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }
  }

  &.is-height-42px {
    .search-input {
      height: 42px !important;

      input {
        height: 100%;
        padding-top: 6px;
        padding-bottom: 6px;
      }
    }
  }
}

span.arrow-left {
  line-height: 1 !important;

  i.icon-thick-down-caret {
    line-height: 1 !important;
    transform: rotate(90deg);
  }
}

span.arrow-right {
  line-height: 1 !important;

  i.icon-thick-down-caret {
    line-height: 1 !important;
    transform: rotate(-90deg);
  }
}

.tooltip.show {
  opacity: 1 !important;
}

.copilot-cont {
  height: calc(100vh - 51px);
  display: flex;

  .cop-sidebar-wrp {
    min-height: calc(100vh - 51px);
    width: 254px;
    position: relative;
    background-color: transparent;

    .cop-sidebar-inner-wrp {
      height: 100%;
    }

    .cop-sidebar-compo {
      overflow-x: hidden;
      overflow-y: auto;
      border-right: 1px solid #e0e8f0;
      background-color: #fff;
      width: 254px;
      height: 100%;
      transition: all ease 0.3s;
    }



    .inner-content {
      width: 254px;
      position: relative;

      > :last-child {
        margin-bottom: 30px;
      }
    }

    .inner-content {
      height: 100%;
    }

    .mentee-info {
      padding: 16px 16px 16px 16px;
      border-bottom: 1px solid #e0e8f0;

      &.no-border {
        border-top: 0 !important;
        border-bottom: 0 !important;
      }

      .mentee-calls {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -8px;
      }

      .named-entity {
        position: relative;

        .mentee-initial {
          background-color: $dark-blue;
          font-family: $font-medium;
          color: #fff;
          display: none;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

    }

    .call-btn-with-lbl {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;

      .btn {
        border: 1px solid $secondary-blue;
        background-color: $secondary-blue;
        padding: 0 !important;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all ease 0.3s;
        padding-top: 1px !important;

        i {
          font-size: 20px;
        }

        &:hover {
          background-color: $primary-blue;
          border-color: $secondary-blue;
        }
      }

      .lbl {
        margin-left: 12px;
        font-size: 16px;
        color: $primary-blue;
        font-family: $font-regular;
      }

      &.is-small {
        flex-direction: column !important;
        align-items: center;
        // width: 42px;
        display: inline-flex;

        .lbl {
          margin-left: 0;
          color: #576071;
          font-size: 14px;
        }
      }
    }

    .call-btn {
      border: 1px solid $secondary-blue;
      background-color: $secondary-blue;
      padding: 0 !important;
      width: 40px;
      min-width: 40px !important;
      height: 40px;
      border-radius: 50%;
      color: #fff;
      margin-right: 8px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 24px;
      }

      &:hover {
        background-color: $primary-blue;
        border-color: $primary-blue;
      }
    }

    .menu-list-wrp {
      >div:last-child {
        border-bottom: 0 !important;
      }
    }

    .menu-list {
      padding-top: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #e0e8f0;

      &.no-border {
        border-top: 0 !important;
        border-bottom: 0 !important;
      }

      &.small-padding {
        padding-top: 10px;
        padding-bottom: 10px;

        .each-menu {
          margin-top: 12px !important;
          margin-bottom: 12px !important;
        }
      }

      .menu-divider {
        margin-left: -24px;
        margin-right: -24px;
        border-bottom: 1px solid #e0e8f0;
        height: 1px;
      }

      .each-menu {
        padding: 0 14px;
        margin-bottom: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        position: relative;
        text-transform: unset;

        .icon-wrp {
          // width: 20px;
          // height: 20px;
          width: 26px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          .svg {
            width: 18px;
            height: 18px;
          }
        }

        .fz-16 {
          line-height: 24px;
        }

        span {
          color: #374050;
        }

        .circle-count {
          margin-left: 12px !important;
          min-width: 24px !important;
          height: 24px !important;
          text-align: center !important;
          background-color: #ebf6ff !important;
          font-size: 14px !important;
          font-family: $font-medium !important;
          display: inline-flex !important;
          align-items: center !important;
          justify-content: center !important;
          border-radius: 12px !important;
          padding: 2px 4px 4px 4px !important;

          span {
            color: $dark-blue !important;
          }

          &.is-red {
            background-color: #EBF4FF !important;

            span {
              color: #ff4e59 !important;
            }
          }
        }

        &.current-item {
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: -12px;
            bottom: -12px;
            width: 4px;
            background: $dark-blue;
            border-radius: 0px 4px 4px 0px;
          }

          span {
            color: $dark-blue;
          }
        }

        &.is-disabled {
          cursor: not-allowed !important;

          .icon-wrp {
            svg * {
              stroke: #95a0b0;
            }
          }

          &:before {
            content: none;
          }

          span {
            color: #95a0b0;
          }
        }
      }

      >div:last-child {
        margin-bottom: 0;
      }
    }
  }

  .cop-content-wrp {
    width: 100%;
    // height: calc(100vh - 51px);
    // padding-left: 2px;
    overflow-y: auto;
    position: relative;

    // margin-top: 24px;
    &.with-status-bar {
      .cop-ct-columns {
        margin-top: 24px;

        .cop-second .scroll-wrp {
          max-height: calc(100vh - 200px);
        }

        &.with-topbar {
          margin-top: 0;
          padding-left: 0;
          padding-right: 0;
          // height: calc(100vh - 51px);
          max-width: 90vw;
          min-width: 100%;
          width: fit-content;

          .overflow-wrp {
            padding: 24px 24px 0 24px;

            .cop-second .scroll-wrp {
              max-height: calc(100vh - 244px);
            }
          }
        }
      }
    }
  }

  .btn-toggle-sidebar {
    background-color: #ffffff;
    box-shadow: 0px 4px 12px rgba(23, 32, 46, 0.1);
    border-radius: 40px;
    width: 16px;
    height: 48px;
    position: absolute;
    right: -18px;
    top: 40%;
    z-index: 8;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: $secondary-blue;
      transform: rotate(90deg);
    }
  }

  &.sidebar-collapsed {
    .btn-toggle-sidebar {
      i {
        transform: rotate(-90deg);
      }
    }

    .cop-sidebar-wrp {
      width: 60px;
    }
  }

  &.sidebar-collapsed {
    .cop-sidebar-compo {
      width: 60px;
      background-color: #fff;
      overflow-y: hidden;
      border-right: 2px solid #e0e8f0;

      .mentee-info .mentee-initial {
        display: inline-flex;
        margin-left: -6px;
        visibility: visible;
      }

      .each-call-option,
      .es-call-btn-wrp {
        margin-left: -6px;
      }

      .es-call-btn-wrp>span {
        display: none;
      }

      .hide-when-collapse {
        // display: none;
        visibility: hidden;
      }

      .cop-sidebar-inner-wrp {
        width: 60px;
        overflow-x: hidden;
      }

      .call-btn {
        margin-left: -6px;
      }

      .menu-list {
        .each-menu {
          .icon-wrp {
            margin-right: 0;

            svg * {
              stroke: #374050;
            }
          }

          &:before {
            content: none !important;
          }

          &.current-item {
            .icon-wrp {
              margin-right: 0;

              svg * {
                stroke: $dark-blue;
              }
            }
          }
        }
      }

      .mentee-info {
        .mentee-calls {
          flex-direction: column;
          align-items: flex-start;

          .call-btn-with-lbl {
            .lbl {
              display: none;
            }
          }
        }
      }

      .outbound-call-compo-wrp {
        .btn.btn-primary {
          margin-left: -6px;
        }
      }

      .rescheduler-wrp {
        .fz-16.text-charcoal-700 {
          visibility: hidden;
        }


      }

      &.mentor-profile-sidebar {
        .call-btn-with-lbl {
          margin-left: -6px;

          .lbl {
            display: none;
          }
        }
      }
    }
  }
}

.cop-ct-columns {
  padding: 24px;
  overflow-y: auto;
  overflow-x: auto;
  flex-grow: 1;
  max-height: calc(100vh - 51px);
  overflow-y: auto;

  &.with-topbar {
    padding-top: 0;
    height: calc(100vh - 120px);

    .profile-status-bar {
      margin-left: -24px;
      margin-right: -24px;
      margin-bottom: 24px;
      width: calc(100% + 48px) !important;
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  >div.overflow-wrp {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: fit-content;
    padding-right: 24px;

    >div:last-child {
      margin-right: 0;
    }
  }

  .col-wrp {
    width: 480px;
    margin-right: 16px;
    flex-shrink: 0;

    @media (max-width: 1599px) {
      width: 400px;
    }

    @media (max-width: 1599px) {
      width: 364px;
    }
  }

  .inner-col {
    background-color: #fff;
    box-shadow: 0px 2px 10px rgba(55, 64, 80, 0.1);
    border-radius: 16px;
    padding: 24px;
    max-height: 100%;
    overflow-x: auto;
    position: relative;

    .col-expand-btn {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #f6f9fc;
      border-top: 1px solid #e0e8f0;
      text-align: center;
      cursor: pointer;

      i {
        font-size: 14px;
        color: #95a0b0;
      }

      &.is-expanded {
        i {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.cop-ct-columns {
  .scroll-wrp {
    max-height: calc(100vh - 196px);
  }

  &.with-topbar {
    .scroll-wrp {
      max-height: calc(100vh - 250px);
    }
  }
}

.scroll-wrp {
  max-height: calc(100vh - 51px);
}

.cop-second,
.inner-column {
  position: relative;

  .icon.icon-times-btb {
    transform: scale(0.6);
  }

  ul {
    padding-left: 24px;

    li {
      list-style: disc;
      margin-bottom: 8px;
      font-size: 16px;
      font-family: $font-regular;
      color: #374050;
    }
  }

  .scroll-wrp {
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    position: relative;

    .custom-styled-tabs {
      padding: 0;
    }

    .custom-styled-tabs.nav.nav-tabs+.tab-content .inner-tab {
      padding-left: 0;
      padding-right: 0;
    }

    &.is-bordered {
      border-top: 1px solid #e0e8f0;
      // border-bottom: 1px solid #E0E8F0;
    }
  }

  .scroll-wrp2 {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 10px;

    .custom-styled-tabs {
      padding: 0;
    }

    .custom-styled-tabs.nav.nav-tabs+.tab-content .inner-tab {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .cop-list {
    border-radius: 12px;

    .each-item {
      padding: 12px 16px;
      border-radius: 12px;
      margin-left: -16px;
      margin-right: -16px;
      margin-bottom: 2px;

      .item-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        >div:first-child {
          flex-grow: 1;
        }

      }

      &.item-selected,
      &:hover {
        position: relative;
        background-color: #F6F9FC;

        >* {
          z-index: 1;
          position: relative;
        }

        .custom-checkbox input[type='checkbox']+.checkbox-lbl .check-desc .progress-lbl {
          background-color: #c5c4c4;
          color: #fff;
        }
      }
    }

    // .each-item:last-child {
    //   border-bottom: 0;
    // }

    >.track-content:last-child {
      margin-bottom: 0;
    }
  }

  .issues-list {
    .blue-text-btn {
      font-family: $font-regular !important;
      color: $secondary-blue !important;
    }
  }

  .reschedule-btn {
    color: $secondary-blue !important;
  }
}

.copilot-note {
  padding-bottom: 0.6rem;
  border: 1px solid #CCD6E2;
  border-radius: 12px;
  margin-bottom: 12px;
  padding: 12px;

  &.is-conversation {
    // border: 1px solid #e0e8f0 !important;
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 16px !important;
    margin-left: 0;
    // margin-right: 40px;

    &.mentee-note {
      // margin-left: 40px;
      margin-right: 0;
      background-color: #f6f9fc;
    }
  }

  .note-detail {
    padding-left: 36px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .gray-text-btn {
    font-size: 14px;
    font-family: $font-regular;
    position: relative;

    &.with-divider {
      &:after {
        content: '';
        position: absolute;
        width: 2px;
        height: 2px;
        right: -6px;
        top: 50%;
        border: 0;
        background-color: #a8b6c7;
        border-radius: 50%;
      }
    }
  }

  .ta-wrp-w-btn {
    border: 0;
    padding: 0 !important;
    margin-top: 16px;

    &.when-focus {
      >.action-btns {
        bottom: 8px;
      }
    }
  }
}

.copilot-call .copilot-audio-player {
  padding: 12px;
  margin-bottom: 1rem;
  border: 1px solid #CCD6E2;
  border-radius: 12px;
}

.note-avatar {
  .initial-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #88c04e;
    font-size: 14px;
    font-family: $font-medium;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    margin-right: 12px;
    flex-shrink: 0;
    position: relative;

    &.is-new {
      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 8px;
        height: 8px;
        border: 1px solid #fff;
        border-radius: 50%;
        background-color: #ff4e59;
      }
    }
  }

  &.is-medium {
    .initial-icon {
      width: 42px;
      height: 42px;
      font-size: 18px;
    }
  }
}

.copilot-snapshot {
  margin-left: 20px;

  .with-dot {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: -13px;
      top: 8px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #768091;
    }
  }
}

.list-w-bullet {
  // counter-reset: index_num;
  margin-left: 14px;

  >div {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: -14px;
      top: 8px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #95a0b0;
      // counter-increment: index_num;
      // content: counter(index_num) ". ";
      font-size: 16px;
      color: #374050;
      line-height: 1;
    }
  }

  >div:last-child {
    margin-bottom: 0 !important;
  }

  &.is-blue-dot {
    >div {
      &::before {
        background-color: $dark-blue;
        top: 10px;
      }
    }
  }
}

.collapsible-title {
  padding-top: 12px;
  padding-bottom: 12px;

  .icon-angle-up {
    transition: all ease 0.3s;
    font-size: 16px;
    color: #768091;
    flex-shrink: 0;
  }

  &.expanded {
    .icon-angle-up {
      transform: rotate(-180deg);
    }
  }
}

.copilot-goal-single {
  border: 1px solid #e9eff5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.gray-bd-btn {
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #e9eff5;
  border-radius: 8px;
  cursor: pointer;
  transition: all ease 0.3s;
  width: 100%;
  display: block;
  text-decoration: none !important;

  .add-new-goal {
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;

    i {
      font-size: 12px;
      margin-left: 2px;
      margin-right: 9px;
      margin-top: 7px;
      color: #768091;
      flex-shrink: 0;
    }
  }

  &:hover {
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  &.fixed-height {
    padding: 12px;
    min-height: 52px;
    border: 1px solid #E0E8F0;
    border-radius: 16px;
  }
}

.cop-second {
  .custom-input-compo {
    position: relative;

    .custom-note-editor {
      .main-editor {
        border-radius: 8px;

        &.focused {
          min-height: auto;
          padding-bottom: 58px;
        }
      }
    }

    .custom-note-bottom {
      position: absolute;
      right: 15px;
      left: 15px;
      bottom: 15px;

      .btns-wrp {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .btn {
          border-radius: 8px !important;
          margin: 0 !important;
        }
      }
    }
  }

  .upcoming-call-compo {
    background-color: #fff;
    padding-top: 0;
    padding-bottom: 0;
  }

  .sticky-item-title {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
    margin-bottom: 16px;

    &.is-stuck {
      z-index: 4;
    }
  }
}

.blue-select-box-wrp {
  position: relative;
  line-height: 1;
  margin-top: 2px;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    background: transparent;
    padding: 0;
    font-size: 16px;
    font-family: $font-medium;
    color: $secondary-blue;
    padding-right: 20px;
    display: block;
    width: 100%;
  }

  &.is-light {
    select {
      font-family: $font-regular;
    }
  }

  &:after {
    content: '\f10d';
    color: $secondary-blue;
    position: absolute;
    right: 0;
    top: 50%;
    padding-top: 2px;
    transform: translateY(-50%);
    font-family: 'Mentor1to1';
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    text-rendering: auto;
    pointer-events: none;
  }

  &.is-light {
    select {
      font-family: $font-regular;
    }
  }
}

.dot-status-bar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 40px;
  padding-top: 8px;
  width: 100%;

  &.is-in-box {
    border: 1px solid #e0e8f0;
    border-radius: 8px;
    padding: 5px 12px;
  }

  .each-label {
    padding-left: 6px;
    padding-right: 6px;
    position: relative;
    height: 12px;
    flex-grow: 1;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      height: 2px;
      background-color: $light-blue;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      bottom: 0;
      transform: translateX(-50%) translateY(-50%);
      width: 2px;
      height: 12px;
      background-color: #dae2eb;
      border-radius: 1px;
    }

    .dot-label {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 10px;
      color: #576071;
    }

    .active-dot,
    .disabled-dot,
    .completed-dot {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      transform: translateX(-50%) translateY(-50%);

      z-index: 2;
    }

    .disabled-dot {
      background: #ffffff;
      border: 2px solid #ccd6e2;
    }

    .active-dot {
      border: 2px solid $light-blue;
      background-color: #fff;
    }

    .completed-dot {
      background-color: $light-blue;
    }
  }

  &:empty {
    display: none;
  }

  &.without-gray-bar {
    .each-label {
      &:after {
        content: none;
      }

      .dot-label {
        left: 0;
        font-size: 14px;
        padding-top: 5px;
      }

      .active-dot,
      .disabled-dot,
      .completed-dot {
        left: 0;
      }

      &.is-status-0 {
        &:before {
          background-color: #ccd6e2;
        }
      }

      &.is-status-1 {
        &:before {
          border-bottom: 2px dotted #ccd6e2;
          background-color: transparent;
        }

        >span:nth-child(2) {
          border-color: #ccd6e2 !important;
          background-color: #ccd6e2 !important;
        }
      }
    }

    >span:last-child {
      flex-grow: 0;
      padding-left: 0;
      padding-right: 0;

      .dot-label {
        width: 65px;
      }
    }
  }
}

.goal-summary-box {
  background: #f6f9fc;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;

  .left-col {
    padding: 12px;
    border-right: 1px solid #e9eff5;
    flex-shrink: 0;
  }

  .right-col {
    padding: 12px;
    display: flex;
    justify-content: space-around;
    flex-grow: 1;

    >div {
      text-align: center;
      padding-left: 5px;
      padding-right: 5px;

      .text-right {
        margin-right: 5px;
      }

      .text-left {
        margin-left: 5px;
      }
    }
  }
}

.circle-progress-bar {
  // -- vars
  $bg-color: #34495e;
  $default-size: 40px;
  $label-font-size: calc($default-size / 4);
  $label-font-size-redo: 40px;
  width: 40px;

  &.is-big {
    $default-size: 70px;
    width: 70px;

    @mixin size($width, $height) {
      height: $height;
      width: $width;
    }

    @mixin draw-progress($progress, $color) {
      .pie {
        .half-circle {
          border-color: $color;
        }

        .left-side {
          transform: rotate($progress * 3.6deg);
        }

        @if $progress <=50 {
          .right-side {
            display: none;
          }
        }

        @else {
          clip: rect(auto, auto, auto, auto);

          .right-side {
            transform: rotate(180deg);
          }
        }
      }
    }

    // -- selectors
    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }

    .pie-wrapper {
      @include size($default-size, $default-size);
      // float: left;
      // margin: 15px;
      position: relative;

      .pie {
        @include size(100%, 100%);
        clip: rect(0, $default-size, $default-size, calc($default-size / 2));
        left: 0;
        position: absolute;
        top: 0;

        .half-circle {
          @include size(100%, 100%);
          border: 4px solid #e9eff5;
          border-radius: 50%;
          clip: rect(0, calc($default-size / 2), $default-size, 0);
          left: 0;
          position: absolute;
          top: 0;
        }
      }

      .label {
        background: transparent;
        border-radius: 50%;
        bottom: 10px;
        color: #374050;
        font-size: 16px;
        cursor: default;
        text-transform: uppercase;
        left: 10px;
        position: absolute;
        right: 10px;
        text-align: center;
        top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .shadow {
        @include size(100%, 100%);
        border: 4px solid #e9eff5;
        border-radius: 50%;
        box-shadow: none !important;
      }

      @for $i from 0 to 101 {
        &[data-percent='#{$i}'] {
          @include draw-progress($i, $light-blue);
        }
      }
    }
  }

  &.is-small {
    $default-size: 40px;
    width: 40px;

    @mixin size($width, $height) {
      height: $height;
      width: $width;
    }

    @mixin draw-progress($progress, $color) {
      .pie {
        .half-circle {
          border-color: $color;
        }

        .left-side {
          transform: rotate($progress * 3.6deg);
        }

        @if $progress <=50 {
          .right-side {
            display: none;
          }
        }

        @else {
          clip: rect(auto, auto, auto, auto);

          .right-side {
            transform: rotate(180deg);
          }
        }
      }
    }

    // -- selectors
    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }

    .pie-wrapper {
      @include size($default-size, $default-size);
      // float: left;
      // margin: 15px;
      position: relative;

      .pie {
        @include size(100%, 100%);
        clip: rect(0, $default-size, $default-size, calc($default-size / 2));
        left: 0;
        position: absolute;
        top: 0;

        .half-circle {
          @include size(100%, 100%);
          border: 2px solid #e9eff5;
          border-radius: 50%;
          clip: rect(0, calc($default-size / 2), $default-size, 0);
          left: 0;
          position: absolute;
          top: 0;
        }
      }

      .label {
        background: transparent;
        border-radius: 50%;
        bottom: 10px;
        color: #374050;
        font-size: 16px;
        cursor: default;
        text-transform: uppercase;
        left: 10px;
        position: absolute;
        right: 10px;
        text-align: center;
        top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .shadow {
        @include size(100%, 100%);
        border: 2px solid #e9eff5;
        border-radius: 50%;
        box-shadow: none !important;
      }

      @for $i from 0 to 100 {
        &[data-percent='#{$i}'] {
          @include draw-progress($i, $secondary-blue);
        }
      }
    }
  }

  &.is-tiny {
    $default-size: 16px;
    width: 16px;

    @mixin size($width, $height) {
      height: $height;
      width: $width;
    }

    @mixin draw-progress($progress, $color) {
      .pie {
        .half-circle {
          border-color: $color;
        }

        .left-side {
          transform: rotate($progress * 3.6deg);
        }

        @if $progress <=50 {
          .right-side {
            display: none;
          }
        }

        @else {
          clip: rect(auto, auto, auto, auto);

          .right-side {
            transform: rotate(180deg);
          }
        }
      }
    }

    // -- selectors
    *,
    *:before,
    *:after {
      box-sizing: border-box;
    }

    .pie-wrapper {
      @include size($default-size, $default-size);
      // float: left;
      // margin: 15px;
      position: relative;

      .pie {
        @include size(100%, 100%);
        clip: rect(0, $default-size, $default-size, calc($default-size / 2));
        left: 0;
        position: absolute;
        top: 0;

        .half-circle {
          @include size(100%, 100%);
          border: 2px solid #e9eff5;
          border-radius: 50%;
          clip: rect(0, calc($default-size / 2), $default-size, 0);
          left: 0;
          position: absolute;
          top: 0;
        }
      }

      .label {
        background: transparent;
        border-radius: 50%;
        bottom: 10px;
        color: #374050;
        font-size: 16px;
        cursor: default;
        text-transform: uppercase;
        left: 10px;
        position: absolute;
        right: 10px;
        text-align: center;
        top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .shadow {
        @include size(100%, 100%);
        border: 2px solid #e9eff5;
        border-radius: 50%;
        box-shadow: none !important;
      }

      @for $i from 0 to 100 {
        &[data-percent='#{$i}'] {
          @include draw-progress($i, $secondary-blue);
        }
      }
    }
  }
}

.sequence-popup {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(23, 32, 46, 0.1);
  border: 0 !important;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  max-width: unset;
  margin-top: 10px;

  .each-option {
    width: 240px;
    padding: 24px;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    z-index: 3;

    .icon-wrp {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #f6f9fc;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background: #f6f9fc;
    }

    &.is-disabled {
      background-color: #e9eff5;
      cursor: not-allowed !important;
      opacity: 0.6;
    }
  }
}

.sequence-card {
  border-bottom: 1px solid #f1f5f9;
  margin-bottom: 16px;

  &.is-selected {
    position: relative;
    border-bottom: 1px solid #f1f5f9 !important;

    >* {
      // z-index: 1;
      position: relative;
    }

    &:before {
      content: '';
      position: absolute;
      left: -16px;
      right: -16px;
      top: -16px;
      bottom: 0;
      background-color: #f6f9fc;
      border-radius: 8px;
      z-index: 0;
    }
  }
}

.dot-progress-bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  // margin-right: -4px;
  max-width: 100%;


  .each-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #ccd6e2;
    margin-right: 4px;
    margin-bottom: 2px;
    margin-top: 2px;
    position: relative;
    z-index: 3;

    &.completed {
      border-color: $dark-blue;
      background-color: $dark-blue;
    }

    &.current {
      border-color: $dark-blue;
    }
  }

  &.is-linear {
    position: relative;

    // &:before {
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   top: 50%;
    //   right: 4px;
    //   height: 2px;
    //   background-color: #CCD6E2;
    //   z-index: 1;
    //   transform: translateY(-50%);
    // }
    .each-dot:not(:first-child) {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -6px;
        width: 4px;
        height: 2px;
        transform: translateY(-50%);
        background-color: #ccd6e2;
        z-index: 2;
      }

      &.current {
        &:before {
          background-color: $secondary-blue;
        }
      }
    }

    .each-dot.completed:not(:first-child) {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -6px;
        width: 4px;
        height: 2px;
        transform: translateY(-50%);
        background-color: $secondary-blue;
        z-index: 2;
      }
    }
  }

  >span:last-child {
    margin-right: 0;
  }
}

.sequences-list {
  >div:last-child {
    border-bottom: 0;
    margin-bottom: 0;

    .dot-progress-bar {
      margin-bottom: 0;
    }
  }
}

.single-sequence {
  .sequence-line {
    position: relative;
    padding-left: 26px;

    .copilot-note {
      border-bottom: 0;
    }

    .each-sequence {
      position: relative;

      .seq-title {
        position: relative;

        &:before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          position: absolute;
          left: -22px;
          top: 5px;
          background-color: #fff;
          border: 2px solid #ccd6e2;
        }
      }

      &:before {
        content: '';
        position: absolute;
        left: -17px;
        top: 12px;
        width: 2px;
        height: calc(100% + 16px);
        background-color: #ccd6e2;

        @media (max-width: 1599px) {
          top: 7px;
        }
      }

      &.completed {
        .seq-title:before {
          border: 0;
          background-color: transparent;
          background-image: url(../new-theme/images/check_mark_in_blue_circle.svg);
          width: 16px;
          height: 16px;
          top: 3px;
          left: -24px;
        }
      }

      &.pending {
        .seq-title:before {
          border: 0;
          background-color: transparent;
          background-image: url(../new-theme/images/sequence_pending.svg);
          width: 16px;
          height: 16px;
          top: 3px;
          left: -24px;
        }
      }

      &.cancelled {
        .seq-title:before {
          border: 0;
          background-color: transparent;
          background-image: url(../new-theme/images/sequence-cancelled.svg);
          width: 16px;
          height: 16px;
          top: 3px;
          left: -24px;
        }
      }
    }

    >div:last-child {
      &:before {
        content: none;
        // height: calc(100% - 12px);
      }
    }
  }

  .custom__menu {
    width: 294px;

    .sequence-template-option {
      padding: 8px 12px;
      margin-top: 8px;
      margin-bottom: 8px;
      border-radius: 4px;
      margin-left: 8px;
      margin-right: 8px;
      position: relative;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 8px;
        right: 8px;
        height: 1px;
        background-color: #e0e8f0;
      }

      &:hover {
        background: #f6f9fc;
      }
    }

    >div>div:last-child {
      &:after {
        content: none;
      }
    }
  }

  .activate-btn-wrp {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    border-top: 1px solid #e0e8f0;
    padding-top: 10px;
    margin-top: 16px;

    .bordered-button.is-medium {
      padding: 8px 12px !important;
    }

    .bordered-button.is-disabled.is-medium {
      font-family: $font-medium !important;
      color: #95a0b0;
      border-color: #ccd6e2;
      background-color: transparent;
      padding: 8px 12px !important;
    }
  }
}

.tr-status-tag {
  background: #ebf6ff;
  border-radius: 12px;
  padding: 2px 8px 3px 8px;
  color: $secondary-blue;
  font-size: 14px;
  font-family: $font-medium;
  line-height: 1;
}

.blue-circle-btn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0;
  background-color: $secondary-blue;
  text-align: center;

  i {
    color: #fff;
    font-size: 16px;
    transform: scale(0.8);
    line-height: 20px;
  }
}

.talking-point-checkbox {
  padding: 0;

  &.is-action-square.custom-checkbox {
    .custom-checkbox-mark {
      margin-top: 2px !important;
      border-color: $light-blue;
    }
  }

  &.is-animating input[type='checkbox']:checked+.checkbox-lbl .custom-checkbox-mark {
    background-color: $grey-800 !important;
    border-radius: 50% !important;

    &:after {
      border-right: 2px solid #fff !important;
      border-top: 2px solid #fff !important;
    }
  }
}


.checkbox-w-del-btn {
  position: relative;
  padding: 8px 0 10px 0;

  .talking-point-checkbox {
    margin-bottom: 0;
  }

  .btn-remove-item {
    position: absolute;
    right: 10px;
    top: 45%;
    line-height: 1;
    color: #95a0b0;
    cursor: pointer;
    transform: scale(0.8) translateY(-50%);
    display: none;

    i {
      font-size: 16px;
      line-height: 1;
    }
  }

  &:hover {
    background: #f6f9fc;
    border-radius: 8px;
    margin-left: -18px;
    margin-right: -18px;
    padding: 8px 18px 10px 18px;
    position: relative;

    .btn-remove-item {
      display: block;
    }
  }
}

.goal-streaks {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  >button {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
    min-width: unset !important;
    height: 32px;
    border-radius: 8px;
    font-size: 14px !important;

    &.Mui-disabled {
      border: 1px solid #CCD6E2 !important;
      color: #CCD6E2 !important;
    }
  }

  &.is-weekly {
    >button {
      width: 12%;
    }
  }

  &.is-monthly {
    &.is-5 {
      >button {
        width: 19%;
      }
    }

    &.is-6 {
      >button {
        width: 16%;
      }
    }
  }

  // @media (max-width: 1600px) {
  //   flex-wrap: wrap;
  //   justify-content: flex-start;

  //   &.is-monthly>button,
  //   &.is-weekly>button {
  //     margin: 3px !important;
  //     width: 85px !important;
  //   }
  // }
}

.habit-goal-list {
  margin-top: 16px;

  .goal-list-item {
    border: 1px solid #E0E8F0;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 8px;

    .goal-card {
      padding-bottom: 13px;

      .steplet-dropdown {
        margin-left: 15px;
        flex-shrink: 0;
      }
    }
  }
}

.bottom-bd-box {
  padding-bottom: 24px;
  border-bottom: 1px solid #E9EFF5;
}

.title-editable {
  border: 1px solid #e9eff5;
  border-radius: 8px;
  padding: 4px 12px;
  font-size: 26px;
  font-family: $font-medium;
  color: #374050;
  line-height: 1 !important;

  &::-webkit-input-placeholder {
    font-size: 26px;
    font-family: $font-medium;
    color: #768091;
  }

  &::-ms-input-placeholder {
    font-size: 26px;
    font-family: $font-medium;
    color: #768091;
  }

  &::placeholder {
    font-size: 26px;
    font-family: $font-medium;
    color: #768091;
  }
}

.single-habit-goal {
  &.is-disabled {
    .gray-bd-box {
      background-color: #f6f9fc;

      .form-check-input {
        &:checked {
          border-color: #ccd6e2 !important;

          &:before {
            border: 4px solid #ccd6e2 !important;
          }
        }
      }
    }

    .scroll-wrp2 {
      >div:last-child {

        // filter: none;
        .gray-when-disabled {
          filter: grayscale(1);
          pointer-events: none;
        }
      }
    }
  }

  .title-editable {
    flex-grow: 1;
  }

  .btn-close {
    cursor: pointer;
    margin-left: 28px;
    padding: 6px;
    margin-top: 3px;

    i {
      color: #768091;
      font-size: 14px;
    }
  }

  .btns-wrp {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: -24px;
    margin-right: -24px;
    padding: 24px;
    margin-top: 24px;
    border-top: 1px solid #e0e8f0;

    .btn.btn-primary {
      border-radius: 8px;
      padding: 8px 20px;
      margin-left: 16px;
    }
  }
}

.qa-list {
  margin-bottom: 26px;

  >div:last-child {
    margin-bottom: 12px;
  }
}

.qa-wrp {
  margin-bottom: 0;

  .crcw.cr_Textarea textarea {
    background: #ffffff;
    border: 1px solid #e0e8f0;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 0px;
    display: block;
    width: 100%;
    font-size: 16px;
    min-height: 49px !important;
  }
}

.templates-list {
  .gray-bd-box {
    border: 1px solid #e9eff5;
    border-radius: 8px;
    padding: 16px;

    .form-group {
      margin-bottom: 0;

      .form-check {
        input[type='radio'] {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 1.5px solid #ccd6e2;
          -webkit-appearance: none;
          cursor: pointer;
          margin-top: 4px;

          &:checked {
            border: 1px solid $primary-blue;

            &:before {
              content: '';
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              position: absolute;
              border-radius: 50%;
              border: 4px solid $secondary-blue;
            }
          }
        }

        .form-check-label {
          padding-left: 5px;
        }
      }
    }
  }
}

.preview-template {
  position: relative;
  padding-left: 46px;
  margin-top: 12px;

  &:before {
    content: '';
    position: absolute;
    left: 28px;
    top: 2px;
    width: 4px;
    border-radius: 4px;
    height: 100%;
    background-color: #e9eff5;
  }

  .each-qa {
    margin-bottom: 16px;
  }
}

.remind-wrp {
  margin-bottom: 16px;

  .custom__control {
    border: 1px solid #e0e8f0;
    box-shadow: none !important;
    border-radius: 4px;
  }

  .date-time-selector {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .datepicker-wrp,
    .custom-react-select {
      width: calc(50% - 4px);

      .react-datepicker-wrapper {
        width: 100%;
        display: block;

        input {
          border: 1px solid #e0e8f0;
          box-shadow: none !important;
          border-radius: 4px;
          height: 32px;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 16px;
          font-family: $font-regular;
        }
      }
    }
  }

  .btns-wrp {
    border-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
}

.target-goals-list {
  .goal-list-item {
    border: 1px solid #E0E8F0;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 8px;


    .preview-template {
      padding-left: 14px;

      &:before {
        left: 0;
      }

      >div:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.copilot-create-target-goal {
  .card-wrp.steplet-module {
    .form-group {
      margin-bottom: 16px;

      >label {
        font-size: 21px !important;
        color: #374050 !important;
        margin-bottom: 14px !important;
      }



      .react-datepicker-wrapper .react-datepicker__input-container input {
        padding: 12px;
        border: 1px solid #e0e8f0 !important;
        border-radius: 8px !important;
        height: auto !important;
        line-height: 1 !important;
      }
    }

    .text-center.mt-3 {
      text-align: right !important;
      margin-bottom: 0 !important;
      padding: 24px !important;
      margin-left: -24px;
      margin-right: -24px;
      border-top: 1px solid #e0e8f0;

      button {
        padding: 8px 20px !important;
      }
    }
  }
}

.issues-main-wrp {
  position: relative;

  .btn-issue-plus {
    position: absolute;
    right: 0;
    top: 6px;
    z-index: 2;
  }

  .search-wrp .search-input input {
    border-radius: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .issues-list {
    padding-bottom: 0 !important;

    >div:last-child {
      margin-bottom: 0;
    }
  }

  .each-issue-header-item {
    border: 1px solid #CCD6E2;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 8px;
    transform: none !important;
    transition: all ease 0.3s;

    .each-issue-header {
      padding: 0;
      position: relative;

      .alert-count {
        right: -24px;
        top: -24px;
      }
    }

    &.is-selected,
    &:hover {
      background: #F6F9FC;
    }
  }

  .issues-main-tabs.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root {
    margin-right: 16px;
  }

  .MuiTabPanel-root {
    padding: 0;

    .scroll-view-compo {
      margin-top: 0;
    }
  }
}

.issue-status-wrp {
  flex-shrink: 0;

  .custom-react-select {
    .custom__control {
      border: 0;
      min-width: unset;
      padding: 0px 10px 0px 8px;
      border-radius: 16px;
      height: 24px;
      cursor: pointer;
      min-height: unset;
      position: relative;

      .custom__value-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-right: 10px;

        .custom__single-value {
          padding: 0;
          font-size: 14px !important;
          font-family: $font-medium !important;
          line-height: 1 !important;
        }

        .css-mohuvp-dummyInput-DummyInput {
          display: none;
        }
      }

      .custom__indicators {
        display: flex;
        margin-left: 4px;
        height: 100%;
        padding: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        color: inherit;

        .custom__indicator {
          padding: 0;
          width: 12px;
          height: 12px;
          display: flex;
          align-items: center;
          margin-top: 1.5px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 10px;
          color: inherit;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    &.is-active {
      .custom__control {
        background: #EBF4FF;

        .custom__value-container .custom__single-value,
        .custom__indicators .custom__indicator {
          color: #006DEB !important;
        }
      }
    }

    &.is-blocked {
      .custom__control {
        background: #FEF1F2;

        .custom__value-container .custom__single-value,
        .custom__indicators .custom__indicator {
          color: #F5000F !important;
        }
      }
    }

    &.is-resolved {
      .custom__control {
        background: #F0FAF1;

        .custom__value-container .custom__single-value,
        .custom__indicators .custom__indicator {
          color: #30883B !important;
        }
      }
    }

    &.is-notexploredyet {
      .custom__control {
        background: #FFF7EB;

        .custom__value-container .custom__single-value,
        .custom__indicators .custom__indicator {
          color: #E08700 !important;
        }
      }
    }
  }
}

.track-status-wrp {
  flex-shrink: 0;
  max-width: 100%;

  .custom-react-select {
    .custom__control {
      border: 0;
      min-width: unset;
      padding: 2px 8px 2px 9px;
      border-radius: 16px;
      height: 24px;
      cursor: pointer;
      min-height: unset;

      .custom__value-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-right: 10px;

        .custom__single-value {
          padding: 0;
          font-size: 14px !important;
          font-family: $font-medium !important;
          line-height: 1 !important;
        }

        .css-mohuvp-dummyInput-DummyInput {
          display: none;
        }
      }

      .custom__indicators {
        display: flex;
        margin-left: 4px;
        height: 100%;
        padding: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        .custom__indicator {
          padding: 0;
          width: 12px;
          height: 12px;
          display: flex;
          align-items: center;
          margin-top: 1.5px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 10px;
          color: inherit;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}

.btn-steplet-new.btn.btn-primary {
  border: 1px solid #e9eff5;
  background-color: #fff !important;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px !important;
  font-family: $font-medium !important;
  color: $secondary-blue !important;
  line-height: 1 !important;
  width: 100%;
  margin-bottom: 16px !important;

  .icon-wrp {
    flex-shrink: 0;
    margin-right: 12px;

    svg {
      width: 24px;
      height: 24px;

      * {
        color: #576071;
      }
    }
  }
}

.copilot-reschedule-compo {
  .upcoming-call-compo {
    background-color: #fff;
  }
}

.copilot-mentee-availability {
  ul {
    padding-left: 0;
    border-bottom: 0;

    li {
      list-style: none;
      margin-left: 0 !important;
      margin-bottom: 0 !important;
      font-size: 16px;
      color: #768091;

      b {
        font-weight: 400;
        font-family: $font-medium;
        color: #374050;
        display: block;
        width: 100%;
      }

      br {
        display: none !important;
      }

      hr {
        display: none !important;
      }
    }
  }

  // >ul > li {
  //   display: flex;
  // }
}

.circle-badge {
  padding: 3px 6px 4px 6px;
  font-size: 14px;
  line-height: 127%;
  font-family: $font-medium;
  border-radius: 12px;

  &.is-red {
    color: #ff4e59;
    background-color: #fdeced;
  }

  &.is-green {
    color: #88c04e;
    background-color: rgba(136, 192, 78, 0.15);
  }
}

.blue-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  background-color: $secondary-blue;
}

.yellow-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  background-color: #ffcc6c;
}

.response-rate-bar {
  position: relative;

  .gray-line {
    width: 100%;
    height: 2px;
    background-color: #e0e8f0;
  }

  .blue-circle {
    position: absolute;
    top: -3px;
  }

  .yellow-circle {
    position: absolute;
    top: -3px;
  }
}

.call-scheduler-wrp {
  position: relative;
  padding-bottom: 76px;

  .resc-btns-wrp {
    position: absolute;
    left: -24px;
    right: -24px;
    bottom: 0;
    padding: 24px 24px 0 24px;
    border-top: 1px solid #e0e8f0;
  }
}

.manual-compensation-wrp {
  .text-padding-wrp {

    input,
    textarea {
      border: 1px solid #e9eff5 !important;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 7px 12px 8px 12px !important;
      font-size: 16px;
    }
  }
}

.remove-last-item-border {
  >div:last-child {
    border-bottom: 0 !important;
  }
}

.workbook-progress-compo {
  height: 52px;
  background-color: #fff;
  border-bottom: 1px solid #e0e8f0;
  padding: 17px 24px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  min-width: 1140px;

  .hover-pg-wrp {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .search-its-compo {
    .search-wrp .search-input input {
      width: 300px;
    }

    .search-results {
      width: 300px;
    }
  }
}

.round-progress-bar {
  background: #e9eff5;
  border-radius: 40px;
  height: 8px;
  max-width: 520px;
  min-width: 200px;
  position: relative;

  .progress-value {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: $secondary-blue;
    border-radius: 40px;
  }

  .call-progress-popup {
    position: absolute;
    z-index: 5;
    width: 240px;
    background-color: #fff;
    box-shadow: 0px 4px 12px rgba(23, 32, 46, 0.1);
    border-radius: 8px;
    padding: 16px;
    top: 20px;
    left: 0;
  }
}

.popover.bs-popover-bottom {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(23, 32, 46, 0.1);
  border-radius: 8px;
  border: 0;
  padding: 16px 24px;

  .arrow {
    &:before {
      border: 0 !important;
    }
  }
}

.custom-input-wrp {
  .custom-note-editor {
    .main-editor.focused {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.mentee-note-editor-wrp {
  border: 1px solid #e0e8f0;
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 16px;

  .mt-7.df.aic.jcsb {
    justify-content: flex-end !important;

    >button {
      margin-left: 14px;
    }
  }
}

.mentee-notes-wrp {
  .custom-ql-editor-wrp .custom-ql-editor-readonly .ql-editor {
    padding-top: 0 !important;
  }

  .note-details {
    margin-bottom: 12px;

    &:empty {
      margin-bottom: 0 !important;
    }

    .issue-tag {
      font-size: 16px;
      color: #374050;
      margin-top: 6px;
      margin-bottom: 6px;
      position: relative;
      padding-left: 12px;

      .color-bar {
        position: absolute;
        left: 0;
        top: 0;
        width: 2px;
        height: 100%;
        border-radius: 4px;
        background-color: $orange-300;
      }
    }
  }
}

.mentee-notes-wrp,
.DraftEditor-editorContainer {

  ul,
  ol {
    padding-left: 20px;
  }
}

.google-visualization-table {
  width: 100%;
  margin-top: 1rem;

  .google-visualization-table-table {
    width: 100%;
    border-collapse: collapse;

    .google-visualization-table-tr-head {
      background: #f6f9fc;
      border-radius: 4px;
      border: 0;

      .google-visualization-table-th.gradient.unsorted {
        background-color: transparent;
        border: 0 !important;
        box-shadow: none !important;
        background-image: none;
        font-size: 16px;
        font-family: $font-medium;
        color: #374050;
        font-weight: 400 !important;
        padding: 10px 16px;
        text-align: left !important;
      }
    }

    tbody tr {
      td {
        background-color: transparent;
        border: 0 !important;
        box-shadow: none !important;
        background-image: none;
        font-size: 16px;
        font-family: $font-regular;
        color: #374050;
        font-weight: 400 !important;
        padding: 10px 16px;
        text-align: left !important;
      }
    }

    tr {
      border-bottom: 1px solid #e0e8f0 !important;
    }
  }
}

.more-ind {
  background: #fff6e0;
  border-radius: 16px;
  padding: 2px;
  font-size: 14px;
  color: #f59300;
  font-family: $font-medium;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .icon-wrp {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.mentee-availability-compo {
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(55, 64, 80, 0.1);
  border-radius: 16px;
  padding: 24px;
  max-height: 100%;
  overflow-x: auto;

  .MuiPaper-root {
    border: 0;
    box-shadow: none;
    outline: none;
  }
}

.copilot-peerbot-checkins {
  .peerbot-msg {
    margin-left: 48px;
    margin-right: 12px;
    position: relative;
    background-color: #f6f9fc;
    border-bottom-right-radius: 0;

    &:before {
      content: '';
      position: absolute;
      right: -15px;
      bottom: -1px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 0 0 15px;
      border-color: transparent transparent transparent #e0e8f0;
    }

    &:after {
      content: '';
      position: absolute;
      right: -12px;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 0 0 12px;
      border-color: transparent transparent transparent #f6f9fc;
    }
  }

  .mentee-msg {
    margin-left: 12px;
    margin-right: 48px;
    position: relative;
    border-bottom-left-radius: 0;

    &:before {
      content: '';
      position: absolute;
      left: -15px;
      bottom: -1px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 15px 15px;
      border-color: transparent transparent #e0e8f0 transparent;
    }

    &:after {
      content: '';
      position: absolute;
      left: -12px;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 12px 12px;
      border-color: transparent transparent #fff transparent;
    }
  }
}

.open-quote,
.close-quote {
  position: relative;
  top: -1px;
}

.ani-btn {
  transition: all ease 0.5s;
}

.inbound-call-notification-compo {
  position: relative;
  background-color: transparent;

  .inner-compo {
    background-color: #fff;
    border-radius: 16px;
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 640px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99999;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  }

  .MuiIconButton-root.call-btn {
    background-color: #9bce63;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: #fff;
      font-size: 20px;
    }
  }

  // .close-btn {
  //   width: 40px;
  //   height: 40px;
  //   border-radius: 50%;
  //   background-color: #ff4e59;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   i {
  //     color: #fff;
  //     font-size: 28px;
  //   }
  // }

  .MuiIconButton-root {
    background-color: #768091;
    color: #fff;
    margin: 0 4px;
    width: 36px;
    height: 36px;
    border-radius: 50%;

    svg {
      width: 18px;
      height: 18px;
    }

    &:hover {
      background-color: #A8B6C7;
    }

    &.is-red {
      background-color: #FF4E59;

      svg {
        transform: scale(0.75);
      }
    }
  }

  .right-gray-bd {
    margin-right: 24px;
    padding-right: 24px;
    border-right: 1px solid #e0e8f0;
  }

  .features-wrp {
    >div {
      height: 100%;
    }
  }

  .mini-call-modal {
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    padding: 9px;
    transition: all ease 0.5s;
    z-index: 10000;
    width: 640px;
    padding: 16px 24px;
    background: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  }
}

.es-call-disabled-popover {
  background-color: rgba(255, 255, 255, 0.95) !important;
}

.rescheduler-wrp {

  &.border-top,
  .border-top {
    border-top: 1px solid #e0e8f0;
  }
}


.copilot-right-sidebar-wrp .fz-24.font-medium {
  font-size: 20px !important;
}


/**
 * Copilot Sidebar
 */
.copilot-right-sidebar-wrp {
  background-color: #fff;
  padding-left: 0 !important;
  padding-right: 0 !important;
  box-shadow: 0 2px 31px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.1s ease-out;
  height: calc(100vh - 51px);
  overflow-y: auto;
  z-index: 2;

  &.MuiGrid-grid-xs-4 {
    padding-left: 0;
    padding-right: 0;
  }

  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e8f0;
    background-color: #fff;

    .menu-btn {
      flex-shrink: 0;
      border: 0;
      box-shadow: none;
      background-color: transparent;
      width: 50px;
      height: 52px;
      padding: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.menu-closed {
      flex-direction: column;
      width: 50px;
      border-bottom: 0;

      .MuiTabs-scroller.MuiTabs-fixed {
        width: 50px;
      }

      .menu-btn {
        width: 50px;
        border-bottom: 1px solid #E0E8F0;
        height: 52px;

        svg {
          transform: rotate(180deg);
        }
      }
    }

  }

  .sidebar-content-main {
    height: calc(100vh - 102px);
    overflow-y: auto;
  }

  &.MuiGrid-grid-xs-4,
  &.MuiGrid-grid-xs-3 {
    width: 380px;
    flex: 0 0 380px;
    max-width: 380px;

    .right-sidebar-nav-select {
      >.MuiTabs-scroller {
        border-bottom: 0;

        >.MuiTabs-flexContainer {
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;

          >button {
            margin-right: 0 !important;
            width: 51px;
            height: 51px !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            padding: 6px !important;

            svg {
              color: #95a0b0 !important;
            }

            &.Mui-selected {
              border-bottom: 1px solid $dark-blue;

              svg {
                color: $dark-blue !important;
              }
            }
          }
        }
      }

      &+.MuiTabPanel-root {
        padding: 0;
      }
    }

  }

  &.MuiGrid-grid-xs-1,
  &.col-lg-1 {
    width: 54px;
    flex: 0 0 54px;
    max-width: 54px;

    .sidebar-content-main {
      display: none;
    }

    .MuiTabs-flexContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      >button {
        margin-right: 0 !important;
        width: 100%;

        svg {
          color: #95A0B0 !important;
        }
      }
    }

    .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root {
      padding: 8px;
    }
  }

  .materials-sent-list {
    margin-left: 0;
    margin-right: 0;
    height: auto;
  }
}

.copilot-col-three {
  height: 100%;

  .col-content-wrp {
    height: 100%;
    display: flex;
    flex-direction: column;

    .col-three-step {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .issues-list {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      height: auto;
      overflow-y: auto;
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;

      >div.mb-3 {
        flex-grow: 1;
        flex-shrink: 0;
      }

      >div.por {
        flex-grow: 1;
        overflow-y: auto;
        margin-left: -24px;
        margin-right: -24px;
        padding-left: 24px;
        padding-right: 24px;

        .scroll-wrp {
          height: auto !important;
        }
      }
    }
  }
}

.cop-issue-pending-actions:empty {
  margin-top: 0;
}

.es-mentee-profile-right-sidebar {
  position: relative;

  .btn-toggle-sidebar {
    position: absolute;
    left: -18px;
    top: 40%;
    right: auto;
  }
}