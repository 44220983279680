dl,
fieldset,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
ul {
  margin: 0;
  padding: 0;
}

ul.custom-styled-accordion {
  >li:first-child {
    // border-top: 1px solid #E9EFF5 !important;
    border-top: 0 !important;
  }

  >li {
    border-bottom: 1px solid #E9EFF5 !important;
    border-top: 0 !important;

    >section {
      >div:first-child {
        padding: 14px 24px;

        >h3>span {
          font-size: 16px !important;
          line-height: 24px !important;
          color: #576071;
          font-family: $font-medium;
          text-align: left;
        }

        .rainbow-button-icon {
          svg {
            display: none !important;
          }

          box-shadow: none !important;
          position: relative;

          &:after {
            content: "\f0bb";
            color: #95A0B0;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-family: 'Mentor1to1';
            display: inline-block;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            line-height: 1;
            text-rendering: auto;
            pointer-events: none;
          }

          &[aria-expanded="true"] {
            &:after {
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }
      }

      >div:last-child {
        padding: 0 24px 24px 24px;
      }
    }
  }
}



.crcw {
  &.cr_TableWithBrowserPagination>section.with-filter {
    >div>div:last-child>div {
      padding-top: 70px !important;
    }
  }

  &.cr_Table>div>div:last-child,
  &.cr_TableWithBrowserPagination>section {
    background-color: #fff;

    >div {
      padding-top: 56px !important;
      border-top: 0;
      background-color: #fff;

      >div {
        border-top: 0;

        >div {
          border: 0 !important;
          box-shadow: none !important;

          >div {
            border: 0 !important;
            box-shadow: none !important;
          }
        }
      }
    }

    .header-w-search-box {
      input {
        width: 100px;
        font-size: 12px;
        padding: 2px 4px;
        border: 1px solid #E0E8F0;
        border-radius: 6px;
        color: #374050;
      }
    }

    table {
      >thead {
        >tr {
          >th {
            >div {
              height: 56px;
              background-color: #F6F9FC;

              >div.rainbow-table_header-container {
                height: 56px;

                >span {
                  display: block;
                  width: 100%;
                  font-size: 16px;
                  font-family: $font-medium;
                  color: #374050;
                  text-align: left;
                  font-weight: 400 !important;
                  text-transform: none !important;
                  text-align: left;
                }
              }

              .rainbow-table_header-resize-bar {

                &:hover,
                &:active,
                &:focus {
                  background-color: $secondary-blue  !important;

                  span {
                    background-color: $secondary-blue  !important;
                  }
                }

              }
            }

            >div:nth-child(2) {
              display: none;
            }
          }
        }
      }

      >tbody {
        background-color: #fff;

        >tr {
          box-shadow: none !important;
          vertical-align: text-top;

          &.current-selected {
            background-color: #FAFAFA;
          }

          &:hover {
            background-color: #fff !important;
          }

          >th,
          >td {
            padding-top: 14px;
            padding-bottom: 14px;
            border-bottom: 1px solid #E9EFF5;
            vertical-align: middle !important;

            >div {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              font-size: 16px;
              font-family: $font-regular;
              color: #768091;
              white-space: normal;
              line-height: 1.2;
              border: 0 !important;
              overflow: visible;
              padding: 0 0.5rem;

              .btn-link {
                padding-left: 0;
              }
            }

            .cell-show-in-row {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;

              >div {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                margin-bottom: 4px !important;
                align-items: center !important;
                margin-right: 0 !important;
                width: 100%;

                .mb-1 {
                  margin-right: 6px !important;
                  margin-bottom: 0 !important;
                  position: relative;

                  &:after {
                    position: absolute;
                    right: -3px;
                    top: 0;
                    content: ':';
                    font-size: 16px;
                    color: #374050;
                  }
                }
              }
            }

            &:focus>div {
              border-color: $secondary-blue;
            }

          }
        }

      }

      .rainbow-table_cell-checkbox {
        >div {
          line-height: 1;
          margin-bottom: 0;

          >label {
            margin-bottom: 0;
          }
        }
      }

      label[data-id="table-input-checkbox_label"] {
        margin-bottom: 0;
        line-height: 1;
      }

      button {
        background-color: transparent;
        border: 0;
        color: $primary-blue;
      }
    }

    &.white-header {
      table {
        >thead {
          >tr {
            >th {
              >div {
                height: 56px;
                background-color: #fff;

                >div.rainbow-table_header-container {
                  height: 56px;
                  border: 0 !important;

                  >span {
                    display: block;
                    width: 100%;
                    font-size: 21px;
                    font-family: $font-regular;
                    color: #95A0B0;
                    text-align: left;
                    font-weight: 400 !important;
                    text-transform: none !important;
                    text-align: left;
                  }
                }

              }
            }
          }
        }

        >tbody {
          background-color: #fff;

          >tr {
            box-shadow: none !important;
            vertical-align: text-top;

            &:hover {
              background-color: #fff !important;
            }

            >th,
            >td {
              padding-top: 10px;
              padding-bottom: 10px;
              border-bottom: 1px solid #E9EFF5;
              vertical-align: middle !important;

              >div {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 16px;
                font-family: $font-regular;
                color: #768091;
                white-space: normal;
                line-height: 1.2;
                border: 0 !important;
                overflow: visible;
                word-break: break-all;

                .btn-link {
                  padding-left: 0;
                }
              }

              &:focus>div {
                border-color: $secondary-blue;
              }
            }
          }

        }
      }
    }

    &.small-column-title {
      >div>div:last-child>div {
        padding-top: 46px !important;
      }

      table {
        >thead {
          >tr {
            >th {
              >div {
                height: 46px;
                background-color: #fff;

                >div.rainbow-table_header-container {
                  height: 46px;
                  border: inherit !important;

                  >span {
                    display: block;
                    width: 100%;
                    font-size: 14px;
                    font-family: $font-medium;
                    color: #A8B6C7;
                    text-align: left;
                    font-weight: 400 !important;
                    text-transform: none !important;
                    text-align: left;
                    text-transform: uppercase !important;
                  }
                }

              }
            }
          }
        }

      }
    }
  }

  &.cr_TableWithBrowserPagination>section.with-filter {
    table {
      >thead {
        >tr {
          >th {
            >div {
              height: 70px;
            }
          }
        }
      }
    }
  }
}

.crcw.cr_Table>div.with-filter>div:last-child table>thead>tr>th>div>div.rainbow-table_header-container,
.crcw.cr_TableWithBrowserPagination>section.with-filter table>thead>tr>th>div>div.rainbow-table_header-container {
  height: 70px;
}

.crcw.cr_Table>div.with-filter>div:last-child table>thead>tr>th>div>div.rainbow-table_header-container>span,
.crcw.cr_TableWithBrowserPagination>section.with-filter table>thead>tr>th>div>div.rainbow-table_header-container>span {
  overflow: visible
}

.crcw.cr_TableWithBrowserPagination>section>div {
  padding-top: 0 !important;

  >div:last-child>div {
    padding-top: 56px !important;
    background-color: #F6F9FC;

    >div {
      min-width: 100%;

      table {
        min-width: 100%;

        thead {
          min-width: 100%;
        }
      }
    }
  }

  .copilot-audio-player {
    margin-bottom: 0 !important;
  }

  nav[aria-label="pagination"] {
    margin-top: 15px;

    &+div {
      margin-top: 15px;

      &:after {
        color: $primary-blue;
      }
    }
  }
}

.crcw.cr_VerticalNavigation {
  >nav {
    >div:first-child {
      border-top: 0 !important;
    }

    >div {
      border-bottom: 1px solid #E9EFF5 !important;
      border-top: 0 !important;
      padding-bottom: 0;
      background-color: transparent !important;

      // &.rainbow-vertical-section-overflow_container--expanded {
      //   background-color: transparent;
      // }
      >button {
        padding: 14px 24px;
        background-color: #fff !important;

        >div>span {
          font-size: 16px !important;
          line-height: 24px !important;
          color: #576071;
          font-family: $font-medium;
          display: block;
          width: 100%;
          text-align: left;
        }

        >svg {
          color: #95A0B0;

          path {
            fill: #CCD6E2;
          }
        }

      }

      >button[aria-expanded="true"]+div {
        max-height: unset !important;
      }

      .inner-content {
        padding: 0 24px 24px 24px;
        background-color: #fff;
      }
    }
  }

  &.without-padding {
    >nav>div {
      border-bottom: 1px solid #E0E8F0 !important
    }

    >nav>div>button {
      padding: 16px 0;
      // &[aria-expanded="true"] {
      //   padding-bottom: 0;
      // }
    }

    >nav>div>button[aria-expanded="true"]+div {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 16px;

      >ul {
        padding-left: 0;
      }
    }
  }
}

/**
 * New table style
 */

.crcw.cr_Table>div>div:last-child,
.crcw.cr_TableWithBrowserPagination>section {
  // table>thead>tr>th>div {
  //   border-bottom: 1px solid #E0E8F0;
  // }

  table thead tr {
    >*:first-child {
      border: 0;
      padding: 0;

      >div {
        padding: 0;
        border: 0;

        >div {
          padding-left: 24px;
        }
      }


    }

    >*:last-child {
      >div>div {
        padding-right: 24px;
      }
    }
  }

  table tbody tr {
    >*:first-child {
      >div {
        padding-left: 24px;
      }
    }

    >*:last-child {
      >div {
        padding-right: 24px;
      }
    }
  }

  table>tbody>tr>th,
  table>tbody>tr>td {
    border-bottom: 1px solid #E0E8F0;

    div {
      color: #374050;
    }
  }
}

.crcw.cr_TableWithBrowserPagination.with-left-checkbox>section {
  table>thead>tr>th:first-child {
    padding: 0;

    >div:first-child {
      border-left: 0;
      border-right: 0;
      border-top: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      >div {
        padding-left: 0;
      }
    }
  }

  table tbody tr>*:first-child>div {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .rainbow-input_faux {
      margin-right: 0;
    }
  }
}


.crcw.cr_Table>div>div:last-child table>thead>tr>th>div>div.rainbow-table_header-container>span,
.crcw.cr_TableWithBrowserPagination>section table>thead>tr>th>div>div.rainbow-table_header-container>span {
  white-space: normal;
}

.crcw.cr_Textarea {
  >div {
    >div {
      border: 0 !important;
      box-shadow: none !important;
      background-color: transparent !important;

    }

    textarea {
      padding: 12px 10px;
      color: #374050 !important;
      font-size: 16px;
      line-height: 18px;
      font-family: $font-regular;
      border: 1px solid #E9EFF5; // !important;
      border-radius: 8px;
      box-shadow: none !important;
      background-color: #fff;
    }
  }

  &.is-border-radius4 {
    textarea {
      border-radius: 4px !important;
    }
  }
}

.crcw.cr_RadioGroup {
  >fieldset {
    >div {
      >div {
        input~label {
          margin-bottom: 0;
        }

        input~label>.rainbow-input_faux {
          background-color: transparent;
          border-color: $secondary-blue;
          border-width: 2px;
        }

        input~label>.rainbow-input_faux+span {
          color: #374050;
          font-size: 16px;
        }

        input:checked~label {
          font-family: $font-medium;
        }

        input:checked~label>.rainbow-input_faux {
          border-color: $secondary-blue;
          // box-shadow: 0 0 2px $secondary-blue;
          background-color: transparent;
          ;

          &:after {
            content: "\f0db";
            font-family: 'Mentor1to1';
            display: inline-block;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            line-height: 1;
            text-rendering: auto;
            color: $secondary-blue;
            background-color: transparent;
            transform: translate3d(-50%, -50%, 0) scale(0.8);
            position: absolute;
            top: 50%;
            left: 50%;

            // display: block;
            // content: '';
            // height: 4.5px;
            // width: 9px;
            // position: absolute;
            // top: 44%;
            // left: 50%;
            // transform: translate3d(-50%,-50%,0) rotate(-45deg);
            // border-bottom: 2px solid;
            // border-left: 2px solid;
            // border-color: $secondary-blue;
            // box-sizing: border-box;
            // padding: 0;
            // border-radius: 3px;
          }
        }
      }
    }
  }
}

.crcw.cr_Button {
  button {
    background-color: $secondary-blue;
    border-color: $secondary-blue;
    color: #fff;
    font-size: 16px;
    font-family: $font-medium;
    box-shadow: none !important;
    outline: 0 !important;
  }

  &.base {
    button {
      background-color: transparent;
      border-color: transparent;
      color: #768091;
    }
  }
}

.crcw.cr_Input {
  label {
    width: 100%;
    display: block;
    text-align: left;
    font-size: 18px;
    color: #768091;
    font-family: $font-regular;
    margin-bottom: 3px;
  }

  >div {
    input[type='text'] {
      padding: 12px;
      color: #374050;
      font-size: 16px;
      font-family: $font-regular;
      border: 1px solid #E9EFF5;
      border-radius: 8px;
      box-shadow: none !important;
    }
  }
}

.crcw.cr_Spinner {
  >div>div {
    background-color: $secondary-blue;
  }
}

.crcw.cr_CheckboxGroup {
  fieldset>div>div {
    .rainbow-input_faux {
      border-radius: 3px;
    }

    input~label>.rainbow-input_faux+span {
      color: #374050;
      font-size: 16px;
    }


    input:checked~label>.rainbow-input_faux {
      border-color: $secondary-blue;
      box-shadow: 0 0 2px $secondary-blue;

      &:after {
        border-color: $secondary-blue;
        top: 43%;
        left: 51%;
      }
    }
  }

  &.is-small {
    fieldset>div>div {
      margin-bottom: 5px;

      .rainbow-input_faux {
        border-radius: 3px;
      }

      input~label {
        margin-bottom: 0;
      }

      input~label>.rainbow-input_faux+span {
        color: #374050;
        font-size: 13px;
      }


      input:checked~label>.rainbow-input_faux {
        border-color: $secondary-blue;
        box-shadow: 0 0 2px $secondary-blue;

        &:after {
          border-color: $secondary-blue;
          top: 43%;
          left: 51%;
        }
      }
    }
  }
}

.crcw.cr_Select {
  >div {
    >div {
      select {
        border: 1px solid #E9EFF5;
        border-radius: 8px;
        height: 32px;
        font-size: 16px;
        font-family: $font-regular;
        color: #576071;
        background-color: transparent;
        box-shadow: none !important;
        outline: 0 !important;
      }

      &:after {
        color: #768091;
        font-size: 12px;
        width: 6px;
        height: 6px;
      }

      &:hover:after {
        color: $secondary-blue;
      }
    }
  }
}

.crcw.cr_RadioButtonGroup {
  >fieldset>div {
    border: 1px solid #E9EFF5;
    border-radius: 8px;

    >div {
      >span {
        background-color: $secondary-blue;
        border-color: $secondary-blue;
        border-radius: 8px;
      }
    }

    >span {
      >label {
        margin-bottom: 0;
        font-size: 16px;
        font-family: $font-medium;
        line-height: 1;
      }
    }
  }
}

.crcw.cr_ButtonGroup {
  >div {
    >div {
      >button {
        height: auto;
        width: auto;
      }

      >div {
        >ul {
          li {
            font-size: 14px;
            font-family: $font-regular;
            color: #576071;
          }
        }
      }
    }
  }
}

.crcw.cr_ButtonMenu {

  >div,
  button {
    box-shadow: none !important;
    outline: 0 !important;
    border: 0 !important;
    background-color: transparent !important;

    &:hover {
      color: $secondary-blue;
    }
  }
}


.crcw.cr_RadioGroup2 {
  >fieldset {
    >div {
      >div {
        margin-bottom: 0;

        input~label {
          margin-bottom: 0;
          line-height: 1;
          margin-bottom: 12px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

        }

        input~label>.rainbow-input_faux {
          background-color: transparent;
          border: 1.5px solid #0094F3;
          width: 16px;
          height: 16px;
          box-shadow: none !important;
          margin-right: 8px;
          margin-top: 2px;
        }

        input~label>.rainbow-input_faux+span {
          color: #374050;
          font-size: 16px;
          width: calc(100% - 24px);
          line-height: 1.2;
        }

        input:checked~label>.rainbow-input_faux {
          // border: 0;
          background-color: $secondary-blue;
          position: relative;

          &:after {
            content: "\f0db";
            font-family: 'Mentor1to1';
            display: inline-block;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            line-height: 1;
            text-rendering: auto;
            color: #fff;
            background-color: transparent;
            transform: translate3d(-50%, -50%, 0) scale(0.8);
            position: absolute;
            top: 52%;
            left: 50%;
          }
        }
      }
    }
  }
}

.crcw.cr_CheckboxGroup2 {
  fieldset>div>div {
    margin-bottom: 0;

    .rainbow-input_faux {
      border-radius: 8px;
      box-shadow: none !important;
      border-width: 1.5px !important;
      border-color: $secondary-blue  !important;
      width: 16px;
      height: 16px;
      margin-right: 8px;
      margin-top: 2px;
      transition: none !important;
      animation: none !important
    }

    input~label {
      margin-bottom: 0;
      line-height: 1;
      margin-bottom: 12px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }

    input~label>.rainbow-input_faux+span {
      color: #374050;
      font-size: 16px;
      width: calc(100% - 24px);
      line-height: 1.2;
    }


    input:checked~label>.rainbow-input_faux {
      border-color: $secondary-blue;

      background-color: $secondary-blue;
      position: relative;

      &:after {
        content: "\f0db";
        font-family: 'Mentor1to1';
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        line-height: 1;
        text-rendering: auto;
        color: #fff;
        background-color: transparent;
        transform: translate3d(-50%, -50%, 0) scale(0.8);
        position: absolute;
        top: 52%;
        left: 50%;
        border: 0 !important;
        width: 12px;
        height: 12px;
        box-shadow: none !important;
      }
    }
  }
}

.custom-textarea {
  .ta-wrp-w-btn {
    margin-bottom: 0;
  }

  .crcw.cr_Textarea>div textarea {
    padding: 10px 16px;
  }
}

.crcw.cr_CheckboxToggle {
  display: flex;
  align-items: center;

  >label {
    margin-bottom: 0;

    .rainbow-checkbox-toggle_faux-container {
      .rainbow-checkbox-toggle_faux {
        background-color: #E0E8F0;
        border-color: #E0E8F0;
        border-radius: 16px;
        width: 40px;
        height: 20px;
        margin-top: 4px;

        &::after {
          width: 16px;
          height: 16px;
        }
      }
    }

    input[type='checkbox']:checked {
      &+.rainbow-checkbox-toggle_faux-container {
        .rainbow-checkbox-toggle_faux {
          background-color: $secondary-blue;
          border-color: $secondary-blue;
        }
      }
    }
  }

  &.mt0 {
    margin-bottom: 0;

    .rainbow-checkbox-toggle_faux-container {
      .rainbow-checkbox-toggle_faux {
        margin-top: 0;
      }
    }
  }
}


.cr_slider {
  >div>div {
    padding-bottom: 30px;

    input[type='range'] {
      background-color: #E9EFF5;
      border-radius: 4px;
      -webkit-appearance: none;
      height: 6px;
      width: 100%;

      &::-webkit-slider-runnable-track {
        height: 10px;
        -webkit-appearance: none;
        color: #f00;
        margin-top: -1px;
      }

      &::-webkit-slider-thumb {
        width: 24px;
        height: 24px;
        border: 1px solid #768091;
        background-size: 9px;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url(../new-theme/images/slider_thumb.svg);
        border-radius: 50%;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: 0;
        margin-top: -9px;
      }
    }

    >span {
      position: absolute;
      font-size: 16px;
      color: #374050;
      font-family: $font-medium;
      top: 24px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}