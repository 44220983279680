/**
 * variables 
 */
$color-text-default: #222B45;
$color-basic-300: #EDF1F7; 
$color-basic-400: #E4E9F2; 
$color-basic-500: #C5CEE0; 
$color-basic-600: #8F9BB3;

$color-primary-500: #0852ED; 

$color-success-500: #00D68F;

$color-danger-500: #FF3D71; 