.loading-gradient {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;
  color: transparent !important;
  position: relative;
  user-select: none !important;
  color: transparent !important;
  > span.profileTabClick {
    color: transparent !important;
  }

  h2 {
    color: transparent;
  }
  button {
    visibility: hidden;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }

  100% {
    background-position: 468px 0
  }
}



@for $i from 0 to 6 {
  .remove-with-fade#{$i} {
    max-height: 0px;
    overflow: hidden;
    animation-name: collapseAnimation#{$i} ;
    animation-duration: 0.4s;
    margin: 0;
  }

  .add-with-fade#{$i} {
    max-height: #{$i*90 +34}px;
    opacity: 1;
    transition-timing-function: ease;
    // transition: max-height 0.4s cubic-bezier(.75,1.37,.47,1.95);
    overflow: hidden;
    animation-name: expandAnimation#{$i} ;
    animation-duration: 0.4s;
  }

  @keyframes expandAnimation#{$i} {
    0% {
      max-height: 0px;
    }

    60% {
      max-height: #{$i*60 + 34 + 24}px;
    }

    80% {
      max-height: #{$i*60 + 34 + 15}px;
    }

    100% {
      max-height: #{$i*60 + 34}px;
    }
  }

  @keyframes collapseAnimation#{$i} {
    from {
      max-height: #{$i*60 + 34}px;
    }

    to {
      max-height: 0px;
    }
  }
}


.slide-in-from-right {
    position: relative;
    float:right;
    -webkit-animation: slide-in-from-right 0.1s forwards;
    animation: slide-in-from-right 0.1s forwards;
    transition: all 0.1s ease;
    width: 0%;
}

@-webkit-keyframes slide-in-from-right {
    100% { right: 0%; width: 100%;}
}

@keyframes slide-in-from-right {
    100% { right: 0%; width: 100%;}
}

.slide-in-from-left {
    position: relative;
    -webkit-animation: slide-in-from-left 0.1s forwards;
    animation: slide-in-from-left 0.1s forwards;
    overflow-x: hidden;
    margin-left: -130%;
    width: 100%;
    transition: all 0.1s ease;
}

@-webkit-keyframes slide-in-from-left {
    100% { margin-left: 0%;}
}

@keyframes slide-in-from-left {
    100% { margin-left: 0%;}
}

.fade-out {
    -webkit-animation: fade-out-anim 0.2s forwards;
    animation: fade-out-anim 0.2s forwards;
    animation-delay: 1s;
    &.is-no-duration {
      animation-delay: 100s;
    }
}
.gradient-loader-wrp {
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
  background: #fff;
}

@keyframes fade-out-anim {
    0% {
      opacity: 1
    }
    50% {
      opacity: 0.5
    }
    100% {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      max-height: 0;
      overflow: hidden;
    }
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #212d52;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 0.9) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 74px;
  }
  50%, 100% {
    top: 24px;
    height: 42px;
  }
}