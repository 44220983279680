button.text-btn {
  font-size: 12px;
  color: #000000;
  font-family: 'Calibre';
  margin-top: 0;
  border: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;
  transition: all ease 0.3s;
  &:hover {
    opacity: 0.8;
  }
}

/**
 * Side Modal Style
 */

.push-back-modal {
  .content {
    height: 100vh;
    background-color: #fff;
    padding: 0;
    border-radius: 0;
    background-color: #f9f9f9;
    overflow: auto;
    .modal-inner {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 20px 30px 100px 30px;
      overflow: auto;
      .modal-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        .text-btn {
          font-size: 15px;
          color: #000;
          i.icon-push-back {
            margin-right: 11px;
            font-size: 16px;
          }
        }
      }
      .kanban-card {
        margin-bottom: 25px;
        background-color: #fff;
        border-radius: 5px;
      }
    }
  }
}
.assign-to-optional-note {
  border: 1px solid #ebebeb !important;
  border-radius: 5px;
  padding: 6px 10px;
  outline: none !important;
  box-shadow: none !important;
}

.portal-mentee-profile-wrp {
  border-right: 1px solid #e9eff5;
  .Profile-Container {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    .left-sidebar {
      background-color: #fff;
      width: 321px;
      .snapshot-comp-wrp {
        height: 100%;
        box-shadow: none !important;
      }
    }
    .snapshot-comp-wrp.is-ccp {
      width: 320px;
      padding: 24px;

      .member-info {
        margin-top: 0;
        .mentee-name {
          padding-right: 0;
          margin-right: 0;
        }
        .mentee-status {
          margin-bottom: 6px;
        }
      }
      .snapshot-mentee-availability-wrp {
        border-top: 1px solid #e9eff5;
        border-bottom: 1px solid #e9eff5;
      }
      .clinical-measure-compo {
        box-shadow: none;
        border-bottom: 1px solid #e9eff5;
        border-top: 0;
      }
    }
  }
}

.cp-issue-details p {
  font-family: 'Calibre';
  text-decoration: underline dotted;
  text-align: left;
}

.mentee-issue-card {
  padding: 16px 16px 12px 16px;
  border: 1px solid #e9eff5;
  margin: 12px 16px;
  border-radius: 8px;
  &.is-goal {
    height: 163px;
  }
  &.is-call {
    height: 150px;
  }
  &.is-goal, &.is-call {
    .mentee-issue-card-header {
      cursor: pointer;
      height: 100%;
      position: relative;
      .desc {
        span.named-entity {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .card-tags-wrp {
        width: 100%;
        overflow-x: auto !important;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        .mt-1 {
          margin-top: 0 !important;
        }
        .mb-1 {
          margin-bottom: 2px !important;
        }
        .badge {
          white-space: nowrap;
        }
      }
    }
  }
  &.selected {
    background-color: #f9fcfe;
    .round-tag.bd-only {
      border: 1px solid #ccd6e2;
    }
  }
}

.filter-tag {
  padding: 6px;
  border-radius: 4px;
  font-size: 14px !important;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #848587;
  background: #f6f9fc;
  font-family: 'Calibre';
  &.selected {
    background: #007be2;
    font-family: 'Calibre-Medium';
    color: #ffffff;
  }
}
.jit-main-content {
  .sidebar-col {
    .comments-compo-wrp {
      margin-right: 0px;
    }
    .nav-tabs {
      padding-left: 48px !important;
    }
    .tab-pane {
      margin-left: 24px;
    }
  }
}

.clinical-measure-compo {
  .cright-nav.opened > .content {
    background-color: #fff;
    .card {
      border: 0 !important;
    }
  }
}

.outreach-prospect-referrals-compo {
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 8px;
  .mentees-list {
    flex-shrink: 0;
    height: calc(100vh - 97px);
    overflow-y: auto;
    border-right: 1px solid #e9eff5;
  }
  .mentee-details {
    flex-grow: 1;
    height: calc(100vh - 97px);
    overflow-y: auto;
    padding: 24px;

    .custom-react-select .custom__control {
      padding: 9px 12px !important;
      height: unset !important;
      border: 1px solid #e0e8f0 !important;
    }
  }
  .unanswered-questions-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    > div {
      padding-right: 15px;
      width: 100%;
    }
    .full-row {
      width: 100%;
    }
    .half-row {
      width: 33.3333%;
    }
    // >div {
    //   width: 25%;
    //   padding-right: 15px;
    // }
  }
}

/**
 * New Update 2020/11/2
 */
.ccp-inbox-filters {
  border: 1px solid #e9eff5;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;

  .Search-input {
    input {
      border: 1px solid rgba(132, 133, 135, 0.5);
      border-radius: 4px;
      width: 360px;
      height: 32px;
      font-size: 16px;
      color: #374050;
      padding: 5px 12px;
      line-height: 16px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;

      &::placeholder {
        color: #768091;
        font-size: 16px;
        line-height: 16px;
      }
    }
  }

  .Select {
    width: 200px;
  }

  .filters-list {
    .blue-text-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: $secondary-blue;
      font-size: 18px;
      .number-badge {
        color: #0094f3;
        font-size: 14px;
        font-family: $font-medium;
        background: #ebf6ff;
        border-radius: 12px;
        padding: 2px 8px;
        display: inline-block;
      }
    }
  }
  .filters-wrp {
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    margin-top: 16px;
    .each-filter {
      width: 100%;
      margin-bottom: 12px;
    }
    > div:last-child {
      margin-bottom: 0 !important;
    }
    .custom-react-select .custom__control {
      min-height: 48px;
      border-radius: 4px;
    }
  }
  &.is-open {
    background-color: #f9fcfe;
  }
}

.inboxes-list {
  .inbox-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #374050;
    position: relative;
    margin-left: -24px;
    margin-right: -24px;
    padding: 8px 24px;
    cursor: pointer;
    margin-bottom: 12px;
    svg {
      color: #374050;
    }
    &.is-selected {
      cursor: unset;
      color: $secondary-blue;
      svg {
        color: $secondary-blue;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background: #0094f3;
        border-radius: 0px 4px 4px 0px;
      }
    }
  }
}
