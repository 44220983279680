.outreach-call {
  .outreach-header {
    border-bottom: 1px solid #e9eff5;
  }

  .sidebar-compo {
    padding-left: 24px;
    padding-right: 24px;

    .sidebar-header {
      padding-left: 0;
      padding-right: 0;
      border-bottom: 0;
    }

    .sidebar-header .nav.nav-tabs {
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
      overflow-x: visible;
      border-bottom: 1px solid #dee2e6;
    }

    .sidebar-header {
      padding-top: 8px;
      position: relative;

      .menu-btn.is-close {
        position: absolute;
        right: -24px;
        top: 0;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        background-color: transparent;
        color: #95a0b0;
        height: 100%;
        border-left: 1px solid #e0e8f0;
        cursor: pointer;
      }
    }


    .issues-main-wrp {
      >nav.custom-styled-tabs.nav.nav-tabs.is-big-text {
        a {
          border-bottom: none !important;
          padding-bottom: 0 !important;
          line-height: 1;

          span {
            font-size: 19px !important;
            font-family: $font-regular !important;
            color: #374050 !important;
            padding-bottom: 0 !important;
          }
        }
      }

      .custom-styled-tabs.nav.nav-tabs.have-circle-counts .nav-item .custom-tab-link.with-circle-count {
        font-size: 16px;
      }
    }
  }
}

.outreach-header {
  min-height: 97px;
  background-color: #fff;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  .back-btn {
    color: #c2c2c2;
    margin-right: 49px;
  }

  .mentee-info {
    width: 100%;

    .tag-lbl {
      display: inline-block;
      margin-left: 20px;
      margin-top: 2px;
    }
  }

  .outreach-actions {
    flex: 0 1 auto;
  }
}

.mentee-calls {
  >div:last-child {
    margin-bottom: 0 !important;
  }
}

.mentee-outreach-wrp {
  background-color: #fff;

  .sidebar-compo .sidebar-header .nav.nav-tabs {
    margin-left: 0;
    margin-right: 0;
  }
}

.outreach-call.is-mentee-outreach-profile {
  .outreach-header {
    display: block;
    padding-bottom: 0;

    .person {
      width: 100%;

      >div {
        width: 100%;
      }
    }

    .outreach-actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      >div {
        margin-right: 16px;
      }
    }
  }

  .outreach-main .round-pane.enrollment-detail {
    border-top: none;
  }

  .btn-with-border {
    width: calc(100% + 48px);
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: -24px;
    margin-right: -24px;
    padding: 24px;
    border: 0;
    border-bottom: 1px solid #e0e8f0;
    border-radius: 0;

    &:hover,
    &:active {
      background-color: transparent;
      border-bottom: 1px solid #e0e8f0;
    }

    &.border-top {
      border-top: 1px solid #e0e8f0 !important;
    }
  }

  .custom-styled.ccp-updated.nav.nav-tabs {
    flex-wrap: wrap !important;

    .nav-item {
      padding-left: 0;
      padding-right: 0;
      margin-right: 16px;
      margin-top: 9px;
    }
  }



  .flexible-col {
    .round-pane {
      padding: 0;
    }
  }

  .q-box .q-title {
    font-size: 16px;
    color: #374050;
  }
}

.mentor-outreach-profile-wrp .outreach-call {
  .flexible-col {
    padding-top: 16px;
  }

  .outreach-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
  }
}

.notification-box {
  border-radius: 12px;
  padding: 14px;
  margin-bottom: 24px;
  width: 100%;
}


/** Outreach Dashboard **/
.outreach-dashboard-cont {
  background-color: #fff;

  .white-box {
    width: 100%;

    .blue-select-box-wrp {
      width: 120px;

      select {
        font-family: $font-regular;
      }
    }
  }

  .outreach-dashboard-tabs {
    &+div {
      background-color: #e9eff5;
      height: calc(100vh - 153px);
      overflow-y: auto;
    }
  }
}

.outreach-call {
  background-color: #fff;

  .outreach-main {
    padding: 0;
    margin: 0;

    .left-col {
      .round-pane {
        padding: 24px;
      }
    }

    .round-pane {
      margin-top: 15px;
      padding: 9px;

      .pane-title {
        padding: 18px 16px 6px 16px;
      }

      &.enrollment-detail {
        padding: 24px 0;
        border-top: 1px solid #e9eff5;

        .callinfo {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .round-player-wrp {
            margin: 0 0 0 10px;
          }
        }
      }

      &.program-wrp {
        .pane-title {
          padding: 0;
        }

        .each-program {
          padding: 13px;
          background-color: #f6f9fc;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      &.physician-wrp {
        padding-left: 0;
        padding-right: 0;

        .pane-title {
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 0;
          padding-bottom: 15px;
          border-bottom: 1px solid #ebebeb;
        }
      }

      &.script-wrp {
        min-height: 250px;

        .talkingpoints-body {
          // max-height: 500px;
          // overflow: auto;
          padding: 16px;
          border: 1px solid #e9eff5;
        }

        .pane-title {
          padding: 0;
        }
      }
    }

    .left-col {
      padding: 0;
    }

    .flexible-col {
      padding: 0;
    }

    .recent-activities-compo .new-timelines .date-line .activity-list>div:not(:last-child) {
      top: 4px;
    }

    .recent-activities-compo .new-timelines .date-line .activity-name:before {
      top: 4px;
    }

    .custom-input-compo {
      padding-top: 0;
      position: relative;

      .custom-note-close-btn {
        right: 0;
      }
    }
  }

  .tags-wrp {
    border-top: 1px solid #e9eff5;

    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .sidebar-compo {
    padding-left: 18px;
    padding-right: 18px;
    background-color: #fff;
    overflow-x: hidden;
  }


  .sidebar-compo.col-lg-1 {
    .inner-col {
      height: 100%;

      .sidebar-header.menu-closed {
        height: 100%;
      }
    }
  }
}

.talkingpoints-body table {
  width: 100%;
}

.quick-summary {
  .summary-list {
    .summary-value {
      padding-left: 25px;
      position: relative;
      padding-bottom: 24px;

      &:before {
        content: '';
        position: absolute;
        left: 6px;
        top: 5px;
        width: 2px;
        height: 100%;
        background-color: #88c04e;
      }

      .value-detail {
        display: flex;
        align-items: flex-start;
        position: relative;

        &:before {
          content: '';
          left: -24px;
          width: 12px;
          height: 12px;
          top: 5px;
          border-radius: 6px;
          position: absolute;
          background-color: #88c04e;
        }
      }

      &.inactive {
        &:before {
          background-color: #dae2eb;
        }

        .value-detail {
          &:before {
            background-color: #ccd6e2;
          }
        }
      }
    }

    >div:last-child {
      &:before {
        content: none !important;
      }
    }
  }
}

.outreach-call {
  .properties {
    display: flex;
    flex-wrap: wrap;

    >li {
      width: 50%;
      padding-right: 15px;
      border-bottom: 0;
      margin-bottom: 16px;
    }

    .property-label {
      font-size: 14px;
      font-family: $font-regular;
      color: #576071;

      &+span {
        display: block;
        width: 100%;
        font-size: 16px;
        color: #374050;
        font-family: $font-medium;
        line-height: 1.2;
      }
    }
  }
}

/**
* new outreach styles
*/
.mentee-outreach-profile-wrp {
  display: flex;

  >div {
    max-height: calc(100vh - 160px);
    overflow-y: auto;
  }

  .outreach-left-sidebar-wrp {
    width: 254px;
    border-right: 1px solid #e0e8f0;
    box-shadow: 0px 2px 10px rgba(55, 64, 80, 0.1);
    padding: 24px 16px;

    .outreach-left-sidebar {
      &-outreach-actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        >div {
          margin-right: 16px;
        }

        .mute-btn {
          margin-right: 10px;
          display: inline-flex !important;

          >span {
            display: none;
          }
        }

        .call-btn-with-lbl.is-small {
          .lbl {
            margin-left: 8px;
            color: #576071;
            font-size: 14px;
          }
        }
      }

      .btn-with-border {
        width: calc(100% + 32px);
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: -16px;
        margin-right: -16px;
        padding: 16px;
        border: 0;
        border-bottom: 1px solid #e0e8f0;
        border-radius: 0;

        &:hover,
        &:active {
          background-color: transparent;
          border-bottom: 1px solid #e0e8f0;
        }

        &.border-top {
          border-top: 1px solid #e0e8f0 !important;
        }
      }
    }

    .enrollment-detail {
      width: auto;
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;

      .enrollment-wrp,
      .other-details {
        padding: 0 !important;
      }

      .tags-wrp {
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 8px;
      }
    }
  }

  .outreach-main-content {
    flex-grow: 1;
  }
}

.outreach-left-sidebar-menu {
  padding-top: 14px;
  padding-bottom: 14px;

  &.border-top {
    border-top: 1px solid #e0e8f0;
  }

  &.border-bottom {
    border-bottom: 1px solid #e0e8f0;
  }

  .menu-item-wrp {
    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #374050;
      padding: 8px 0;

      svg {
        color: #ccd6e2;
        margin-right: 8px;
      }

      &:hover {
        text-decoration: none !important;
      }

      &[disabled] {
        pointer-events: none;
        color: #768091;
      }
    }

    &.is-completed {
      a {
        color: #374050;

        svg {
          color: #0094f3;
        }
      }
    }

    &.is-selected {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: -16px;
        top: 0;
        width: 4px;
        height: 100%;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background-color: #0094f3;
      }

      a {
        color: #0094f3;

        svg {
          color: #0094f3;
        }
      }
    }
  }
}

.mentee-information-wrp {
  .filters-wrp {
    padding: 0;

    .each-filter {
      margin-bottom: 24px;

      >select,
      >input,
      >textarea {
        min-height: 48px;
        border: 1px solid #e0e8f0;
        border-radius: 4px;
      }

      .custom-react-select .custom__control {
        min-height: 48px;
        border: 1px solid #e0e8f0;
        border-radius: 4px;
      }

      >span {
        font-size: 16px;
        color: #374050;
        margin-bottom: 8px;
      }
    }
  }
}

.sidebar-compo.is-mentee-outreach-sidebar {
  .sidebar-header.is-outrichsidebar {
    padding-top: 12px;
    .MuiTabs-root .MuiTabs-scroller {
      border-bottom: none;
    }
    .MuiTabs-flexContainer .MuiButtonBase-root {
      width: 42px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.menu-closed {
      padding: 12px 4px;
      .MuiTabs-flexContainer .MuiButtonBase-root {
        width: 100%;
        height: 42px;
      }
    }
  }



  // &.is-collapsed {
  //   padding-left: 0;
  //   padding-right: 0;
  //   background-color: #fff;
  //   max-width: 100%;
  //   .sidebar-header {
  //     &.menu-closed {
  //       background-color: #fff;
  //       padding: 0;
  //     }
  //     .menu-btn.is-close {
  //       position: relative;
  //       height: auto;
  //       width: 100%;
  //       transform: rotate(180deg);
  //       left: 0;
  //       right: 0;
  //       top: 0;
  //       height: 58px;
  //       border-top: 1px solid #e0e8f0;
  //       border-left: 0;
  //     }
  //     .custom-styled.ccp-updated.nav.nav-tabs {
  //       width: 100%;
  //       flex-direction: column;
  //       padding: 0;
  //       height: auto;
  //       border-bottom: 0;
  //       margin: 0;
  //       .nav-item {
  //         height: 48px;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         padding: 0 !important;
  //         .nav-link {
  //           color: #95a0b0;
  //         }
  //       }
  //     }
  //   }
  // }
}

.outreach-mentee-peer-match {
  &-match-types-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: -9px;
    margin-right: -9px;

    .gray-bd-box {
      width: calc(50% - 18px);
      text-align: center;
      margin-right: 9px;
      margin-left: 9px;
      margin-bottom: 18px;
      max-width: 360px;

      .icon-wrp {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
      }

      .btn {
        padding: 10px 20px 12px 20px !important;
        height: auto !important;
      }
    }
  }

  .custom-styled-tabs.ccp-updated.nav.nav-tabs {
    padding-left: 0;
    padding-right: 0;

    .nav-item {
      color: #95a0b0;
      // border-bottom: 0 !important;
      padding-bottom: 4px !important;
      line-height: 1;
      height: 30px !important;

      span {
        font-size: 17px !important;
        color: #95a0b0;
      }

      .MuiBadge-root {
        width: max-content;
        margin: 0 5px !important;

        .MuiBadge-badge {
          background-color: #95a0b0;
          color: white !important;
          right: -4px !important;
        }
      }

      >div {
        padding-bottom: 8px;
      }

      svg {
        color: #95a0b0;
        width: 16px;
        height: 16px;
      }

      &.Mui-selected {
        color: #374050 !important;

        &.color-8ABE57 {
          border-bottom: 2px solid #8abe57 !important;

          svg {
            color: #8abe57;
          }

          .MuiBadge-badge {
            background-color: #8abe57;
          }
        }

        &.color-EC6254 {
          border-bottom: 2px solid #ec6254;

          svg {
            color: #ec6254;
          }

          .MuiBadge-badge {
            background-color: #ec6254;
          }
        }

        &.color-5AC9F1 {
          border-bottom: 2px solid #5ac9f1;

          svg {
            color: #5ac9f1;
          }

          .MuiBadge-badge {
            background-color: #5ac9f1;
          }
        }

        &.color-F6BF5E {
          border-bottom: 2px solid #f6bf5e;

          svg {
            color: #f6bf5e;
          }

          .MuiBadge-badge {
            background-color: #f6bf5e;
          }
        }
      }
    }

    &+.tab-content {
      padding-top: 24px;
    }
  }
}

.notion-script-wrp {
  .notion-page-offset {
    margin-top: 0;
  }
}

.outreach-main-content-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e0e8f0;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  z-index: 3;
}


.bg-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: $font-medium;
  padding: 2px 8px;
  border-radius: 12px;
  border: 1px solid transparent;

  &.is-outlined {
    border: 1px solid #e0e8f0;
    color: #768091 !important;
    background-color: transparent !important;
  }
}

.sidebar-tab-btn {
  margin-left: -16px;
  margin-right: -16px;
  position: relative;
  padding: 12px 16px;
  color: #374050;

  &.is-selected {
    color: $secondary-blue;

    &:before {
      content: '';
      background-color: $secondary-blue;
      position: absolute;
      left: -2px;
      top: 0;
      height: 100%;
      width: 4px;
      border-radius: 2px;
    }

  }
}


.mentee-outreach-availabilties-wrp {
  padding-bottom: 120px;
}

.mentee-outreach-tabs.MuiTabs-root  {
  .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root {
    margin-right: 16px;
    font-size: 20px;
    &.Mui-selected {
      color: #374050;
    }
  }
}