.embed-audio-player {
  display: flex;
  background: #FFFFFF;
  border: 0.25px solid #E1E1E1;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  color: #000;
  padding: 5px 10px 10px 10px;
  width: 100%;

  @media(max-width: 1110px) {
    margin-left: 30px;
    margin-right: 30px;
    width: auto;
  }

  .audio-wrp {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .audio-meta {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;

      .timelabels {
        color: #000;
        line-height: 1;
        font-variant-numeric: tabular-nums;
      }

      .action-btns {
        margin-bottom: 0;

        button {
          color: #808080;
          font-family: $font-regular;
          font-size: 15px;
          padding: 0;
          margin: 0 2px;
          width: 32px;
          height: 32px;
          display: inline-flex;
          align-items: center;
          justify-content: center;


          i {
            font-size: 16px;
          }

          &::hover {
            color: #fff;
          }

          &.current {
            font-family: $font-bold;
          }
        }
      }
    }

    >p {
      margin-top: 11px;
      color: #808080;
    }

    .audio-player-pgbar {
      border: 0;
      height: 5px;
      cursor: pointer;
      background-color: #e6e6e6;
      color: #35BCE6;
      width: 100%;
      border-radius: 0;

      &::-webkit-progress-bar {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &::-webkit-progress-value {
        background-color: #35BCE6 !important;
      }

      &::-moz-progress-bar {
        background-color: #35BCE6 !important;
      }
    }

    .text-danger {
      position: absolute;
      left: 0;
      bottom: -15px;
      width: 100%;
      text-align: center;
    }
  }

  &.is-popup {
    display: flex;
    z-index: 1000;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1110px;
    padding: 20px 30px;

    .call-info {
      width: 25%;
    }

    .audio-wrp {
      width: 50%;
    }

    .close-msg {
      position: absolute;
      right: 12px;
      top: 12px;
      width: auto;
      opacity: 0.5;
      cursor: pointer;
    }
  }
  &:empty {
    display: none;
  }
}

.simple-audio-player {
  margin: 10px 0px;

  .audio-wrp {
    display: flex;
    align-items: flex-start;

    .play-btn {
      color: #ffffff;
      background-color: #0094F3;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      cursor: pointer;


      svg {
        color: #fff;
        transform: scale(0.6);
      }
    }

    .audio-player-bar {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: 13px 15px 0 15px;

      .progress-bar {
        border: 0;
        height: 5px;
        cursor: pointer;
        background-color: #DAE2EB;
        color: #768091;
        width: 100%;
        border-radius: 0;

        &::-webkit-progress-bar {
          background-color: #DAE2EB;
        }

        &::-webkit-progress-value {
          background-color: #95A0B0 !important;
        }

        &::-moz-progress-bar {
          background-color: #95A0B0 !important;
        }
      }

      .timelabels {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        color: #768091;
      }
    }

    .steplet-dropdown {
      margin-top: 0 !important;
    }

    .speed-buttons {
      color: #768091;
      font-size: 5px;

      .rainbow-button-icon--border-filled {
        background-color: transparent;
        color: #768091;
        border: 0;
      }

      .rainbow-button-icon {
        box-shadow: none;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}



.copilot-audio-player {
  border: 1px solid #E0E8F0;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 16px;
  .audio-wrp {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .play-btn {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #0094F3;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      flex-shrink: 0;

      svg {
        color: #fff;
        transform: scale(0.6);
      }
    }

    .audio-player-bar {
      flex-grow: 1;
      padding-top: 8px;
      position: relative;
      .progress-bar {
        width: 100%;
        height: 4px;
        border-radius: 2px;
        background: #E9EFF5;
        color: $secondary-blue;
        &::-webkit-progress-bar {
          background-color: #E9EFF5;
          border-radius: 2px;
        }

        &::-webkit-progress-value {
          background-color: $secondary-blue;
          border-radius: 2px;
        }

        &::-moz-progress-bar {
          background-color: $secondary-blue !important;
          border-radius: 2px;
        }
      }
      .clip-inds {
        position: absolute;
        left: 0;
        top: 8px;
        right: 0;
        pointer-events: none;
        height: 4px;
        .each-clip {
          position: absolute;
          background-color: #FF8C00;
          border-radius: 2px;
          height: 4px;
          opacity: 0.3;
        }
      }
      .timelabels {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .speed-buttons {
      flex-shrink: 0;
      margin-left: 2px;
      line-height: 1;
      >button {
        border: 0;
        height: auto;
        padding: 6px 0;
        width: 30px;
        i {
          font-size: 10px;
          color: #768091;
          transform: scale(0.4);
        }
      }
    }
  }
  &.nopd {
    padding: 0;
  }
  &.nobd {
    border: 0;
  }
}

.audio-wrp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .play-btn {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #0094F3;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    flex-shrink: 0;

    svg {
      color: #fff;
      transform: scale(0.6);
    }
  }
}