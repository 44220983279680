.listing-container-with-sidebar {
  background-color: #fff;

  .left-nav-listing-header-col {
    .detail-filters-wrp {
      // >div:first-child {
      //   border-top: 1px solid #E9EFF5 !important;
      // }

      .card {
        h4 {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          i {
            font-size: 20px;
            margin-right: 20px;
            color: #768091;
          }
        }

        &.active {
          h4 {
            i {
              color: #374050;
            }
          }
        }
      }
    }

    &.collapsed {
      flex: 0 0 70px;
      max-width: 70px;

      .main-header {
        text-align: center;

        button {
          padding: 6px 14px !important;
        }
      }

      .left-nav-listing-header {
        padding: 24px 5px;

        .fz-22.ff-semibold.text-charcoal-700 {
          font-size: 16px !important;
          line-height: 20px !important;
        }

        .detail-filters-wrp {
          margin-left: -5px;
          margin-right: -5px;

          .card {
            padding: 20px 15px;

            h4 {
              justify-content: center;

              i {
                margin-right: 0;
              }
            }
          }
        }
      }


      .menu-wrp {
        justify-content: center;

        span {
          cursor: pointer;
          transition: all ease 0.3s;
        }

        span i {
          // color: #fff;
          cursor: pointer;
          transform: none;
          transition: none;
        }
      }
    }
  }

  .main-content-col {
    &.collapsed {
      flex: 0 0 calc(100% - 70px);
      max-width: calc(100% - 70px);
    }
  }

  &.ivr-home-wrp {
    padding-left: 0;
    padding-right: 0;

    >.row {
      margin-left: 0;
      margin-right: 0;

      >div {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .ivr-content-wrp {
      >div {
        padding: 24px;
      }
      h4 {
        font-size: 18px;
        font-family: $font-medium;
        color: #374050;
        line-height: 32px;
        margin-bottom: 12px;
      }
    }
    .react-datepicker__input-container {
      input {
        width: 400px;
        font-size: 16px;
        color: #576071;
      }
    }
  }
}
.filter-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  font-size: 16px;
  font-family: $font-medium;
  color: $primary-blue;
  text-decoration: none !important;

  i.icon {
    font-size: 16px;
    margin-left: 2px;
    line-height: 1 !important;
    margin-top: 2px;
  }
}
.searchbar-w-filters-toggle {
  padding: 24px 24px 20px 24px;
  width: 100%;



  .mentee-filters-wrp {
    .container-fluid {
      padding: 0;
    }
  }

  .search-box {
    margin-top: 0;

    .filter-searchbox {
      height: 32px;
      margin-right: 20px;
      margin-top: 0;
      max-width: 360px;
      margin-bottom: 0 !important;

      #search-query {

        background-color: #fff;
        font-size: 16px;
        line-height: 1;
        font-family: $font-regular;
        border: 1px solid #CCD6E2;
        box-sizing: border-box;
        padding-top: 6px;
        padding-bottom: 6px;
        max-height: 32px;
      }

      .input-group-btn {
        display: block;
      }
    }
  }
}



.es-listing-main {
  .sort-filter {
    width: 200px;
  }
}

.number-badge {
  background: $secondary-blue;
  border-radius: 4px;
  font-size: 14px;
  font-family: $font-medium;
  color: #fff;
  line-height: 16px;
  margin-left: 6px;
  margin-right: 6px;
  padding: 1px 5px 2px 5px;
  text-align: center !important;
  min-width: 16px;
  display: inline-block;
  // width: 16px;
  // height: 16px;
  // &.square {
  //   padding-left: 5px;
  //   padding-right: 5px;
  // }
  &.inactive {
    background: #E9EFF5;
  }
  &.is-circle {
    border-radius: 12px;
    background-color: #F6F9FC;
    font-size: 14px;
    line-height: 1;
    padding: 4px 5px 4px 5px;
    color: #768091;
    min-width: 24px;
    text-align: center;
    font-family: $font-medium;
  }
  &.is-red {
    background: #FEF1F2;
    color: #F5000F;
  }
}

.cright-nav.opened {
  background-color: #fff;

  >.content {
    background-color: #fff;

    >.tab-content {
      background: #fff;

      >div>.card {
        border-top: 0;
        background-color: #fff;
        border: 0;

        .card-wrp.steplet-module {
          background-color: #fff;
          padding: 15px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .steplet-modal-header {
    .dropdown-wrp {
      width: 100px;
    }

    .filter-wrp {
      width: calc(100% - 100px);

      .Select-control {
        height: 30px;
        outline: 0;
        box-shadow: 0;
        border: 1px solid #ebebeb;
        border-radius: 5px;
      }

      .Select-control,
      .Select-menu-outer {
        * {
          font-size: 15px !important;
          font-family: $font-regular !important;
        }

        .Select-value {
          line-height: 30px;
        }

        .Select-input {
          height: 30px;

          input {
            height: 100%;
          }
        }
      }
    }
  }

  .new-steplet-dropdown {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .dropdown-toggle.btn.btn-primary {
      background-color: transparent;
      border: 0;
      box-shadow: 0;
      padding: 0;
      margin: 0;
      color: #979797;
      font-size: 15px;
      font-family: $font-regular;
      box-shadow: none;

      &::after {
        content: none;
      }
    }

    .dropdown-menu {
      background-color: #fff;

      a {
        font-size: 15px;
        font-family: $font-regular;
        color: #000;
      }
    }
  }

  .cright-steplets-tabs.nav.nav-tabs {
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 5px;

    a.nav-item.nav-link {
      border: 0;
      font-size: 15px;
      font-family: $font-regular;
      color: #7F7F7F;
      background-color: transparent;
      width: 130px;

      &.active {
        font-size: 15px;
        font-family: $font-bold;
        color: #000;
      }
    }
  }

  .custom-modal-header {
    padding: 18px 24px;
    background-color: #fff;

    .icon-menu-to-right {
      color: #768091;
      font-size: 16px;
    }
  }

  .custom-modal-content {
    background-color: #fff;
    height: calc(100vh - 138px);
    overflow-y: auto;

    .filters-wrp {
      background-color: #fff;
      padding: 24px 14px 14px 24px;

      .each-filter {
        padding-right: 10px;
      }

    }
  }

  .custom-modal-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    border-top: 1px solid #DAE2EB;
  }
}

.filters-wrp {
  display: flex;
  // align-items: center;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 24px 24px 14px 24px;
  background: #F6F9FC;
  border-radius: 4px;
  position: relative;
  width: 100%;

  >h3 {
    width: 100%;
  }

  .search-input.search-input-float input {
    background-color: #fff;
  }

  .each-filter {
    margin-bottom: 10px;
    width: 20%;
    padding-right: 10px;

    &.twice-width {
      width: 40%;
    }

    @media(max-width: 1600px) {
      width: 30%;
    }

    @media(max-width: 1200px) {
      width: 50%;
    }

    >span {
      display: block;
      width: 100%;
      font-size: 14px;
      font-family: $font-regular;
      line-height: 20px;
      margin-bottom: 5px;
      color: #576071;
      background-color: transparent;
    }

    >select,
    >input,
    >textarea {
      background-color: #fff;
      border: 1px solid #DAE2EB !important;
      display: block;
      height: auto;
      font-size: 16px;
      color: #374050;
      padding: 3px 10px;
      border-radius: 4px;
      width: 100%;
      box-shadow: none !important;
      outline: 0 !important;
    }


    >select,
    >input {
      height: 32px;
    }

    >.inner-wrp {
      >span {
        display: block;
        width: 100%;
        font-size: 14px;
        font-family: $font-regular;
        line-height: 20px;
        margin-bottom: 5px;
        color: #576071;
        background-color: transparent;
      }
    }

    .custom-styled.selector-wrp {
      background-color: #fff;
    }

    .selected-vals {
      background-color: #fff;
      border: 1px solid #DAE2EB;
      display: block;
      min-height: 32px;
      border-radius: 5px;
      padding: 6px 26px 6px 10px;
      color: #7F7F7F;
      line-height: 19px;
      font-size: 15px;
      position: relative;

      .badge {
        background-color: $secondary-blue;
        font-size: 14px;
        color: #fff;
        border-radius: 5px;
        margin: 3px;
        white-space: unset;
        text-align: left;
      }
    }
  }

  .btn-clear-all-filters {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #768091;
    font-size: 16px;
    line-height: 13px;
    font-family: $font-regular;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important;

    i {
      font-size: 10px;
      color: #768091;
      margin-right: 8px;
    }
  }

  >.search-box {
    width: 100%;
    padding-left: 0;
    width: 100%;

    .filter-searchbox {
      width: 100%;
    }
  }
  &.is-no-bg {
    background-color: transparent;
  }
  &.is-no-padding {
    padding: 0;
  }
  &.mentee-list-header {
    padding: 14px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search-box {
      margin-bottom: 0;
      margin-top: 0;
      width: 400px;
      display: flex;
      align-items: center;
    }
    .btn-add-filter {
      margin-bottom: 0 !important;
    }
    >div:first-child {
      width: calc(100% - 450px);
    }
    .icon.icon-times-btb {
      transform: scale(0.8);
    }
  }
}


.interested-app-checkbox {
  margin-bottom: 0;
}

.mobile-types {
  .Select-control {
    background-color: #fff;
    border: 1px solid #DAE2EB;
    // display: block;
    // height: auto;
    font-size: 16px;
    color: #374050;
    // padding: 7px 10px;
    // border-radius: 4px;
    width: 100%;
  }
}

.generic-stats-wrp {
  width: 100%;
}

.mini-stats-table {
  width: 100%;
  thead {
    th {
      font-size: 14px;
      color: #768091;
      font-weight: 400;
      font-family: $font-regular;
    }
    th:not(:first-child) {
      text-align: center;
    }
  }
  &.is-solid-header {
    thead {
      background-color: #F6F9FC;
      border-bottom: 1px solid #E0E8F0;
      th {
        padding: 14px 24px;
      }
    }
    tbody {
      td {
        padding: 14px 24px;
      }
    }
  }
  tbody {
    tr {
      td {
        padding-top: 11px;
        padding-bottom: 11px;
        border-bottom: 1px dashed #E9EFF5;
        line-height: 1.2;
        vertical-align: top;
      }
      td:first-child {
        font-size: 18px;
        color: #374050;
      }
      td:not(:first-child) {
        font-size: 18px;
        font-family: $font-medium;
        color: $secondary-blue;
        text-align: center;
      }
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
  &.is-center-align {
    tbody {
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
  }
  &.is-solid-border {
    tbody {
      > tr {
        td {
          border-bottom: 1px solid #E9EFF5;
        }
        &:last-child {
          td {
            border-bottom: 0;
            tr {
              td {
                border-bottom: 1px solid #E9EFF5;
              }
            }
          }
        }
      }
    }
  }
  &.is-more-space {
    tbody {
      tr {
        td {
          padding-top: 16px;
          padding-bottom: 16px;
        }
      }
    }
  }
  tbody {
    tr.is-dark-border {
      td {
        border-bottom: 1px solid #CCD6E2;
      }
    }
  }
  &.is-selectable {
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
    tbody {
      tr {
        &.is-selected {
          td {
            position: relative;
            > * {
              z-index: 1;
              position: relative;
            }

            &:before {
              content: '';
              position: absolute;
              left: -16px;
              right: -16px;
              top: -1px;
              bottom: -1px;
              border-radius: 8px;
              background-color: #F6F9FC;
              z-index: 0;
            }
          }
        }
      }
    }
  }
}

.on-call-bg {
  background: #FDECED;
  border-radius: 28px;
  padding-right: 15px;
}

.g-with-centered-dot {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  .centered-circle {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #374050;
    flex-shrink: 0;
    margin-right: 6px;
    margin-top: 7px;
  }
}
.with-centered-dot {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  .centered-circle {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #374050;
    flex-shrink: 0;
    margin-right: 8px;
    margin-top: 8px;
  }
}