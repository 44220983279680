.portal-mentee-list-wrp {
  .MuiTabs-root {
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 1px solid #E9EFF5;

    .MuiTabs-scroller {
      border-bottom: 0;
    }
  }
}

.mail-with-attach-wrp {
  margin-left: -43px;
  margin-right: -43px;
  padding-left: 43px;
  padding-right: 43px;
  background-color: #fff;
  padding-top: 32px;
  padding-bottom: 23px;
  flex-grow: 1;

  textarea {
    width: 100%;
    background: #F5F5F5;
    border-radius: 3px;
    border: none;
    margin-bottom: 28px;
    padding: 0.5rem;
    outline: 0 !important;
    box-shadow: none;
    font-size: 16px;
  }

  .form-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .gray-btn {
      background: #DDDDDD;
      border-radius: 3px;
      border: 0;
      font-size: 15px;
      font-family: 'Calibre';
      opacity: 0.5;
      padding: 13px 17px;
      margin-right: 45px;
    }

    .nopd.nobd {
      margin-right: 23px;

      img {
        width: 21px;
      }
    }
  }
}

.bottom-btn {
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  height: 60px;
  color: #fff;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5695CF;
  border: 0;
  box-shadow: none;
}

.round-tag-outline {
  border: 1px solid #768091 !important;
  border-radius: 4px !important;
  background-color: transparent;
  font-size: 13px;
  padding: 4px 8px 5px 8px;
  font-family: $font-regular;
  color: #576071;
  margin-right: 7px;
  margin-bottom: 7px;
}

.round-tag {
  background-color: #dfdfdf;
  padding: 4px 8px 5px 8px;
  border-radius: 3px;
  margin-right: 10px;
  line-height: 1;
  color: #000;
  opacity: 0.7;
  font-size: 13px;
  font-family: 'Calibre';
  margin-right: 7px;
  margin-bottom: 7px;
  border: 0;
  box-shadow: none;
  display: inline-block;

  &:hover {
    opacity: 1;
  }

  &.selected {
    background-color: #FFF7EC;
    position: relative;

    &.has-button {
      padding-right: 20px;

      button {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%) scale(0.4);
        font-size: 12px;
        color: #768091;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        line-height: 1;
        background-color: transparent;
        border: 0 !important;
        padding: 0 !important;
      }
    }
  }

  &.bd-only {
    border: 1px solid #E9EFF5;
    background-color: transparent;
    color: #576071;
    opacity: 1;
  }

  &.bd-none {
    border-color: transparent;
    background-color: transparent;
    color: #576071;
  }
}

.round-bd-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: $font-regular;
  color: #768091;
  line-height: 1;
  padding: 4px 8px 4px 8px;
  border: 1px solid #E9EFF5;
  box-sizing: border-box;
  border-radius: 8px;
}




.comments-compo-wrp {
  position: relative;
  margin-left: -24px;
  margin-right: -24px;

  .comments-list {
    padding-left: 24px;
    padding-right: 24px;

    .each-comment {
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #E0E8F0;

      .comment {
        max-width: 80%;
      }

      .ql-editor {
        font-family: Avenir LT Std Roman;
        margin: 0px 0px 10px 0px;
        padding: 0px !important;
      }
    }
  }

  .custom-note-editor {
    margin: 0 !important;
    padding: 0 !important;
  }

  .custom-input-compo .custom-note-bottom {
    background-color: transparent;
  }

  .custom-input-compo .custom-note-close-btn {
    right: 20px;
  }

  .bottom-fixed-wrp {
    position: relative;
    background-color: #fff;
    border-top: 1px solid #E9EFF5;
    border-bottom: 1px solid #E9EFF5;
    padding: 24px;
    margin-top: 16px;

    .ta-wrp-w-btn {
      margin-bottom: 0 !important;
    }

    .row {
      margin: 0 !important;

      >div {
        padding: 0 !important;
      }
    }
  }
}




.jit-main-content {
  background-color: #fff;
  min-height: calc(100vh - 200px);

  .kanban-issues-list-wrp {
    display: flex;

    .review-call-component {
      width: 100%;
    }

    .portal-mentee-profile-wrp {
      height: calc(100vh - 157px);
      overflow-y: auto;
      overflow-x: hidden;
      flex-shrink: 0;
    }

    .issues-category {
      width: 320px;
      border-right: 1px solid #E9EFF5;
      padding: 12px 24px;
      height: calc(100vh - 157px);
      overflow: auto;
      flex-shrink: 0;
      overflow-x: hidden;

      * {
        user-select: none !important;
      }

      .w-right-gray-arrow {
        margin-bottom: 10px;
      }

      .each-item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 8px 1em;
        border-radius: 4px;
        cursor: pointer;

        i {
          font-size: 16px;
          margin-right: 10px;
          margin-top: 3px;
          color: #768091;
        }

        span {
          font-size: 16px;
          color: #576071;
          font-family: $font-regular;
        }

        &:hover {
          background-color: #F6F9FC;
        }

        .number-tag {
          background-color: #F6F9FC;
          padding: 2px 6px 4px 6px;
          border-radius: 12px;
          margin-left: 6px;
          line-height: 1 !important;
          color: #95A0B0;
          font-family: $font-medium;
          font-size: 13px;
        }

        &.selected {
          i {
            color: $secondary-blue;
          }

          .number-tag {
            background-color: #EBF6FF;
          }

          span {
            color: $secondary-blue;
          }
        }
      }
    }

    .mentees-list {
      width: 400px;
      border-right: 1px solid #E9EFF5;
      height: calc(100vh - 157px);
      // overflow: auto;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;

      .search-wrp {
        .search-input {
          input {
            padding: 14px 16px 14px 42px;
            border-radius: 8px;
          }
        }
      }

      .mentee-issues-cards-list {
        flex-grow: 1;
        overflow: auto;
        padding-top: 12px;

        .virtualized-list {
          height: 100% !important;
        }
      }
    }

    .mentee-detail {
      flex-grow: 1;
      height: calc(100vh - 157px);
      overflow-y: auto;

      .mentee-issue-detail {
        height: 100%;
      }

      .mentee-gray-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #F9FCFE;
        border: 1px solid #E0E8F0;
        border-radius: 8px;
        padding: 20px 24px;
        margin: 24px 24px 8px 24px;

        .mentee-info-wrp {}
      }
    }


    .searchbox-with-clear-btn {
      position: relative;

      .gray-text-btn {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%) scale(0.5);
        color: #95A0B0 !important;
      }
    }

    .Search-input {
      padding: 10px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        font-size: 10px;
        color: #768091;
        font-family: 'Mentor1to1';
        display: inline-block;
        font-size: inherit;
        font-weight: normal;
        font-style: normal;
        line-height: 1;
        text-rendering: auto;
        content: '\f0e2';
        border: 0 !important;

      }

      input {
        border: 1px solid #CCD6E2;
        border-radius: 4px;
        width: 100%;
        height: 32px;
        font-size: 14px;
        color: #374050;
        padding: 5px 24px 5px 34px;
        line-height: 16px;
      }
    }
  }
}

.w-right-gray-arrow {
  position: relative;
  padding-right: 15px;

  &:after {
    content: "\f10d";
    color: #A8B6C7;
    position: absolute;
    right: 0;
    top: 2px;
    font-family: 'Mentor1to1';
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    text-rendering: auto;
    pointer-events: none;
    padding-top: 3px;
  }

  &.toggled {
    &:after {
      transform: rotate(-180deg);
    }
  }
}


.btn.btn-primary.round-btn {
  border-radius: 20px;
  // width: 120px;
  height: 40px;
  font-size: 16px;
  font-family: $font-medium;
  color: #fff;
  background-color: $secondary-blue;
  border: 0 !important;
  transition: all ease 0.3s;

  &:hover {
    background-color: $primary-blue;
  }
}


// .all-boards-wrp {
//   .jit-main-content .kanban-issues-list-wrp {
//     .issues-category {
//       height: calc(100vh - 143px);
//     }

//     .mentees-list {
//       height: calc(100vh - 143px);
//     }

//     .mentee-detail {
//       height: calc(100vh - 143px);
//     }
//   }
// }



.kanban-issues-list-wrp.partner-profile-tab {

  .portal-mentee-profile-wrp {
    width: 400px;
    height: auto;
    /* overflow-y: auto; */
    /* overflow-x: hidden; */

    .Profile-Container {
      height: 100%;

      .left-sidebar {
        width: 100%;
        height: 100%;

        .snapshot-comp-wrp {
          width: 100%;
        }
      }
    }
  }

  .w-right-sidebar {
    min-height: calc(100vh - 157px);
    width: calc(100% - 400px);
  }
}

.ccp-filter {
  overflow: hidden;
  flex-shrink: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 6px;
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;

  .filter-tag {
    padding: 4px 8px;
    font-size: 14px;
    color: #95A0B0;
    background-color: transparent;
    ;
    border: 1px solid #E0E8F0;
    border-radius: 8px;
    margin-right: 6px;
    margin-bottom: 6px;

    &.selected {
      background-color: $secondary-blue;
      border-color: $secondary-blue;
      color: #fff;
    }
  }

  &:hover {
    overflow: auto;
  }
}

.step-threads.is-ccp {
  position: relative;

  .ccp-scroll-wrp {
    // max-height: calc( 100vh - 280px );
    overflow: auto;
    margin-right: -24px;
    padding-right: 24px;

    .copilot-note {
      &.is-from-other {
        background-color: #fff;
        border: 1px solid #E0E8F0;
        border-radius: 8px;
        border-bottom-left-radius: 0;
        width: 80%;
        margin-left: 30px;
        margin-right: auto;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 0;
          height: 0;
          border: 20px solid transparent;
          border-right-color: #E0E8F0;
          border-left: 0;
          border-bottom: 0;
          margin-top: -10px;
          margin-left: -20px;
        }

        &:after {
          content: '';
          position: absolute;
          left: 2px;
          bottom: 0px;
          width: 0;
          height: 0;
          border: 20px solid transparent;
          border-right-color: #fff;
          border-left: 0;
          border-bottom: 0;
          margin-top: -10px;
          margin-left: -20px;
        }
      }

      &.from-current-user {
        background-color: #F6F9FC;
        border: 1px solid #E0E8F0;
        padding: 16px;
        border-radius: 8px;
        width: 80%;
        margin-left: auto;
        margin-right: 30px;
        position: relative;
        border-bottom-right-radius: 0;

        &:before {
          content: '';
          position: absolute;
          right: 0;
          bottom: -1px;
          width: 0;
          height: 0;
          border: 20px solid transparent;
          border-left-color: #E0E8F0;
          border-right: 0;
          border-bottom: 0;
          margin-top: -10px;
          margin-right: -20px;
        }

        &:after {
          content: '';
          position: absolute;
          right: 2px;
          bottom: 0px;
          width: 0;
          height: 0;
          border: 20px solid transparent;
          border-left-color: #F6F9FC;
          border-right: 0;
          border-bottom: 0;
          margin-top: -10px;
          margin-right: -20px;
        }
      }
    }
  }

  .bottom-fixed-wrp {
    border-top: 1px solid #E0E8F0;
    margin-left: -24px;
    margin-right: -24px;
    padding: 24px;

    .ta-wrp-w-btn {
      margin-bottom: 0 !important;
    }
  }
}

.sidebar-compo .sidebar-header .nav.nav-tabs.ccp-updated.dashboard-nav {
  width: 100%;

  @media(max-width: 1680px) {
    .nav-item a {
      font-size: 18px !important;
    }
  }

  @media(max-width: 1440px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.ccp-updated.custom-styled-tabs.nav.nav-tabs {
  .nav-item {
    margin-right: 23px;
    font-size: 21px;
    font-family: $font-regular;
    color: #95A0B0;

    span {
      font-size: 21px;
      font-family: $font-regular;
      color: #95A0B0;
    }

    &.active {
      color: #374050;

      span {
        color: #374050;
      }

      .close-btn {
        border-color: #A8B6C7 !important;

        i {
          color: #A8B6C7 !important;
        }
      }
    }
  }

  &.is-small-gap {
    .nav-item {
      margin-right: 15px;
      font-size: 18px;
      font-family: $font-regular;
      color: #95A0B0;

      span {
        font-size: 18px;
        font-family: $font-regular;
        color: #95A0B0;
      }

      &.active {
        color: #374050;

        span {
          color: #374050;
        }

        .close-btn {
          border-color: #A8B6C7 !important;

          i {
            color: #A8B6C7 !important;
          }
        }
      }
    }
  }

  &.is-small-size {
    .nav-item {
      font-size: 16px;

      span {
        font-size: 16px;
      }
    }
  }

  &.is-medium-size {
    .nav-item {
      font-size: 19px;

      span {
        font-size: 19px;
      }
    }

    .nav-item {
      margin-right: 14px;
    }

    @media(max-width: 1599px) {
      .nav-item {
        font-size: 14px;

        span {
          font-size: 14px;
        }
      }
    }
  }

  &.remove-side-paddings {
    padding-left: 0;
    padding-right: 0;
  }

  &.is-multiline {
    flex-wrap: wrap;
  }
}

.issues-details-tabs.custom-styled-tabs {
  .nav-item {
    span {
      font-family: $font-regular;
      color: #95A0B0;
    }

    &.active {
      span {
        color: #374050;
      }
    }
  }
}

.select-filters-compo {
  .MuiPaper-root {
    box-shadow: 0px 4px 24px rgba(55, 64, 80, 0.36) !important;
    border-radius: 8px;
    width: 300px;

    >div {
      display: flex;
      flex-direction: column;
      background-color: #fff;

      .MuiList-root {
        max-height: 300px;
        overflow-y: auto;
        flex-grow: 1;
        width: 100%;
        margin: 6px;
      }

      .fixed-bottom-btn-wrp {
        padding: 6px;
        width: 100%;
        border-top: 1px solid #ebebee;
      }

    }
  }
}

body .assigned-to-modal .MuiPaper-root {
  width: 480px;
  box-shadow: 0px 4px 16px rgba(55, 64, 80, 0.16);
  border-radius: 16px;

  .MuiDialogContent-root {
    min-width: auto !important;
    width: 100%;
    padding: 20px 24px;
    border-radius: 16px;

    .MuiTabs-root.is-selected-gray {
      .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root {
        color: #768091;
        font-family: $font-medium;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        &.Mui-selected {
          color: #17202E;
        }
      }
    }
  }
}

.filter-checkbox-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 24px;

  .MuiFormControlLabel-root {
    position: relative;
    margin: 0;

    .MuiFormControlLabel-label {
      padding: 4px 12px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #576071;
      border: 1px solid #CCD6E2;
      border-radius: 18px;
      transition: all ease 0.3s;
    }

    .button-hidden-select {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;
    }

    .MuiButtonBase-root.MuiCheckbox-root {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;

      &.Mui-checked {
        &+.MuiFormControlLabel-label {
          border-color: $dark-blue;
          background-color: $dark-blue;
          color: #fff;
        }
      }
    }

  }
}
