
.cms-wrapper {
  .list-group-item {
    .custom-react-select .custom__control {
      padding: 2px 16px;
      border-radius: 0;
      height: 32px !important;
      min-height: unset;
    }

    .input-group-append, .input-group-prepend {
      > span {
        height: 32px !important;
      }
      > button {
        border-radius: 0;
        border-color: #DAE2EB !important;
        height: 32px !important;
        &:last-child {
          border-top-right-radius: 32px;
          border-bottom-right-radius: 32px;
        }
      }
    }
  }
  .dndflow .dndnode {
    border-radius: 4px;
    font-size: 14px;
    padding: 2px 4px;
    height: unset;
  }
  .react-flow__node-input {
    border-color: #DAE2EB !important;
  }
  .react-flow__node {
    border: none !important;
  }
}


