@include cosmetic(margin, (s: 10px,sm: 15px, m: 20px, l: 30px, xl: 40px, none: 0));
@include cosmetic(margin-top, (s: 10px,sm: 15px, m: 20px, l: 30px, xl: 40px, none: 0));
@include cosmetic(margin-bottom, (s: 10px,sm: 15px, m: 20px, l: 30px, xl: 40px, none: 0));
@include cosmetic(margin-right, (s: 10px,sm: 15px, m: 20px, l: 30px, xl: 40px, none: 0));
@include cosmetic(margin-left, (s: 10px,sm: 15px, m: 20px, l: 30px, xl: 40px, none: 0));

// padding
@include cosmetic(padding, (s: 10px,sm: 15px, m: 20px, l: 30px, xl: 40px, none: 0));
@include cosmetic(padding-top, (s: 10px,sm: 15px, m: 20px, l: 30px, xl: 40px, none: 0));
@include cosmetic(padding-bottom, (s: 10px,sm: 15px, m: 20px, l: 30px, xl: 40px, none: 0));
@include cosmetic(padding-right, (s: 10px,sm: 15px, m: 20px, l: 30px, xl: 40px, none: 0));
@include cosmetic(padding-left, (s: 10px,sm: 15px, m: 20px, l: 30px, xl: 40px, none: 0));

.padding-tiny{
    padding: 5px;
}