.custom-placeholder-input {
  position: relative;
  box-sizing: content-box;  
  width: 100%;
  > input, > textarea{
    font-size: 14px;
    font-family: $font-regular;
    color: #000; 
    background-color: #F7F7F7;
    // box-shadow: 0 2px 4px 0  rgba(0,0,0,0.5); 
    border: 1px solid #EAEAEA; 
    border-radius: 3px;
    padding: 6px 11px; 
    height: auto;
    box-shadow: none; 
  }
  > textarea {
    padding-bottom: 20px;
    box-shadow: none;  
  }
  .placeholder {
    font-size: 14px;
    font-family: $font-regular;
    color: #7f7f7f; 
    transition: all ease 0.3s; 
    position: absolute;
    transform: translateY(-50%); 
    background-color: transparent;
    padding-left: 3px;
    top: 53%;
    pointer-events: none; 
    &.empty-val {
      left: 11px;
      opacity: 1;
    }
    &.has-val {
      right: 11px; 
      left: auto; 
      opacity: 0.4;
    }
  }
  &.single-select, &.multi-select {
    .Select-control {
      height: 42px;
      border-radius: 3px;
      border: 1px solid #EAEAEA; 
      background-color: #F7F7F7;
      .Select-placeholder {
        font-size: 14px;
        font-family: $font-regular;
        color: #7f7f7f; 
        padding: 3px 12px;
      }
      .Select-input {
        font-size: 14px;
        font-family: $font-regular;
        min-height: 40px;
        padding-top: 3px;
      }
      .Select-value { 
        color: #000; 
        font-size: 14px;
        font-family: $font-regular;
        padding: 11px;
        line-height: 18px !important;
        .Select-value-label {
          
        }
      }
    }
    .Select-menu-outer {
      width: 100%;
      * {
        font-size: 15px;
        font-family: $font-regular;
        color: #000; 
      }
    }
    .placeholder.empty-val {
      opacity: 0;
    }
    .placeholder.has-val { 
      opacity: 0.4;
      right: 25px; 
    }
    span.Select-aria-only {
      padding: 5px 11px 16px 11px; 
      font-size: 15px;
      font-family: $font-regular;
      color: #7f7f7f; 
    }
  }
  &.multi-select {
    .Select-control {
      height: auto;
      min-height: 42px; 
      padding-bottom: 0;
      .Select-value { 
        padding: 3px;
        line-height: 1 !important;
      }
    }
    .placeholder.has-val { 
      right: 25px;
      bottom: 0;
      top: auto;
      opacity: 0.4; 
    }
    .placeholder.empty-val {
      opacity: 0;
    }
  }
  &.with-textarea {
    .placeholder.empty-val {
      left: 11px;
      opacity: 1;
      top: 22px;
    }
    .placeholder.has-val {
      right: 20px; 
      left: auto; 
      opacity: 0.4;
      bottom: 0; 
      top: auto; 
    }
  }
}
.mentee-information-wrp {
  .mentee-form {

    .filters-wrp {
      background-color: #fff;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    h3.form-title {
      font-size: 15px;
      font-family: $font-bold;
      color: #000; 
      margin-bottom: 15px;
      margin-top: 20px;
    }
    .form-column-1 {
      border-right: 1px solid #ebebeb;
    }
    .form-column-1, .form-column-2 {
      padding-left: 40px;
      padding-right: 40px;
      .row {
        margin-left: -7.5px;
        margin-right: -7.5px;
      }
      .col-6, .col-4 {
        padding-left: 7.5px;
        padding-right: 7.5px;
      }
    }
    .mobile-types {
      margin-bottom: 0;  
    }
    
    /*
    .mobile-settings-wrp {
      margin-top: 15px;
      background-color: #F7F7F7;
      border: 1px solid #ebebeb;
      margin-bottom: 15px;
      border-radius: 3px; 
      .switch {
        border: none;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
        background-color: #fff;
        .switch-toggle {
          border: 1px solid #fff;
          height: 25px;
        }
      }
      .switch.on {
        background-color: #4CD964;
        .switch-toggle {
          border: 1px solid #fff;
        }
      }
      .form-group {
        padding: 12px 9px; 
        display: flex; 
        margin-bottom: 0;
        justify-content: space-between;
        .interested-app {
          display: flex;
          align-items: center;
          span {
            margin-left: 9px;
          }
        }
        .will-get-sms {
          display: flex;
          align-items: center;
          span {
            margin-left: 9px;
          }
        }
        // .mobile-types {
        //   display :flex; 
        //   align-items: center;
        //   justify-content: space-between;
        //   width: 320px;
        //   border-radius: 5px; 
        //   box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
        //   overflow: hidden;
        //   height: 25px;

        //   button {
        //     flex-grow: 1; 
        //     padding: 0;
        //     border: 0; 
        //     border-right: 1px solid #ebebeb;
        //     border-radius: 0; 
        //     padding-top: 5px;
        //     padding-bottom: 3px;
        //     &.selected-type {
        //       background-color: $primary-blue;
        //       color: #fff !important; 
        //       height: 100%;
        //     }
        //   }
        //   button:last-child {
        //     border-right: 0;
        //   }
        // }
        .custom-placeholder-input {
          width: 100%;
          input {
            background-color: #fff;
          }
        }
       
      }
    }
    */
  }
  .next-btn {
    bottom: 11px; 
    right: 30px; 
    border: none; 
    border-radius: 40px; 
    width: 94px; 
    text-align: center;
    padding-top: 12px;
    padding-bottom: 10px;
    background-color: #fff; 
    box-shadow: none !important; 
    background-color: $primary-blue; 
    cursor: pointer; 
    color: #fff; 
    transition: all ease 0.3s; 
    font-family: $font-bold;
    font-size: 15px;
    margin-bottom: 40px;
    &:hover {
        opacity: 0.8;
    }
  }
}