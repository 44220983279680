

.react-datepicker__tether-element-attached-top .react-datepicker__triangle,
.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle,
.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__tether-element-attached-top .react-datepicker__triangle::before,
.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle::before,
.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle,
.react-datepicker__tether-element-attached-top .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 4px;
  display: inline-block;
  position: relative;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker__tether-element-attached-bottom.react-datepicker__tether-element {
  margin-top: -20px;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__current-month {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 15px;
  padding: 10px !important;
  &.react-datepicker__current-month--hasYearDropdown {
    margin-bottom: 4px !important;
    padding-bottom: 0 !important;
  }
}
.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
  margin-bottom: 16px;
}

.react-datepicker__current-month--hasYearDropdown {
  margin-bottom: 16px;
}

.react-datepicker__navigation {
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  border: 6px solid transparent;
  text-indent: -9999px;
  background-color: transparent;;
  padding: 0;
  &.react-datepicker__navigation--next {
    width: 8px !important;
    height: 8px !important;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #95A0B0;
    right: 8px;
  }
  &.react-datepicker__navigation--previous {
    width: 8px !important;
    height: 8px !important;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #95A0B0;
    left: 8px;
  }
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
  z-index: 10;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
  z-index: 10;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month {
  margin: 5px;
  text-align: center;
}

.react-datepicker__day-name,
.react-datepicker__day {
  color: #000;
  display: inline-block;
  width: 24px;
  line-height: 24px;
  text-align: center;
  margin: 2px;
}

.react-datepicker__day {
  cursor: pointer;
}

.react-datepicker__day:hover {
  border-radius: 4px;
  background-color: #f0f0f0;
}

.react-datepicker__day--today {
  font-weight: bold;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range {
  border-radius: 4px;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover {
  background-color: transparent;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

.react-datepicker__year-read-view {
  width: 50%;
  left: 25%;
  position: absolute;
  bottom: 25px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.react-datepicker__year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow {
  border-top-color: #ccc;
  margin-bottom: 3px;
  left: 5px;
  top: 9px;
  position: relative;
  border-width: 6px;
}

.react-datepicker__year-read-view--selected-year {
  right: 6px;
  position: relative;
}

.react-datepicker__year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.react-datepicker__year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.react-datepicker__year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected {
  position: absolute;
  left: 30px;
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 0;
  outline: 0;
  padding: 0;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  color: #95A0B0 !important;
  background-color: transparent !important;
  border-radius: 50% !important;
  bottom: 0 !important;
  box-sizing: border-box !important;
  content: "\00d7" !important;
  cursor: pointer !important;
  font-size: 24px !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 1 !important;
  margin: 0 auto 0 !important;
  padding: 2px !important;
  position: absolute !important;
  right: 7px !important;
  text-align: center !important;
  top: 50% !important;
  transform: translateY(-50%);
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
}

.react-datepicker__tether-element {
  z-index: 2147483647;
}


.react-datepicker {
  font-family: $font-regular;
  display: flex;
  margin-left: auto;
  margin-right: auto;

  .react-datepicker__current-month {
    font-weight: normal;
  }

  .react-datepicker__time-container {
    .react-datepicker__header--time {
      display: flex;
      align-items: center;
      justify-content: center;

      .react-datepicker-time__header {
        font-size: 15px;
        font-family: $font-regular;
        font-weight: normal;
      }
    }

    .react-datepicker__time {
      .react-datepicker__time-box {
        width: 100%;
      }
    }

  }



  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: $secondary-blue;
    color: #fff;
    margin: 3px;
    border-radius: 4px;
    line-height: 1;
    height: auto;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 115px !important;
  }

  .react-datepicker__day--selected {
    background-color: $secondary-blue;
    color: #fff;
    border-color: #00CBF6;
  }
}

.react-datepicker-popper {
  margin-top: 4px;

  .react-datepicker.react-datepicker--time-only {
    border: 1px solid #DAE2EB;
    border-radius: 4px;
    background-color: #fff;

    .react-datepicker__time-container {
      width: 100px;
    }
  }

  &[data-placement^="top"] {
    margin-bottom: 4px;
  }
}

.react-datepicker__triangle {
  display: none;
}


.react-datepicker__navigation {
  top: 18px;
}

.react-datepicker-popper {
  margin-top: 4px;

  &[data-placement^="top"] {
    margin-bottom: 4px;
  }

  .react-datepicker {
    border: 1px solid #DAE2EB;
    border-radius: 4px;
    background-color: #fff;

    .react-datepicker__header {
      background-color: #fff;
      border-bottom: 0;

      .react-datepicker__day-name {
        line-height: 1;
        font-size: 13px;
        color: #768091;
        font-family: $font-regular;
      }

      .react-datepicker__current-month {
        padding-top: 5px;
        padding-bottom: 12px;
        font-size: 16px;
        color: #374050;
        font-family: $font-regular;
      }
    }

    .react-datepicker__day {
      line-height: 27px;
    }
  }
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker-wrapper {
  cursor: pointer;
  width: 100%;
}

.react-datepicker {
  width: 100%;
  background-color: #fafafa;
  border-radius: 0;
  border: none;

  .react-datepicker__navigation {
    top: 22px;
  }

  .react-datepicker__month-container {
    .react-datepicker__header {
      background-color: transparent;
      border-bottom: none;

      .react-datepicker__current-month {
        font-size: 18px;
        font-family: $font-bold;
        line-height: 23px;
        padding-top: 12px;
        padding-bottom: 15px;
      }

      .react-datepicker__day-names {
        .react-datepicker__day-name {
          font-size: 14px;
          font-family: $font-bold;
          color: rgba(0, 0, 0, 0.54);
        }
      }

    }

    .react-datepicker__day {
      font-family: $font-bold;
      font-size: 14px;
      &.react-datepicker__day--selected {
        background-color: #0094F3;
        color: #fff;
      }

      &.react-datepicker__day--outside-month {
        opacity: 0.7;
      }
    }
  }
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: $secondary-blue !important;
  color: #fff !important;
}

.popover {
  .react-datepicker {
    background-color: #fff;
  }
}

.remove-btn-pd {
  .btn.btn-link {
    padding: 0;
    border: none !important;
  }
}

// .react-datepicker-wrapper .react-datepicker__close-icon::after {
//   content: none;
// }
//
// .react-datepicker__input-container {
//   position: relative;

//   &:after {
//     content: "\f10d";
//     color: #95A0B0;
//     position: absolute;
//     right: 10px;
//     top: 50%;
//     transform: translateY(-50%);
//     font-family: 'Mentor1to1';
//     display: inline-block;
//     font-size: 12px;
//     font-weight: normal;
//     font-style: normal;
//     line-height: 1;
//     text-rendering: auto;
//     pointer-events: none;
//   }

// }


.datepicker-w-icon-wrp {
  position: relative;
  .datepicker-start-icon {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 1;
  }
  .icon-thick-down-caret {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 1;
    color: #576071;
    padding-top: 1px;
  }
  .react-datepicker-wrapper .react-datepicker__input-container input {
    padding-left: 32px;
    padding-right: 24px;
  }
  .react-datepicker__close-icon::after {
    right: 24px !important;
  }
  &.is-border-radius8 {
    .react-datepicker-wrapper .react-datepicker__input-container input {
      border-radius: 8px;
    }
  }
}