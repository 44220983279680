.alarm-edit-modal.custom-styled {
  .modal-header {
    padding: 24px 24px 4px 24px;  
    border-bottom: 0;
  }
  .alarm-body {
    // padding: 24px; 
    .MuiOutlinedInput-root{
      padding: unset !important;
      .MuiOutlinedInput-input {
        padding: 6px 10px !important;
      }
    }
    .form-label {
      font-size: 16px;
      color: #374050;
    }
    input.form-control {
      border: 1px solid #E0E8F0; 
      border-radius: 4px; 
      padding: 8px 12px; 
    }
    .custom-react-select .custom__control {
      border: 1px solid #E0E8F0; 
      border-radius: 4px; 
      padding-left: 12px;
      padding-right: 12px;
      min-height: 44px; 
    }
    .action-box {
      position: relative;
      .btn-action-close {
        position: absolute;
        right: 16px;
        top: 16px;
        z-index: 1; 
        i {
          transform: scale(0.8);
        }
      }
      .custom-react-select {
        .custom__control {
          height: 48px; 
          border: 1px solid #E0E8F0;
          border-radius: 4px; 
        }
      }
      input.form-control {
        height: 48px !important; 
        width: 70px !important;
        border: 1px solid #E0E8F0;
        border-radius: 4px; 
      }
    }
    .alarm-footer-btns-wrp {
      margin-left: -24px;
      margin-right: -24px;
      border-top: 1px solid #E0E8F0;
      padding-top: 24px;
      padding-left: 24px;
      padding-right: 24px;
    }
    .btn-action-condition-close {
      border: 1px solid #CCD6E2;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%; 
      margin-top: 28px;
      i {
        font-size: 10px;
        transform: scale(0.7);
        color: #CCD6E2;
      }
    }
  }
}