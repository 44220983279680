.outreach-profile-sidebar {
  padding-right: 0px;
  padding-left: 0;
  padding-right: 15px;
  .round-pane {
    background-color: #fff;
  }
    // border-radius: 5px;
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    // margin-bottom: 20px;
    // overflow: hidden;

  &.col-lg-1 {
    flex: 0 0 70px !important;
    max-width: 70px !important;  
  }
  .notes-header  {
  	margin-left: 0px;
  }
}
