.mentor-issues {
  width: 100%;
}

.portal-mentee-profile-wrp {
  .snapshot-comp-wrp {
    .pt-6.pb-6.border-top {
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

.review-call-component {
  .mentee-tags-wrp {
    .round-tag {
      background: #f1f5f9;
      border-radius: 4px;

      b {
        margin-right: 6px;
      }
    }
  }
  br {
    width: 100%;
    display: block;
  }

  .attributes-wrp {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .each-attribute {
      font-size: 16px;
      font-family: $font-regular;
      color: #374050;
      margin-bottom: 16px;
      width: auto;
      padding-right: 40px;
      &.w100p {
        width: 100%;
      }
      .attribute-name {
        font-size: 16px;
        font-family: $font-medium;
        color: #374050;
        display: block;
        width: 100%;
      }
    }
  }

  .content-w-left-bar {
    .middle-content {
      .sidebar-header {
        padding: 0;
        margin-left: 0;
        margin-right: 0;
        border-bottom: 0 !important;

        .nav.nav-tabs {
          margin-left: -24px;
          margin-right: -24px;
          padding-left: 24px;
          padding-right: 24px;
          border-bottom: 1px solid #e9eff5;
          width: auto;
          &.ccp-updated {
            flex-wrap: wrap;
            .nav-item {
              padding-left: 0;
              padding-right: 0;
              margin-right: 28px;
              margin-top: 4px;
              a {
                color: #95a0b0;
                font-family: $font-regular;
                padding-left: 0;
                padding-right: 0;
                font-size: 21px;
                border-bottom: 2px solid transparent;
              }
              &.current {
                a {
                  color: #374050;
                  border-bottom: 2px solid #0094f3;
                }
              }
            }
          }
        }
      }
      .custom-styled-tabs.ccp-updated {
        margin-left: -24px;
        margin-right: -24px;
        padding-left: 24px;
        padding-right: 24px;
        flex-wrap: wrap;
        a {
          margin-top: 4px;
          span {
            font-size: 21px !important;
          }
        }
      }
    }

    .call-updates {
      margin-bottom: 24px;

      .gray-box {
        padding: 0;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 16px;
        flex-wrap: wrap;

        .metric {
          width: 20%;
          padding: 24px;
        }
      }
    }
  }
}

.workbook-summary {
  .gray-box {
    padding: 16px 24px;
  }
}

.content-w-left-bar {
  &.w-right-sidebar {
    > .row {
      width: 100%;
    }

    .middle-content {
      &.col-lg-6 {
        flex: 0 0 calc(75% - 400px);
        max-width: calc(75% - 400px);
      }

      &.col-lg-8 {
        flex: 0 0 calc(75% - 70px);
        max-width: calc(75% - 70px);
      }
    }

    .sidebar-wrp {
      border-left: 1px solid #e9eff5;
      padding: 20px 24px;

      &.col-lg-3 {
        flex: 0 0 400px;
        max-width: 400px;
      }

      &.col-lg-1 {
        flex: 0 0 70px;
        max-width: 70px;
      }
    }
  }
}

.right-sidebar {
  &.collapsed {
    .toggle-menu {
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
    }
  }

  .custom-styled-tabs {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }

  .custom-styled-tabs + .tab-content {
    margin-left: -24px !important;
    margin-right: -24px !important;
  }

  .comments-wrp {
    height: 100%;

    > .comments-compo-wrp {
      height: 100%;
      display: flex;
      flex-direction: column;

      .comments-list {
        flex-grow: 1;
        overflow-y: auto;
      }
    }
  }

  .materials-sent-list {
    margin-left: -24px;
    margin-right: -24px;

    .p-6.fz-14.fc-charcoal-500 {
      padding-top: 8px !important;
    }
  }
}

