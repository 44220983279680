
.gray-bd-box {
  border: 1px solid #CCD6E2;
  border-radius: 8px;
  padding: 14px 16px;
  &.is-light-border {
    border: 1px solid #E9EFF5;
  }
  &.is-red {
    border-color: #fdeced;
  }
  &.is-blue {
    border-color: $primary-blue;
  }
  &.is-round {
    border: 1px solid #e0e8f0;
    border-radius: 8px;
  }

  &.is-border-medium {
    border: 1px solid #e0e8f0;
  }

  &.is-border-dark {
    border: 1px solid #a8b6c7;
  }
  &.is-padding16 {
    padding: 16px;
  }
  &.is-padding8-14 {
    padding: 8px 14px;
  }
  &.is-padding8-12 {
    padding: 8px 12px;
  }
  &.is-padding16-14 {
    padding: 16px 14px;
  }

  &.is-padding14 {
    padding: 14px;
  }

  &.is-padding12 {
    padding: 12px;
  }
  &.is-padding10-20 {
    padding: 10px 20px;
  }
  &.is-padding14-24 {
    padding: 14px 24px;
  }
  &.is-padding16-20 {
    padding: 16px 20px;
  }

  &.is-padding14-20 {
    padding: 14px 20px;
  }
  &.is-padding11-20 {
    padding: 11px 20px;
  }
  &.is-padding12-16 {
    padding: 12px 16px;
  }
  &.is-padding16-32 {
    padding: 16px 32px;
  }
  &.is-padding8 {
    padding: 8px;
  }
  &.is-border-radius8 {
    border-radius: 8px;
  }
  &.is-border-radius12 {
    border-radius: 12px !important;
  }
  &.is-border-radius16 {
    border-radius: 16px;
  }
  &.is-selected {
    background-color: #f9fcfe;
  }
  &.inline {
    display: inline-block;
    width: 360px;
  }
  &.is-inline {
    display: inline-block;
  }
  &.is-border-light {
    border: 1px solid #e9eff5;
  }
  &.is-border-dark {
    border: 1px solid #a8b6c7;
  }
  &.is-blue-border {
    border-color: #0094f3;
  }
  &.is-width200 {
    width: 200px;
  }
  &.is-selected-blue {
    background-color: $secondary-blue !important;
    border-color: $secondary-blue !important;
    * {
      color: #fff !important;
    }
    .ff-reg {
      font-family: $font-medium !important;
    }
    .loading-gradient {
      color: transparent !important;
    }
  }
  &.is-white {
    background-color: #fff;
  }
  .row {
    margin-left: -8px;
    margin-right: -8px;
    > div {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  &.is-orange-border {
    border: 1px solid #ff9a2d;
  }
  &.is-green-box {
    border-color: #9BCE63;
    background-color: rgba(155, 206, 99, 0.06);
  }

  &.is-orange-box {
    border-color: #FFCC6C;
    background-color: rgba(255, 204, 108, 0.1);
  }
  &.is-cosmic-box {
    border-color: #85485F;
    background-color: rgba(165, 115, 124, 0.1);
  }
  &.is-gray-box {
    border-color: #E0E8F0;
    background-color: #F9FCFE;
  }
  &.is-dark {
    border: 1px solid #CCD6E2;
  }
  &.is-button {
    border-radius: 16px;
    padding: 4px 10px;
    cursor: pointer;
    user-select: none;
    &:hover, &.is-selected {
      background-color: $grey-500;
    }
  }
  &.is-note-to-peer {
    background: #EBF4FF;
    border-radius: 8px;
    border: 0;
    padding: 16px !important;
  }

  &.is-sticky-note {
    background: #EBF4FF;
    position: relative;
    border: 0;
    overflow: visible;

    &::after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 8px 8px 0 8px;
      border-color: #EBF4FF transparent transparent transparent;
      bottom: -8px;
      left: 12px;
    }
  }

  .number-span {
    min-width: 26px;
  }
}

.white-box {
  border-radius: 8px;
  background-color: #fff;
  padding: 24px;
  &.is-padding16 {
    padding: 16px;
  }
  &.is-border-radius16 {
    border-radius: 16px;
  }
  &.h-600px {
    height: 600px;
    overflow-y: auto;
  }
}

.selectable-round-box {
  border-radius: 8px;
  padding: 16px;
  &.is-overflown {
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  &.is-selected {
    background-color: #f6f9fc;
  }
}



.is-padding16 {
  padding: 16px;
}

.is-padding12 {
  padding: 12px;
}

.is-padding14-24 {
  padding: 14px 24px;
}

.is-padding14-20 {
  padding: 14px 20px;
}

.is-padding12-16 {
  padding: 12px 16px;
}

.is-border-radius8 {
  border-radius: 8px;
}

.white-bg-box {
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(55, 64, 80, 0.1);
  border-radius: 5px;
  padding: 10px 20px;
  &.is-border-radius16 {
    border-radius: 16px;
  }
}


.gray-wrp {
  padding: 12px;
  margin-left: -12px;
  margin-right: -12px;
  background-color: $gray-bg1;
  border-radius: 12px;
}
.pink-icon-box {
  width: 40px;
  height: 40px;
  background: #FFF7EB;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #E08700;
}

.light-blue-bg-box {
  padding: 12px 16px 8px 16px;
  border-radius: 8px;
  background-color: #EBF4FF;
  div:empty {
    display: none;
  }
}

.note-box {
  padding-left: 16px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    border-radius: 4px;
    background-color:#CCD6E2;
  }
}