.custom-input-compo {
  >.row {
    margin-left: 0;
    margin-right: 0;

    >.col-sm-12 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .custom-note-close-btn {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    color: #768091;
    padding: 5px;
    line-height: 1;
    outline: 0 !important;
    box-shadow: none !important;
    cursor: pointer;
    i {
      font-size: 12px;
    }
  }

  .custom-note-editor {
    background-color: #fff;
    position: relative;

    .draftJsToolbar__toolbar__dNtBH {
      background-color: transparent;
      border: 1px solid #E9EFF5;
      border-radius: 4px 4px 0 0;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: #F6F9FB;

      >div {
        background-color: #f6f6f6;
        transition: all ease 0.3s;

        >button {
          background-color: transparent;
          padding: 0;
          border-radius: 3px;
        }

        &:hover {
          >button {
            background-color: #ededed;
          }
        }
      }
    }

    .main-editor {
      border: 1px solid #E9EFF5;
      border-radius: 8px;

      .DraftEditor-editorContainer {
        padding: 6px 10px;
      }

      &.focused {
        margin-top: 0;
        border-radius: 0 0 4px 4px;
        min-height: 200px;
        overflow: auto;
        background-color: #fff;
      }
    }
  }

  .public-DraftEditorPlaceholder-inner {
    padding: 6px 10px;
    font-size: 16px;
    font-family: $font-regular;
    color: #768091;
  }

  .DraftEditor-editorContainer {
    padding-top: 0;
  }

  .custom-note-bottom {
    position: relative;
    margin-top: 10px;

    .editor-wrp {
      .notifyHolder {
        border: 1px solid #E9EFF5;
        border-radius: 4px;
        height: auto;

        .DraftEditor-editorContainer {
          padding: 6px 10px !important;
          font-size: 14px;
        }
      }

      .btns-wrp {
        .btn {
          font-size: 16px;
          font-family: $font-medium;
          color: #fff;
          padding: 4px 8px;
          margin-right: 10px;
          border-radius: 4px;
          line-height: 1 !important;
          height: 32px;
          min-width: auto;
        }

        .btn.btn-danger {
          border: 0;
          background-color: transparent;
          color: #576071;
          padding-left: 10px;
          padding-right: 10px;
          font-family: $font-regular;
          outline: 0 !important;
          box-shadow: none !important;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .tag-suggestions {
      position: absolute;
      top: calc( 100% - 64px) !important;
      >div {
        margin: 0 !important;
        left: 0 !important;
        top: 0 !important;
        transform: none !important;
        transform-origin: none;

        >div {
          padding: 0 !important;

          .mentionSuggestionsEntryText {
            padding-left: 15px;
            padding-right: 15px;
            width: auto !important;
            padding: 0 10px !important;
            word-wrap: break-all;
          }
        }
      }
    }
  }


  .mentionSuggestionsEntryContainer {
    .mentionSuggestionsEntryText {
      padding-left: 21px;
      padding-right: 21px;
      font-size: 15px;
      font-family: $font-regular;
      text-align: left;
      color: #4A4A4A;
      height: 30px;
      display: flex;
      align-items: center;
    }
  }

  .slash-command-container {
    padding-bottom: 10px;
    .slash-command-name {
      padding-left: 21px;
      padding-right: 21px;
      font-size: 15px;
      font-family: $font-medium;
      text-align: left;
      color: #4A4A4A;
      height: 30px;
      display: flex;
      align-items: center;
    }
    .slash-command-description {
      padding-left: 21px;
      padding-right: 21px;
      font-size: 15px;
      font-family: $font-regular;
      text-align: left;
      color: #4A4A4A;
      height: 30px;
      display: flex;
      align-items: center;
      line-height: 15px;
    }
  }
}



.custom-note-editor, .each-comment {
  ol {
    list-style: decimal;
    margin-left: 20px;
    list-style-position: initial;
  }

  ul {
    list-style: disc;
    margin-left: 20px;
  }
}