.left-nav.opened {
  .content {
    height: 100vh;

    .board-content {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.board-content {
  .notification-header {
    height: 55px;
    overflow: hidden;
    display: flex;
    background-color: #fff;

    .icon-wrp {
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #606060;
      cursor: pointer;
      height: 55px !important;

      i {
        font-size: 20px;
        color: #fff;
        opacity: 0.2;
      }

      &.all,
      &:hover {
        background-color: #434343;

        i {
          color: #fff;
          opacity: 1;
        }
      }
    }

    .title-wrp {
      width: calc(100% - 70px);
      display: flex;
      align-items: center;
      padding-left: 18px;
      padding-right: 18px;
      font-size: 17px;
      border-bottom: 1px solid #ebebeb;
      height: 55px !important;

      &.high_priority {
        border-left: 2px solid #FF4E4E;
        color: #FF4E4E;
      }

      &.medium_priority {
        color: #FFB600;
        border-left: 2px solid #FFB600;
      }

      &.mentions {
        color: #00B6FF;
        border-left: 2px solid #00B6FF;
      }
    }
  }

  .tabs-for-notifications {
    display: flex;
    height: calc(100vh - 55px);

    &.current-tab-all {
      .tab-selector>a i {
        color: #fff !important;
      }
    }

    .tab-selector {
      width: 70px;
      background-color: #606060;
      height: 100%;

      >a {
        border-radius: 0;
        background-color: transparent;
        text-align: center;
        border: 0 !important;
        padding-top: 15px;
        padding-bottom: 15px;

        i {
          font-size: 20px;
          color: #fff;
          opacity: 0.2;
        }

        &:hover,
        &.active {
          background-color: rgba(0, 0, 0, 0.3);

          i.icon.icon-high-priority {
            color: #FF4E4E;
            opacity: 1;
          }

          i.icon.icon-low-priority {
            color: #FFB600;
            opacity: 1;
          }

          i.icon.icon-mention {
            color: #00B6FF;
            opacity: 1;
          }
        }
      }
    }

    .tab-content {
      width: calc(100% - 70px);
      height: 100%;
      overflow-y: auto;

      .card {
        border: none;
        border-radius: 0;

      }

      .card-body {
        padding: 0;

        .each-notitification {
          border-bottom: 1px solid #ebebeb;

          .noti-header {
            padding: 14px 19px 7px 19px;
            position: relative;

            >button {
              text-align: left;
              line-height: 1 !important;

              span {
                line-height: 1 !important;
              }
            }

            >div {
              >button {
                line-height: 1.1 !important;
                user-select: none;
              }
            }

            .toggle-btn {
              i {
                color: #000;
                font-size: 14px;
                transition: all ease 0.3s;
                padding: 0 14px;
              }

              &.expanded {
                i {
                  transform: rotate(-180deg);
                }
              }
            }
          }

          .noti-content {
            margin-left: 20px;
            margin-bottom: 8px;
            padding: 15px 16px 10px 16px;
            background-color: #f6f6f6;
            transition: all ease 0.3s;

            p {
              margin-bottom: 8px;
            }

            &.remove-with-fade {
              padding: 0;
              margin-bottom: 0;
            }
          }

          .dismiss-btn {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.notifications-dropdown {
  position: absolute;
  top: 51px;
  right: 5%;
  width: 600px;
  z-index: 2000;
  background-color: #FFFFFF;
  border: 1px solid #DAE2EB;
  box-shadow: 0px 2px 12px rgba(23, 32, 46, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  .notifications-tabs {
    flex: 1;
    .MuiTabs-root .MuiTabs-scroller {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .notifications-content {
    max-height: 70vh;
    overflow-y: auto;

    .tab-pane {
      overflow: auto;
      height: auto;
    }
  }


  .each-notification {
    border-top: 1px solid #DAE2EB;
    padding: 14px 24px;

    .notification-content {
      width: 100%;
      overflow-wrap: break-word;
      word-wrap: break-word;

      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;

      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }

    .notification-dismiss {
      display: none;
    }

    &:hover {
      background-color: #F6F9FC;

      .notification-dismiss {
        display: block;
      }

      .notification-time {
        display: none;
      }
    }

    .notification-title {
      flex: 1;
    }

    .notification-dot {
      height: 6px;
      width: 6px;
      background-color: #FF4E59;
      border-radius: 50%;
      margin-right: 0.75rem;
      flex: none;
      margin-top: 9px;
    }

    .notification-column {
      flex: 1;
    }
  }

  .tab-selector {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #DAE2EB;
    margin-left: 0;
    flex-wrap: nowrap;

    .nav-item {
      padding: 0 6px;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media(max-width: 1399px) {
        padding: 0 7px;
      }

      a {
        padding: 0;
        font-size: 18px;
        color: #768091;
        text-decoration: none !important;
        border: 0;
        padding-bottom: 3px;
      }

      .circle-count {
        color: #95A0B0;
        background-color: #F6F9FC;
        margin-top: -3px;
        margin-left: 6px;
      }

      .nav-link {
        font-weight: 400;

        &:hover {
          color: #768091;
        }
      }

      &.current {
        a {
          color: #0094f3;
          border-bottom: 1px solid #0094f3;
        }

        .circle-count {
          color: #0094F3;
          background-color: #EBF6FF;
        }
      }
    }

    >div:first-child {
      padding-left: 0;
    }
  }


  .view-all {
    border-top: 1px solid #DAE2EB;
  }

}

.notifications-click-overlay {
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: -100%;
  opacity: 0;
  background: transparent;
}

.opened {
  .notifications-click-overlay {
    opacity: 1;
    left: 0px;
  }
}

.notifications-modal {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;
  background-color: #FFFFFF;
  width: 840px;
  height: 100vh;
  min-height: 100vh;
  box-shadow: 0px 2px 6px rgba(23, 32, 46, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .notifications-modal-header {
    border-bottom: 1px solid #DAE2EB;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .notifications-body {
    flex-grow: 5;
    overflow: auto;
    /*padding-bottom: 50px;*/
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .notifications-section {
      flex-grow: 1;
      overflow-y: auto;
    }
  }



  .notifications-footer {
    background-color: #F6F9FC;
    padding: 20px;
    width: 100%;
    border-top: 1px solid #CCD6E2;
    flex-shrink: 0;
  }

  .section-header {
    border-bottom: 1px solid #DAE2EB;
    margin: 0 20px;
    padding: 20px 0 10px;
  }

  .each-notification {
    border-bottom: 1px solid #DAE2EB;
    margin: 0 20px;
    padding: 20px 20px 20px 20px;

    .notification-content {
      width: 100%;
      overflow-wrap: break-word;
      word-wrap: break-word;

      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word;

      -ms-hyphens: auto;
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
    }

    .notification-dismiss {
      display: none;
    }

    &:hover {
      background-color: #F6F9FC;

      .notification-dismiss {
        display: block;
        min-width: 150px;
        text-align: right;
      }

      .notification-time {
        display: none;
      }
    }

    .notification-title {
      width: 75%;
    }

    .notification-dot {
      height: 6px;
      width: 6px;
      background-color: #FF4E59;
      border-radius: 50%;
      margin-right: 0.75rem;
      flex: none;
      margin-top: 9px;
      margin-left: 5px;
    }

    .notification-column {
      flex: 1;
    }
  }
}

.tab-link {
  text-transform: none;
}

.tab-pane {
  /*padding: 10px 0 !important;*/

  .no-tab-padding {
    padding: -10px 0 !important;
  }

}