@import "new-variables";
@import "login";

$icon-font-path: "../../node_modules/bootstrap-sass/assets/fonts/bootstrap/";
/*@import "./fonts";*/
@import "./variables.scss";
// @import "../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss";


@import "./bootstrap.css";


@import "./mixins.scss";
@import "./spacing.scss";
@import "./notes.scss";
// @import "./react-select/default.scss";
@import "./common.scss";
@import "./icons.scss";
@import "./react-datepicker.scss";
@import "./react-datetime.scss";
@import "./behaviors.scss";
@import "./react-sortable.scss";
@import "./chart.scss";
@import "./wysihtml.scss";
@import "./notepad.scss";
@import "./tags.scss";
@import "./base";
@import "./engaged-support";
@import "./dashboard";
@import "./navs";

@import "./bootstrap.overrides.scss";

@import "../components/EngagedSupportMentee/ClinicalMeasures/clinical-measures-component.scss";
@import "../components/EngagedSupportMentee/ESCallSchedule/es-call-schedule-component.scss";
@import "../components/EngagedSupportMentee/SearchITS/search-its-component.scss";
@import "../components/EngagedSupportMentee/RecentCalls/recent-calls-component.scss";
@import "./engaged-support-issues.scss";
@import "../components/EngagedSupportMentee/Alerts/alerts-component.scss";
@import "../components/EngagedSupportMentee/MaterialsSent/materials-sent-component.scss";
@import "../components/EngagedSupportMentee/Sidebar/sidebar-component.scss";
@import "./survey-component.scss";
@import "../components/EngagedSupportMentee/EnrolledStatsFilterComponent/engaged-support-mentees-sidebar.scss";
@import "../components/Outreach/OutreachCallComponent/components/editorStyles.scss";
@import "../components/Outreach/OutreachCallComponent/outreach-call-component.scss";
@import "../components/Outreach/OutreachListComponent/outreach-list-component.scss";
@import "../components/EngagedSupportMentee/Snapshot/snapshot-component.scss";
@import "./embed-audio-component.scss";
@import "../components/Common/CustomInput/custom_input.scss";
@import "../components/Common/Switch/switch-component.scss";
@import "./quality-metrics.scss";
@import "../components/Mentee/RecentActivity/recent-activity-component.scss";
@import "../components/Mentee/Voicemails/voicemails-component.scss";
@import "../components/Mentor/MentorSummary/mentor-summary.scss";
@import "../components/Common/Sop/overview-sop-component.scss";
@import "../components/Mentor/PaymentLogComponent/mentor-payment-log.scss";
@import "../components/Mentor/AddCompensation/add-compensation.scss";
@import "../components/Common/Sop/mentor-sop.scss";
@import "./react-select.scss";
@import "./custom-react-tables";
@import "../elements/StepLine/stepline.scss";
@import "../elements/Checkbox/checkbox.scss";

@import "../containers/App/App.scss";
@import "../containers/Enroll/MenteeInfo/menteeinformation.scss";
@import "../containers/Enroll/MatchWithMentor/matchWithMentor.scss";
@import "../containers/Enroll/EnrollScheduleCall/EnrollScheduleCall.scss";
@import "../containers/Enroll/Enrolled/Enrolled.scss";
@import "../containers/Mentor/MentorDetail.scss";
@import "../containers/ESDashboard/esdashboard.scss";
@import "../containers/Mentors/MentorList.scss";

@import "../components/Enroll/MenteeInformationForm/menteeForm.scss";
@import "../components/Outreach/Outreach.scss";
@import "../components/EngagedSupportMentee/ListFilters/engaged-support-mentee-list-filters.scss";
@import "../containers/MenteeList/engaged-support-mentees.scss";
@import "../components/Outreach/ForTheMentorComponent/for-the-mentor.scss";


@import "../components/Mentee/PhaseManagementComponent/phase-management-component.scss";

@import "../components/Common/Sop/SopDetail.scss";
@import "../components/Mentor/MentoringProgressComponent/MentoringProgress.scss";
@import "../components/Dashboard/Notifications/notification-component.scss";
@import "../components/Enroll/EnrollSteps/enrollsteps.scss";
@import "../components/Enroll/PeerCard/PeerCardComponent.scss";
@import "../components/Enroll/MenteeAttributeHeader/menteeAttributeHeader.scss";
@import "../components/Mentor/ClinicalInfoManager/clinicalInfoManager.scss";
@import "../components/Mentor/Dashboard/mentor_dashboard.scss";
@import "../components/Mentee/Reschedule/reschedule.scss";
@import "../containers/MenteeProfile/issue-step-track-modal.scss";
@import "./command-center.scss";
// Care Coordination Platform Styles
@import "./ccp-mentee-list.scss";
@import "../components/CareCoordination/Common/Issues/issues-timeline-component.scss";
@import "../containers/CareCoordination/MenteeIssueDetail/mentee-issue-detail.scss";
@import "./callreview.scss";
@import "./es-mentee-profile-main.scss";
@import "../components/CareCoordination/CareCoordinationInboxComponent/careCoordinationInbox.scss";
@import "../components/CareCoordination/Navbar/ccp-navbar.scss";
@import "../components/CareCoordination/DietAndSuggestionsComponent/diet-and-suggestions-component.scss";
@import "../components/EngagedSupportMentee/Profile/profile-main-component.scss";
@import "../components/Mentor/PerformanceMetrics/performance-metrics-component.scss";
@import "../components/Common/StatusManager/status-manager-component.scss";
@import "../components/Mentor/CompetencyDomains/competency-domain-component.scss";
@import "../components/Outreach/Outreach.scss";
@import "../containers/MentorAccountSignup/mentor-account-signup.scss";
@import "../components/Dashboard/MentorPerformanceTab/mentor-performance-tab.scss";
@import "../containers/WaitlistManagement/waitlist-management.scss";
// Main Dashboard
@import "../containers/MainDashboard/main-dashboard.scss";



@import "./outreach-profile";

// @import "./filepond.min.css";
// @import "./filepond-plugin-image-preview.min.css";
//@import "../components/Enroll/MenteeInformationForm/menteeForm.scss";
//@import "../components/Login/LoginForm/LoginForm.scss";
//@import "../containers/Home/Home.scss";
@import "./MenteeDetail.scss";
@import "./menteeMasterFollowUp.scss";
@import "./steps.scss";
@import "./custom-rainbow-overrides.scss";
@import "./es-responsive.scss";
@import "./animations.scss";
@import "./common-components.scss";
@import "./client-portal.scss";
@import "./issueareas.scss";
@import "./outreach.scss";
@import "./talkingpoints.scss";
@import "./alarm.scss";
@import "./outbound-calls.scss";

@import "./resizablecolumns.scss";
@import "./availabilties.scss";

@import "./custom-table.scss";
@import "./box.scss";
@import "./peer-navigator.scss";
@import "./materials-overrides.scss";

// cms styles
@import './cms/common.scss';
@import './cms/cms-variables.scss';
// @import "./cms/react-select.scss";
@import './cms/layout.scss';
@import './cms/table.scss';
@import './cms/components.scss';
@import './cms/modal.scss';

@import "./inquisit-theme.scss";
@import "./copilot.scss";
@import "./es-mentor-dashboard.scss";
@import "./screen-recorder.scss";

html{ font-smoothing: antialiased; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; text-rendering: optimizeLegibility; }
// body{font-size:1rem !important; background: #fff !important;}