.mentor-summary-compo {
  display: flex;
  .left-sidebar {
    width: 320px;
    padding: 24px;
    border-right: 1px solid #E9EFF5;
    height: auto; 
    min-height: calc( 100vh - 150px ); 
  }
  .content-n-sidebar {
    padding: 24px;
    width: calc( 100% - 320px); 
    .row {
      margin-left: 0;
      margin-right: 0;
    }
    .gray-box {
      display: flex;
      padding: 0;
      background-color: #F6F9FC;
      border-radius: 4px; 
      >div {
        height: auto;
        padding: 24px 24px;
        border-right: 1px solid #E9EFF5;
        flex-grow: 1; 
        &:last-child {
          border-right: 0 !important; 
        }
      }
    }
    .es-capacity-box {
      .df {
        .each-item {
          width: calc( 20% - 16px );
          padding: 16px 16px 16px 0; 
          margin-right: 16px;
          border-right: 1px solid #E9EFF5;
        }
        >div:last-child {
          border-right: 0 !important;  
        }
      }
    }
    .summary-main {
      .border-box {
        border: 1px solid #E9EFF5;
        border-radius: 4px;
        padding: 20px;
        hr {
          margin-top: 15px;
          margin-bottom: 15px;
          height: 1px;
          background-color: #E9EFF5;
        }
      }
    }
  }
}

.square-transparent-badge { 
  font-size: 22px; 
  color: $secondary-blue; 
  font-family: $font-medium; 
  width: 32px;
  text-align: center;
  line-height: 29px;
  border: 2px solid $secondary-blue;
  border-radius: 8px; 
}