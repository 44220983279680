.allset{
    border-radius: 22px;
    font-size: 19px;
    padding: 13px;
}
.allsettitle{
    display: inline-flex;
    line-height: 42px;
}
#enrollconpage{
    .titletext{
        font-size: 20px;
        color: #404040;
        font-weight: lighter;
        font-style: italic;
        span{
            font-weight: lighter;
        }
    }
}
.enroll-html-avail{
    text-align: center;
    & > div{
        display: inline-block;
        float: none;
    }
    .time-list{
        li{
            padding-left: 0; 
            background: #FFF;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
            position: relative;
            font-size: 14px;
            color: #374050;
            i {
                color: #768091;
                font-size: 16px;
            }
        }
    }
}
