.callsline-header{
	line-height: 1.2;
	.count{
		opacity: 0.7;
		font-weight: 100;
		font-family: $font-regular;
		span{
			font-size: 36px;
			font-family: $font-bold;
			margin-right: 3px;
			font-weight: 300;
		}
	}
	.cat{
		font-size: 19px;
		padding-left: 2px;
	}
}
.callsline-body{
	& > div:first-child{
		margin-bottom: 4px;
		font-weight: 600;
	}
	.light-color{
		opacity: 0.7;
	}				
	.callsline{
		padding-left: 20px;
		border-left: 1px solid rgba(255, 255, 255, 0.7);
		margin-left: 3px;
		& > li {
			display: flex;
			.order-number{
				min-width: 20px;
				width: 20px;
				opacity: 0.7;
				font-family: $font-bold;
			}
			.avatar-contain{
				.avatar{
					background: #FFF;
					color: $blue-color;
				}
			}
			.person{
				padding-left: 0;
				display: flex;
				align-items: center;
				margin-bottom: 10px;
				line-height: 1.4;
				.avatar{
					position: static;
					display: inline-block;
					width: 35px;
					height: 35px;
					line-height: 35px;
					font-size: 15px;
					margin-right: 0;
				}
				.avatar-contain{
					margin-right: 10px;
				}
				.name{
					margin-top: 0;
					font-size: 15px;
					font-family: $font-bold;
				}
				.info{
					color: #FFF;
					clear: both;
				    font-size: 14px;
				    font-family: $font-regular;
				    display: block;
				}
			}

			&.has-data{
				position: relative;
				&:after{
					content: "";
			    position: absolute;
			    width: 100px;
			    height: 0;
			    bottom: 0;
			    border-bottom: 1px solid #FFF;
			    opacity: 0.5;
			    left: 20px;
				}
				.order-number{
					opacity: 1;
				}
			}
		}
	}
}