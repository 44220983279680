@font-face {
  font-family: 'Calibre';
  src:  url('../new-theme/fonts/CalibreWeb-Regular.woff2') format('woff2'),
        url('../new-theme/fonts/CalibreWeb-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Calibre-Bold';
  src:  url('../new-theme/fonts/CalibreWeb-Bold.woff2') format('woff2'),
        url('../new-theme/fonts/CalibreWeb-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Calibre-Semibold';
  src:  url('../new-theme/fonts/CalibreWeb-Semibold.woff2') format('woff2'),
        url('../new-theme/fonts/CalibreWeb-Semibold.woff') format('woff');
}
@font-face {
  font-family: 'Calibre-Light';
  src:  url('../new-theme/fonts/CalibreWeb-Light.woff2') format('woff2'),
        url('../new-theme/fonts/CalibreWeb-Light.woff') format('woff');
}
@font-face {
  font-family: 'Calibre-Medium';
  src:  url('../new-theme/fonts/CalibreWeb-Medium.woff2') format('woff2'),
        url('../new-theme/fonts/CalibreWeb-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Inter-Semibold';
  src:  url('../new-theme/fonts/Inter-SemiBold.woff2') format('woff2'),
        url('../new-theme/fonts/Inter-SemiBold.woff') format('woff');
}