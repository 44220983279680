.tag-box {
  background: #FFFFFF;
  border: 1px solid #DAE2EB;
  border-radius: 4px;

  .tag-box-pills {
    padding: 8px 8px 0 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    >li {
      margin-top: 0;
      margin-right: 8px;
      margin-bottom: 8px;
      color: #fff;
      font-size: 14px;
      padding: 4px 6px 4px 8px;
      line-height: 1;
      box-shadow: none;
      background: #006DEB;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      button.remove {
        padding: 0;
        margin-left: 2px;
        margin-top: -1px;
        width: 12px;
        height: 20px;
        opacity: 0.6;
        font-size: 20px;
        color: #fff;
        background: transparent;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  >input {
    padding: 0 8px 8px 8px;
    border: 0 !important;
    outline: none;
    background-color: transparent;
    width: 100%;
    font-size: 16px;
  }

  .autocomplete {
    padding: 8px;
  }
}


ul.tag-box-pills.tag-square {
  padding-left: 0;

  li {

    margin-right: 12px;
    margin-top: 5px;
    background-color: #0094F3;
    border-radius: 0;
    color: #fff;
    display: inline-block;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    font-family: 'Calibre-regular';

    .remove {
      color: #fff;
      background: transparent;
      border: none;
    }

    button {
      padding: 4px 10px 3px 10px;
      cursor: pointer;
    }

    &.system {
      background-color: #7AC143;
      color: #fff;
    }
  }
}