.dashboard-calendar-compo {
  .date-title {
    font-size: 18px;
    font-family: $font-medium;
    color: #374050;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 1px solid #E0E8F0;
    padding-bottom: 4px;
    margin-bottom: 12px;
  }
  .single-event {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #E0E8F0;
  }
}
.DayPicker {
  width: 100%;
  border: 1px solid #E0E8F0;
  border-radius: 8px;
  font-family: $font-regular;
  .DayPicker-wrapper {
    padding-bottom: 24px;
    outline: none !important;
    .DayPicker-NavButton {
      right: 10px;
      top: 10px;
      border: 0 !important;
      outline: none !important;
    }
    .DayPicker-NavButton.DayPicker-NavButton--prev {
      background-image: url(../new-theme/images/blue_chevron.svg);
      transform: rotate(180deg) scale(0.6);
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .DayPicker-NavButton.DayPicker-NavButton--next {
      background-image: url(../new-theme/images/blue_chevron.svg);
      transform: scale(0.6);
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;

    }
  }
  .DayPicker-Month {
    width: 100%;
    margin: 0;
    .DayPicker-Caption {
      padding: 10px 16px;
      border-bottom: 1px solid #E0E8F0;
      line-height: 1;
      margin-bottom: 0;
      > div {
        line-height: 1;
        font-size: 21px;
        font-family: $font-medium;
        color: #374050;
        font-weight: 400;
      }
    }
    .DayPicker-Weekdays {
      display: block;
      margin-top: 0;
      .DayPicker-WeekdaysRow {
        padding-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 16px 8px 16px;
        .DayPicker-Weekday {
          padding: 0;
          width: 32px;
          text-align: center;
          > * {
            font-size: 14px;
            color: #95A0B0;
            font-family: $font-regular;
          }
        }
      }
    }
    .DayPicker-Body {
      padding: 0 16px;
      display: block;
      .DayPicker-Week {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .DayPicker-Day {
          font-size: 16px;
          color: 374050;
          width: 32px;
          height: 32px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          line-height: 1 !important;
          margin-bottom: 8px;
        }
      }
    }
  }
  a{
    text-decoration: none;
    &:hover {
      text-decoration: none !important;
    }
  }
}

.outcomes-compo {
  .outcomes-metrics {
    .row {
      flex-wrap: wrap;
      .col-4 {
        @media(max-width: 1599px) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 15px;
        }
        >div {
          height: 100%;
        }
      }
    }
  }
}