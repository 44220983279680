.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 3px -3px #ABABAB;
  background: #fefefe;
  text-align: left;
}
.public-DraftEditorPlaceholder-inner {
	position: absolute;
}
// .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner{
// 	padding-top: 10px;
// }
// .public-DraftEditorPlaceholder-inFocus .public-DraftEditorPlaceholder-inner{
// 	padding-top: 10px;
// }
// .editor .public-DraftEditorPlaceholder-inFocus .public-DraftEditorPlaceholder-inner{
// 	padding-top: 0px;
// }

// .notifyHolder .public-DraftEditorPlaceholder-inner {
// 	padding-left: 10px;
// }

// .notifyHolder :global(.public-DraftEditor-content) {
//   min-height: 50px;
// }

// .DraftEditor-editorContainer {
// 	padding-top: 10px;	
// 	text-align: left;
// }

.buttonWrapper {
  display: inline-block;
}

.editor .button {
  background: #333;
  color: #ddd;
  font-size: 20px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
  border-radius: 4px;
}

.editor .button svg {
  fill: #ddd;
}

.editor .button:hover, .button:focus {
  background: #444;
  outline: 0; /* reset for :focus */
}

.editor .active {
  color: #6a9cc9;
}

.editor .active svg {
  fill: #6a9cc9;
}
.editor {
	input:focus::-webkit-input-placeholder {
	     text-align: right;
	}

	input:focus:-moz-placeholder{ /* Firefox 18- */
	      text-align: right;
	}

	input:focus::-moz-placeholder{  /* Firefox 19+ */
	   text-align: right;
	}

	input:focus:-ms-input-placeholder{  
	      text-align: right;
	}
}
.notifyHolder { 
	height: 40px;
	border: 1px solid #ddd;
}
.notifyHolder .DraftEditor-editorContainer {
	padding-left: 10px;
}
.mention {
  color: #4a85bb;
  text-decoration: none;
}

.mentionSuggestions {
  border-top: 1px solid #eee;
  background: #fff;
  border-radius: 2px;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform-origin: 50% 0%;
  transform: scaleY(0);
  margin: -16px;
}

.mentionSuggestionsEntryContainer {
  display: table;
  width: 100%;
}

.mentionSuggestionsEntry {
  padding: 7px 10px 3px 10px;
  transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
}

.mentionSuggestionsEntry:active {
  background-color: #cce7ff;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mentionSuggestionsEntryTitle {
  font-size: 80%;
  color: #a7a7a7;
}

.mentionSuggestionsEntryAvatar {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}