.mentor-overview-sop-wrp {
  padding-left: 0;
  padding-right: 0;
  background-color: #fff; 
  >.row {
    margin-left: 0;
    margin-right: 0;
    >div {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .col-lg-3 {
    border-left: 1px solid #E9EFF5;
    &.left-col {
      border-left: 0;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .col-lg-6 {
    border-left: 1px solid #E9EFF5; 
  }

  .inner-col {
    .fc-green {
      color: #88C04E !important;
    }
    h3 {
      padding: 24px; 
      border-bottom: 1px solid #E9EFF5;
    }
    .each-sop-info,  .each-call-tx{
      padding: 18px 24px;
      border-bottom: 1px solid #E9EFF5;  
    }
    .each-sop-info {
      &.active {
        background: #F6F9FC;
        border-left: 4px solid $secondary-blue; 
      }
    }
  }
}