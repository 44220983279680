body {

  .MuiButton-root {
    text-transform: none;
    font-family: $font-medium;
    border-radius: 8px;
    white-space: nowrap;
    padding: 8px 12px 8px 12px;
    box-shadow: none !important;
    line-height: 1.4;

    span {
      font-size: 16px;
    }

    &.MuiButton-link {
      color: $dark-blue;
      letter-spacing: 0;
      text-transform: none;
      font-family: $font-medium;
      padding: 0 2px;

      &.MuiButton-sizeSmall {
        font-size: 14px;
      }
    }

    &.blue-text-btn {
      border: 0;
      box-shadow: none !important;
      text-transform: none;
      background-color: transparent;
      font-size: 16px;
      font-family: $font-medium;
    }

    i {
      margin-right: 4px;
    }

    i.icon-angle-right {
      font-size: 16px;
      transform: scale(0.8);
    }

    &.gray-text-btn {
      border: 0 !important;
      background-color: transparent !important;
      padding: 0;
      min-width: unset;
      color: #576071;
      font-family: $font-medium;
      box-shadow: none !important;
      outline: none !important;

      .MuiTouchRipple-root {
        display: none !important;
      }
    }

    &.btn-full-width {
      width: 100%;
      padding: 10px 12px 10px 12px;
    }

    &.is-big {
      font-size: 16px;
      padding: 6px 12px 6px 12px;
    }

    &.is-small {
      font-size: 14px;
      padding: 4px 6px 4px 6px;
      border-radius: 6px;
    }

    &.blue-disabled {
      color: #fff;
      background-color: $secondary-blue;
    }

    .MuiButton-endIcon {
      margin-left: 0;
    }

    .MuiButton-startIcon {
      margin: 0 6px 0 0;

      i {
        margin: 0;
      }
    }

    &.is-padding6-16 {
      padding: 6px 16px !important;
    }

    &.text-base {
      font-size: 16px !important;
    }
  }

  .sidebar-header {
    .MuiTabs-flexContainer {
      .MuiButtonBase-root {
        justify-content: center !important;
      }
    }
  }

  .MuiDialogContent-root {
    min-width: 600px !important;
    max-width: 100%;
  }

  .MuiOutlinedInput-root {
    .MuiSelect-select {
      padding: 0px !important;
      padding-right: 32px !important;
    }
  }

  .MuiTabs-root {
    min-height: unset;
    flex-shrink: 0;

    .MuiSvgIcon-root {
      fill: black !important;
    }

    .MuiTabScrollButton-root.MuiTabScrollButton-horizontal {

      padding: 0;
      width: unset;
    }

    .MuiTabs-scroller {
      position: relative;
      height: auto;
      border-bottom: 1px solid #E9EFF5;
      // &:before {
      //   content: '';
      //   position: absolute;
      //   bottom: -1px;
      //   height: 1px;
      //   width: 100%;
      //   background-color: #E9EFF5;
      // }

      .MuiTabs-flexContainer {
        .MuiButtonBase-root {
          padding: 0 0 3px 0;
          height: unset;
          min-height: unset;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 16px;
          margin-right: 16px;
          min-width: unset;
          text-transform: none;

          .MuiBadge-root {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 0;
            color: #768091;

            .MuiBadge-badge {
              // top: 50%;
              // transform: translateY(-50%);
              // right: -4px;
              // z-index: 0 !important;
              margin-left: 6px;
              background-color: #F1F5F9;
              color: #576071;
              font-size: 14px;
              font-family: $font-medium;
              position: relative;
              transform: none;
            }

            .MuiBadge-invisible {
              display: none;
            }
          }

          &.Mui-selected {
            border-bottom: 2px solid $primary-blue;

            .MuiBadge-root {
              color: $primary-blue;
            }

            .MuiBadge-root .MuiBadge-badge {
              background-color: #EBF4FF;
              color: $dark-blue;
            }
          }
        }
      }

      .MuiTabs-indicator {
        display: none;
      }
    }
  }

  .MuiPopover-root {
    background-color: transparent;

    .MuiPaper-root {
      box-shadow: 0px 2px 4px rgba(55, 64, 80, 0.15);
    }
  }

  .MuiFormGroup-root {
    width: 100%;
  }

  .MuiOutlinedInput-root {
    legend {
      display: none !important;
    }
  }

  .cright-nav.opened {
    .MuiPaper-root {
      box-shadow: none;

      .MuiCardContent-root {
        border: 0;
      }
    }
  }

  .inq-table-w-pagination {
    border: 0;
    background: white;
    border-radius: 8px;
    .new-tag-editor {
      .MuiOutlinedInput-root {
        padding-top: 4px !important ;
      }
    }
    .MuiOutlinedInput-root {
      padding-top: 0 !important;

      .MuiSelect-select {
        padding-right: 18px !important;
      }

      .MuiSelect-icon {
        right: 5px !important;
      }
    }

    .MuiDataGrid-cell:focus {
      outline: none !important;
    }

    .MuiDataGrid-columnHeaders {
      background-color: $table-header-bg;
      border-bottom: 0;
      border-radius: 8px;


      .MuiDataGrid-columnHeadersInner {
        >div>div:first-child:not(.MuiDataGrid-columnHeaderCheckbox) {
          padding-left: 0;
          width: auto;

          >div:last-child {
            >div {
              padding-right: 24px;
            }
          }

          .MuiDataGrid-columnHeaderDraggableContainer {
            width: auto;
            padding-left: 0;
            padding-right: 0;
          }
        }

        .MuiDataGrid-columnHeaderTitleContainer {
          padding-left: 8px;
          padding-right: 8px;
        }

        >div:last-child {
          padding-right: 24px;
        }

        .MuiDataGrid-columnHeaderCheckbox {
          .MuiDataGrid-columnHeaderDraggableContainer {
            .MuiDataGrid-columnHeaderTitleContainerContent {
              display: flex;
              justify-content: center;
            }

            .MuiCheckbox-root {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }

        .MuiDataGrid-columnHeader {
          padding-left: 0;
          padding-right: 0;
          // padding-left: 8px;
          // padding-right: 8px;
          border: 0 !important;
          outline: none !important;
        }
      }

      .MuiDataGrid-columnHeaderTitle,
      .MuiDataGrid-columnHeaderTitleContainerContent {
        color: #374050;
        font-size: 16px;
        line-height: 20px;
        font-family: $font-medium;
        width: 100%;
        text-overflow: initial;
        white-space: normal;
        word-wrap: break-word !important;

        div.text-center {
          width: 100%;
          text-align: center;
        }

        >div {
          line-height: 1;
        }
      }

      .MuiDataGrid-columnHeadersInner {
        >div:first-child {
          .MuiDataGrid-columnHeaderTitle {
            color: $charcoal-700;
          }
        }
      }
    }

    .MuiDataGrid-columnSeparator {
      display: none;
    }

    .MuiDataGrid-row {
      .MuiDataGrid-cell {
        border-bottom: 0;
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 16px;
        cursor: pointer;
        // word-break: break-all !important;
      }

      &.Mui-selected,
      &:hover {
        background-color: #F6F9FC !important;
        border-radius: 6px;
      }
    }

    &.is-fixed-header {
      .MuiDataGrid-virtualScroller {
        max-height: calc(100vh - 351px);
        overflow-y: auto !important;
      }

      &.is-pn-list {
        .MuiDataGrid-virtualScroller {
          max-height: calc(100vh - 288px);
          overflow-y: auto !important;
        }
      }
    }
  }

  .MuiDataGrid-virtualScrollerContent {
    min-height: 525px !important;

    .MuiDataGrid-row {
      >div:first-child:not(.MuiDataGrid-cellCheckbox) {
        // padding-left: 24px;
      }

      >div:last-child {
        // padding-right: 24px;
      }

      .MuiDataGrid-cell {
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    // .MuiDataGrid-row:nth-child(even) {
    //   background-color: #F6F9FC;
    //   border-radius: 6px;
    // }
  }

  .MuiButton-link {
    min-width: unset;
    padding: 0;
  }

  .choices-wrp {
    .MuiToggleButtonGroup-root {
      margin-left: -4px;
      margin-right: -4px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .MuiButtonBase-root {
        margin: 4px !important;
        border-width: 1px !important;
        border: 1px solid $light-blue !important;
        color: $dark-blue;
        text-transform: none;
        border-radius: 8px;
        font-size: 16px;
        padding: 4px 6px 4px 6px;

        min-height: 32px;
        min-width: 60px;
        line-height: 1.1;
        font-family: $font-medium;

        &.Mui-selected {
          border: 1px solid $dark-blue !important;
          background-color: $dark-blue !important;
          color: #fff;
        }

        .MuiTouchRipple-root {
          display: none !important;
        }

        &.Mui-disabled {
          opacity: 0.6;
        }
      }
    }
  }

  .call-scheduler-wrp {
    .MuiToggleButtonGroup-root {
      height: 40px;

      :nth-child(1) {
        border-radius: 8px 0 0 8px !important;
      }

      :last-child{
        border-radius: 0 8px 8px 0 !important;
      }

      .MuiButtonBase-root {
        text-transform: none;
        font-weight: bold;

        &.Mui-selected {
          border: 1px solid $secondary-blue !important;
          background-color: $secondary-blue !important;
          color: #fff;
        }
      }
    }
  }

  .MuiFormControl-root {
    width: 100%;

    fieldset.MuiOutlinedInput-notchedOutline {
      top: 0;
      border: 1px solid #DAE2EB;
    }

    .MuiInput-root {
      .MuiInput-input {
        margin-bottom: 0;
      }
    }
  }

  .MuiPopover-root {
    .popover-inner {
      background: #FFFFFF;
      box-shadow: 0px 4px 12px rgba(23, 32, 46, 0.1);
      border-radius: 8px;
      border: 0;
      padding: 16px;
      position: relative;
      color: #576071;
      opacity: 1;
      font-size: 14px;
      position: relative;
      padding: 16px 24px 16px 16px;

      // padding-top:
      .btn-popover-close {
        color: #95A0B0;
        font-size: 9px;
        cursor: pointer;
        position: absolute;
        right: 4px;
        top: 4px;
        padding: 5px;
      }
    }

    .arrow {
      &:before {
        border-right-color: #fff !important;
      }
    }

    // .MuiBackdrop-root.MuiBackdrop-invisible {
    //   pointer-events: none;
    // }
  }

  .MuiDrawer-root {
    z-index: 100 !important;
  }

  .inq-switch-wrp {
    position: relative;
    height: 32px;

    .MuiFormControlLabel-root {
      margin: 0;

      .MuiSwitch-root {
        padding: 0;
        width: 72px;

        .MuiSwitch-switchBase {
          padding: 0;
          display: block;

          .MuiSwitch-thumb {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background-color: #fff;
            padding: 0;
            position: absolute;
            left: 2px;
            top: 2px;
            right: auto;
          }

          &.Mui-checked {
            .MuiSwitch-thumb {
              left: 22px;
            }
          }
        }

        .MuiSwitch-track {
          width: 72px;
          height: 32px;
          border-radius: 32px;
          background-color: $brand-secondary;
          opacity: 1;
        }
      }
    }

    .MuiFormControlLabel-label {
      position: absolute;
      font-family: $font-medium;
      left: 34px;
      top: 4px;
      color: #fff;
    }

    &.is-checked {

      .MuiFormControlLabel-root .MuiSwitch-root .MuiSwitch-track {
        background-color: $brand-success;
      }

      .MuiFormControlLabel-label {
        left: 14px;
      }
    }
  }

  .MuiTable-root {
    thead {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #F6F9FC;
        border-radius: 8px;
        pointer-events: none;
        z-index: 0;
      }

      tr {
        position: relative;

        // z-index: 1;
        th {
          padding: 16px;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 8px 16px;
          border-bottom: 1px solid #E9EFF5;
        }
      }
    }
  }

  .MuiButtonGroup-root {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    button {
      border-radius: 8px !important;
    }
  }

  .MuiLinearProgress-root {
    border-radius: 24px;
    height: 4px;
    background-color: #E0E8F0;
  }
}

.MuiDataGrid-footerContainer {
  display: flex;
  justify-content: center !important;

  .MuiDataGrid-selectedRowCount {
    display: none;
  }
}

.MuiOutlinedInput-root {
  border-radius: 8px !important;
  padding: 4px 10px !important;

  input {
    padding: 0 !important;
  }
}



.MuiLinearProgress-root {
  background-color: #e9eff5 !important;
}


.MuiChip-root {
  font-size: 14px !important;
  padding: 2px 8px !important;
  min-height: 21px !important;
  max-height: 24px !important;
  min-width: 24px;

  .MuiChip-label {
    padding: 0 !important;
  }

  &.is-rect {
    border-radius: 4px !important;
  }
  &.is-multiline {
    max-height: unset !important;
    white-space: break-spaces;
    height: auto;
    > span {
      white-space: inherit;
      text-align: center;
      line-height: 1.2;
    }
  }
  &.round-tag {
    border: 1px solid $primary-blue;
    font-size: 13px;
    font-family: $font-regular;
    background: transparent;
    border-radius: 16px;
    color: $primary-blue;
    margin: 0 4px 0 0;
    line-height: 1;
    opacity: 1;
    border-radius: 5px;
  }
}

.MuiToggleButtonGroup-root {
  &.w-full {
    width: 100%;
    display: flex;

    button {
      flex: 1;
    }
  }
}

.voice-memo-compo {
  .copilot-audio-player {
    padding: 0 !important;
    border: 0 !important;
    margin-bottom: 0 !important;
  }
}

body .toggle-buttons-w-space.MuiToggleButtonGroup-root.w-full {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    flex: 1;
    width: 120px;
    border-radius: 4px;
    border: 1px solid $secondary-blue;
    color: $secondary-blue;
    background-color: transparent;
    max-width: 84px;
    height: 32px;
    margin: 8px;
    border-radius: 8px;
    line-height: 1;

    &:hover {
      color: #fff;
      background-color: $secondary-blue;
    }
  }
}

body .MuiContainer-root {
  max-width: unset !important;
}

body {
  .MuiPopperUnstyled-root {
    z-index: 9;
  }

  .inq-popper-box-wrp {
    z-index: 11;
  }

  .inq-popper-box {
    box-shadow: 0px 4px 24px rgba(55, 64, 80, 0.16) !important;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 16px;
    padding: 24px 16px;
    background-color: #fff;
    z-index: 999;
    position: relative;
    width: 292px;
    height: 292px;
    overflow-y: auto;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.025);
    overflow-x: hidden;

    .copilot-note {
      margin-bottom: 0;
      border-bottom: 0;
    }

    .popper-notes-list {
      flex-grow: 1;
      overflow-y: auto;
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
    }

    .popper-fixed-footer {
      border-top: 1px solid #E9EFF5;
      padding: 16px;
      margin-left: -16px;
      margin-right: -16px;
      overflow-x: hidden;
      flex-shrink: 0;

      .gray-bd-box.mb-3 {
        margin-bottom: 0 !important;
        padding: 6px 8px !important;
        border-radius: 8px !important;
      }

      .custom-ql-editor-wrp {
        border-radius: 8px !important;

        .ql-editor {
          padding: 6px 8px !important;
        }
      }
    }
  }

  .MuiAlert-root.MuiAlert-standardPrimary.inq-alert {
    background: #EBF4FF;
    border-radius: 8px;
    padding: 16px;
    color: #17202E;

    .MuiAlert-icon {
      padding-top: 2px;
      padding-bottom: 0;
      margin-right: 8px;
    }

    .MuiAlert-message {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .MuiTextField-root.mui-datepicker-w-clear-btn {
    .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
      margin-right: 12px;
    }

    .MuiIconButton-root {
      width: 24px;
      height: 24px;
      padding: 0;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &.MuiFormControl-root.is-border-dark fieldset.MuiOutlinedInput-notchedOutline {
      border: 1px solid #ccd6e2;
      border-radius: 4px;
      min-height: 32px;
    }
  }
}

.MuiIconButton-root.MuiIconButton-sizeSmall {
  &.is-24px {
    width: 24px;
    height: 24px;

    svg {
      font-size: 20px;
    }
  }

  &.is-rect {
    border-radius: 4px;
  }

  &.is-bordered {
    border: 1.5px solid #768091;
  }

  &.is-icon16 {
    width: 16px;
    height: 16px;
    padding: 0;

    svg {
      font-size: 12px;
      color: #768091
    }
  }
}

.MuiButtonBase-root.MuiIconButton-root.is-rect {
  border: 1.5px solid $charcoal-500;
  border-radius: 4px;

  .MuiTouchRipple-root {
    display: none;
  }

  &.is-16px {
    width: 16px;
    height: 16px;
    padding: 1.5px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.full-screen-modal-content-wrapper {
  height: calc(100vh - 48px);
  margin-top: 48px;
  overflow-y: auto;
  padding: 24px;
}

body {
  .MuiFormControlLabel-root {

    .MuiCheckbox-root,
    .MuiRadio-root {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }
  }

  .MuiPopper-root {
    z-index: 999999;
  }
  .MuiButtonBase-root.MuiChip-root {
    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon {
      display: none;
    }
    &:hover {
      .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon {
        display: block;
        margin-right: 0;
        margin-left: 1px;
        transform: scale(0.85);
      }
    }
  }
}