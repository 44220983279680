.es-mentor-dashboard {
  background-color: #E9EFF5;
  // height: calc(100vh - 51px);
  display: flex;

  .es-md-sidebar {
    position: relative;
    border-right: 1px solid #E0E8F0;

    .sidebar-inner-wrp {
      height: 100%;
      transition: width ease 0.3s;
      width: 255px;

      .es-md-sidebar-compo {
        width: 255px;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(55, 64, 80, 0.1);
        height: calc(100vh - 51px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        // overflow-y: auto;
      }
    }


    .es-md-top {
      padding: 24px 16px;
      border-bottom: 1px solid #E0E8F0;
      flex-shrink: 0;
    }

    .menu-list {
      padding-top: 20px;
      padding-bottom: 20px;
      flex-grow: 1;
      max-height: calc(100% - 40px);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .each-menu {
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        position: relative;
        flex-shrink: 0;

        .icon-wrp {
          // width: 20px;
          // height: 20px;
          width: 26px;
          margin-right: 12px;
          display: flex;
          align-items: center;
          justify-content: center;

          .svg {
            width: 18px;
            height: 18px;
          }
        }

        .fz-16 {
          line-height: 24px;
        }

        span {
          color: #374050;
        }

        &.current-item {

          .icon-wrp {
            svg * {
              stroke: $dark-blue;
            }
          }

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 4px;
            background: $dark-blue;
            border-radius: 0px 4px 4px 0px;
          }

          span {
            color: $dark-blue;
          }
        }

        &.is-disabled {
          cursor: not-allowed !important;

          .icon-wrp {
            svg * {
              stroke: #95A0B0
            }
          }

          &:before {
            content: none;
          }

          span {
            color: #95A0B0;
          }
        }

      }
    }

    .btn-toggle-sidebar {
      background-color: #FFFFFF;
      box-shadow: 0px 4px 12px rgba(23, 32, 46, 0.1);
      border-radius: 40px;
      width: 16px;
      height: 48px;
      position: absolute;
      right: -18px;
      top: 40%;
      z-index: 8;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        color: $secondary-blue;
        transform: rotate(90deg);
      }
    }

    &.is-client-portal {
      background-color: #fff;
      width: 260px;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }

  .es-md-content {
    padding: 24px 24px 24px 24px;
    flex-grow: 1;
    overflow-x: auto;

    // .rbc-time-content {
    //   max-height: calc(100vh - 360px);
    //   overflow-y: auto;
    // }

    &.is-mentees-list {
      padding: 0;
      background-color: #fff;
    }

    &.is-client-portal {
      padding: 0;
    }
  }

  &.is-sidebar-collapsed {
    .es-md-sidebar {
      .btn-toggle-sidebar {
        i {
          transform: rotate(-90deg);
        }
      }
    }
  }
}

.es-mentees-filters-wrp {
  padding-left: 52px;
  // overflow-y: auto;
  // padding-bottom: 10px;

  // &.is-bordered {
  //   border-top: 1px solid #E0E8F0;
  //   border-bottom: 1px solid #E0E8F0;
  // }

  .custom-checkbox.square .custom-checkbox-mark {
    border-color: #A8B6C7;
  }
}


.scrollable-columns {
  overflow-x: auto;
  height: 100%;

  .inner-wrp {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: fit-content;

    .each-column {
      width: 480px;
      flex-shrink: 0;
      margin-right: 16px;

      .inner-column {
        background-color: #fff;
        box-shadow: 0px 2px 10px rgba(55, 64, 80, 0.1);
        border-radius: 16px;
        padding: 24px;
        max-height: 100%;
        // overflow-x: auto;
      }

      @media(max-width: 1599px) {
        width: 405px;
      }

      @media (max-width: 1599px) {
        width: 360px;
      }
    }

    >div:last-child {
      margin-right: 0;
    }
  }
}

.es-mentee-list-cont {
  background: #FFFFFF;
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 24px;

  &.is-mentor-profile {
    padding: 0;
  }

  .search-wrp .search-input input {
    border-radius: 8px;
    padding: 14px 16px 14px 40px;
    height: 42px;
  }

  .es-mentee-list-header {
    @media(max-width: 1300px) {
      .header-title {
        width: 100%;
      }

      >div {
        width: 100%;
      }
    }
  }

  .mentee-list-pg-wrp {
    height: 42px;
    min-width: 430px;

    @media(max-width: 1300px) {
      width: 100%;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

}


.es-updated-table-wrp {
  .mentee-list-table-wrp.container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}


.mentor-payment-history-compo {
  height: 100%;

  .custom-styled-tabs.ccp-updated {
    &+.tab-content {
      height: unset;
      padding-top: 0 !important;

      .scroll-wrp.scroll-view-compo {
        max-height: calc(100vh - 388px);
        border-top: 0 !important;
      }
    }
  }

  .scrollable-columns {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.payments-history-wrp {
  >div:first-child {
    border-top: 1px solid #F1F5F9;
  }

  .each-history {
    padding-bottom: 16px;
    padding-top: 16px;
    border-bottom: 1px solid #F1F5F9;
    padding-left: 20px;

    .color-dot {
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
    }

    &.is-selected {
      position: relative;

      >* {
        z-index: 2;
      }

      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: -16px;
        right: -16px;
        bottom: 0;
        background: #F6F9FC;
        border-radius: 8px;
        pointer-events: none;
      }
    }
  }
}

.payments-by-mentee-wrp {
  .each-history {
    border: 1px solid #E9EFF5;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 16px;
  }
}

.payments-workbooks-wrp {
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
  height: calc(100vh - 237px);
  overflow-y: auto;
  overflow-x: hidden;

  .each-workbook {
    border: 1px solid #E9EFF5;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 16px;

    .workbook-details {
      padding-top: 10px;
      padding-bottom: 10px;

      .each-workbook-detail {
        padding-bottom: 10px;
        margin-bottom: 6px;
        border-bottom: 1px solid #E0E8F0;
      }

      >div:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.pg-filters-wrp {
  .gray-bd-box.loading-gradient {
    * {
      opacity: 0;
    }
  }
}

.pg-filters-wrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  >.gray-bd-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid #CCD6E2;

    >div {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .progress-bar-compo {
      width: 100%;

      .pg-wrp {
        width: 100%;
      }
    }
  }

  .filter-metrics-wrp {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 240px);
    gap: 12px;

    .gray-bd-box {
      border: 1px solid #CCD6E2;
      border-radius: 8px;
      padding: 12px;
      display: grid;
      grid-template-columns: 1fr 48px;
      gap: 8px;

      &.loading-gradient {
        * {
          opacity: 0;
        }
      }

      .cat-title {
        color: #576071;
        font-family: 'Calibre';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;
        display: flex;
        align-items: center;

        svg {
          width: 20px;
          height: 20px;
        }

        .cat-title-w-icon {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          span.lh1 {
            flex-shrink: 0;
            margin-right: 8px;
          }
        }
      }

      .cat-count {
        color: #374050;
        font-family: $font-medium;
        font-size: 30px;
        line-height: 40px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-width: 26px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      &.is-selected {
        background-color: $dark-blue;
        border-color: $dark-blue;

        .cat-title {
          color: #fff;
        }

        .cat-count {
          color: #fff;
        }

        svg {
          color: #fff !important;
        }
      }
    }

    &.is-first-item-highlighted>div:first-child:not(.is-selected) {
      border-color: $dark-blue;

      .cat-title {
        color: $dark-blue;
      }

      .cat-count {
        color: $dark-blue;
      }
    }

    &.is-one-line {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      .gray-bd-box {
        border-radius: 18px;
        padding: 6px 12px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .cat-title {
          svg {
            width: 16px;
            height: 16px;
          }

          div {
            margin-bottom: 1px;
          }
        }

        .cat-count {
          font-size: 16px;
          font-family: $font-medium;
          line-height: 1.2;
        }

        .close-icon-toggle {
          width: 12px;
          margin-left: 4px;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            color: #B8D9FF;
            font-size: 10px;
            transform: scale(0.8);
          }
        }

        &.is-urgent.is-selected {
          background-color: #FF4753;
          border-color: #FF4753;

          .text-charcoal-600,
          .text-charcoal-700 {
            color: #fff !important;
          }
        }

        &.is-outcomes {
          .icon.icon-times-btb {
            font-size: 10px;
          }
          &.is-selected {
            background-color: $blue-color;
            border-color: $blue-color;
            .icon.icon-times-btb,
            .text-charcoal-600,
            .text-charcoal-700 {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}

.icon-size-17 {
  svg {
    width: 17px;
    height: 17px;
  }
}

.round-color-band {
  left: 0;
  height: 100%;
  width: 3px;
  border-radius: 0 4px 4px 0;
  position: absolute;
}

.mentee-list-table-wrp .round-color-band {
  left: -24px;
}

.inq-card {
  background-color: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(55, 64, 80, 0.1);
  border-radius: 16px;
  padding: 24px;
}