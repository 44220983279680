.portal-issues-timeline-wrp {
  h3 {
    text-transform: uppercase;
  }
}
.pi-timelines {
  padding-left: 24px;
  li {
    position: relative;
    padding-top: 14px;
    padding-bottom: 14px;
    &:before {
      content: ''; 
      position: absolute;
      left: -19px;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: #000;
      opacity: 0.1;
    }
    &:after {
      content: ''; 
      position: absolute;
      left: -25px;
      top: 17px;
      border-radius: 7px;
      width: 14px;
      height: 14px;
      background-color: #ebebeb;
      border: 1px solid #dbdbdb;
    }
  }
  >li:last-child {
    &:before {
      content: ''; 
      position: absolute;
      left: -19px;
      top: 0;
      height: 19px;
      width: 1px;
      background-color: #000;
      opacity: 0.1;
    }
  }
}