.survey-wrp {

  .carousel-inner {
    overflow: visible !important;
  }

  .survey-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .survey-sets-list {
    .each-survey-group {
      .hash-tag {
        margin-bottom: 16px;
        color: #576071;
        font-size: 18px;
        font-family: $font-medium;
      }

      .survey-sets {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .each-survey-set {
          border-bottom: 1px solid #E9EFF5;
          padding-top: 16px;
          padding-bottom: 16px;
          margin-left: -24px;
          margin-right: -24px;
          padding-left: 24px;
          padding-right: 24px;
          width: calc(100% + 48px);

          .survey-progress {
            height: 4px;
            border-radius: 50px;

            .bg-success {
              background-color: $secondary-blue !important;
            }
          }
        }
      }
    }
  }

  .survey-detail-header {
    background-color: #fff;

    .back-btn-wrp {
      width: 100%;
      margin-bottom: 16px;

      button {
        border: 0;
        padding: 0;
        box-shadow: none;
        font-size: 16px;
        background-color: transparent;
        padding-top: 2px;
        background-color: transparent !important;
        border-color: transparent !important;
      }
    }



    .single-survey-filters {
      display: flex;
      justify-content: flex-start;
      position: relative;
      z-index: 3;
      border-bottom: 1px solid #E9EFF5;
      margin-bottom: 20px;
      margin-left: -27px;
      padding-left: 27px;
      margin-right: -27px;
      padding-right: 27px;

      .each-filter {
        margin-bottom: -1px;
        cursor: pointer;
        font-size: 16px;
        font-family: $font-medium;
        color: #576071;
        margin-right: 20px;
        padding-bottom: 6px;

        &.current {
          font-family: $font-bold;
          color: $secondary-blue;
          border-bottom: 1px solid $secondary-blue;
        }
      }
    }
  }

  .survey-detail-content {
    padding: 0;

    button.btn {
      position: relative;
      z-index: 2;
    }

    .custom-react-select {
      position: relative;
      // z-index: 3;
    }

    .btn.btn-primary.bordered-button {
      font-size: 16px !important;
    }

    .btn.btn-outline-info {
      border: 1px solid #40C8B7;
      border-radius: 8px;
      padding: 3px 12px 4px 12px;
      font-size: 16px;
      font-family: $font-regular;
      margin-right: 10px;
      width: 60px;
      text-align: center;
      padding-left: 3px;
      padding-right: 3px;

      &:hover {
        background-color: transparent !important;
      }
    }

    .element .card {
      box-shadow: none;
      border: 0;
      margin-bottom: 8px;

      .card {
        border: 0;
      }

      .each-question {
        border: 0;
        padding: 0;
        margin-bottom: 10px;

        .info-container {
          .each-question {
            margin-bottom: 10px;
          }
        }

        .assignee-wrp {
          border: 1px solid #E0E8F0;
          border-radius: 8px;
          padding: 16px;
          margin-top: 16px;
          margin-bottom: 16px;

          .assigned-items-list {
            .each-assignee {
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              margin-left: 26px;
              margin-bottom: 8px;
              position: relative;

              .centered-circle {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                display: inline-block;
                margin-right: 8px;
                margin-top: 10px;
              }

              .badge.badge-light {
                border: 1px solid #FFAA00;
                border-radius: 6px;
                background-color: transparent;
                font-size: 14px;
                font-family: $font-medium;
                color: #FFAA00;
                padding: 1px 4px 2px 4px;
                margin-top: 2px;
                margin-bottom: 2px;
              }

              >i {
                margin-top: 3px;
              }
            }

            >div:last-child {
              margin-bottom: 0;
            }
          }

          .cop-issue-card {
            padding: 12px 16px;
          }
        }

        .custom-checkbox.square {
          margin-bottom: 0 !important;

          .custom-checkbox-mark {
            margin-top: 2px !important;
            border-color: #A8B6C7 !important;
          }

          input[type='checkbox']:checked+.checkbox-lbl .custom-checkbox-mark {
            border-color: $light-blue !important;
          }
        }

      }

    }

    .custom-react-select .custom__control {
      padding: 1px 10px
    }

    .question-option-holder {
      input.opentext-input {
        font-size: 16px !important;
        font-family: $font-regular !important;
        color: #374050;
        padding: 12px;
        border: 1px solid #E0E8F0;
        border-radius: 8px;
      }

      .opentext-input {
        label:empty {
          display: none;
        }
      }

    }
  }


}

.questions-wrp.round-pane {
  overflow: visible;

  .carousel-inner {
    overflow: visible;
  }
}


.unanswered-questions-list {
  .btn.btn-primary.bordered-button {
    font-size: 16px !important;
  }

  .btn.btn-primary.bordered-button.is-disabled {
    background: transparent !important;
    color: #95A0B0 !important;
  }

  .btn.btn-primary.bordered-button {
    padding-left: 16px;
    padding-right: 16px;
    height: 32px;
    // padding: 6px 12px 6px 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 10px;
      margin-right: 6px;
      transform: scale(0.8);
      margin-top: 1px;
    }

    &.is-submitted {
      color: #CCD6E2 !important;
      border-color: #CCD6E2 !important;
    }
  }
}

.survey-single-select,
.opentext-compo,
.multi-select-compo {
  box-shadow: none;
  border: 0;
  margin-bottom: 16px;

  .card {
    border: 0;
  }

  .each-question {
    border: 0;
    padding: 0;
    margin-bottom: 0;

    .talking-point-checkbox {
      margin-bottom: 4px;
    }

    .answered-date-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      color: #768091;
      font-family: $font-regular;
      margin-top: 0;
    }

    .assignee-wrp {
      border: 1px solid #E0E8F0;
      border-radius: 8px;
      padding: 0;
      margin: 16px 0;

      .assigned-items-list {
        .each-assignee {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          margin-left: 26px;
          margin-bottom: 8px;
          position: relative;

          .centered-circle {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 8px;
            margin-top: 10px;
          }

          .badge.badge-light {
            border: 1px solid #FFAA00;
            border-radius: 6px;
            background-color: transparent;
            font-size: 14px;
            font-family: $font-medium;
            color: #FFAA00;
            padding: 1px 4px 2px 4px;
            margin-top: 2px;
            margin-bottom: 2px;
          }

          >i {
            margin-top: 3px;
          }
        }

        >div:last-child {
          margin-bottom: 0;
        }
      }
    }

    .guiders-list {
      position: relative;
      border: 1px solid #E9EFF5;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 16px;

      ul {
        list-style: none !important;
        padding-left: 0 !important;

        li {
          position: relative;
          padding-left: 16px;
          font-size: 16px;
          line-height: 1.2;
          color: #576071;
          margin-bottom: 4px;
          list-style: none !important;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 7px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #576071;
          }
        }
      }
    }

    .question-content-holder {
      .custom-react-select .custom__control {
        // padding: 5px 12px;
        border: 1px solid #E0E8F0;
        border-radius: 8px;
      }
    }
  }
}

.survey-multi-detailer-questions-list {
  margin-top: 16px;
  margin-left: 24px;
  position: relative;

  // &:before {
  //   content: '';
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   height: 100%;
  //   width: 4px;
  //   border-radius: 4px;
  //   background-color: #E9EFF5;
  // }
  .each-question {
    margin-bottom: 10px;
  }

  >div:last-child {
    margin-bottom: 0 !important;
  }

  .survey-single-select,
  .survey-multi-select {
    >div:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.survey-issue-tracks {
  >div:last-child {
    margin-bottom: 0 !important;
  }
}

.ob-wrp {
  .survey-wrp {
    .survey-list-header {
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
      border-bottom: 1px solid #E9EFF5;
      margin-bottom: 0;
      padding-bottom: 16px;
    }
  }
}


.question-type-slider-compo {
  position: relative;

  .min-ind {
    position: absolute;
    left: 0;
    top: 24px;
    font-family: $font-regular;
    color: #95A0B0;
    font-size: 14px;
  }

  .max-ind {
    position: absolute;
    right: 0;
    top: 24px;
    font-family: $font-regular;
    color: #95A0B0;
    font-size: 14px;
    text-align: right;
  }

  .btn.btn-primary.bordered-button {
    padding-left: 16px;
    padding-right: 16px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;

    i {
      font-size: 10px;
      margin-right: 6px;
      transform: scale(0.8);
      margin-top: 1px;
    }

    &.is-submitted {
      color: #CCD6E2 !important;
      border-color: #CCD6E2 !important;
      background-color: #fff;
    }
  }
}

.question-type-likert-compo {
  .likert-steps-wrp {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .likert-step {
      width: 20%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 12px;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        height: 2px;
        background-color: #CCD6E2;
        border-radius: 2px;
      }

      .likert-circle {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-bottom: 4px;
        z-index: 2;
        background-color: #fff;
      }

      &:first-child {
        &:after {
          left: 50%;
        }
      }

      &:last-child {
        &:after {
          right: 50%;
          left: auto;
        }
      }
    }
  }

  .btn.btn-primary.bordered-button {
    padding-left: 16px;
    padding-right: 16px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;

    i {
      font-size: 10px;
      margin-right: 6px;
      transform: scale(0.8);
      margin-top: 1px;
    }

    &.is-submitted {
      color: #CCD6E2 !important;
      border-color: #CCD6E2 !important;
      background-color: #fff;
    }
  }
}

.choices-wrp {
  .MuiFormGroup-root {
    .MuiFormControlLabel-root {
      margin-bottom: 4px;

      .MuiRadio-root {
        padding: 0;
        margin-right: 8px;
      }
    }
  }
  .MuiToggleButtonGroup-root {
    margin-top: -4px;
  }

  .block-question-compo {
    .block-table-header {
      background: #F1F5F9;
      border-radius: 8px;
      padding: 12px 0;

      .text-center {
        padding-left: 4px;
        padding-right: 4px;
        color: #374050;
        font-size: 16px;
        font-family: $font-medium;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.1;
        padding-left: 4px;
        padding-right: 4px;
      }
    }

    .choices-wrp {
      .checkmark-radio-group:nth-child(2n+1) {
        background-color: #F6F9FC;
      }
    }

    .checkmark-radio-group {
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 8px;
    }

    .btn-group {
      .btn.btn-primary {
        min-width: unset !important;

        input[type=radio] {
          display: none;
        }

        .talking-point-checkbox {
          margin-bottom: 0 !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
          justify-content: center !important;

          .custom-checkbox-mark {
            margin-right: 0 !important;
          }
        }
      }
    }
  }

  .main-col-wrp.is-reduced {
    .block-question-compo .block-table-header .text-center {
      font-size: 14px;
      line-height: 1;
    }
  }
}

.opentext-compo {
  .opentext-input {
    .MuiInputBase-root {
      padding: 0 !important;

      fieldset.MuiOutlinedInput-notchedOutline {
        display: none;
      }

      textarea.MuiInputBase-input {
        padding: 6px 8px;
        border: 1px solid #CCD6E2;
        border-radius: 8px !important;
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #17202E;
      }
    }
  }

  .datepicker-w-icon-wrp {
    .react-datepicker-wrapper .react-datepicker__input-container input {
      height: 32px !important;
      border: 1px solid #CCD6E2;
      border-radius: 8px;
      font-size: 16px !important;
      line-height: 20px;
      color: #17202E;
    }
  }
}