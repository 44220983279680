$portal_nav_height: 51px;

.portal-navbar {
  background: #212D52;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  height: 51px;
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 999;
  .main-nav-menu {
    padding-left: 12px;
    padding-right: 16px;
  }
  a.back-btn {
    height: $portal_nav_height;
    padding-left: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #576071;
    font-size: 16px;
    text-decoration: none !important;
  }

  a.print-btn {
    padding: 9px 20px;
    height: $portal_nav_height;
    display: flex;
    align-items: center;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    text-decoration: none !important;
  }

  .each-selector {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: auto !important;
    height: $portal_nav_height;
    border-right: 1px solid rgba(255, 255, 255, 0.2);

    select {
      -webkit-appearance: none;
      background-color: transparent;
      border: none !important;
      color: #fff;
      padding: 9px 42px 9px 19px;
      outline: 0 !important;
      font-size: 16px;
      font-family: $font-medium;
      line-height: 1;
      height: auto;
      min-width: 150px !important;
      box-shadow: none !important;
      // margin-right: -25px;
      padding-right: 25px;
      width: auto;
      cursor: pointer;
      -webkit-user-select: all;
      /* Chrome 49+ */
      -moz-user-select: all;
      /* Firefox 43+ */
      -ms-user-select: all;
      /* No support yet */
      user-select: all;
      position: relative;

      option {
        color: #404040;
      }

      @media(max-width: 960px) {
        font-size: 13px;
        width: 120px;
      }

      &::-ms-expand {
        display: none;
      }
    }

    &::after {
      position: absolute;
      right: 12px;
      top: 50%;
      pointer-events: none;
      transform: translateY(-50%);
      background-image: url(../new-theme/images/bottom-triangle.svg);
      width: 12px;
      height: 6px;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      content: '';
      pointer-events: none;
      opacity: 0.2;
    }

    .form-control:disabled {
      cursor: not-allowed !important;
    }
  }
  .dropdown-menu {
    border-radius: 0;
    width: 300px;
    background-color: #17202E;
    border-radius: 0;
    border: 0;
    padding: 16px 0;
    width: 300px;
    left: 0;
    // left: -90px;

    .dropdown-item {
      color: #fff;
      font-size: 16px;
      line-height: 24px;
      padding: 6px 24px 6px 24px;
      border-radius: 0;
      font-family: $font-regular;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:hover,
      &:active {
        background-color: #007BE2;
        color: #FAFCFF;
        border-radius: 4px;
        font-family: $font-medium;
      }
    }
  }
  .right-menus .dropdown-menu {
    left: auto;
    right: 0;
    width: 220px;
    top: 100%;
  }
  .notifications-dropdown {
    top: $portal_nav_height;
  }

}

.portal-navbar .dropdown.nav-item .dropdown-toggle.nav-link[aria-expanded="true"] .hamburger--slider .hamburger-inner {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}

.portal-navbar .dropdown.nav-item .dropdown-toggle.nav-link[aria-expanded="true"] .hamburger--slider .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}

.portal-navbar .dropdown.nav-item .dropdown-toggle.nav-link[aria-expanded="true"] .hamburger--slider .hamburger-inner::after {
  transform: translate3d(0, -14px, 0) rotate(-90deg);
}

.ccp-nav-right-menu {
  margin-top: 9px;
}

.ccp-left-main-nav {
  margin-top: 3px;
}