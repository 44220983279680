.cms-wrapper .cms-table {
  background-color: #fff;
  >div:first-child {
    >div:nth-child(2) {
      >div {
        border-top: 1px solid $color-basic-300;
        >div {
          table {
            thead {
              tr {
                th {
                  >div {
                    height: 48px;
                    border: 0 !important;
                    >div {
                      background-color: #fff;
                      height: 48px;
                      border-bottom: 1px solid $color-basic-300;
                      border-left: 0;
                      border-right: 0;
                      border-top: 0;
                      > span {
                        color: $color-basic-600;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        font-size: 12px;
                        font-family: $font-medium;
                      }
                    }
                    .rainbow-table_header-arrow {
                      color: $color-primary-500;
                    }
                  }
                  .search-input {
                    line-height: 1;
                    padding: 2px 4px;
                    font-size: 14px;
                    border: 1px solid $color-basic-300;
                    margin-bottom: 0;
                  }
                }
              }
            }
            tbody {
              tr {
                box-shadow: 0 1px 0 0 $color-basic-300;
                >*:first-child {
                  >* {
                    padding-left: 18px;
                  }
                }
                >th, >td {
                  > div {
                    padding: 10px;
                    white-space: normal;
                    line-height: 1.4;
                    font-size: 16px;
                    color: $color-text-default;
                    border: 0 !important;
                  }
                  .btn.btn-link {
                    padding: 0;
                    text-align: left;
                  }
                  .custom-react-select .custom__control {
                    padding: 4px 8px;
                  }
                  .form-group {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  >div:nth-child(2) {
    background-color: #F9FAFE;
    border: 0;
  }
  tbody tr td:last-child {
    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      i, span {
        color: $secondary-blue;
        margin: 2px;
      }
    }
  }
}

.cms-bs-table {
  background-color: #fff;
  thead {
    tr {
      th {
        padding: 8px;
        border-top: 0;
        font-family: $font-medium;
        color: $color-basic-600;
        border-bottom: 1px solid $color-basic-300;
      }
    }
  }
  tbody {
    tr {
      box-shadow: none;
      td {
        padding: 8px;
        border-top: 1px solid $color-basic-300;
        .btn.btn-link {
          padding: 0;
          text-align: left;
        }
        .custom-react-select .custom__control {
          padding: 4px 8px;
        }
        .form-group {
          margin-bottom: 0;
        }
        &:last-child {
          box-shadow: none;
          i {
            color: $secondary-blue;
            margin: 5px !important;
          }
        }
      }
    }
  }
  &.is-top-aligned {
    tbody {
      tr td {
        padding-top: 20px;
        padding-bottom: 20px;
        vertical-align: top !important;
        .btn-link {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          display: block;
          margin-top: 4px;
        }
      }
    }
  }
  .editable-buttons {
    i {
      margin-right: 0 !important;
    }
  }
}
.cms-content {
  .MuiDataGrid-cell .MuiButton-root {
    max-width: 400px;
    display: inline-block;
    white-space: unset !important;
    line-height: 1.1 !important;
    padding: 4px 8px !important;
    height: unset;
    p {
      max-width: 100%;
      text-align: left;
    }
  }
  form > div {
    margin-bottom: 16px;
  }
}
#show-step-tr {
  background-color: #F9FAFE;
  .track-steps-list {
    margin: 30px;
    table {
      width: 100%;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 4px;
    }
  }
}

.editable-textfield-wrp {
  padding: 24px;
  border-radius: 4px;
  border: 1px solid $color-basic-300;
  .editable-buttons {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      border-radius: 12px !important;
      i {
        margin-right: 0 !important;
      }
    }
  }
}
