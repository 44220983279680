.stepLine {
	margin-bottom: 0;
	position: relative;

	/*&:before{
		content: "";
		height: 3px;
		width: 100%;
		position: absolute;
		background: #e1e1e1;
		left: 0;
		top: 39px;
	}*/
	.right-line {
		height: 3px;
		width: 80px;
		position: absolute;
		background: #e1e1e1;
		left: 20px;
		top: 8px;
	}

	li {
		float: left;
		margin-right: 60px;
		width: 20px;
		height: 20px;
		background-color: #e1e1e1;
		border-radius: 20px;
		text-align: center;
		line-height: 20px;
		position: relative;
		margin-top: 30px;
		margin-bottom: 20px;

		&:last-child {
			float: left;
			margin-right: 0;
		}

		span.tick {
			position: absolute;
			top: -30px;
			left: 0;
			color: #7f7f7f;
			min-width: 20px;
		}

		i {
			font-size: 15px;
			display: none;

			&.icon {
				display: block;
			}
		}

		.icon {
			z-index: 1;
			color: gray;
			line-height: 20px;
		}

		&.active {
			background: #FFCE33;

			i {
				display: inline-block;

				.icon {
					display: block;
				}
			}
		}

		&.completed {
			background: green;

			i {
				display: inline-block;

				.icon {
					display: block;
				}
			}
		}

		&.grey {
			background-color: #e1e1e1;
		}

		&.green {
			background: green;
		}
	}

	.probation {
		color: red;
		z-index: 2;
	}

	.probation_active {
		color: #fff;
		z-index: 2;
	}
}



.card-wrp.steplet-module {
	.MuiPaper-root {
		box-shadow: none;
		background-color: transparent;
		border: 0;

		button {
			box-shadow: none !important;
		}
	}

	>button {
		margin-right: 5px;
	}

	.form-group {
		margin-bottom: 3px;

		label {
			margin-bottom: 0;
		}
	}

	.card.bg-light {
		background-color: transparent !important;
		border: none;

		.card-body {
			padding: 0;
			background-color: transparent;
			border: none;
		}
	}

	.card-body p.mb-9.fz-12.ff-bold.fc-black {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		button {
			margin-right: 5px;
			margin-top: 2px;
			max-width: 20px;
			max-height: 20px;

			i {
				font-size: 20px;
				color: #7c7c7c;
			}
		}
	}

	.card.bg-light {
		width: 100%;
	}
}

.steplets-collapse-btn {
	border: 0;
	padding: 0;
	box-shadow: none;
	color: #000;
	font-size: 13px;
	cursor: pointer;

	i {
		font-size: 9px;
		margin-right: 3px;
	}
}

.steplet-guider {

	.guider-content {
		ul {
			margin-bottom: 0;
			list-style: disc;
			padding-left: 22px;

			li {
				margin-bottom: 4px;
				font-size: 16px;
				font-family: $font-regular;
				color: #576071;

				>div {
					position: relative;

					i {
						font-size: 4px !important;
						position: absolute;
						left: 3px;
						top: 10px;
					}
				}
			}
		}

		.ql-editor ol li:not(.ql-direction-rtl),
		.ql-editor ul li:not(.ql-direction-rtl) {
			padding-left: 1rem;
		}

		.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
			padding-left: 2rem;
		}

		.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
			padding-left: 3rem;
		}
	}

	.form-check {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		.form-check-input {
			position: relative;
			font-family: 'Mentor1to1';
			display: inline-block;
			font-size: inherit;
			font-weight: normal;
			font-style: normal;
			line-height: 1;
			text-rendering: auto;
			color: #D8D8D8;
			transition: all ease 0.3s;
			-webkit-appearance: none;
			cursor: pointer;
			font-size: 10px;
			margin-right: 13px;
			margin-top: 2px;

			&:checked {
				color: #64DD31;
			}

			&:before {
				content: '\f0e5';
				/* your icon's unicode here */
			}
		}

		.form-check-label {
			padding-bottom: 8px;
			margin-bottom: 14px !important;
			border-bottom: 1px solid #e6e6e6;
			flex-grow: 1;
			margin-right: -12px;
			padding-right: 12px;
		}
	}
}


.step-threads-list {
	.thread-items-list {
		>div:last-child {
			.copilot-note {
				border-bottom: 0;
				margin-bottom: 0;
			}
		}
	}
}

.steplet-guider {
	.custom-ql-editor-wrp .custom-ql-editor-readonly .ql-editor {
		max-height: unset;
	}
}