
.timeblock{
    margin-bottom: 15px;
    background-color: #fff;
    border: 1px solid #E9EFF5;
    border-radius: 5px;
    overflow: hidden;
    .timeblock-header {
        background-color: #FAFCFF;
        color: #576071;
        font-size: 16px;
        padding:16px 20px;
        text-align: center;
    }
    .box{
        padding: 11px 15px;
        background: #FFF;
        width: 100%;
        float: left;
    }
    .time_slot {
        font-size: 16px;
        font-family: $font-medium;
        color: #007BE2;
        padding:13px 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #E9EFF5;
        cursor: pointer;
        i {
            font-size: 16px;
            color: #007BE2;
            margin-right: 12px;
        }
    }
}
.time-list{
    .box{
        margin-bottom: 0;
        border-bottom: none;

        // &:last-child{
        //     border-bottom: 1px solid #e9e9e9;
        // }
        &.selected_slot {
            background: $blue-color;
            color: #FFF;
            font-weight: 700;
            i {
                color: #fff;
            }
        }
    }
    &.__hover{
        .box{
            &:hover{
                background: $blue-color;
                color: #FFF;
                i {
                    color: #fff;
                }
            }
            &.nohover{
                background: #FFF;
                color: #000;

            }
        }
    }
}

.enroll-confirmation-wrp {
    .select-wrp {
        width: auto;
        input {
            font-size: 14px;
            font-family: $font-regular;
            color: #374050;
            box-shadow: none;
            border: 1px solid #DAE2EB;
            background-color: #fff;
            display: inline-block;
        }
    }
}