.left-nav-listing-header {
  padding: 24px;
  border-right: 1px solid #E9EFF5;
  height: 100%;
  min-height: calc(100vh - 50px);

  .gray-bd-box {
    >div {
      width: 100%;
      position: relative;

      .editable-click {
        width: 100%;
        display: block;
      }

      i {
        font-size: 14px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: #576071;
        pointer-events: none;
      }
    }
  }

  .menu-wrp {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 20px;
    transition: all ease 0.3s;

    >div {
      display: flex;

      >span {
        display: flex;
        cursor: pointer;
        transition: all ease 0.3s;
      }
    }

    span i {
      font-size: 17px;
      color: #768091;
      cursor: pointer;
      cursor: pointer;
      transition: none;
      transform: translateX(-10px) rotate(180deg);
    }
  }

  .detail-filters-wrp {
    margin-left: -24px;
    margin-right: -24px;

    .card {
      position: relative;
      padding: 12px 24px 12px 24px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      display: inline-flex;
      justify-content: center;
      flex-direction: column;
      border: none;
      cursor: pointer;
      width: 100%;

      h2 {
        font-size: 34px;
        font-family: $font-bold;
        line-height: 1;
        margin-bottom: 0;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        div {
          font-family: $font-bold;
        }
      }

      .status-symbol {
        width: 24px;
        height: 24px;
        position: relative;
        display: inline-block;
        margin-right: 12px;

        &.is-completed {
          color: #0094F3;
        }

        &.is-disabled {
          &:before {
            content: '';
            position: absolute;
            left: 2px;
            top: 2px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1.5px solid #CCD6E2;
          }
        }

        &.is-active {
          &:before {
            content: '';
            position: absolute;
            left: 2px;
            top: 2px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1.5px solid #0094F3;
          }
        }
      }

      h4 {
        font-size: 16px;
        font-family: $font-regular;
        line-height: 1;
        margin-bottom: 0;
        color: #576071;
        font-weight: 400;
      }

      .card-body {
        padding: 0;
      }

      .indicator {
        width: 4px;
        height: 100%;
        display: block;
        position: absolute;
        left: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background: transparent;
      }

      &.active {
        .indicator {
          background: #0094F3;
        }

        h4 {
          color: #0094F3;
          font-size: 16px;
        }
      }
    }
  }

  @media(max-width: 767px) {
    flex-wrap: wrap;
    padding-left: 15px;
    padding-right: 15px;

    h1 {
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
    }

    .detail-filters-wrp {
      width: 100%;
    }
  }
}

.ivr-home-compo {
  .jumbotron {
    padding: 16px;
  }
}