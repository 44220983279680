
.alerts-wrp {
  height: 100%;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	margin-left: -24px;
	margin-right: -24px;
	padding-left: 24px;
	padding-right: 24px;
	.MuiTabPanel-root.tab-pane {
		flex-grow: 1;
    height: 0;
    overflow-y: auto;
		margin-left: -24px;
		margin-right: -24px;
		padding-left: 24px;
		padding-right: 24px;
	}
	.each-alert {
		border-bottom: 1px solid #DAE2EB;
		padding-top: 14px;
		padding-bottom: 14px;
		.alert-content {
			width: 100%;
			overflow-wrap: break-word;
  		word-wrap: break-word;
		}

		.alert-time {
			min-width: 25%;
			text-align: right;
			display: block;
		}
	}

	.blue-anchor {
		margin-top: 16px;
    color: #007BE2 !important;
	}
}

