.quality-metrics-components-wrp {
  margin-left: -24px;
  margin-right: -24px;
  border-top: 1px solid #F1F5F9;
  padding: 16px 24px;
  .gradient-loader-wrp {
    left: 24px !important;
    right: 24px !important;
    top: 16px !important;
    bottom: 16px !important;
  }
  .quality-metrics-component {
    padding-left: 18px;
    padding-right: 15px;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    padding-top: 17px;
    padding-bottom: 17px;
    display: flex;
    flex-direction: column;
    .top-line {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 6px;
      .icon-wrp {
        width: 32px;
        text-align: center;
        margin-right: 14px;
        i {
          font-size: 32px;
          color: #b3b3b3;
        }
        &.measure-active {
          i {
            color: #000;
          }
        }
      }
    }
    .btm-line {
      .btn.btn-primary {
        font-size: 14px;
        line-height: 1 !important;
        color: #007BE2;
        font-family: "Calibre-Medium";
        border: 0;
        padding: 0;
        background-color: transparent;
        outline: 0 !important;
        box-shadow: none;
        transition: all ease 0.3s;
        &:hover {
          opacity: 0.8;
        }
      }
      .btn.btn-primary.blue-round-btn {
        font-size: 14px;
        line-height: 1 !important;
        background-color: $primary-blue;
        font-family: "Calibre-Medium";
        border: 0;
        padding: 8px 20px;
        color: #fff;
        outline: 0 !important;
        box-shadow: none;
        border-radius: 4px;
        transition: all ease 0.3s;
        &:hover {
          opacity: 0.8;
        }
      }
      .btn.btn-secondary {
        font-size: 14px;
        line-height: 1 !important;
        color: #768091;
        font-family: "Calibre";
        border: 0;
        padding: 0;
        background-color: transparent;
        outline: 0 !important;
        box-shadow: none;
        transition: all ease 0.3s;
         &:hover {
          color: #000;
        }
      }
    }
    &.is-copilot {
      margin-bottom: 12px;
      cursor: pointer;
      position: relative;
      padding: 12px 12px 12px 40px;
      background-color: #fff;
      border: 1px solid #CCD6E2;
      border-radius: 12px;
      box-shadow: none !important;
      &.is-selected {
        background-color: #F9FCFE;
      }
      .color-dot {
        position: absolute;
        left: 18px;
        top: 20px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
      .top-line {
        margin-bottom: 0;
      }
    }
  }
  .expanded-content {
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: -16px;
    margin-top: 16px;
  }
  .react-datepicker-wrapper input.form-control {
    border: 1px solid #DAE2EB;
    padding: 4px 10px !important;
    border-radius: 4px;
    line-height: 1;
    height: auto;
    &:focus, &:active {
      border: solid 2px $primary-blue !important;
      box-shadow: 0 0 2px $primary-blue !important;
    }
  }
}
