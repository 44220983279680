

.call-list{
	margin-bottom: 30px;
	color: #000;
	background: #f7f7f7 !important;
	.first-col{
		position: relative;
		width: 60px;
		border-left: 2px solid #e1e1e1;
		border-bottom: none;
		border-top: none;
		span{
			position: absolute;
			width: 30px;
			height: 30px;
			text-align: center;
			line-height: 30px;
			background: #e1e1e1;
			border-radius: 30px;
			left: -16px;
			top: 10px;
			font-weight: bold;
		}
	}
	td{
		padding-left: 0 !important;
		border-bottom: 1px solid #dedede;
	}

	tr:first-child{
		td.first-col{
			border-top: 1px solid #e1e1e1;
		}
	}
	tr:last-child{
		td.first-col{
			border-bottom: 1px solid #e1e1e1;
		}
	}
	.inactive{
		color: $text-grey-color;
	}
	.hidden {
		display: none;
	}
	.block {
		display: block;
	}

	& > tbody > tr > td{
		padding: 12px 15px;
	}
}