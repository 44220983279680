.client-portal-wrp {
  background-color: #e9eff5;
  .full-col {
    .inner-col {
      width: 1376px;
      @media(max-width: 1441px) {
        width: 100%;
      }
    }
  }
  .two-columns-wrp {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .inner-col {
      width: 680px;
      // width: calc(50% - 8px);
      margin-right: 16px;
    }
    >div:last-child {
      margin-right: 0;
    }
  }
  .row.three-columns {
    margin-left: -8px;
    margin-right: -8px;
    max-width: 1393px;
    >div {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

.high-level-summary-report-compo {
  padding: 24px;
  .inner-col {
    background-color: #fff;
    border-radius: 16px;
    padding: 24px;
  }
}
.issues-report-compo {
  .inner-col {
    padding: 0;
    .top-row {
      margin-left: 0;
      margin-right: 0;
      .col-xl-4 {
        padding: 24px;
        @media(min-width: 1200px) {
          border-right: 1px solid #CCD6E2;
        }
        .graph-wrp {
          z-index: 10;
          position: relative;
        }
      }
      .col-xl-8 {
        padding: 24px;
      }
    }
  }
}
.gray-boxes-wrp {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  >div {
    min-width: 280px;
    width: calc(25% - 16px);
  }
}
.case-studies-report-compo {

}