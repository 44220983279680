.search-input {
  margin-bottom: 7px;

  input {
    width: 100%;
    display: block;
    border: 1px solid #e0e0e0;
    box-shadow: none;
    outline: 0;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
    color: #333;

    &::-webkit-search-cancel-button {
      // color: #95A0B0;
      -webkit-appearance: none;
      height: 16px;
      width: 16px;
      border-radius: 10px;
      background-image: url(../assets/images/remove-simple.svg);
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  &.is-height-32px {
    input {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
      height: 32px !important;
    }
  }
}

.tracks-sl-com {
  padding-left: 0;
  display: flex;
  flex-direction: column;

  .search-input-with-icon {
    i.icon {
      top: 40%;
    }
  }

  .col-inner-wrp {
    box-shadow: none;
  }

  .rainbow-vertical-section-overflow--show {
    max-height: 100% !important;
    background-color: #f6f9fb;
  }

  .rainbow-vertical-section-overflow_icon {
    path {
      fill: #95a0b0 !important;
    }
  }

  .rainbow-vertical-section-overflow_button {
    padding: 16px 24px;
    border-top: 1px solid #e9eff5;

    &[aria-expanded='true'] {
      background-color: #f6f9fb;
    }

    .rainbow-vertical-section-overflow_icon {
      margin-right: 1px;
    }
  }

  .rainbow-vertical-section-overflow_container--expanded {
    padding-bottom: 0;
  }

  .rainbow-vertical-section-overflow_action-description--expanded {
    display: none;
  }

  >.search-input {
    margin-bottom: 7px;

    input {
      width: 100%;
      display: block;
      border: 1px solid #e0e0e0;
      box-shadow: none;
      outline: 0;
      border-radius: 5px;
      padding: 5px;
      font-size: 14px;
      color: #333;
    }

    &.filter-dropdown-opened {
      input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .issues-search-container {
    background: #fff;
    width: 100%;
    position: absolute;
    max-height: 300px;
    overflow: auto;
    z-index: 2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    // border: 1px solid #e0e0e0;
    border: 0;
    margin-top: 0;
    margin-left: -1px;
    margin-right: -1px;

    .list-group-item {
      &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .col-inner-wrp {
    // background-color: #EBF4F9;
    // background-color: #fff;
    background-color: transparent;
    // overflow: hidden;
    min-height: 190px;
  }
}

.tracks-inner-header {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  background-color: #fff;
  z-index: 1;
  position: relative;

  .issue-select-control-wrp {
    position: relative;
    cursor: pointer;

    .custom-react-select {
      .custom__control {
        padding-left: 0;
        padding-right: 0;
        border: 0;
        min-width: 70px;

        .custom__value-container .custom__single-value {
          color: #555 !important;
        }
      }
    }
  }

  .tab-selector {
    min-width: 130px;

    .Select-control {
      border-radius: 0;
      box-shadow: none;
      color: $primary-blue;
      background-color: #fff;
      height: 24px;
      border: 0;

      * {
        box-shadow: none !important;
        outline: 0 !important;
      }

      .Select-multi-value-wrapper {
        height: 24px;
      }

      .Select-value {
        padding: 0;
        height: 24px;
        display: flex;
        align-items: center;
      }

      .Select-arrow-zone {
        padding: 0;
        display: none;
      }

      .value-render {
        // height: 35px;
        // padding-left: 5px;
        height: 24px;
        color: $primary-blue;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        line-height: 1;
        font-family: $font-medium;
        font-size: 18px;
      }
    }

    .Select-menu-outer {
      margin-top: 3px;

      .Select-option {
        color: #000;
        font-family: $font-regular;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        line-height: 1;
        padding-left: 13px;

        i {
          font-size: 14px;
          margin-right: 5px;
        }
      }
    }
  }

  .actions-wrp {
    img {
      cursor: pointer;
    }
  }
}

/**
 * Steps Tab Specific Style
 */
.steps-wrp {
  position: relative;

  .steps-left-line {
    position: absolute;
    width: 3px;
    height: 100%;
    left: 0;
    top: 0;
  }

  >div:last-child {
    border-bottom: 0 !important;
  }

  &.steps-tab {
    &.action-required-steps {
      border-top: 0;
    }

    .each-step {
      &.step-wrp-steps-tab {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #ccd6e2;
        cursor: pointer;
        background-color: #fff;
        position: relative;

        .corner-triangle {
          position: absolute;
          left: 0;
          top: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 12px 12px 0 0;
          border-color: $primary-blue transparent transparent transparent;
        }

        .step-header {
          display: flex;
          flex-direction: column;
          position: relative;

          >div {
            width: 100%;
          }

          i.icon-angle-down-fa {
            position: absolute;
            right: 0;
            top: 5px;
            font-size: 16px;
            color: #768091;
            cursor: pointer;

            &.is-collapsed {
              transform: rotate(180deg);
            }
          }
        }

        .step-inner-content {
          padding-left: 2.275rem;
        }

        .single-step-wrp {
          overflow: visible;
          padding: 1rem 1.125rem;

          &.not-collapsed {
            background-color: #f6f9fb;
          }
        }
      }
    }
  }

  .each-step {
    margin-bottom: 0;

    .triangle-right {
      opacity: 0;
    }

    .single-step-wrp {
      border-radius: 3px;
      position: relative;

      button.step-edit {
        position: absolute;
        right: 15px;
        top: 3px;
        z-index: 3;

        i.icon-step-edit {
          font-size: 12px;
          color: #768091;
        }
      }

      &.non-active-step {
        font-style: italic;
      }

      .step-header {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        transition: all ease 0.1s;
        position: relative;

        &.is-selected {
          background-color: #f6f9fc;
          border-radius: 8px;

          // color: #fff;
          .fc-768091 {
            color: #374050 !important;
          }

          .step-header-title {
            font-family: $font-medium !important;
          }

          /*
          .alert-circle {
            background-color: #B9E0FF !important;
            border: 2px solid #fff !important;
            transform: scale(0.7) translateY(-70%) !important;
          }

          .badge.is-outline.badge-warning {
            border-color: #B9E0FF !important;
            color: #B9E0FF !important;
          }

          .step-status-box {
            background-color: $secondary-blue !important;
            border-color: #fff !important;

            .step-checkbox-inner {
              color: #fff !important;
            }
          }


          .blue-text-btn {
            color: #fff !important;
          }

          .last-update-date {
            color: #B9E0FF !important;
          }

          .fc-374050,
          .fc-768091,
          .step-header-title {
            color: #fff !important;

            .font-medium {
              font-family: $font-regular;
            }
          }
          */
        }

        &.non-active-step.is-selected {
          .fc-768091 {
            color: #768091 !important;
          }

          .step-header-title {
            font-family: $font-regular !important;
          }
        }

        &:hover {
          .triangle-right {
            opacity: 1;
          }
        }

        .checkbox-wrp {
          margin-right: 16px;
          line-height: 1 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;

          // height: 1.4em;
          // margin-top: 0.21em;
          i.icon-checkboxcomplete {
            color: $primary-blue;
            font-size: 16px;
          }

          .emtpy-check-img {
            margin-top: 1px;
            width: 12px;
            height: 12px;
            cursor: pointer;
          }

          i.icon-checkboxempty {
            color: #768091;
            font-size: 13px;
            margin-top: 2.5px;
          }

          i.icon-checkbox-marked {
            font-size: 16px;
            margin-top: 1px;
          }
        }

        .p-with-checkbox {
          width: calc(100% - 25px);
        }

        .action-required-badge {
          height: 8px;
        }

        &.small-step-card {
          margin-bottom: 10px;
          width: calc(100% + 24px);
          border-radius: 8px !important;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4px 12px 4px 12px;
          margin-left: -12px;
          margin-right: -12px;

          &:hover:not(.is-selected) {
            background: #F1F5F9;
          }

          &:hover {
            .triangle-right {
              opacity: 1;
            }
          }

          .gray-text-btn {
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0;
            padding-bottom: 0;

            .plus-icon {
              font-size: 28px;
              line-height: 0;
            }
          }

          &.non-active-step {
            margin-bottom: 0;
          }

          &.inactive_step {
            background-color: #e9eff5;
          }

          .alert-circle {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #ff4e59;
            display: inline-block;
            margin-left: 3px;
            transform: scale(0.5) translateY(-70%);
          }

          &.is-closed:not(.is-skipped):not(.is-selected) {
            .step-header-title {
              text-decoration: line-through;
              color: #95a0b0 !important;
            }
          }

          &.is-selected.is-closed:not(.is-skipped) {
            .step-header-title {
              text-decoration: line-through;
            }
          }

          &.is-skipped:not(.is-close):not(.is-selected) {

            .step-header-title,
            .last-update-date {
              color: #95a0b0 !important;
              font-style: italic;
            }
          }

          &.is-skipped.is-selected {

            .step-header-title,
            .last-update-date {
              font-style: italic;
            }
          }
        }


        .badge {
          font-size: 14px;
          font-family: $font-medium;
          border-radius: 12px;
          padding: 3px 10px 4px 10px;
          color: #fff;
          border: 0 !important;
          font-weight: 400;

          &.badge-primary {
            background-color: $secondary-blue;
          }

          &.badge-warning {
            background-color: #fff6e0;
            color: #ffaa00;
          }

          &.badge-secondary {
            background-color: #007be2;
            color: #ebf6ff;
          }

          &.badge-light {
            background-color: #f6f9fc;
            color: #768091;
          }

          &.is-outline {
            border-radius: 6px !important;

            &.badge-warning {
              border: 1px solid #ffaa00 !important;
              background-color: transparent;
              color: #ffaa00;
              padding: 0 8px 2px 8px;
            }
          }
        }
      }

      .step-inner-content {
        padding-left: 1.25rem;

        .step-actions-wrp {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-bottom: 0.75rem;
          margin-top: 0.25px;

          .custom-react-select {
            height: 16px;
            min-width: 60px;
            margin-right: 0;

            .custom__control {
              height: 16px;
              min-height: 16px;
              min-width: auto;
              box-shadow: none;
              border: 0;
              padding-left: 0;
              padding-right: 0;
              cursor: pointer;

              .custom__indicators {
                display: none;
              }

              .custom__value-container {
                /*height: 24px;*/
                width: 100%;

                .Select-input {
                  display: none !important;
                }

                .custom__single-value {
                  width: 100%;
                  height: 14px !important;
                  font-size: 14px !important;
                  line-height: 1 !important;
                  color: $primary-blue !important;
                  font-family: $font-medium !important;
                  padding-top: 0px;
                  padding-bottom: 0px;

                  @media (max-width: 1380px) {
                    font-size: 12px;
                  }
                }
              }
            }

            .custom__menu {
              width: 150px;
            }
          }
        }

        .steplet-content {
          margin-bottom: 0.75rem;
          background: white;
          padding: 0;
        }
      }

      &.not-collapsed {
        .step-header.small-step-card {
          .icon-caret-right {
            transform: rotate(90deg);
            top: 9px;
          }
        }
      }
    }
  }
}

.border-radius-4 {
  border-radius: 4px;
}

/**
 * Escalate Modal Style
 */
.escalate-modal-inner {
  padding: 24px;
  // height: 100vh;
  overflow-y: auto;
  background-color: #fff;

  .modal-title-header {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;

    margin-bottom: 16px;

    h3 {
      font-size: 26px;
      font-family: $font-medium;
      color: #374050;
    }
  }

  form>div {
    margin-bottom: 24px;
  }

  .escalate-scroll-wrp {
    height: calc(100vh - 146px);
    overflow-y: auto;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 60px;

    .crcw.cr_CheckboxGroup {
      >fieldset {
        >div {
          >div {
            /// each option wrapper
            margin-bottom: 16px;

            label {
              margin-bottom: 0;
              display: block;
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              cursor: pointer;

              .rainbow-input_faux {
                margin-top: 2px;
                width: 16px;
                height: 16px;
                margin-right: 8px;
                flex-shrink: 0;
                background-color: #fff !important;
                border: 1px solid $secondary-blue;
                border-radius: 4px !important;
              }

              >span:last-child {
                line-height: 125%;
              }
            }

            input[type='checkbox']:checked~label>.rainbow-input_faux {
              background-color: $secondary-blue !important;
              box-shadow: none !important;

              &:after {
                border-color: #fff;
                transform: translate3d(-50%, -50%, 0) rotate(-45deg) scale(0.8);
              }
            }
          }

          >div:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .crcw.cr_Textarea>div textarea {
      background: #ffffff;
      border: 1px solid #e0e8f0 !important;
      border-radius: 8px;
      padding: 14px 12px;
    }
  }

  .escalate-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90px;
    background-color: #fff;
    border-top: 1px solid #e0e8f0;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;

    .btn.btn-primary {
      margin-right: 0;
      font-size: 16px;
      font-family: $font-medium;
      padding: 10px 19px;
    }
  }

  .form-label {
    font-size: 16px;
    font-family: $font-medium;
    color: #374050;
  }

  .form-group {

    input,
    textarea {
      outline: 0 !important;
      box-shadow: none !important;
      border: 1px solid #ebebeb;
      padding: 5px 10px;
    }
  }

  button.btn {
    margin-right: 15px;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 16px;
    line-height: 1;
  }

  .buttons-group {
    margin-right: 15px;
    padding-right: 15px;
    position: relative;

    label {
      border: 1px solid #ccd6e2;
      background-color: transparent;
      color: #576071;
      font-family: $font-medium;
      box-shadow: none !important;
      outline: 0 !important;
      user-select: none;
      font-size: 16px;
      padding: 8px 10px;
      line-height: 1;
      border-radius: 4px;
      margin-right: 4px;

      &.active {
        color: #fff;
        background-color: $primary-blue;
        border-color: $primary-blue;
      }
    }
  }
}

/**
 * Tracks Tab Specific Style
 */
.tracks-wrp {
  padding: 0;
  padding-bottom: 200px;

  .track-content {
    padding-left: 16px;
  }

  .rainbow-vertical-section-overflow_button {
    position: relative;

    .corner-triangle {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 12px 0 0;
      border-color: $primary-blue transparent transparent transparent;
    }

    .rainbow-vertical-section-overflow_action-label {
      display: block;
      width: 100%;
      text-align: left;
    }
  }

  .rainbow-vertical-section-overflow_button:focus {
    background-color: #fff;
  }

  .each-track-wrp {
    margin-bottom: 8px;
    border-radius: 4px;
    overflow: hidden;
  }

  .each-track-btn {
    .track-meta {
      flex-wrap: wrap;
      margin-top: 0;

      .fg1 {
        flex-grow: 1;
        padding-left: 15px;
        text-align: right;
      }

      .fz-12 {
        line-height: 1 !important;
      }
    }
  }

  .rainbow-vertical-section-overflow--show {
    position: relative;
    z-index: 2;
  }

  .each-track {
    .track-header {
      padding: 0;
      border-top-width: 5px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      .title-wrp {
        width: 100%;

        .title-desc-wrp {
          width: 100%;
        }
      }

      .track-edit {
        position: absolute;
        right: 12px;
        top: 24px;

        i {
          color: #374050;
        }
      }
    }

    .track-step-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 12px;
      margin-right: 12px;
      margin-top: 5px;
      margin-bottom: 5px;

      .blue-anchor {
        margin-top: 6px;
      }

      i {
        font-size: 16px;
      }
    }
  }
}

.issues-wrp {
  padding-left: 0;
  padding-right: 0;
  color: #000;
  background-color: transparent;
  position: relative;

  .add-issue-btn {
    position: absolute;
    right: 11px;
    top: -40px;
    background-color: transparent;
    padding: 0;
    z-index: 2;

    i {
      font-size: 14px;
      color: $primary-blue;
      line-height: 20px;
    }
  }

  >div:last-child {
    border-bottom: 0;
  }

  .each-issue {
    background-color: #fff;
    position: relative;
    margin-top: 8px;
    border-radius: 12px;
    box-shadow: 0px 2px 6px rgba(23, 32, 46, 0.1);

    .rainbow-vertical-section-overflow_button {
      padding-left: 24px;

      @media (max-width: 1600px) {
        padding-left: 16px;
      }
    }

    .corner-triangle {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 12px 0 0;
      border-color: $primary-blue transparent transparent transparent;
    }

    .relative.issue-expanded {
      .border-line {
        border-bottom-left-radius: 0;
      }
    }

    .blue-anchor {
      margin-top: 0;
    }

    .active-track-cnt {
      padding: 14px 24px;
      background-color: transparent;
      border-top: 1px solid #dee2e6 !important;
      display: flex;
      align-items: center;
      justify-content: space-between;

      >span {
        margin-right: 10px;
      }

      .add-track-btn {
        padding: 0;
        border: 0;
        background-color: transparent;

        i {
          color: $primary-blue;
          font-size: 14px;
        }

        &:hover {
          background-color: transparent;
          color: $secondary-blue;
        }
      }
    }

    .issue-content.collapse.show {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      overflow: hidden;
    }

    .single-track-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      /*padding-left: 27.5px;*/
      margin-bottom: 18px;

      .bold-cap {
        font-size: 13px;
        color: #000;
        opacity: 0.7;
        font-family: $font-regular;
      }

      .light-cap {
        font-size: 13px;
        line-height: 15px;
        font-family: $font-regular;
        color: #000;
      }
    }

    .steps-wrp {
      .step-header {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }

      @media (max-width: 1560px) {
        margin-left: 0;
      }
    }

    .track-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0;
      margin-top: 2px;
      background-color: #fbfbfb;
      margin-left: -12px;
      margin-right: -12px;
    }
  }
}

.issue-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: $font-regular;
  flex-wrap: wrap;
  width: 100%;

  .btn-switch-tab {
    font-size: 14px;
    font-family: $font-regular;
    color: #768091;
    line-height: 1;
  }

  .round-tag {
    font-size: 13px;
    font-family: $font-regular;
    background: #f6f9fc;
    border-radius: 16px;
    margin-right: 8px;
    color: #576071;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1;
    margin-top: 3px;
  }
}

.meta-nums {
  font-size: 14px;
  font-family: $font-medium;
  color: #768091;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .meta-num {
    display: flex;
    align-items: center;
    margin-left: 16px;

    i {
      margin-right: 7px;
      font-size: 14px;

      &.icon-notification-bell {
        font-size: 18px;
      }
    }

    span {
      line-height: 1 !important;
    }
  }
}

// for modal content styles
.modal-inner-content {
  .steps-wrp {
    border-top: none;
    padding: 0;

    .each-step {
      display: flex;
      align-items: center;
    }
  }

  .tracks-wrp {
    border-top: none;

    .each-track {
      border-bottom: none;
    }
  }
}

.edit-step-modal {
  position: fixed;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  background-color: #fff;
  padding-bottom: 0;
  width: 300px;

  // overflow: hidden;
  h3 {
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid $gray-border;
    align-items: center;
    display: flex;
    padding-top: 8px;
    margin-bottom: 0;
  }

  form {
    padding: 20px 18px;

    label {
      font-size: 15px;
      font-family: $font-regular;
      line-height: 17px;
      font-weight: 400;
      color: $gray-text;
      padding-left: 15px;
    }

    .Select-control {
      height: 38px;
      border-radius: 19px;
      background-color: #f2f4f7;
      border: 0;

      .Select-placeholder {
        padding: 6px 100px 5px 20px;
        color: $gray-text;
        line-height: 26px;
        font-size: 15px;
      }

      .Select-input {
        padding-left: 20px;
        padding-right: 20px;
      }

      .Select-value {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 1px;

        .Select-value-label {
          font-size: 15px;
        }
      }
    }

    input[type='text'] {
      height: 38px;
      border-radius: 19px;
      background-color: #f2f4f7;
      padding-left: 19px;
      width: 100%;
      border: 0;
      outline: none !important;
      font-size: 15px;
      font-family: $font-regular;
      padding-right: 19px;
      box-shadow: none;
    }

    .fz-11 {
      padding-left: 19px;
      padding-top: 3px;
    }

    .Select-menu-outer {
      margin-left: 14px;
      margin-right: 14px;
      width: calc(100% - 26px);
    }

    .round-button {
      width: 90px;
    }

    .cancel-btn {
      width: 90px;
      color: $gray-text;
      font-size: 15px;
      font-family: $font-bold;
      letter-spacing: -0.08px;
      line-height: 31px;
    }
  }

  .btm-wrp {
    background-color: #fafbfd;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    p {
      padding-left: 18px;
      padding-right: 18px;
      height: 40px;
      margin-bottom: 0;
      padding-top: 15px;
    }

    .bd-bt-gr {
      padding-bottom: 15px;
      height: auto;
    }
  }

  // date picker modal style
  .engaged-modal-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    .step-due-dp {
      width: 320px;
      background-color: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

      .inner-header {
        background-color: #7ac143;
        color: #fff;

        .weekday {
          background-color: rgba(0, 0, 0, 0.1);
          padding: 10px 10px 7px 10px;
          font-size: 16px;
          line-height: 21px;
          text-align: center;
          font-family: $font-bold;
        }

        .dark-wrp {
          padding-top: 10px;
          padding-bottom: 6px;

          .month {
            text-align: center;
            padding: 7px 10px 9px 10px;
            font-size: 26px;
            line-height: 1;
            font-family: $font-bold;
          }

          .day {
            text-align: center;
            padding: 10px 10px 0 10px;
            font-size: 80px;
            line-height: 1;
            font-family: $font-bold;
          }

          .year {
            text-align: center;
            padding: 7px 10px 9px 10px;
            font-size: 26px;
            line-height: 1;
            font-family: $font-bold;
            opacity: 0.54;
          }
        }
      }

      .picker-main {
        background-color: #fafafa;
      }

      .actions {
        background-color: #fafafa;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 30px;
        padding-bottom: 18px;

        button {
          font-size: 16px;
          font-family: $font-bold;
          color: #979797;
          text-transform: uppercase;
          text-decoration: none;
          padding-left: 24px;

          &:hover {
            color: #0094f3;
          }
        }
      }
    }
  }
}

.add-track-modal {
  background-color: #f6f9fb;
  height: 100vh;
  overflow-y: auto;

  .modal-title-header {
    border-bottom: 1px solid #ccd6e2;
    padding: 16px 21px;

    >* {
      display: block;
      width: 100%;
    }
  }

  .scroll-wrp {
    padding: 26px 21px 29px 21px;
    height: calc(100vh - 180px);
    overflow-y: scroll;
  }

  // .round-button {
  //   font-family: $font-medium;
  //   font-size: 16px;
  //   height: auto !important;
  //   padding: 6px 12px;
  // }

  .modal-fixed-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: #f1f5f9;
    border-top: 1px solid #ccd6e2;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;

    >.btn.btn-primary {
      font-family: $font-medium;
      font-size: 16px;
      background-color: $primary-blue;
      height: 36px;
      padding: 4px 10px;
      width: auto;
      margin-bottom: 0;
    }

    >.btn.btn-secondary {
      font-family: $font-medium;
      font-size: 16px;
      color: #576071;
      border: 1px solid #ccd6e2;
      border-radius: 4px;
      background-color: transparent;
      height: 36px;
      padding: 4px 10px;
      width: auto;
      margin-bottom: 0;
    }
  }

  &.search-result {
    .scroll-wrp {
      padding: 0 24px 24px 24px;
      height: calc(100vh - 90px);
    }
  }
}

.add-track-card {
  border-radius: 8px;
  margin-bottom: 8px;
  border: 1px solid #e9eff5;
  padding: 12px 16px;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  .inner-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 3px;

    .track-title {
      margin-right: 14px;
      display: block;
      width: calc(100% - 15px);
    }
  }

  &.is-es {
    .inner-header {
      .track-title {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  .inner-desc {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    i {
      color: #768091;
      font-size: 14px;
      margin-right: 6px;
    }
  }
}

/**
 * steplet modules style
 */
.popover {
  padding: 12px 10px;
  z-index: 1001;

  .form-group {

    input,
    textarea {
      outline: 0 !important;
      box-shadow: none !important;
      border: 1px solid #ebebeb;
      padding: 5px 10px;
    }

    input {
      height: 30px;
    }
  }

  .btn.btn-link {
    color: #576071;
    font-size: 16px;
    font-family: $font-medium;
    padding: 4px 6px !important;
    display: block;
    width: 100%;
    min-width: 140px;
    text-align: left;

    &:hover {
      background-color: #f6f9fb;
      border-radius: 4px;
    }
  }

  button:last-child {
    margin-bottom: 0 !important;
  }

  .margin-left-s {
    margin-left: 6px !important;
  }

  .margin-right-s {
    margin-right: 6px !important;
  }

  .btn.btn-link {
    padding: 0;
    border: 0;
    margin-bottom: 8px;

    &:hover {
      text-decoration: none !important;
    }
  }
}

.col-inner-wrp>.steps-wrp>.each-step .single-step-wrp .steplet-wrp .steplet-content>.card-wrp {
  margin-left: -4.1875rem;
  margin-top: 1.25rem;
}

.search-input-with-icon {
  position: relative;
  margin-bottom: 6px;

  i.icon {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 15px;
    color: #000;
    opacity: 0.42;
    z-index: 2;
  }

  .search-input {
    input {
      padding-left: 29px !important;
      padding-right: 16px;
      box-shadow: none;
      font-size: 15px !important;
      opacity: 0.9;
      line-height: 15px !important;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0510817);
      border-radius: 3px;
      border: 0;
      height: 40px;

      &::placeholder {
        font-size: 15px;
      }
    }
  }
}

.track-step-actions {
  button {
    border: 0.5px solid #d9d9d9 !important;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 9px 22px;
    font-family: $font-medium;
    text-transform: uppercase;
    background-color: transparent !important;
    color: #595959 !important;
  }
}

button {
  user-select: none !important;
}

.non-fixed-steplets {
  background-color: #e9eff5;
  border-radius: 4px;
  padding: 8px 10px;
  display: flex;
  margin-bottom: 12px;

  .other-steplets-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    /*
    .steplet-container {
      margin-left: 11px;
      margin-right: 11px;

      button.nobd.nopd {
        font-size: 16px;
        color: #374050;
        font-family: $font-regular;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 1 !important;

        i {
          color: #768091;
          font-size: 12px;
          margin-left: 4px;
          margin-right: 4px;
          margin-top: 1px;
        }
      }
    }
    */
  }
}

.track-content {
  padding: 0 16px 0px 38px;

  @media (max-width: 1600px) {
    padding-left: 16px;
  }
}


.tracks-wrp .steps-wrp .each-step .single-step-wrp .step-inner-content,
.issues-wrp .steps-wrp .each-step .single-step-wrp .step-inner-content {
  padding-left: 2.275rem;
}

.single-step-thread {
  position: relative;
  margin-bottom: 16px;
  padding-bottom: 16px;

  .icon-trash-fill {
    position: absolute;
    right: 0;
    top: 6px;
    cursor: pointer;
    color: #95a0b0;
    font-size: 12px;
  }
}

.ta-wrp-w-btn {
  margin-bottom: 12px;

  .por {
    position: relative;
    margin-top: 10px;
  }

  textarea {
    border-radius: 8px !important;
  }

  &.when-focus {
    position: relative;

    textarea {
      padding-bottom: 32px !important;
      min-height: 130px;
      border-radius: 8px !important;
    }

    .action-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      left: 5px;
      position: absolute;
      right: 15px;
      bottom: 0;
      z-index: 99;

      button {
        margin-right: 5px;
      }

      .crcw.cr_CheckboxGroup {
        margin-top: 10px;
      }
    }

    >.action-btns {
      bottom: 8px;
    }
  }

  &.in-notes-to-admin {
    border: 1px solid #e9eff5 !important;
    border-radius: 8px !important;

    ul {
      list-style: none;

      li {
        list-style: none;
      }
    }

    textarea {
      background-color: transparent !important;
      border: 0 !important;
    }

    &.when-focus {
      position: relative;
      padding-bottom: 70px;
    }

    .action-btns {
      >button {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }
}

.ta-wrp-w-btn-wo-bd {
  margin-bottom: 12px;

  .por {
    position: relative;
    margin-top: 10px;
  }

  &.one-line {
    textarea {
      max-height: 36px !important;
    }
  }

  &.when-focus {
    position: relative;

    textarea {
      padding-bottom: 20px !important;
      min-height: 90px;
    }

    .action-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      left: 15px;
      position: absolute;
      right: 15px;
      bottom: 30px !important;

      .gray-text-btn {
        margin-left: 15px;
        margin-right: 15px;
      }

      .crcw.cr_CheckboxGroup {
        margin-top: 10px;
      }
    }
  }
}

.step-tab-content {
  min-height: 100px;

  .ta-wrp-w-btn-wo-bd {
    &.when-focus {
      .action-btns {
        bottom: 6px !important;
      }
    }
  }
}

.action-required-badge {
  border-radius: 7px;
  height: 8px;
  width: 16px;
}

.category-tag {
  color: #59606f;
}

.relative {
  position: relative;
}

.issues-categories-wrp {
  padding: 16px 16px 6px 16px;
  background-color: #fff;

  .issue-category-btn {
    height: 24px;
    border-radius: 4px;
    font-size: 14px;
    font-family: $font-regular;
    color: #fff;
    line-height: 20px;
    margin-right: 10px;
    padding: 0px 7px 2px 7px;
    margin-bottom: 10px;
    user-select: none;
  }
}

.tracks-filters-wrp {
  padding: 3px 16px 16px 16px;
  background-color: #fff;
  margin-bottom: 8px;

  .badge {
    border-radius: 4px;
    font-size: 14px;
    font-family: 'Calibre';
    color: #fff;
    line-height: 16px;
    margin-right: 10px;
    padding: 4px 7px 5px 7px;
    font-weight: 400;
    margin-bottom: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    white-space: unset;
    text-align: left;
  }
}

.steps-filters-wrp {
  padding: 3px 16px 16px 16px;
  background-color: #fff;
  border-bottom: 1px solid #ccd6e2;

  .badge {
    height: 24px;
    border-radius: 4px;
    font-size: 14px;
    font-family: $font-regular;
    color: #fff;
    line-height: 20px;
    margin-right: 10px;
    padding: 1px 7px 2px 7px;
    font-weight: 400;
    margin-bottom: 10px;
    user-select: none;
    cursor: pointer;
  }
}

.tracks-sl-com .rainbow-vertical-section-overflow_button[aria-expanded='true'] {
  background-color: #f6f9fb !important;
}

.tracks-sl-com .rainbow-vertical-section-overflow_button[aria-expanded='false'] {
  background-color: #fff !important;
}

.tracks-sl-com .rainbow-vertical-section-overflow_button .rainbow-vertical-section-overflow_action-label {
  display: block;
  width: 100%;
}

.tracks-sl-com .rainbow-vertical-section-overflow_icon {
  margin-top: 0.425rem;
}

.red-badge {
  width: 16px;
  height: 16px;
  border-radius: 12px;
  font-size: 13px;
  font-family: $font-medium;
  color: #F5000F;
  line-height: 16px;
  background-color: #FEF1F2;
}

.hover:hover {
  font-family: $font-medium !important;
}

.col-inner-wrp {

  >.tracks-wrp,
  >.steps-wrp,
  >.issues-wrp {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    // overflow: hidden;
  }
}

.inactive-step-status {
  display: none;
  position: absolute;
  top: 0px;
  right: 0px;

  padding: 10px 10px 0 0;
}

.empty-checkbox {
  height: 16px;
  width: 16px;
  border: 1px solid #95a0b0;
  box-sizing: border-box;
  border-radius: 4px;
  display: block;
  margin-top: 1px;
  background: #95a0b0;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  i.icon-check,
  i.icon-step-check {
    font-size: 16px;
    line-height: 1 !important;
    color: #fff;
    transform: scale(0.5);
  }

  span {
    display: block;
    width: 16px;
    text-align: center;
    height: 16px;
  }

  &.color-blue {
    background-color: $secondary-blue;
    border-color: $secondary-blue;
  }
}

.empty-checkbox-complete {
  background: $secondary-blue;
  border: 1px solid $secondary-blue;
}

.generic-plus-icon {
  position: relative;
  display: block;
  margin-left: 8px;
  margin-right: 7px;

  // margin-top: 2px;
  .horizontal-plus-line {
    position: absolute;
    border: 1px solid #768091;
    height: 2px;
    width: 14px;
    box-sizing: border-box;
    border-radius: 4px;
    display: block;
    margin-top: 1px;
    left: -6px;
    top: 5px;
  }

  .vertical-plus-line {
    border: 1px solid #768091;
    height: 14px;
    width: 2px;
    box-sizing: border-box;
    border-radius: 4px;
    display: block;
    margin-top: 1px;
  }
}

.each-track {
  .pg-wrp {
    width: 100%;
    height: 2px;
    margin-top: 8px;
    position: relative;
    background-color: #e9eff5;

    .value-bar {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-color: $secondary-blue;
    }
  }
}

.step-status-box {
  width: 16px;
  height: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  flex-shrink: 0;
  margin-top: 3px;
  cursor: pointer;
  margin-left: -1px;

  .inactive-step-label {
    border: 1.5px solid #95A0B0;
    width: 12px;
    height: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #95A0B0;
    border-radius: 3px;
    font-size: 12px;
  }
}

.active-inactive-divider {
  height: 15px;
  width: 100%;
  border-top: 1px solid #e9eff5;
  background-color: #e9eff5;
}

.w-down-arrow {
  position: relative;
  padding-right: 15px;
  margin-right: 15px;

  &:after {
    content: '\f10d';
    color: $primary-blue;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Mentor1to1';
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    text-rendering: auto;
    pointer-events: none;
    padding-top: 3px;
  }
}

.hide-line {
  &:before {
    background-color: transparent !important;
  }
}

.alert-count {
  position: absolute;
  right: -12px;
  top: -12px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background-color: #ff4e59;
  color: #fff;
  font-size: 14px;
  font-family: $font-medium;
  border-radius: 4px;
}

.issue-item-with-tracks {
  border: 1px solid #CCD6E2;
  border-radius: 12px;
  padding: 0;
  position: relative;

  &.is-expanded {
    .each-issue-header {
      border-bottom: 1px solid #e9eff5;
    }

    .track-content {
      border-left: 0;
      border-right: 0;
      border-top: 0;
      border-bottom: 1px solid #e9eff5;
      margin-bottom: 0;
      border-radius: 0;
    }

    >div:last-child {
      border-bottom: 0;
    }
  }

  .status-feedback-compo {
    border: none;
  }
}

.issue-new-message {
  border-radius: 16px;
  background-color: #fdeced;
  font-size: 14px;
  font-family: $font-medium;
  line-height: 1;
  color: #ff4e59;
  padding: 2px 8px 4px 8px;
  margin: 0;
  flex-shrink: 0;
}

.action-request-msg {
  border-radius: 6px;
  background-color: #fdeced;
  font-size: 14px;
  font-family: $font-medium;
  line-height: 1.4;
  color: #ff4e59;
  padding: 12px 16px;
  flex-shrink: 0;
}

.each-issue-header {
  padding: 12px 16px;

  .issue-category {
    position: relative;
    font-family: $font-medium;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    line-height: 1 !important;

    .circle-ind {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 55%;
      transform: translateY(-50%);
    }

    .issue-new-message {
      margin-top: 3px;
    }
  }

  .btn-toggle-important {
    margin-top: 1px;
    display: flex;
  }

  &.add_issue {
    border: 1px solid #CCD6E2;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 8px;

    .add-issue-title-wrp {}
  }
}

.track-header-card {
  padding: 14px 20px 14px 20px;
  border-bottom: 1px solid #e9eff5;
  transition: all ease 0.3s;

  &.copilot {
    padding: 12px 16px;
    margin-bottom: 12px;
    border: 1px solid #CCD6E2;
    border-radius: 12px;

    &.is-expanded {
      background-color: #fff;
      // background-color: #F9FCFE;
    }

    .gray-border-box {
      border: 0 !important;
      padding: 0 !important;
      margin-bottom: 12px;
    }
  }


}



.modal-with-close-btn {
  .card-header-tabs {
    margin-bottom: 0 !important;
  }

  .custom-styled-tabs.nav.nav-tabs {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;

    &+.tab-content {
      height: calc(100vh - 130px);
      overflow-y: auto;
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .btn-modal-close {
    position: absolute;
    z-index: 2;
    right: 15px;
    top: 15px;
  }

  .card-header {
    padding: 12px 24px;

    .btn-modal-close {
      top: 24px;

      i {
        font-size: 9px !important;
      }
    }
  }

  .card-body {
    padding: 24px 24px;
  }

  &.with-no-title-border {
    .card-header {
      border-bottom: 0;
      padding-bottom: 0;
      padding-top: 24px;

      .btn-modal-close {
        top: 28px;
      }
    }

    .card-body {
      padding-top: 16px;
    }
  }
}

.status-feedback-compo {
  border: 1px solid #e0e8f0;
  border-radius: 8px;
  padding: 16px;
  transition: all 0.3s ease;
  margin-bottom: 24px;

  &.is-expanded {
    background-color: #f9fcfe;
  }

  .sf-header {
    padding-right: 0px;
    position: relative;

    .track-title {
      position: relative;

      .check-icon {
        position: absolute;
        right: 0;
        
      }
    }

    .toggle-btn {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;

      i {
        color: #95a0b0;
        font-size: 16px;
      }

      &.is-expanded {
        transform: rotate(180deg);
      }
    }
  }

  .round-items-wrp {
    position: relative;
    display: flex;
    justify-content: center;

    &:before {
      content: '';
      left: 30px;
      right: 30px;
      height: 2px;
      top: 11px;
      background-color: #ccd6e2;
      position: absolute;
      z-index: 1;
    }

    >div {
      position: relative;
      z-index: 2;
    }

    >div:first-child {
      margin-left: 0;
    }

    >div:last-child {
      margin-right: 0;
    }

    .each-item1 {
      width: 20%;
      min-width: 70px;
      border: 0;
      background-color: transparent;
      padding: 2px !important;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      position: relative;

      &:before {
        content: '';
        border-radius: 50%;
        width: 24px;
        height: 24px;
        left: 50%;
        top: -2px;
        transform: translateX(-50%);
        position: absolute;
        background-color: #f9fcfe;
        z-index: 1;
      }

      .circle-btn {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid #ccd6e2;
        display: inline-block;
        z-index: 2;
        position: relative;

        svg {
          color: #fff;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      span.fz-14 {
        text-align: center;
        margin-top: 10px;
      }

      &.active {
        span.fz-14 {
          font-family: $font-medium;
        }

        .circle-btn {
          border-color: $secondary-blue;
          background-color: $secondary-blue;
          color: #fff;
        }
      }
    }
  }
}

.round-tag-step {
  background-color: #0094f3;
  border-radius: 12px;
  color: #fff;
  padding: 2px 10px 4px 10px;
  font-size: 14px;
  font-family: $font-medium;
  line-height: 1;
}

.track-title-compo {
  .track-status-badge {
    background: #EBF4FF;
    border-radius: 16px;
    height: 22px;
    padding: 0px 8px 2px;
    color: #006DEB;
    font-size: 14px;
    font-family: $font-medium;
  }
}

.red-dot-badge {
  background: #FF4753;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-top: 1px;
}

.col-wrp.is-level-three {
  .track-content {
    .each-track {
      margin-left: -12px;
      margin-right: -12px;
    }
  }
}