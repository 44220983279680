// .mentee-form{
//     width: 100%;
//     .form-group{
//         margin-bottom: 15px;
//         display: flex; 
//         & > label{
//             width: 300px;
//             margin-top: 5px;
//             padding-right: 10px;
//             font-size: 14px;
//             color: #576071;
//             font-family: $font-regular;
//             &.margin-none{
//                 margin: 0;
//             }

//             &.multiline{
//                 margin-top: -1px;
//             }
//         }
//     }
//     .help-block{
//         color: #a94442;
//     }
// }
.form-title{
    margin-bottom: 30px;
}
.form-error{
    color: #ed3021;
}
.Select--multi .Select-value {
    border: 1px solid #DAE2EB;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 5px;
    background: $secondary_blue;
    border-radius: 4px;
    span {
        color: #fff !important;
        border-right: 0; 
        &.Select-value-label {
            font-size: 14px;
            font-family: $font-regular;
        }
    }
}
.enroll-each-step-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px; 
    margin-left: -24px;
    margin-right: -24px;
    border-bottom: 1px solid #E9EFF5; 
}