.mentor-payment-log {
  background-color: #fff;
  border-radius: 16px; 
  padding: 24px;
  .inner-wrp {
    background-color: #fff;
    margin-top: 5px;
    border-radius: 5px;

    .green-header {
      background-color: #72BB53;
      padding: 15px 24px;
    }

    .inner-main {
      padding: 15px 24px;

      table.custom-styled.white-head {
        border: 0;

        thead {
          border-bottom: 1px solid #ececec;
        }

        tbody {
          tr {
            border-bottom: 0;

          }

          tr:nth-child(2n+1) {
            background: #fbfbfb;
          }
        }
      }
    }
  }

  .light-gray-bg {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    border-top: 1px solid #ECECEC;
    padding-top: 22px;
    margin-top: 20px;

  }

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    width: auto;

    >.row {
      margin-left: 0;
      margin-right: 0;

      >div {
        padding: 0;
        margin: 0;
        &.pending-column {
          padding-bottom: 0;
        }
      }
    }
  }
}