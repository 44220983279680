.materials-sent-list,
.logistical-issues-list {
  padding: 0;
  background-color: #fff;
  margin-left: -18px;
  margin-right: -18px;
  // height: 100vh;
  // overflow: auto;

  .each-item {
    padding: 16px 24px;
    background-color: #fff;
    border-bottom: 1px solid #E9EFF5;

    .item-heading {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;


      .item-date {
        font-size: 14px;
        font-family: $font-regular;
        color: #768091;
        width: 150px;
        text-align: right;
      }

    }

    .mt-content {
      .gray-box {
        padding: 20px;
        margin-top: 16px;

        i {
          min-width: 25px;
        }
      }
    }

    .badge {
      font-size: 14px;
      color: #fff;
      font-family: $font-medium;
      padding: 6px 15px 7px 15px;
      border-radius: 4px !important;
    }

    .rainbow-button-icon--large {
      width: auto;
      height: auto;
      border: none !important;
      box-shadow: none !important;
    }

    .editable-click.btn.btn-link {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .related-info {
    .each-relation {
      display: flex;
      border-bottom: 1px solid #E9EFF5;
      padding-top: 12px;
      padding-bottom: 16px;

      .relation-name {
        width: 70px;
      }

      .relation-value {
        width: calc(100% - 70px);
      }
    }

    >div:last-child {
      border-bottom: 0 !important;
    }
  }
}

.new-goal {
  padding: 10px 24px;
  background-color: #fff;
  border-bottom: 1px solid #E9EFF5;
}

.guiders {
  border-top: 1px solid #E9EFF5;
  padding-top: 10px;

  ul {
    margin-bottom: 0;
    list-style: disc;
    padding-left: 22px;

    li {
      margin-bottom: 4px;
      font-size: 16px;
      font-family: $font-regular;
      color: #576071;

      >div {
        position: relative;

        i {
          font-size: 4px !important;
          position: absolute;
          left: 3px;
          top: 10px;
        }
      }
    }
  }


}


/**
 * updated design
 */
.cop-sidebar-materials {
  padding: 16px 24px;

  .each-item {
    border: 1px solid #CCD6E2;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 16px !important;

    &.is-expanded {
      background-color: #F6F9FC;
    }

    .item-header {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .icon-wrp {
        margin-right: 12px;
        width: 24px;
        flex-shrink: 0;
        svg {
          color: #576071;
        }
      }
    }

    .mt-content {
      position: relative;
      margin-top: 8px;
    }
  }
}