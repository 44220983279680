.recent-calls-compo {
	background-color: transparent; 	
	line-height: 1;
	h5 {
		font-size: 16px;
		line-height: 18px;
		font-family: $font-bold;
		color: #000; 
	}
	.blue-anchor.btn.btn-link {
		margin-top: -12px;
	}
	.each-recent-call {
		font-family: $font-regular;
		margin-top: 12px;
		margin-bottom: 12px;
		line-height: 1;
		display: flex;
		align-items: center;
		.icon-wrp {
			line-height: 1;
			i {
				font-size: 16px;
				margin-right: 10px;
				color: $snapshot_blue;
				cursor: pointer;
			}
		}
		.call-info-wrp {
			display: flex;
			flex-grow: 1; 
			align-items: center;
			justify-content: space-between;
			.calltime {
				display: inline-flex; 
				justify-content: flex-start;
			}
			.call-duration {
				font-size: 16px;
				line-height: 14px;
				text-align: left; 
				color: #576071; 
				font-family: $font-regular;
			}
		}
		
	}
}