.phase-mangement-compo {
  border-bottom: 2px solid #E9EFF5;
  display: flex;
  >div {
    width: 50%;
  }
  .behavior-inner {
    padding: 20px 0 15px 0;
    border-bottom: 1px solid #ebebeb;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    i {
      font-size: 18px;
      color: #7F7F7F;
      margin-top: -2px;
    }

    .behavior-title {
      margin-left: 16px;
    }

    .key-behavior {
      margin-left: 16px;
    }
  }

  .completed-challenges {
    .behavior-inner {
      align-items: flex-start;

      .behavior-number {
        margin-top: -5px;
      }

      i {
        margin-left: 15px;
        // margin-top: 3px;
      }
    }
  }

  .behaviors-accordion {
    .behavior {
      width: 100%;

      .behavior-inner {
        align-items: flex-start;
        // padding-left: 15px;
        // padding-right: 15px;
        padding-left: 0;
        position: relative;
        border-bottom: 1px solid #ebebeb;
        ;

        .behavior-number {
          margin-top: -4px;
        }

        i.icon-bars {
          margin-left: 15px;
          margin-top: 0 !important;
        }

        .tag {
          // position: absolute;
          // right: 20px;
          // top: 20px;
          border-radius: 5px;
          margin-left: 30px;
          font-family: $font-medium;
          font-size: 15px;
          line-height: 1;
          padding-top: 5px;
          padding-bottom: 3px;
        }

        .remove-btn {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 30px;
          display: flex;

          i {
            line-height: 1 !important;
          }
        }
      }
    }

    .panel-content {
      padding: 10px 15px;

      .custom-placeholder-input {
        margin-bottom: 10px;

        textarea {
          background-color: #fff;
        }
      }

      .behavior-actions {
        .btn-group {
          button {
            padding-left: 0;

            &.active {
              font-family: $font-medium !important;
              color: #000 !important;
            }
          }
        }
      }
    }
  }

  .enroll-note-for-mentor {
    margin-bottom: 36px;

    textarea {
      padding-left: 35px;
      width: 100%;
      box-shadow: none;
      outline: 0;
    }

    .placeholder {
      font-size: 15px;
      color: #7f7f7f;
      left: 35px !important;
      top: 22px !important;

      &.has-val {
        right: 15px !important;
        bottom: 0px !important;
        left: auto !important;
        top: auto !important;
      }
    }

    i {
      position: absolute;
      left: 10px;
      top: 20px;
      transform: translateY(-50%);
      font-size: 22px;
      color: #7f7f7f;
    }
  }
  
  .second-col {
    .behaviors-accordion .behavior .behavior-inner {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}