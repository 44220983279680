.dashboard-content-wrp {
    padding: 24px !important;
    min-height: calc(100vh - 111px);
    border-radius: 0;

    .sidebar-compo .sidebar-header {
        padding-left: 15px;
        padding-right: 15px;
        border-left: 0;
        border-right: 0;
    }
}

.dashboard-card {
    margin-bottom: 1.75rem;
    border: none;
    box-shadow: 0px 2px 6px rgba(23, 32, 46, 0.1);
    border-radius: 4px;
    background-color: #fff;

    >div {
        padding: 0px 20px 20px 20px;

        &.custom-react-select {
            padding: 0 !important;
            margin-left: 20px;
            margin-right: 20px;
        }

        &.filter {
            padding-bottom: 0;
        }
    }

    .title {
        color: $charcoal-600;
        font-size: 18px;
        line-height: 32px;
        font-family: $font-medium;
        padding-top: 20px;
        padding-bottom: 12px;
        border-bottom: 1px solid #DAE2EB;
        margin-bottom: 20px;
    }

    .form-group {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .filter {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
    }

    .value {
        color: $red-600;
        font-size: 56px;
        line-height: 60px;
        padding-top: 12px;
    }

    .dashboard-card-header {
        background-color: #F1F5F9;
        padding: 1rem 2rem 0 2rem;
    }
}

.dashboard-table,
.cohort-churn-table {
    border: 1px solid #DAE2EB;
    border-radius: 4px;

    thead {
        background-color: #F1F5F9;
        font-family: $font-medium;
        color: #576071;

        th {
            border: none;
            border-bottom: none !important;
            background: #F6F9FC;
            color: #576071;
            font-size: 14px;
        }
    }

    tbody {
        color: $charcoal-600;

        td:not(:first-child) {
            text-align: left;
        }

        tr:nth-child(even) {
            background-color: #F6F9FC;
        }
    }

    th,
    td {
        border-top: #DAE2EB;
    }

    .bg-grey {
        background-color: #E9EFF5;
    }

    .color-grey {
        color: #768091;
    }

    .color-red {
        color: #EC2050;
    }
}

.dashboard-table {
    .rainbow-table_header-content {
        white-space: normal;
        padding: 0 5px 0 5px;
        font-size: 12px;
    }
}

.issues-and-alerts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .app-box-group {
        display: flex;
        cursor: pointer;

        .app-box {
            padding: 20px;
            min-width: 100px;
            height: 100px;
            color: white;
        }

        .green {
            background-color: $green-600;
        }

        .blue {
            background-color: $primary-blue;
        }

        .red {
            background-color: $red-600;
        }
    }
}

.page-title {
    color: $charcoal-600;
    font-size: 18px;
    line-height: 28px;
    font-family: $font-medium;
    margin-bottom: 1rem;
}

.tab-shadow {
    -webkit-box-shadow: 0 0 3px #17202e0d;
    box-shadow: 0 0 3px #17202E0d;
}

.call-stats-box {
    display: flex;
    align-items: center;
}


.enrollment-stats-compo {
    display: grid;
    grid-template-columns: stretch;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
}

.key-stats-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 180px);
    gap: 16px;

    .white-bg-box {
        display: grid;
        grid-template-columns: 1;
        grid-template-rows: 46px 50px 0px 50px;
        padding-bottom: 14px !important;
        .change-ind-compo i.icon-line-triangle {
            margin-top: 0;
        }
        .graph-wrp {
            min-width: 130px;
            width: 100%;
        }
        &.is-selected {
            background-color: $dark-blue;
            color: #fff;
            .text-charcoal-500, .text-charcoal-700, .text-charcoal-800 {
                color: #fff !important;
            }
        }
    }
}

.detail-stats-list {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fill, 275px);
    grid-template-rows: 1;
    .gray-bd-box {
        display: grid;
        grid-template-columns: 1;
        grid-template-rows: 24px 42px;
        gap: 2px;
    }
}