.recent-activities-compo {
	background-color: transparent;

	.new-timelines {
		&:before {
			background-color: #dae2eb;
		}

		.date-line {
			.activity-list {
				padding-left: 24px;
				.activity-name {
					position: relative;
					&:before {
						content: '';
						position: absolute;
						left: -24px;
						top: 4px;
						width: 12px;
						height: 12px;
						border-radius: 50%;
						background-color: #CCD6E2;
					}
				}
				.each-activity.is-expanded {
					position: relative;
					&:after {
						content: '';
						position: absolute;
						top: 10px;
						left: -19px;
						width: 2px;
						height: calc(100% - 22px);
						background-color: #CCD6E2;
					}
				}
				.audio-lbl {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					color: #374050;
					font-size: 16px;
					.icon.icon-play-circle {
						color: #3392FF;
						margin-right: 10px;
					}
					span {
						min-width: 60px;
					}
				}
			}
			.timeline-details {
				> div {
					display: flex;
					justify-content: flex-start;
					padding: 8px 0;
					border-bottom: 1px solid #E9EFF5;;
					>span:nth-child(1) {
						width: 160px;
						color: #374050;
						font-size: 16px;
					}
					>span:nth-child(2) {
						width: calc(100% - 160px);
						color: #374050;
						font-size: 16px;
						&.editable-click {
							pointer-events: cursor;
							color: $primary-blue;
							font-family: $font-medium;
						}
					}
					&:last-child {
						border-bottom: 0;
					}
				}
			}
			// .activity-list>div:not(:last-child):before {
			// 	top: 4px;
			// }

			// .date {
			// 	&:before {
			// 		content: none;
			// 	}
			// }

			// .caller-name {
			// 	font-family: $font-medium !important;
			// 	font-size: 16px !important;
			// 	color: #374050 !important;
			// 	position: relative;

			// 	&:before {
			// 		content: '';
			// 		position: absolute;
			// 		top: 4px;
			// 		left: -24px;
			// 		width: 12px;
			// 		height: 12px;
			// 		border-radius: 6px;
			// 		background-color: #ccd6e2;
			// 		border: 1px solid #ccd6e2;
			// 	}
			// }

			// .margin-left-s {
			// 	margin-left: 0 !important;
			// }

			// p {
			// 	font-size: 14px !important;
			// 	color: #768091 !important;
			// }
		}
	}

	.no-history {
		margin-top: 16px;
	}

	.load-more {
		padding-top: 20px;
		position: relative;
		cursor: pointer;
		margin-top: 0;
		display: inline-block;
		text-transform: uppercase;
	}

	.blue-anchor {
		font-size: 16px;
		color: #007BE2;
		font-family: "Calibre-Medium";
		border: 0;
		padding: 0;
		background-color: transparent;
		text-transform: unset;
	}
}


.in-step-modal {
	.recent-activities-compo {
		margin-left: 0;
		margin-right: 0;
		padding: 16px;
	}
}