.custom-editor {
  background-color: #FFC;
  max-width: 650px;
  padding-left: 40px;
  padding-right: 40px;

  textarea {
    background-color: #FFC;
    border: none;
    box-sizing: boder-box;
    font-family: Verdana;
    font-size: 15px;
    height: 400px;
    margin-top: 20px;
    padding: 5px 0;
    width: 100%;

    &:focus {
      color: black;
    }
  }
}
.editorcontainer {
  background-color: #FFC;
  border-left: 1px solid #fce3b0;
  display: inline-block;
  padding: 30px 0 20px;
  width: 100%;
}
.wysihtml5-toolbar {
  list-style: none;
  margin: 20px 0 0 20px;
  padding: 0;

  > li {
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }

  a, button {
    background-color: #FFD;
    background-repeat: no-repeat;
    border: 1px solid #CDCD99;
    box-shadow: 0 1px 0 #E3E3AE;
    color: #000;
    float: left;
    font-size: 15px;
    height: 22px;
    line-height: 22px;
    margin-right: -1px;
    padding: 0 14px;
    text-align: center;
    text-decoration: none;
    text-rendering: optimizelegibility;

    &:hover,
    &:focus {
      background-color: #FFB;
    }

    &:first-child {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    &[data-wysihtml5-command~="insertUnorderedList"] {
      background-image: url(../new-theme/images/bullets-numbers.png);
      background-position: 9px 2px;
      padding-left: 30px;
    }

    &[data-wysihtml5-command~="insertOrderedList"] {
      background-image: url(../new-theme/images/bullets-numbers.png);
      background-position: 10px -16px;
      padding-left: 30px;
    }
  }
}

.wysihtml5-command-active {
  font-weight: bold;
}

.editor_area{
  clear: both;
  width: 100%;
  float: left;
  margin-top: 20px;
  line-height: 26px;
  overflow: auto;
  height: 471px;

  .parent {
    position: relative;
    display: block;
    clear: both;
  }
  .child {
    margin-left:25px;
    position: relative;
    display: block;
  }

  .controls {
    background: #FFFFFF;
    color: #000000;
    width: 130px;
    position: absolute;
    display: none;
    z-index: 5;
    left: -12px;
    top: 26px;
    padding: 10px;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    &:before{
      border-color: transparent transparent #FFFFFF;
      border-image: none;
      border-style: solid;
      border-width: 5px;
      content: "";
      display: block;
      height: 0;
      opacity: 1;
      position: absolute;
      top: -10px;
      width: 0;
      left: 14px;
    }
    li{
      list-style: none;
    }
    span{
      cursor: pointer;
      font-size: 15px;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}

.editor_bg{
  background-image: url(../new-theme/images/editorbackground.gif);
  background-position: -1px -80px !important;
  background-size: 689px 26px !important;
  padding-bottom: 10px !important;
  min-height: 100%;
  padding-left: 15px !important;

  .mark_important{
    font-size: 27px;
    float: left;
    color: #3fa1c9;
    display:inline-block;
    height: 20px;
    width: 20px;
    text-decoration: none;
    cursor: pointer;
    line-height: 27px;
    &:hover{
      color: #ff7e87;
    }
  }
}

.custom-editor{
  .editor_bg .note {
    max-width: 88%;
    padding-right:5px;
    width: inherit;
    outline: none;

    font-size: 16px;
    min-height: 16px;
    padding-left: 5px;
    word-wrap: break-word;
    outline: none;
    line-height: 26px;
    float: left;
    text-align: left;
  }
  .author, .date {
    font-style: italic;
    font-size:10px;
    float:left;
  }
  .author {
    margin-left:25px;
    }
  .date {
    padding-left: 5px;
  }
}