.mentor-performance-tab {
  .cop-ct-columns {
    padding: 0;
    .mini-stats-table {
      tr {
        border-bottom: 1px solid #F1F5F9;
        &:last-child {
          border-bottom: 0 !important;
          td {
            border-bottom: 0 !important;
          }
        }
      }
      thead {
        tr {
          border-bottom: 1px solid #F1F5F9 !important;
          th {
            padding-bottom: 16px;
          }
        }
      }
    }
  }
}

.change-ind-compo {
  display: flex;
  align-items: center;
  i.icon-line-triangle {
    font-size: 12px;
    transform: scale(0.6);
    margin-right: 4px;
    margin-top: 3px;
    &.is-increased {
      color: #88C04E;
    }
    &.is-decreased {
      color: #FF4E59;
      transform: scale(0.6) rotate(180deg);
    }
  }
}
.text-right .change-ind-compo {
  width: 100%;
  justify-content: flex-end;
}

.dashboard-collapsible-stat {
  border: 1px solid #E9EFF5;
  border-radius: 8px;
  padding: 16px;
  .btn-toggle {
    margin-top: 3px;
    color: #768091;
    font-size: 14px;
    transition: all ease 0.3s;
    flex-shrink: 0 !important;
  }
  &.is-collapsed {
    .btn-toggle {
      transform: rotate(180deg);
    }
  }
}

.graph-wrp.can-w175px {
  canvas {
    width: 175px !important;
    height: 100px !important;
  }
}