.mp-mentee-profile-wrp {
  // background-color: #ebebeb;
  padding: 0;
  background-color: #fff;

  >.row {
    margin-left: 0;
    margin-right: 0;

    >div {
      padding-left: 0;
      padding-right: 0;
    }

    .mp-main-content-wrp {
      flex: 0 0 calc(100% - 400px);
      max-width: calc(100% - 400px);
      min-height: calc(100vh - 70px);
      border-right: 1px solid #E9EFF5;

      &.col-md-11 {
        flex: 0 0 calc(100% - 70px);
        max-width: calc(100% - 70px);
      }
    }

    .mentee-profile-sidebar {
      flex: 0 0 400px;
      max-width: 400px;
      min-height: calc(100vh - 70px);

      &.col-md-1 {
        flex: 0 0 70px;
        max-width: 70px;
        background-color: #17202E;

        .sticky-mentee-detail-sidebar {
          .sticky-inner-wrapper {
            background-color: #17202E;
            padding-left: 8px !important;
            padding-right: 8px !important;

            .toggle-collapse {
              i {
                color: #fff;
                transform: rotate(-180deg);
              }
            }
          }
        }
      }
    }
  }

  .mentee-profile-tabs.nav.nav-tabs {
    background-color: #fff;
    margin-left: 0;
    margin-right: 0;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid #E9EFF5;

    a {
      text-decoration: none;
      border: none;
      font-size: 16px;
      font-family: $font-medium;
      color: #576071;
      line-height: 24px;
      padding-top: 16px;
      padding-bottom: 5px;
      padding-left: 0;
      padding-right: 0;
      transition: all ease 0.3s;
      border-radius: 0;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: -1px !important;

      &:hover,
      &.active {
        color: $primary-blue;
        border-bottom: 2px solid $primary-blue;

      }

    }
  }

  .mentee-detail-tabs {
    padding-top: 0;
    padding-bottom: 0;

    .tab-pane>.card {
      padding: 24px;
      box-shadow: 0;
      border: 0;

      .card-body {
        padding: 0;
      }
    }
  }

  .mentee-detail-sticky-header {
    background-color: #fff;

    .sticky-inner-wrapper {
      background-color: #fff;
    }

    .inner-wrp {
      display: flex;
      justify-content: space-between;
      padding: 0 17px;
      // border-right: 1px solid #E9EFF5;
      border-bottom: 1px solid #E9EFF5;

      .avatar.member-initial {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #AB02B6;
        color: #fff;
        font-size: 17px;
        font-family: $font-regular;
        line-height: 37px;
        padding-top: 1px;
      }

      .person {
        padding-top: 12px;
        padding-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // flex-wrap: wrap;

        @media(max-width: 1440px) {
          .avatar {
            display: none;
          }
        }

        .mentee-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-right: 15px;
          margin-left: 15px;

          i.icon-ellipse3 {
            font-size: 16px;
            margin-left: 3px;
            margin-right: 3px;
            margin-top: 0;
            transform: scale(0.3);
            // margin-top: 2px;
          }

        }

        .round-tag {
          font-size: 13px;
          font-family: $font-regular;
          background: #F1F5F9;
          border: 1px solid #F1F5F9;
          border-radius: 4px;
          margin-right: 8px;
          color: #576071;

          b {
            font-family: $font-medium;
            margin-right: 5px;
          }

          &.bd-only {
            background-color: #fff;
            border-color: #E9EFF5;
          }
        }


      }

      .mentee-status-wrp {
        display: flex;
        justify-content: flex-end;

        .mentee_status_box {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-left: 1px solid #E8E8E8;
          padding: 8px 17px;

          .fz-14 {
            line-height: 1 !important;
          }

          i {
            line-height: 1 !important;
            margin-top: -3px;
          }
        }

        @media(max-width: 1200px) {
          flex-wrap: wrap;

          .mentee_status_box {
            width: 100%;
          }
        }
      }
    }

    .mentee-calls {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .each-call-option {
        margin-right: 10px;

        >span {
          display: block;

          button {
            padding: 3px 7px;
            width: 40px;
            height: 40px;
            border-radius: 40px;
            background-color: $primary-blue;
            border-color: $primary-blue;
            transition: all ease 0.3s;
            font-size: 15px;
            font-family: $font-regular;

            i {
              font-size: 16px;
              color: #fff;
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }

        &.secondary-call {
          >span button {
            background-color: #00DE4F;
            border-color: #00DE4F;
          }
        }

        .call-btn-with-lbl.btn-with-lbl {
          button {
            background-color: $secondary-blue;
            border-color: $secondary-blue;
            width: auto !important;
            height: auto !important;
            border-radius: 4px !important;
            padding: 5px 8px !important;
            display: inline-flex !important;
            justify-content: center !important;
            align-items: center !important;

            .lbl {
              color: #fff;
              font-size: 14px;
              font-family: $font-medium;
              line-height: 1 !important;
            }

            i {
              margin-right: 5px;
              font-size: 11px !important;
            }
          }
        }

      }
    }

    .inner-wrp {
      .small-width-header {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .person .mentee-info {
          margin-left: 0;

          .mentee-name-tag-wrp {
            flex-wrap: wrap;
            margin-bottom: 5px;

            h3 {
              white-space: nowrap;

              @media(max-width: 1024px) {
                width: 100%;
              }
            }
          }
        }

        .call-buttons-wrp {
          width: 100%;
          margin-bottom: 6px;
          flex-wrap: wrap;

          .mentee-calls {
            margin-right: 15px;
            margin-bottom: 6px;
          }

          .es-mentor-view-btn {
            margin-bottom: 6px;
          }
        }
      }
    }

  }



  .tab-content {
    // border-right: 1px solid #E9EFF5;

    .container,
    .container-fluid {
      padding-left: 0;
      padding-right: 0;

      .row {
        margin-left: 0;
        margin-right: 0;

        // >div {
        // padding-left: 5px;
        // padding-right: 5px;
        // }
      }
    }

    .col-inner {
      background-color: #fff;
      padding: 30px 24px;
      border-radius: 5px;


      .workbook-summary {
        .overview-behavior-box {
          .tag {
            font-size: 13px;
            font-family: $font-bold;
            margin-top: 0;
            padding-top: 3px;
            display: inline-block;
          }

          .behavior-status-info {
            position: relative;
            padding-left: 30px;
            font-size: 15px;

            .icon {
              font-size: 16px;
              color: $grey-color;
              position: absolute;
              left: 0;
              top: 0;
            }

            .icon-notes {
              color: #000000;
              top: 4px;
            }
          }
        }
      }
    }

    .card-header-tabs {
      margin-left: 0;
      margin-right: 0;
    }
  }




  .nav-link.disabled {
    display: none;
  }

  .table.custom-styled tbody tr td button {
    color: #fff;
  }
}

.mentee-detail-sidebar {
  border-left: 1px solid #E9EFF5;

  .card {
    border-radius: 0;
  }
}



.mentee-profile-sidebar {
  background-color: #fff;

  .collapse-btn-wrp {
    padding: 17px 24px;

    .toggle-collapse {
      i {
        font-size: 16px;
        color: #768091;
      }
    }

    &.collapsed {
      padding-left: 8px;
      padding-right: 8px;

      .toggle-collapse {
        i {
          color: #fff;
          transform: rotate(-180deg);
        }
      }
    }
  }

  .mp-sidebar-content {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;

    .mp-sidebar-divider {
      height: 1px;
      background-color: #E9EFF5;
      margin-top: 15px;
      margin-left: -24px;
      margin-right: -24px;
    }
  }


  .alert {
    padding: 5px 10px;
    font-size: 14px;
    font-family: $font-medium;
  }

  .nopd {
    padding: 0 !important;
  }

  select {
    max-width: 100%;
    border-color: #DAE2EB !important;
    background-color: #fff !important;
    outline: 0 !important;
    box-shadow: none !important;
    // font-size: 16px;
    // font-family: $font-regular;
    // padding: 5px 3px;
    // height: 32px;
    width: 100%;
  }

  button {
    border-radius: 4px;
    font-size: 16px;
    font-family: $font-medium;
    line-height: 1;
    padding: 8px 18px;
    transition: all ease 0.3s;
    transform: scale(1) !important;
    box-shadow: none !important;
    outline: 0 !important;

    &.text-btn {
      text-align: left;
      color: $primary-blue;
      font-size: 16px;
      font-family: $font-medium;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      i {
        font-size: 16px;
        margin-right: 10px;
      }
    }

    &.rainbow-button--brand {
      background-color: $primary-blue;

    }

    &.rainbow-button--desctructive {
      background-color: #EC2050;

      &:hover {
        background-color: #EC2050;
        opacity: 0.8;
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .btns-wrp {
    button {
      margin-right: 10px;
    }
  }
}

.call-btn-with-lbl {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;

  .lbl {
    font-size: 16px;
    color: #0094F3;
  }

  .btn.btn-primary,
  .btn.btn-success {
    // margin-right: 12px;
    background-color: $secondary-blue !important;
    border-color: $secondary-blue !important;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    i {
      color: #fff;
    }
  }

}

.sop-component-wrp .sop-main .details-list .each-detail .btn-toolbar {
  .btn.btn-success {
    background-color: #88C04E !important;
    border-color: #88C04E !important;

  }
}

.content-w-left-bar {
  background-color: #fff;

  >.MuiGrid-container {
    margin-left: 0;
    margin-right: 0;

    .middle-content {
      &.MuiGrid-grid-lg-8 {
        flex: 0 0 calc(75% - 64px);
        max-width: calc(75% - 64px);
      }
    }

    .sidebar-wrp {
      border-left: 1px solid #E9EFF5;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 16px;

      &.MuiGrid-grid-lg-1 {
        flex: 0 0 64px;
        max-width: 64px;
        padding: 16px !important;
      }

      .toggle-menu {
        margin-bottom: 16px;
      }
    }
  }

  .left-sidebar {
    border-right: 1px solid #E9EFF5;
    padding: 25px 24px;
    min-height: calc(100vh - 100px);
  }

  .middle-content {
    padding: 16px 24px;

    .recent-activities-compo {
      margin-left: 0;
      margin-right: 0;
      padding: 0;
    }
  }
}

.review-call-component {
  .content-w-left-bar {
    .row {
      height: 100%;

      >div {
        min-height: unset !important;
        height: 100%;
        overflow-y: auto;

        .scroll-wrp {
          overflow-y: unset;
        }
      }
    }

    .left-sidebar {
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .v-ob-wrp {
        flex-grow: 1;
        overflow-y: auto;
        width: calc(100% + 48px);
        margin-left: -24px;
        margin-right: -24px;
        padding-left: 24px;
        padding-right: 24px;
      }

      .bottom-fixed-wrp {
        padding-top: 24px;
        margin-left: -24px;
        margin-right: -24px;
        padding-left: 24px;
        padding-right: 24px;
        width: calc(100% + 48px);

        button {
          margin-right: 0 !important;
          width: 100%;
          padding-top: 8px;
          padding-bottom: 8px;
          font-family: $font-medium;
        }
      }
    }
  }
}

.custom-timeline-event-wrp {
  >div>section {
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    >div:first-child {
      top: 5px !important;
      left: 6px !important;
    }

    >span {
      >div {
        padding-left: 30px !important;
      }
    }

    .gray-text-btn {
      font-size: 14px;
      color: #768091;
      font-family: $font-regular;
    }
  }
}

.workbook-completed-list {
  .left-nav-listing-header-col {
    padding-left: 0;
    padding-right: 0;
  }

  .main-content-col {
    padding: 24px;
  }
}

#mentee_tabs-tabpane-edit {
  .mentee-status-manager-compo {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;

    .gray-wrp {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.mentoring-program-mentee-profile-wrp {
  .crcw.cr_VerticalNavigation {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.btn-mentee-profile-info-expand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -24px;
  margin-right: -24px;
  padding: 15px 24px;
  border-top: 1px solid #E9EFF5;
  ;
  border-bottom: 1px solid #E9EFF5;
  // margin-bottom: 16px;
  margin-top: 16px;
  cursor: pointer;

  i.icon-caret-down-solid {
    color: #95A0B0;
    font-size: 16px;
    transform: rotate(-90deg);
    transition: all ease 0.3s;
  }

  &.is-expanded {
    margin-bottom: 0;
    border-bottom: 0;

    i.icon-caret-down-solid {
      transform: none;
    }
  }
}

.mentee-profile-information-wrp {
  padding-bottom: 10px;
  border-bottom: 1px solid #E9EFF5;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .status-wrp {
    width: 50%;
    padding-right: 15px;
    margin-bottom: 16px;

    >span {
      display: block;
      width: 100%;

      &:first-child {
        margin-bottom: 2px;
      }
    }
  }
}




/**
 * New layout
 */
.mentee-profile-status-bar {
  background-color: #fff;
  margin-bottom: 0;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 61px;

  >div {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  border-bottom: 1px solid #E9EFF5;

  .mentee_status_box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;
    border-left: 1px solid #E8E8E8;
    height: 44px;
  }

  .border-right {
    height: 44px;
    border-right: 1px solid #E8E8E8;
  }

  @media(max-width: 1550px) {
    .pr-3 {
      padding-right: 8px !important;
    }

    .mr-3 {
      margin-right: 8px !important;
    }

    .mentee_status_box {
      padding: 0 8px;

      .mr-2\.5 {
        margin-right: 4px !important;
      }
    }
  }

  @media(max-width: 1434px) {
    .fz-14 {
      font-size: 14px !important;
    }

    .fz-12 {
      font-size: 12px !important;
    }

    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }
}

.horizontal-timeline-compo-wrp {
  width: 100%;
  overflow-x: auto;
  max-height: 0;
  transition: all 0.5s ease-out;
  overflow-y: hidden;

  &.is-open {
    max-height: fit-content;
  }
}

.horizontal-timeline-compo {
  background-color: #fff;
  padding: 14px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 1024px;

  &.is-open {
    padding-top: 14px;
    padding-bottom: 14px;
    height: auto;
  }

  .timelines {
    flex-grow: 1;
    margin-left: 10px;
    margin-right: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:before {
      content: none;
    }

    .each-event {
      margin-right: 8px;
      flex-grow: 1;
      flex-basis: 0;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 9px;
        left: 22px;
        right: 0;
        height: 1px;
        background-color: #CCD6E2;
      }

      .top-line {
        line-height: 1;
        color: #0094F3;
      }
    }

    >div:last-child {
      &:before {
        content: none !important;
      }
    }
  }

  &.is-activated {
    .timelines {
      .each-event {
        .top-line {
          color: #0094F3;

        }

        &.is-executed {
          &:before {
            background-color: #9BCE63;
          }

          .top-line {
            color: #9BCE63;
          }

        }
      }
    }
  }

  @media(max-width: 1434px) {
    .fz-14 {
      font-size: 14px !important;
    }

    .fz-12 {
      font-size: 12px !important;
    }

    svg {
      width: 16px !important;
      height: 16px !important;
    }

    .mr-4 {
      margin-right: 10px !important;
    }
  }
}

.line-divided-row {
  >div {
    flex-grow: 2;
    // flex-basis: 0;
  }

  .line-divider {
    width: 1px;
    flex-grow: 1;
    border-left: 1px solid #E9EFF5;
    ;
    margin: 0 10px;
  }
}


/**
 * Action Plan
 */
.transfer-button.btn.btn-primary {
  position: fixed;
  right: 74px;
  bottom: 0;
  z-index: 9999;
  width: 320px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #212D52;
  border-color: #212D52;
  border-radius: 8px 8px 0 0;

  &:active {
    background: #212D52;
    border-color: #212D52;
    opacity: 0.9;
  }

  >div {
    width: 100%;
  }
}

.mentee-action-plan-modal {
  border-radius: 16px;
  overflow-x: hidden;
  padding: 0 !important;
  width: 560px;
  z-index: 1000;
  background-color: #fff;
  position: fixed;
  left: calc(50vw - 280px);
  top: 10vh;
  transform: translateY(-50%);
  top: calc(50vh - 326px);
  box-shadow: 0px 16px 40px rgba(23, 32, 46, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  >* {
    width: 100%;
  }

  .mapm-header {
    border-radius: 16px 16px 0 0;
    background-color: #212D52;
    padding: 16px 24px 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .btn-close {
      transform: rotate(180deg);
    }
  }

  .custom-styled-tabs.nav.nav-tabs {
    background-color: #212D52;

    .nav-item.nav-link {
      color: #95A0B0;
      margin-bottom: 0;
      font-size: 21px;
      margin-right: 28px;

      &.active {
        color: #fff;
        font-family: $font-regular;
      }
    }
  }

  .tab-content {
    flex-grow: 1;

    >div {
      height: 560px;

      >div {
        height: 560px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        >* {
          width: 100%;
        }

        .mct-wrp {
          overflow: auto;
          flex-grow: 1;
        }

        .btm-btns-wrp {
          margin: 0 !important;
          padding: 24px !important;
        }
      }
    }
  }

  .custom-react-select .custom__control {
    border: 1px solid #E0E8F0;
    border-radius: 4px;
    padding: 8px 12px;
  }

  .tasks-selector-wrp {
    border: 1px solid #E0E8F0;
    border-radius: 4px;
    padding: 8px 12px;
    position: relative;
  }

  .opentext-input>div {
    border: 0;
    outline: none !important;
    box-shadow: none !important;
    // textarea {
    //   border: 1px solid #E0E8F0;
    //   border-radius: 4px;
    //   padding: 8px 12px;
    //   outline: none !important;
    //   box-shadow: none !important;
    // }
  }

  input[type="number"] {
    border: 1px solid #E0E8F0;
    border-radius: 4px;
    padding: 8px 12px;
    outline: none !important;
    box-shadow: none !important;
  }

  .custom-styled-tabs {
    .round-badge {
      background-color: #162143;
      color: #576071
    }

    .active {
      .round-badge {
        background-color: #0094F3;
        color: #fff;
      }
    }
  }
}


.action-plan-task {
  cursor: default !important;

  .fz-16.ff-reg.text-charcoal-700 {
    cursor: pointer;
  }

  .actions-wrp {
    opacity: 0;
    transition: all 0.3s ease;
  }

  &:hover {
    .actions-wrp {
      opacity: 1;
    }
  }
}

.mentee-action-plan-compo {
  &.is-es-profile {
    .custom-styled-tabs.nav.nav-tabs+.tab-content {
      padding-top: 16px;
      padding-bottom: 12px;
    }
  }

  .custom-styled-tabs.nav.nav-tabs {
    padding-left: 0;
    padding-right: 0;

    .nav-item.nav-link {
      color: #95A0B0;
      margin-bottom: 0;
      font-size: 21px;
      margin-right: 16px;

      &.active {
        font-family: $font-regular;
        color: #374050;
      }
    }

    +.tab-content {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}

.mui-round-badge {
  display: flex;
  align-items: center;
  justify-content: center;

  svg,
  span {
    color: inherit !important;
    margin: 0 4px !important;

    &.MuiChip-deleteIconMedium,
    &.MuiChip-deleteIconSmall {
      font-size: 0;
      transition: all 0.2s ease-in-out;
    }
  }

  &:hover {

    svg,
    span {

      &.MuiChip-deleteIconMedium,
      &.MuiChip-deleteIconSmall {
        margin-right: -1px !important;
        font-size: 1rem;
        visibility: visible;
      }
    }
  }
}

.assign-form-wrapper {
  .MuiInputBase-root.MuiInputBase-multiline {
    border-radius: 4px;
    background-color: #fff;

    .MuiOutlinedInput-notchedOutline {
      border-radius: 4px;
    }
  }

  .custom-react-select .custom__control {
    border-radius: 4px;
  }
}

.admin-mentor-profile.copilot-cont {
  .cop-content-wrp {
    display: flex;
    flex-direction: column;

    >div {
      width: 100%;
      >div:first-child {
        flex-shrink: 0;
      }

      &.mentee-profile-status-bar {
        flex-shrink: 0;
      }

      &.cop-content-wo-statusbar {
        flex-grow: 1;
        height: 0;
        overflow-y: auto;
        .cop-ct-columns {
          height: 100% !important;
          overflow-y: auto;
          margin-top: 0 !important;
          padding-top: 24px !important;
          .cols-wrp {
            padding-top: 0 !important;
          }
        }
        .copilot-right-sidebar-wrp {
          height: 100%;
          overflow-y: auto;
        }
      }
    }

    .sidebar-content-main {
      height: calc(100vh - 166px);
    }
  }
}