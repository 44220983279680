.command-center-wrp {
  padding: 24px;
  background-color: #e9eff5;
  min-height: calc(100vh - 51px);

  .white-box.table-box {
    padding: 8px 0;
    overflow: hidden;

    .filters-wrp {
      padding: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    table tbody tr:last-child {

      th,
      td {
        border-bottom: 0 !important;
      }
    }
  }
}

.cop-content-wrp {
  height: calc(100vh - 51px);
  overflow-y: auto;

  .command-center-wrp {
    padding: 0;
  }
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.white-box {
  .white-box {
    border: 1px solid #E9EFF5;
    box-shadow: none !important;
  }
}

.tickets-numbers-wrp {
  overflow-y: auto;

  @media(max-width: 1600px) {
    .flex-break {
      display: none;
    }
  }

  .white-box {
    margin-right: 24px;
    margin-bottom: 24px;
    position: relative;
    width: 220px;
    flex-shrink: 0;
    border-radius: 16px;
    padding: 16px 24px 75px 24px;
    box-shadow: 0px 2px 10px rgba(55, 64, 80, 0.1);
    cursor: pointer;

    &.is-large {
      width: 280px;
      padding-bottom: 16px;

      &.is-selected {
        background-color: $gray-bg;

        .ticket-count {
          color: #374050;
        }

        .number-title {
          color: #374050;
        }

        .ticket-lbl {
          color: #374050;
        }

        .diff-val {
          color: #374050;
        }
      }

      @media(max-width: 1600px) {
        width: 18%;
      }

      @media(max-width: 1439px) {
        width: 280px;
      }
    }

    .number-title {
      font-size: 18px;
      color: #374050;
      line-height: 1.2;
    }

    .ticket-count {
      font-size: 48px;
      line-height: 1;
      margin-right: 6px;
      font-family: $font-medium;
      color: #374050;
    }

    .ticket-lbl {
      font-size: 18px;
      line-height: 1.8;
      color: #768091;
      margin-top: 16px;
    }

    .diff-val {
      font-size: 14px;
      color: #768091;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 5px;
      margin-top: 18px;

      .icon-line-triangle {
        transform: scale(0.4);
        color: #88C04E;
        margin-top: 1px;
      }

      &.is-reduced {
        .icon-line-triangle {
          color: #FF4E59;
          transform: rotate(180deg) scale(0.4);
        }
      }
    }

    .number-info {
      position: absolute;
      left: 24px;
      right: 24px;
      bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;


    }

    &.is-selected {
      background-color: $secondary-blue;
      color: #fff;

      .ticket-count {
        color: #fff;
      }

      .number-title {
        color: #fff;
      }

      .ticket-lbl {
        color: #fff;
      }

      .diff-val {
        color: #fff;
      }
    }
  }

  &.is-small-text {
    .white-box {
      padding: 16px 24px 60px 24px;

      .number-title {
        font-size: 16px;
      }

      .ticket-count {
        font-size: 40px;
      }

      .ticket-lbl {
        font-size: 16px;
      }
    }
  }
}

.tickets-compo-wrapper {
  display: grid;
  grid-template-columns: 400px minmax(0,1fr);
  gap: 0;
  transition: all ease 0.3s;

  >div:nth-child(1) {
    border-right: 1px solid #E0E8F0;
    padding: 24px;

    .hide-when-collapsed {
      transition: all ease 0.2s;
      opacity: 1;
    }
  }

  >div:nth-child(2) {
    padding: 24px;
  }

  .aor-ticket-list-wrapper, .graph-table-wrapper {
    height: calc(100vh - 236px);
    overflow-y: auto;
    overflow-x: visible;
    margin: 0 -24px;
    padding: 0 24px;
  }

  .aor-ticket-list {
    .aor-ticket-list-item {
      margin-left: -12px;
      margin-right: -12px;
      padding: 10px;
      border-radius: 12px;

      &.is-selectable {
        cursor: pointer;

        &:hover,
        &.is-selected {
          background-color: #F6F9FC;
        }
      }
    }
  }

  .tickets-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, 275px);
    gap: 16px;

    .white-box {
      border: 1px solid #E9EFF5;
      width: 100%;
      display: grid;
      grid-template-columns: 1;
      grid-template-rows: 0.7fr 1fr;
      gap: 8px;
      border-radius: 16px;
      padding: 16px 20px;
      cursor: pointer;

      &.is-selected {
        background-color: $primary-blue;

        * {
          color: #fff;
        }
      }
    }
  }

  &.is-collapsed {
    grid-template-columns: 60px minmax(0,1fr);

    .toggle-btn {
      transform: rotate(180deg);
      margin-left: -100px;
    }

    >div:nth-child(1) {
      padding: 24px 12px;

      .hide-when-collapsed {
        opacity: 0;
      }
    }

    .aor-ticket-list-wrapper {
      overflow: hidden;
    }
  }
}

.tickets-compo-table .MuiDataGrid-virtualScroller {
  max-height: 560px;
  overflow-y: auto !important;
}