.screen-recorder-wrapper {
  position: absolute;
  top: 62px;
  right: 16px;

  #draggable-screen-recorder {
    padding: 12px;
    width: 280px;
  }
  &-video {
    box-shadow: 3px 5px 24px 0 #00000070;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 10000;
    transform: translateX(-50%) translateY(-50%);
    width: 800px;
    height: auto;
    max-width: 90vw;
    background-color: #fff;
    border-radius: 8px;
    video {
      margin-bottom: 12px;
    }
  }
  &-addl-note {
    background-color: #fff;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
  }
  &-control {
    &.Mui-disabled {
      opacity: 0.6;
    }
  }
}
