#react-confirm-alert {
  z-index: 999999 !important;
  position: absolute;
}

.react-confirm-alert-overlay {
  z-index: 98;
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.alert.alert-dismissible.show {
  .close {
    font-size: 18px;
    top: 5px;
    right: 5px;
  }
}



// Inputs
.item-radio,
.item-checkbox {
  position: relative;
  width: 100%;

  &>div {
    padding: 2px;
    line-height: 1.3;
    margin-left: 20px;
  }

  input {
    display: none;
  }

  .circle {
    float: left;
    margin-top: 0.2em;
    border: 1px solid #737373;
    border-radius: 20px;
    width: 15px;
    height: 15px;
    z-index: 3;
    background: #fff;
  }

  input:checked~.circle {
    background: #737373;
  }

  &.big {
    .circle {
      width: 20px;
      height: 20px;
    }

    div {
      margin-left: 30px;
    }
  }
}

.item-checkbox {
  .circle {
    border-radius: 3px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2) inset;
  }
}

.clear-editable-input {
  padding: 0px;
  border: none;
  font-size: 14px;
  line-height: normal;
  font-family: inherit;
}

.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

// Box Classes
.box {
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  &.shadow-none {
    box-shadow: none;
  }
}

.mr-11 {
  margin-right: 11px;
}

.box-title {
  font-size: 17px;
  font-weight: 700;
}

// List CSS Classes
.horizon-list {
  li {
    line-height: 1;
    float: left;
    padding: 0 10px 0 0;
    margin-right: 10px;
    border-right: 1px solid #c7c7c7;
    margin-bottom: 5px;

    &:last-child {
      border-right: none;
    }
  }
}

// Borders
.left-border {
  border-left: 1px solid;
}

// Colors
.bg-blue {
  background-color: $blue-color;
  color: #ffffff;
}

.bg-green {
  background-color: $green-color;
  color: #ffffff;
}

.bg-grey {
  background-color: $grey-color;
  color: #ffffff;
}

.bg-red {
  background-color: $red-color;
  color: #ffffff;
}

.bg-lightred {
  background-color: $red-color;
  color: #ffffff;
}

.bg-white {
  background-color: #ffffff;
}

.bg-purple {
  background-color: #9d8ac7;
  color: #fff;
}

.bg-yellow {
  background-color: $yellow-color;
}

.bg-orange {
  background-color: #f48c2f;
  color: #fff;
}

.bg-light-grey {
  background-color: #f1f1f1;
}

.highlight-blue {
  background-color: #cdf4fe;
}

.highlight-yellow {
  background-color: #fdffcd;
}

.highlight-green {
  background-color: #d7ffdb;
}

.color-white {
  color: #fff;
}

.color-red {
  color: $red-color;
}

.color-blue {
  color: $blue-color;
}

.color-green {
  color: $green-color !important;

  &.light-color {
    color: $light-green-color;
  }
}

.color-grey {
  color: $text-grey-color;
}

.color-orange {
  color: #ff4500;
}

.color-desc {
  color: $text-grey-color;
}

.color-purple {
  color: #9d8ac7;
}

.blue-link {
  color: $blue;
}

// Fonts
.bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-small {
  font-size: $font-size-small;
}

.font-smaller {
  font-size: 12px;
}

.second-font {
  font-family: $montserrat-font !important;
  text-transform: uppercase;
}

.uppercase {
  text-transform: uppercase;
}

.small-text {
  font-size: 12px;
}

.font-12px {
  font-size: 14px;
}

// Navs
.left-nav {
  .content {
    overflow: auto;
  }
}

// layout
.display-flex {
  display: flex;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.inline-block {
  display: inline-block;
}

.full-block {
  width: 100%;
}

.main-content {
  padding: 20px 30px;
}

.form-wrapper {
  background: #f7f7f7;
  border: 1px solid #e2e1e1;
  border-radius: 2px;
  margin-top: 40px;
  padding: 20px;
}

.position-relative {
  position: relative;
}

// Tables
.table {
  &>thead {
    &>tr {
      &>th {
        border-bottom: 1px solid #e5e5e5;
        padding: 14px 15px;
        font-weight: 400;
      }
    }
  }

  &>tbody,
  &>tfoot {
    &>tr {
      &>td {
        padding: 14px 15px;
      }
    }
  }

  &.stats {
    background: none;

    td,
    th {
      max-width: 90px;
      width: 10%;
      padding: 5px;
      text-align: center;
      border: none;
      border-right: 1px solid #e5e5e5;

      &:last-child {
        border-right: none;
      }
    }
  }

  &.valign-center {

    td,
    th {
      vertical-align: middle;
    }
  }

  &.dark-header {
    &>thead {
      &>tr {
        background: $grey-color;

        &>th {
          font-weight: bold;
          color: #fff;
          border: 1px solid $grey-color;
        }
      }
    }
  }
}

.table.border-none {
  &>tbody {
    &>tr {
      &>td {
        border: none;
      }
    }
  }
}

.issues-table {
  margin-bottom: 0;

  .mainheader {
    padding: 19px 15px;
  }

  .header-columns {
    background: #f1f1f1;
  }

  .delta-icon {
    margin-left: 15px;
    margin-right: 5px;
  }

  .fa-chevron-right {
    color: #9a9a9a;
    font-size: 14px;
  }
}

.live-search-table {
  position: absolute;
  background: #ffffff;
  z-index: 10;
  top: 34px;
}

// Material Multi Select Field
.multiselect-check {
  left: -7px !important;
  top: 4px !important;
}


// Editor CSS
.editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
}

.editor:global(.public-DraftEditor-content) {
  min-height: 100px;
}

// TimeLines
.timeline-avatar {
  width: 35px;
  height: 35px;
  font-size: 17px;
  line-height: 35px;
  position: absolute;
  color: #fff;
  background: #03a7e0;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  border-radius: 35px;
  margin-right: 10px;
}

.timeline-text {
  display: block;
  margin-left: 45px;
}

// Tags
.tag {
  padding: 2px 8px;
  border-radius: 3px;
  text-transform: uppercase;
  font-family: $second-font-bold;
  font-size: 15px;
}

.status-tag {
  border-radius: 11px;
  display: inline-block;
  padding: 2px;
  text-align: center;
  width: 110px;

  &.__blue {
    background-color: $blue-color;
    color: #ffffff;
  }

  &.__green {
    background-color: $green-color;
    color: #ffffff;
  }

  &.__grey {
    background-color: $grey-color;
    color: #ffffff;
  }

  &.__red {
    background-color: $red-color;
    color: #ffffff;
  }

  &.__lightred {
    background-color: $red-color;
    color: #ffffff;
  }

  &.__white {
    background-color: #ffffff;
  }

  &.__purple {
    background-color: #9d8ac7;
    color: #fff;
  }

  &.__yellow {
    background-color: #f48c2f;
    color: #fff;
  }

  &.__orange {
    background-color: #f48c2f;
    color: #fff;
  }

  &.__light-grey {
    background-color: #f1f1f1;
  }
}

// Button Classes
.btn.btn-no-border {
  border: none;
}

.circle-btn {
  display: inline-block;
  width: 23px;
  height: 23px;
  line-height: 23px;
  text-align: center;
  border-radius: 23px;
  border: none;

  &.dark-grey {
    background: #4c4c4c;
    color: #fff;
  }
}

// Other Classes
.cursor {
  cursor: pointer;
  user-select: none;
}

.vertical-middle {
  vertical-align: middle !important;
}

.person {
  position: relative;
  display: flex;
  align-items: center;

  .avatar {
    width: 43px;
    height: 43px;
    line-height: 43px;
    text-align: center;
    font-size: 24px;
    border-radius: 43px;
    margin-right: 15px;
  }

  &>div {}

  .title {
    font-size: 15px;
    line-height: 1.3;
    margin: 0;
  }

  .subtitle {
    color: #464646;
    font-size: $font-size-small;
  }

  .desc {
    margin-top: 5px;
    font-weight: 300;
  }

  &.big {
    .avatar {
      width: 64px;
      height: 64px;
      line-height: 64px;
      font-size: 19px;
      margin-right: 1em;
    }

    .title {
      font-size: 21px;
    }
  }

  .secondary-call {
    margin-left: 3px;

    .btn-success {
      background-color: #f0ad4e;
      border-color: #eea236;

      &:hover {
        background-color: #ec971f;
        border-color: #d58512;
      }
    }
  }
}

ul.activities {
  li {
    margin-bottom: 5px;
  }
}

.properties {
  color: #000;

  &>li {
    border-bottom: 1px solid #e9eff5;
    padding: 12px 0;

    .property-label {
      color: #464646;
      font-size: 15px;
      line-height: 14px;
      margin-bottom: 5px;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.check-items {
  .form-group {
    margin: 0;

    .checkbox {
      margin-top: 5px;
      margin-bottom: 0;
    }
  }
}

.tagbox {
  display: inline-block;
}

.smalltag {
  float: left;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 15px;
  color: #fff;
  border-radius: 4px;

  &>span {
    display: inline-block;
    padding: 1px 4px;
  }
}

.initials-avatar {
  width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  text-align: center;
  font-size: 17px;
  border-radius: 43px;
  margin-right: 15px;
}

.rbc-gutter-cell,
.rbc-allday-cell {
  display: none !important;
}

.rbc-gutter-cell {
  width: 80px !important;
}

.rbc-time-content>.rbc-time-gutter {
  width: 80px !important;
}

.drop-container {
  display: table;
  width: 100%;
  height: 150px;
  overflow: hidden;
  background: #ededed;
  font-size: 24px;
}

.drop-container-inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

/* For call summary view */

.metric {
  position: relative;
  padding: 20px;
  line-height: 1;
  min-width: 162px;

  .number {
    font-size: 48px;
    font-weight: 300;
  }

  .stat-msg {
    font-size: 15px;
    text-transform: none;
    color: #464646;
    margin-bottom: 20px;
    line-height: 1.3;
  }

  .date {
    font-size: 15px;
    color: #8f8f8f;
  }

  .icon {
    font-size: 36px;
    color: #e1e1e1;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &.call-completed {
    .number {
      color: $green-color;
    }
  }

  &.call-unanswered {
    .number {
      color: $red-color;
    }
  }
}

.call-notes {
  .note-list {
    li {
      margin-bottom: 16px;
      padding-bottom: 16px;
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
      border-bottom: 1px solid #e9eff5;
    }
  }
}

.overview-behavior-box {
  padding-bottom: 0;
  position: relative;
  overflow: hidden;

  .sub-behaviors-contain {
    background: #f6f6f6;
    margin-left: -15px;
    margin-right: -15px;

    .title {
      font-size: 15px;
      padding: 5px;
    }
  }
}

.goal {
  border-radius: 4px;
  padding: 12px 12px 8px 38px;
  position: relative;

  .icon-goal-status {
    color: #787878;
    font-size: 18px;
    left: 14px;
    top: 14px;
    position: absolute;
  }

  .status-row {
    display: none;
    margin-bottom: 4px;

    span {
      font-size: 11;
    }
  }

  .goal-content {
    h4 {
      width: 100%;
      float: left;
      margin-right: 20px;
      font-size: 15;
      font-weight: 300;
    }

    span {
      display: inline-block;
      font-size: 11;
    }

    .goal-behavior {
      padding: 6px 0;
    }

    .goal-date {
      padding: 5px 0 6px;
    }

    tag {
      margin-left: 8px;
    }

    .sentiment {
      margin-top: 5px;

      .custom-icon-rating {
        width: 20px;
        height: 20px;
        margin-left: 8px;
      }
    }
  }

  .progress {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &.active {
    background-color: #f5f5f5;

    .status-row {
      display: block;
    }

    .goal-date {
      margin-top: 0px;
    }
  }

  .goal-actions {
    font-size: 15px;

    li {
      margin: 0 8px;
      cursor: pointer;
      float: left;

      a {
        padding: 6px 10px;
        float: left;

        &.btn {
          font-size: 15px;
          border: 1px solid #f1f1f1;

          &:hover {
            border: 1px solid #d7d7d7;
            background: #f5f5f5;
          }
        }
      }
    }
  }

  &.goal-overdue {
    .progress>div {
      background-color: $red-color;
    }
  }
}

.active-goal-history {
  padding: 24px 12px 22px 40px;
  background-color: #fefae5;
  border-radius: 0 0 4px 4px;

  .history-header {
    font-size: 15px;
  }

  .properties {
    padding: 14px 0 6px;

    .horizon-list {
      vertical-align: bottom;

      li {
        border-color: $green-color;

        &:last-child {
          min-width: 135px;
          text-align: center;
        }
      }
    }

    .custom-icon-rating {
      width: 20px;
      height: 20px;
      vertical-align: top;
    }
  }

  &>ul {
    &>li {
      padding-bottom: 8px;
      margin-bottom: 2px;
      border-bottom: 1px solid #c8c5b5;
    }

    margin-bottom: 16px;
  }

  .hide-history {
    font-size: 12px;
  }
}

.challenges-tab {
  .challenge-list {
    display: inline-block;
    width: 350px;
    vertical-align: middle;
    margin-left: 12px;
  }
}

.payments {
  &>li {
    padding: 15px 0;
    border-bottom: 1px solid #cccccc;

    .value-label {
      color: #777777;
      font-size: 15px;
    }

    .amount {
      font-size: 18px;
    }
  }

  .more-content {
    background: #f8f7f7;
    border-radius: 2px;
    padding: 0 20px;
  }

  .more-stats {
    margin-bottom: 0;

    &>tbody {
      &>tr {
        &>td {
          border-bottom: 1px solid #e6e5e5;
          border-top: none;
          position: relative;
          padding: 10px 10px 10px 0;
          vertical-align: middle;
        }

        &:last-child {
          &>td {
            border-bottom: none;
          }
        }
      }
    }

    .stats-num {
      font-size: 36px;
    }

    .left-icon {
      font-size: 23px;
      color: #7c7b7b;
      position: relative;
      margin-right: 10px;
    }
  }

  table.small {

    &>tbody,
    &>thead {
      &>tr {

        &>td,
        &>th {
          padding: 8px;
        }
      }
    }
  }

  .fs-small {
    font-size: 15px;
  }

  .link {
    cursor: pointer;
  }
}

.enroll-wrapper {
  display: flex;
  position: relative;
  background-color: #fff;

  >.main-content {
    padding-top: 0;
  }

  .paperform-section {
    display: none;
    position: relative;
    background: #efefef;
    box-shadow: 2px 0 15px 3px rgba(0, 0, 0, 0.5);
    -webkit-transition: margin 1s;
    transition: margin 1s;
    width: 400px;

    &.opened {
      display: block;

      @media (max-width: 1024px) {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 400px;
        z-index: 111;
      }
    }

    .handle {
      position: absolute;
      right: -32px;
      background: #efefef;
      width: 32px;
      text-align: center;
      cursor: pointer;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      top: 137px;
      padding: 8px 0;
      font-size: 18px;
      line-height: 1;
      z-index: 1;
      box-shadow: 7px 0 6px 0px rgba(0, 0, 0, 0.5);

      &:hover {
        color: $blue-color;
      }
    }

    .form-html {
      padding: 18px;
      width: 100%;
      overflow: hidden;

      .paperform-wrapper {
        overflow: auto;
      }
    }
  }

  .main-content.container {
    width: calc(100% - 400px);

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .main-content.container2 {
    width: 100%;
  }

  &.form-opened {
    @media (max-width: 1440px) {
      .new-match-card {
        width: 100%;
      }
    }
  }
}

#xeditable_select_popover {
  select {
    min-width: 200px;
    max-width: 100%;
  }
}

.each-info {
  border-right: 2px solid $light-grey-color;
  padding-top: 10px;
  padding-bottom: 10px;
}

.summary-info {
  @media (min-width: 768px) {
    .each-info.col-md-3:nth-of-type(4n) {
      border-right: none;
    }
  }
}

.history-info {
  @media (min-width: 768px) {
    .each-info.col-md-2:nth-of-type(2n) {
      border-right: none;
    }
  }
}

.mentee-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .search-box {
    display: flex;
    align-self: stretch;
    margin-left: auto;
    margin-top: 10px;
    padding-left: 5px;
    padding-right: 5px;

    .filter-searchbox {
      margin-top: 0;
      align-self: flex-end;
      margin-bottom: 0;
    }
  }

  &:before,
  &:after {
    content: none;
  }
}

html {
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  // min-height: calc( 100vh - 51px );
  overflow-y: auto;
  // overflow-x: hidden;
  font-family: 'Calibre';
}

span {
  >svg {
    display: block;
  }
}

.invisible {
  visibility: hidden;
  opacity: 0;
  transition: 300ms;
}

.hidden {
  display: none;
}

.fade-in {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.3s, opacity 0.3s linear;
}

.add-with-fade {
  transition: opacity 0.2s ease;
  opacity: 1;
}

.remove-with-fade {
  // transition: max-height 0.3s ease, opacity 0.3s ease 0.3s;
  transition: max-height 0.2s ease; //, opacity 0s ease;
  opacity: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  max-height: 0;
  overflow: hidden;
}

.carousel-item {
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out !important;
}

.flash-item {
  animation: colorchange 2s;
  -webkit-animation: colorchange 2s;
}

@keyframes colorchange {
  0% {
    background: transparent;
  }

  50% {
    background: #fffbe5;
  }

  100% {
    background: transparent;
  }
}

@-webkit-keyframes colorchange {
  0% {
    background: transparent;
  }

  50% {
    background: #fffbe5;
  }

  100% {
    background: transparent;
  }
}

.center-vertically {
  align-items: center;
  justify-content: flex-end;
  display: flex;
}

.borderless {
  border: none !important;
}

.padding-none {
  padding: 0;
}

.btn {
  user-select: all;
}

.btn-group-toggle {
  label {
    user-select: none !important;
  }
}

.middle {
  vertical-align: middle !important;
  align-items: center;
}

.loading-modal .modal-content {
  background-color: transparent;
  border: none;
  text-align: center;

  .spinner-grow {
    width: 10rem;
    height: 10rem;
  }
}

.btn-warning {
  background-color: #ffc63d !important;
}

.select-none {
  user-select: none !important;
}

.blue-bg-nav {
  background-color: #223c88;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;

  .nav-item {
    border: 0 !important;
    background-color: transparent;

    a {
      color: #939fc1;
      transition: all ease 0.3s;
      font-size: 15px;
      cursor: pointer;
      min-width: 80px;
      text-align: center;

      &:hover,
      &:active {
        border-color: transparent !important;
      }
    }
  }
}

.top-right-close {
  position: absolute;
  right: 33px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  i {
    font-size: 13px;
  }
}

.round-item {
  border-radius: 20px;
  width: 40px !important;
  padding: 8px !important;
}

.selected-btn {
  background-color: #28a745 !important;
}

.selected-btn-border {
  border-color: #0094f3;
}

.red-border {
  border-color: #dc3545;
}

.icon-check-all-mdc {
  color: green;
}

.flex-grow {
  flex-grow: 1;
}

.red-pill {
  border: 1px solid red;
  border-radius: 20px;
  padding: 0 10px 0 10px;
}

.grey-pill {
  border: 1px solid #7b7b7b;
  border-radius: 20px;
  padding: 0 10px 0 10px;
}

.feedback-ui {
  text-align: center;
  padding: 40px;
  background: $primary-blue;
  color: #fff;

  button {
    width: 160px;
    padding: 10px;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #fff;
    font-size: 14px;

    &:active,
    &:hover,
    &:focus {
      background-color: #0276c1;
      border-color: #0276c1;
    }
  }
}

.feedback-ui-container {
  .rainbow-modal_content {
    padding: 0px;
    border-radius: 0.875rem;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    max-height: calc(90vh);
  }

  .rainbow-checkbox-group_checkbox .rainbow-checkbox-group_checkbox-label {
    color: #fff;
  }

  .rainbow-modal_close-button {
    display: none;
  }
}

.rainbow-radio-button-group--brand .rainbow-radio-button-group_items-container .rainbow-radio-button-group_radio--checked {
  background-color: $primary-blue;
}

.Select-input {
  outline: 0;
}

.f-size-sm {
  font-family: $font-regular;
  font-size: 14px;
  line-height: 20px;
  color: $charcoal-500;
}

.f-size-md {
  font-family: $font-regular;
  font-size: 16px;
  line-height: 24px;
  color: $charcoal-500;
}

.f-size-lg {
  font-family: $font-regular;
  font-size: 18px;
  line-height: 28px;
  color: $charcoal-500;
}

.f-title {
  font-family: $font-regular;
  font-size: 20px;
  line-height: 32px;
  color: $charcoal-500;
}

.fw-med {
  font-family: $font-medium;
  color: $charcoal-600;
}

.fw-semi {
  font-family: $font-medium;
  color: $charcoal-700;
}

.custom-data-table .rainbow-table_header-wrapper {
  display: none;
}

.custom-data-table .rainbow-table_container--scrollable-x {
  padding: 0;
  border: none;
}

.bg-none {
  background-color: transparent !important;
}

.popover {
  padding: 10px 10px;
}

.bs-popover-bottom-end {
  left: -12px !important;
  top: 12px !important;
}

.margin-right-xs {
  margin-right: 5px;
}

.br-4 {
  border-radius: 4px !important;
}

.br-8 {
  border-radius: 8px !important;
}

.br-16 {
  border-radius: 16px !important;
}

.blue {
  color: $primary-blue;
}

.alert-dot {
  background-color: #ff4e59;
  height: 4px;
  width: 4px;
  border-radius: 2px;
  position: absolute;
}

.rainbow-spinner--brand>div,
.rainbow-color_brand {
  color: $secondary-blue !important;
}

.lowercase {
  text-transform: lowercase;
}

.text-overflow-hidden {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nowrap {
  white-space: nowrap;
}

.triangle-right {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #768091;
  display: block;
  transition: all ease 0.3s;
}

.triangle-down {
  transition: all ease 0.3s;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-180 {
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  -webkit-transform: rotate(90deg);
  transform: rotate(-180deg);
}

.rotate180 {
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  -webkit-transform: rotate(90deg);
  transform: rotate(180deg);
}

.rotate-90 {
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  -webkit-transform: rotate(90deg);
  transform: rotate(-90deg);
}

.rotate90 {
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

#content .react-bootstrap-table .table thead tr th label {
  display: block;

  input:focus {
    outline: 0;
    box-shadow: none;
  }
}

#content .react-bootstrap-table .table thead tr th:active label input,
#content .react-bootstrap-table .table thead tr th:focus label input,
#content .react-bootstrap-table .table thead tr th:hover label input {
  padding: 0.375rem 0.75rem;
}

.grey-border {
  border: 1px solid #dae2eb;
  border-radius: 4px;
}

.padding-xs {
  padding: 5px 0 0 24px !important;
}

.add-blur {
  filter: blur(4px);
  pointer-events: none;
}

.react-confirm-alert-blur {
  filter: none !important;

  &:before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10005;
    pointer-events: none;
    backdrop-filter: blur(2px);
  }
}

.react-confirm-alert-body {
  width: 100% !important;
  min-width: 400px;
  max-width: 800px;
}

.table-bg-red {
  .rainbow-table_cell-content {
    color: red;
  }

  .rainbow-table_container--scrollable-x .rainbow-table_body>tr>th span {
    color: red !important;
  }
}

.custom-styled-datepicker {
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      >input {
        color: #374050 !important;
        font-size: 16px !important;
        line-height: 21px !important;
        font-family: 'Calibre' !important;
        border: 1px solid #e9eff5 !important;
        border-radius: 4px !important;
        box-shadow: none !important;
        padding: 4px 5px !important;
      }
    }
  }
}

.underline {
  text-decoration: underline;
}

.td-dashed {
  padding-bottom: 1px !important;
  border-bottom: 1px dashed #768091 !important;
  display: inline !important;
  line-height: 1.4 !important;
}

.dotted-underline {
  border-bottom: 1px dotted #007be2;
}

.td-blue-dash {
  border-bottom: 1.5px dashed $secondary-blue !important;
}

.fc-red2 {
  color: #ff4e59;
}

.fc-red3 {
  color: #e52c40;
}

.border-none {
  border: none !important;
}

.alert-bg {
  background-color: #ff4e59 !important;
  color: #fff;
}

.overflow {
  overflow: auto;
}

.sticky {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}

.overflow-scroll-gradient {
  position: relative;
}

.overflow-scroll-gradient::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25px;
  background: linear-gradient(rgba(255, 255, 255, 0.001), white);
  /* transparent keyword is broken in Safari */
}

.not-allowed {
  cursor: not-allowed;
}

.custom-input {
  padding: 3px 10px;
  color: #374050 !important;
  font-size: 16px;
  font-family: 'Calibre';
  border: 1px solid #e9eff5;
  border-radius: 8px;
  box-shadow: none !important;
}

.italic {
  font-style: italic;
}

.flash-red {
  animation: colorchange-red 2s;
  -webkit-animation: colorchange-red 2s;
}

@keyframes colorchange-red {
  0% {
    background: transparent;
  }

  50% {
    background: rgba(238, 22, 22, 0.4);
  }

  100% {
    background: transparent;
  }
}

@-webkit-keyframes colorchange-red {
  0% {
    background: transparent;
  }

  50% {
    background: rgba(238, 22, 22, 0.4);
  }

  100% {
    background: transparent;
  }
}

.display-none {
  display: none;
}

.icon-size-24 {
  svg {
    width: 24px;
    height: 24px;
  }
}

.icon-size-18 {
  svg {
    width: 18px;
    height: 18px;
  }
}

svg.icon-size-16 {
  width: 16px;
  height: 16px;
}

svg.icon-size-14 {
  width: 14px;
  height: 14px;
}

.hover-bg-gray {
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative;

  &:hover {
    margin-left: -8px;
    margin-right: -8px;
    border-radius: 8px;
    background-color: #f6f9fc;
  }
}

.last-child-margin-zero {
  >*:last-child {
    margin-bottom: 0 !important;
  }
}

.icon-24 {
  svg {
    width: 24px;
    height: 24px;
  }
}

.icon-16 {
  svg {
    width: 16px;
    height: 16px;
  }
}

.hover-bg-gray {
  padding: 8px;
  margin-left: -8px;
  margin-right: -8px;
  border-radius: 8px;
  background-color: transparent;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f6f9fc;
  }
}

.por {
  position: relative;
}

.ttu {
  text-transform: uppercase;
}

.top-right {
  position: absolute;
  right: 10px;
  top: 10px;
}

.editable-click.btn.btn-link {
  &.is-underlined {
    color: $primary_blue;
    font-size: 16px;
    font-family: $font-medium;
    border-bottom: 1px dashed $primary_blue;
    padding: 0;
    margin: 0;
    border-radius: 0;
    line-height: 1 !important;
  }
}

.red-dot {
  margin-left: 8px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #ff4e59;
  display: inline-block;
  flex-shrink: 0;
  display: inline-block;
  transform: translateY(-35%);
}

.bottom-dashed {
  border-bottom: 1px dashed #374050;
}

.sidebar-content-main {
  ol {
    padding-left: 16px;
  }
}

.w-100p {
  width: 100%;
}

.minh-50px {
  min-height: 50px;
}

.no-padding-direct-child {
  >div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.float-draggable-modal-compo {
  background-color: #fff;
  display: block;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 9999;
  position: relative;
}

.floating-resizable-draggable {
  background-color: transparent;
  position: relative;
  z-index: 9999;

  .modal-close-btn {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
    z-index: 2;
    padding: 4px;
    transform: none;

    i {
      font-size: 9px;
      color: #768091;
    }
  }

  .float-draggable-modal-compo {
    background-color: #fff;
    border: 1px solid #e9eff5;
    border-radius: 8px;
    box-shadow: 0 2px 31px rgba(0, 0, 0, 0.15);
  }

  .notes-draggable-handle {
    cursor: move;
  }

  .custom-textarea {
    .ta-wrp-w-btn {
      textarea {
        min-height: 200px !important;
      }
    }
  }
}

.admin-avatar-bg {
  background-color: #d1a41b;
}

.coordinator-avatar-bg {
  background-color: #5ac9f1;
}

.navigator-avatar-bg {
  background-color: #ec6254;
}

.partner-avatar-bg {
  background-color: #5ac9f1;
}

.mentor-avatar-bg {
  background-color: #8abe57;
}

.round-close-btn {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #CCD6E2;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  i {
    color: #CCD6E2;
    font-size: 16px;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
}

.confirm-alert-content {
  width: 400px;
  padding: 24px;
  background: #fff;
  color: #576071;
  box-shadow: 0px 2px 12px rgba(23, 32, 46, 0.1);
  border-radius: 4px;
}

.confirm-alert-content>h1 {
  margin-top: 0;
}


.quality-measure-compo {
  .is-view-mode {
    .icon-wrp {
      width: 28px;
      height: 28px;
      border-radius: 8px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      margin-right: 12px;
    }

    .result-badge {
      font-size: 16px !important;
      font-family: $font-medium;
      margin-top: -2px;
    }
  }

  .is-edit-mode {
    .MuiInputBase-root.MuiOutlinedInput-root {
      width: 100%;

      .MuiOutlinedInput-notchedOutline {
        top: 0;
        border: 1px solid #CCD6E2;
        border-radius: 8px;
      }
    }

    .react-datepicker__input-container input {
      border: 1px solid #CCD6E2;
      border-radius: 8px;
    }
  }

  .qm-expandable-content {
    margin-left: 14px;
    border-left: 1.5px dashed #CCD6E2;
    padding-left: 26px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .qm-green-dot {
    width: 6px;
    height: 6px;
    background-color: #59C567;
    border-radius: 50%;
    margin-right: 8px;
  }

  .qm-red-dot {
    width: 6px;
    height: 6px;
    background-color: #FF4753;
    border-radius: 50%;
    margin-right: 8px;
  }

  .gray-dot-divider {
    margin-left: 6px;
    margin-right: 6px;
  }
}

.collapsible-list {
  &-item-title {
    cursor: pointer;
  }

  &-item-content {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.icon-lbl-w-count {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-lbl-w-count-count {
    position: absolute;
    top: -4px;
    right: -8px;
    background: #FF4E59;
    border-radius: 12px;
    height: 16px;
    min-width: 16px;
    padding: 0 4px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 100%;
    display: block;
    color: #576071;
  }
}


.sc-06 {
  transform: scale(0.6) !important;
}

.counter-input {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  overflow: hidden;
  max-width: 320px;


  .MuiButtonBase-root {
    border-radius: 0;
    height: 40px;
    font-size: 24px;
    min-width: 48px;
    flex-shrink: 0;
  }

  .MuiFormControl-root .MuiInputBase-root {
    height: 40px;
    flex-grow: 1;
    background-color: #fff;
    border-color: #A8B6C7;
    border-width: 2px 0 2px 0;
    border-radius: 0 !important;

    input {
      text-align: center;
      &::-webkit-inner-spin-button  {
        display: none;
      }
    }
    fieldset {
      border-radius: 0;
      border: 0;
      border-radius: 0;
    }
  }
}
.section-header-semibold {
  font-family: 'Inter-Semibold';
  color: #8D8D99;
  font-size: 13px;
}

body .general-center-modal .MuiFormControl-root {
  width: 100% !important;
}