.inbound-calls-wrp {
  background-color: #e9eff5;
  padding: 24px;
  min-height: calc(100vh - 51px);

  &.is-in-outreach {
    padding: 0;
    background-color: transparent;
    min-height: unset;
  }

  &.is-in-mentor-dashboard {
    padding: 0;
    background-color: transparent;
    min-height: unset;
  }
}

.scrollable-columns {
  overflow-x: auto;
  height: 100%;
  margin-bottom: 24px;

  .inner-wrp {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: fit-content;
    min-height: calc(100vh - 148px);

    .two-columns {
      width: 992px;

      @media (max-width: 1599px) {
        width: 844px;
      }

      @media (max-width: 1599px) {
        width: 748px;
      }

      .each-column {
        height: 50%;
      }
    }

    .each-column {
      width: 480px;
      flex-shrink: 0;
      margin-right: 16px;
      margin-bottom: 16px;

      @media (max-width: 1599px) {
        width: 400px;
      }

      @media (max-width: 1599px) {
        width: 360px;
      }
    }

    >div:last-child {
      margin-right: 0;
    }

    >div {
      .inner-column {
        background-color: #fff;
        box-shadow: 0px 2px 10px rgba(55, 64, 80, 0.1);
        border-radius: 16px;
        padding: 24px;
        max-height: 100%;
        // overflow-x: auto;
      }
    }
  }

  .three-columns {
    width: 1376px;

    &.full-width {
      width: 100%;
    }

    @media (max-width: 1441px) {
      width: 100%;
    }
  }

  &.full-width {
    width: 100%;
  }
}

.es-dashboard-wrp {
  >.container-fluid {
    >h1 {
      margin-top: 25px;
      margin-bottom: 0;
      padding-left: 20px;

      @media (max-width: 1024px) {
        margin-bottom: 15px;
      }
    }
  }

  .today-main-wrp {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .card {
      padding: 12px 11px;
      background-color: #f7f7f7;
      width: 150px;
      margin-left: 6px;
      margin-right: 6px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      display: inline-flex;
      justify-content: center;
      flex-direction: column;
      border: none;

      h2 {
        font-size: 30px;
        font-family: $font-bold;
        line-height: 1;
        margin-bottom: 0;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        div {
          font-family: $font-bold;
        }
      }

      h4 {
        font-size: 14px;
        font-family: $font-regular;
        line-height: 1;
        margin-bottom: 0;
      }

      .card-body {
        padding: 0;
      }
    }

    @media (min-width: 1024px) {
      >div:first-child {
        margin-left: 0;
      }
    }
  }

  .es-dashboard-main {
    background-color: #ebebeb;
    margin-left: -15px;
    margin-right: -15px;

    .row {
      margin-left: 0;
      margin-right: 0;
    }

    .detail-numbers-wrp {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 28px;
      padding-bottom: 28px;
      min-height: calc(100vh - 120px);

      .Select-control {
        height: 38px;
        border-radius: 3px;
        border: none;
        width: 180px;
        margin-left: auto;
        margin-right: 0;
        margin-bottom: 25px;

        .Select-placeholder {
          padding: 6px 100px 5px 20px;
          color: #000;
          line-height: 26px;
          font-size: 15px;
        }

        .Select-input {
          padding-left: 20px;
          padding-right: 20px;
        }

        .Select-value {
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 1px;
          color: #000;

          .Select-value-label {
            font-size: 15px;
            font-family: $font-regular;
          }
        }
      }

      .Select-menu-outer {
        width: 180px;
        right: 0;
        left: auto;

        * {
          font-size: 15px;
          font-family: $font-regular;
          color: #000;
        }
      }

      >h3,
      >h4 {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #d1d1d1;
        text-transform: capitalize;
      }

      .toremove-bd-last-child {
        >h4 {
          padding-top: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid #d1d1d1;
          text-transform: capitalize;
        }

        >h4:last-child {
          border-bottom: 0;
        }
      }
    }

    .calendar-wrp {
      padding-top: 30px;

      .white-bg {
        background-color: #fff;
        min-height: 600px;
        padding: 10px;
      }
    }
  }

  .rbc-events-container .rbc-event {
    height: 50px !important;
  }

  .count {
    color: #768091;
  }

  .title {
    color: #768091;
  }

  .active-tab {

    .count,
    .title {
      color: #0094f3;
    }

    .title {
      border-bottom: 2px solid #0094f3;
      padding-bottom: 8px;
    }
  }

  .days-filter {
    margin-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    button {
      border: 1px solid #ccd6e2;
      border-radius: 4px;
      background-color: transparent;
      color: #768091;
      margin-right: 13px;
      margin-bottom: 13px;
      font-size: 14px !important;

      &:hover,
      &.selected {
        color: #fff;
        border: 1px solid $primary-blue;
        background-color: $primary-blue;
      }
    }
  }
}

.big-timeslot .rbc-time-slot {
  border-bottom: 1px dashed #ccd6e2;
}

.big-timeslot {

  .rbc-time-slot,
  .rbc-day-slot .rbc-event {
    min-height: 45px !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .rbc-event-content {
      font-size: 12px;
      width: 100%;
      text-align: center;
      display: block;
      font-family: $font-regular;
      color: #374050;
      line-height: 1;
      max-height: 20px;
    }
  }
}

.rbc-time-slot,
.rbc-day-slot .rbc-event {
  font-family: $font-regular  !important;
  font-size: 16px;
  text-align: center;
  border-radius: 0px;
}

.rbc-day-slot .rbc-event-content {
  text-align: left;
  margin-top: 2px;
  font-size: 12px;
}

.rbc-label {
  width: 85px !important;
}

.rbc-time-content>.rbc-time-gutter {
  border-left: none;
}

.rbc-timeslot-group .rbc-label {
  width: 81px !important;
  border-top: 1px solid #f7f7f7;
}

.general-dashboard-wrp {
  background-color: #e9eff5;
  min-height: calc(100vh - 51px);

  .general-dashboard-content {
    padding-bottom: 0 !important;
  }
}


// .grouped-alerts-list {
//   padding-bottom: 40px;
// }

.dashboard-tasks-compo {
  .es-dashboard-box-container {
    box-shadow: none;

    .grouped-alerts-list {
      padding: 0;

      >div:hover {
        background-color: transparent;
      }

      >div:last-child {
        margin-bottom: 4px !important;
      }

      .todo-list-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-right: 20px;
        position: relative;
        line-height: 1.2;
        .list-item {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          position: relative;
          >div {
            overflow-wrap: anywhere;
          }
        }

        .force-ind {
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #ff4e59;
          right: 2px;
          top: 50%;
          transform: translateY(-50%);
        }

        &.is-hover {
          .icon-custom-talking-point-left-ind {
            position: absolute;
            top: 3px;
            left: -18px;
            transform: scale(0.6);
            font-size: 16px;
            color: #ccd6e2;
          }

          .steplet-dropdown {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            position: absolute;
            right: -12px;
            top: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            .MuiTouchRipple-root {
              display: none;
            }
          }

          &:before {
            content: '';
            position: absolute;
            left: -18px;
            right: -18px;
            top: -4px;
            bottom: -4px;
            background-color: #f6f9fc;
            border-radius: 8px;
            z-index: 1;
          }

          * {
            position: relative;
            z-index: 2;
          }


        }
      }

      .list-item {
        margin-left: 0;
        &::marker {
          display: none;
          content: '';
        }
      }
    }
  }

  .custom-styled-tabs.nav.nav-tabs.copilot+.tab-content {
    padding-bottom: 0;
  }

  .survey-wrp .survey-detail-content .custom-react-select {
    z-index: unset;
  }

  .cright-nav.opened {
    .notifications-section {
      iframe {
        height: calc(100vh - 300px) !important;
      }
    }
  }
}

.full-width-dropdown-toggle {
  position: relative;
  padding-left: 6px;
  padding-right: 6px;

  &:hover {
    >* {
      z-index: 2;
    }

    &:before {
      content: '';
      position: absolute;
      left: -18px;
      right: -18px;
      top: -4px;
      bottom: -4px;
      background-color: #f6f9fc;
      border-radius: 8px;
      z-index: 1;
    }
  }

  .icon-custom-talking-point-left-ind {
    left: -13px !important;
  }

}

// todo list styles
.es-dashboard-alerts-wrp {
  margin-left: -15px;
  margin-right: -15px;
  background-color: #fff3cd;
  position: relative;

  >.nobd.nopd {
    top: 15px;
    right: 15px;
    color: #000;
    position: absolute;
  }
}

.dashboard-feed-compo {
  padding-top: 30px;
  padding-bottom: 30px;

  .feed-table {
    margin-bottom: 0;
    border: 1px solid #000;

    th {
      font-size: 15px;
      font-family: $font-bold;
      color: #000;
      padding: 5px 10px;
      border: 1px solid #000;
    }

    td {
      font-size: 14px;
      font-family: $font-regular;
      color: #000;
      padding: 10px 10px;

      .nobd.nopd {
        color: #000;
        font-size: 14px;
      }
    }
  }
}

.es-dashboard-box-container {
  box-shadow: 0px 2px 6px rgba(23, 32, 46, 0.1);
  border-radius: 4px;
  background-color: #ffffff;

  .esdb-header {
    border-bottom: 1px solid #e9eff5;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .grouped-alerts-count {
      background-color: #f6f9fc;
      border-radius: 8px;
      color: #768091;
      font-size: 14px;
      padding: 2px 10px;
      letter-spacing: 0.04em;
    }
  }

  & div:last-child {
    border-bottom: none;
  }

  .grouped-alerts-list {
    display: flex;
    flex-direction: column;

    // padding-bottom: 40px;
    >div {
      // margin: 4px 0;
      padding: 6px 0;
      border-radius: 4px;
      font-size: 16px;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #f6f9fc;
      }

      >i {
        padding: 5px;
      }

      >input {
        margin: 0 10px 0 20px;
        height: 16px;
        width: 16px;
      }

      .crcw.cr_ButtonMenu {
        margin-right: 0;

        .dropdown-button-menu {
          >button {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .list-item {
      color: #374050;
      flex: 1;
    }

    &.dismissed-alerts {
      .list-item {
        color: #768091;
        flex: 1;
        text-decoration-line: line-through;
        font-style: italic;
      }
    }

    .dropdown-button-menu {
      color: #768091;
      font-size: 12px;
      margin-right: 8px;

      &:hover {
        background: #e9eff5;
        border-radius: 4px;
      }

      .rainbow-primitive-menu_dropdown {
        border-radius: 4px;

        .rainbow-menu-item>a {
          margin: 0 6px;
          border-radius: 4px;

          &:hover {
            background: #e9eff5;
          }

          &:active {
            background: #e9eff5;
          }
        }
      }

      .rainbow-button-icon--border-filled {
        background-color: transparent;
        color: #768091;
        border: 0;
      }

      .rainbow-button-icon {
        box-shadow: none;

        &:focus {
          box-shadow: none;
        }
      }

      .rainbow-button-icon--medium {
        height: 1.75rem;
        width: 1.75rem;
      }
    }
  }
}