.loginPage {
  .form-wrp {
    display: flex;
    flex-direction: column;

    .logos-wrp {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .main-logo {
        width: 160px;
        height: auto;
      }

      .one-to-one-logo {
        width: 40px;
        height: auto;
      }
    }

    @media(max-width :1024px) {
      width: 360px;
      padding-top: 80px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 30px;
    }
  }


  .loginForm {
    display: block;
    margin: 0 auto;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 60px;

    .MuiFormControl-root.MuiTextField-root {
      position: relative;

      .MuiInputLabel-root {
        position: absolute;
        transform: translate(14px, 8px) scale(1);

        &.MuiInputLabel-shrink {
          transform: translate(0, -24px) scale(0.75);
          padding: 2px;
        }
      }

      .MuiInputBase-root {
        padding: 0 !important;
        overflow: hidden;
        input {
          height: 32px !important;
          padding: 4px 12px !important;
        }
      }
    }

    .invalid-credential {
      input {
        border-color: #E52C40 !important;
        color: #E52C40;
      }
    }

    .login-noti {
      padding: 12px 16px;
      font-family: $font-medium;
      border-radius: 4px;
      margin-bottom: 15px;
      font-size: 14px;
      text-align: left;

      &.success-noti {
        background-color: #F6F9FC;

        span {
          display: block;
          width: 100%;
          text-align: left;
          color: #768091;
        }
      }

      &.error-noti {
        background-color: rgba(255, 78, 89, 0.1);
        color: #E52C40;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        i {
          font-size: 12px;
          margin-right: 8px;
          margin-top: 5px;
        }

        span {
          width: calc(100% - 24px);
        }
      }
    }

    .buttons-wrp {
      margin-top: 20px;
      margin-bottom: 24px;

      button.btn-success {
        width: 100%;
        display: block;
        border-radius: 4px;
        background-color: $secondary-blue;
        border: 1px solid $secondary-blue;
        color: #fff;
        font-size: 16px;
        font-family: $font-medium;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: none !important;
        padding: 11px 20px;

        &:disabled {
          opacity: 1;
          background-color: #E9EFF5;
          border-color: #E9EFF5;
        }
      }
    }

    .spinner {
      font-size: 14px;
      margin-left: 10px;
      margin-top: 4px;

      i {
        margin-right: 7px;
      }

      .icon-loading {
        animation: spin 1.5s infinite linear;
        -webkit-animation: spin2 1.5s infinite linear;
      }
    }

    button.text-btn {
      font-size: 14px;
      font-family: $font-regular;
      color: $primary-blue;
      margin-bottom: 12px;
      line-height: 15px;
    }
  }
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }

  to {
    transform: scale(1) rotate(360deg);
  }
}

@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}


.zitadel-login-page {
  .left-icon-btn.MuiButton-root {
    margin: 0 0 16px 0;
    border-radius: 8px;
    padding: 8px 50px;
    position: relative;
    height: 48px;
    background-color: #fff;
    font-family: $font-medium;
    font-size: 16px;
    border: 1px solid #CCD6E2;
    color: $charcoal-800;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .MuiButton-startIcon {
      width: 40px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 0;
      background-color: #fff;
      padding: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #CCD6E2;

      img {
        width: 16px;
        height: 16px;
        object-fit: cover;
        object-position: center center;
        padding: 0;
      }
    }

    .MuiButton-endIcon {
      position: absolute;
      right: 16px;
      top: 50%;
      color: #A8B6C7;
      font-size: 14px;
      transform: translateY(-50%);
    }
  }
}