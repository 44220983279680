.sidebar-compo .sidebar-header .ccp-updated.nav.nav-tabs {
  .nav-item {
    a {
      font-size: 21px; 
      color: #95A0B0; 
      font-family: $font-regular;
    }
    &.current {
      a {
        color: #374050; 
      }
    }
  }
}


.main-dashboard-wrap {
  background-color: #e9eff5;

  .bg-gray {
    background-color: #e9eff5;
  }

  .row {
    margin-left: -8px;
    margin-right: -8px;

    >div {
      padding-left: 8px;
      padding-right: 8px;

      .inner-col {
        background-color: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(55, 64, 80, 0.1);
        border-radius: 16px;
        padding: 24px;

        .scroll-wrp {
          margin-left: -24px;
          margin-right: -24px;
          padding-left: 24px;
          padding-right: 24px;
          height: calc(100vh - 285px);
          overflow-y: auto;
        }
      }
    }
  }
}

.stat-chart-card-compo {
  .inner-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .desc-part {
      width: 30%;
    }

    .graph-wrp {
      width: 50%;
      margin: 0;
      // flex-grow: 1; 
    }

    .numbers-part {
      width: 80px; 
      .count-diff {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        i.icon-line-triangle {
          font-size: 8px;
          color: #768091;
          margin-right: 4px;
          transform: scale(0.8);
        }

        &.is-increased {
          i.icon-line-triangle {
            color: #88C04E;
          }
        }

        &.is-decreased {
          i.icon-line-triangle {
            color: #FF4E59;
            transform: scale(0.8) rotate(180deg);
          }
        }
      }
    }
  }
}

.g-color-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: 10px;
  background-color: #B9E0FF;

  @media(max-width: 1559px) {
    margin-top: 6.5px;
  }
}
.bg-gray {
  background-color: #e9eff5;
}
.bg-f1f5f9 {
  background-color: #f1f5f9;
}