.cms-wrapper {
  .cright-nav {
    &.opened {
      .content {
        .card {
          border: 0; 
          .card-header {
            padding: 16px 30px;
            background-color: #fff;
          }
          .card-body {
            padding: 16px 30px;
            background-color: #fff;

          }
          .card-footer {
            padding: 16px 30px;
            background-color: #fff;
          }
        }
      }
    }
  }
}