.track-issue-step-modal {
	border-left: 8px solid #000;
	display: flex; 
	background-color: #fff;
	.issues-filters {
		width: 30%;
		padding: 51px 33px; 
		border-right: 1px solid #d6d6d6;
		h3 {
			margin-bottom: 0;
			padding-left: 5px;
		}
		.filters {
			margin-bottom: 20px;
		}
		.filters, .sort-options {
			.each-filter {
				border: 0; 
				background-color: transparent;
				cursor: pointer;
				padding: 0;
				display: flex; 
				align-items: center; 
				justify-content: flex-start;
				font-size: 15px;
				color: #808080;
				margin-top: 8px;
				margin-bottom: 7px;
				.icon-wrp {
					width: 22px;
					margin-right: 7px;
				}
			}
			.current-filters-list {
				padding-left: 29px;
				.filters-list {
					.each-filter-detail {
						border-bottom: 1px solid #e6e6e6;
						margin-bottom: 7px;
						.operator-wrp {
							position: relative;
							max-width: 200px; 
							.operator {
								width: 100%;
								display: block;
								margin-bottom: 7px;
								line-height: 29px;
								max-width: 200px;
								padding: 0 7px;
								outline: 0 !important;
								background: #FFFFFF;
								opacity: 0.5;
								border: 1px solid #979797;
								border-radius: 2px;
								box-sizing: border-box;
								box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.1);
								font-size: 15px;
								-webkit-appearance: none;
								position: relative;
							}
							.icon.icon-angle-down-fa {
								position: absolute;
								right: 10px;
								top: 50%;
								transform: translateY(-50%); 
								font-size: 24px;
								opacity: 0.3;
								pointer-events: none; 
							}
						}
						.search-text {
							width: 100%;
							display: block;
							margin-bottom: 7px;
							background: #FFFFFF;
							opacity: 0.5;
							border: 1px solid #979797;
							box-sizing: border-box;
							box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.1);
							border-radius: 2px;
							line-height: 29px;
							outline: 0 !important;
							padding-left: 7px;
							padding-right: 7px;
							font-size: 15px;
						}
						.datepicker-wrp {
							margin-bottom: 7px;
							display: flex;
							align-items: center;
							justify-content: flex-start;
							.react-datepicker-wrapper {
								flex-grow: 1; 
								.react-datepicker__input-container {
									width: 100%;
									input[type='text'] {
										width: 100%;
										display: block;
										background: #FFFFFF;
										opacity: 0.5;
										border: 1px solid #979797;
										box-sizing: border-box;
										box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.1);
										border-radius: 2px;
										line-height: 29px;
										outline: 0 !important;
										padding-left: 7px;
										padding-right: 7px;
										font-size: 15px;
									}
								}
							}
							.icon.icon-filter-date-created {
								font-size: 23px;
								color: #757575; 
								margin-left: 9px;
								margin-right: 24px;
							}
						}
					}
					.blue-anchor.btn.btn-link {
						margin-top: 0px;
					}
				}
			}
		}
		.divider {
			background-color: #D6D6D6;
			height: 1px;
			width: auto;
			margin-left: -33px;
			margin-right: -33px;
			margin-bottom: 25px;
		}
	}
	.issues-content {
		width: 70%;
		height: 100vh;
		overflow: auto;
		.issues-modal-content-header {
			position: relative;
		}
		.issue-listing-wrp {
			height: calc( 100% - 50px );
			overflow-y: auto; 
		}
	}
}