.sop-component-wrp {
  .sop-header {
    padding: 15px 25px 10px 25px; 
    .btn-group {
      button {
        font-size: 14px;
        font-family: $font-regular;
      }
      .btn-success {
        color: #fff; 
        font-family: $font-bold; 
        background-color: #72BB53;
        border: 1px solid #72BB53; 
      }
      .btn-null {
        border: 1px solid #979797; 
      }
    }
  }
  .sop-main {
    padding: 24px; 
    .timeline-view {
      display: block;
      width: auto;
      background-color: #f7f6f6;
      border-bottom: 4px solid #e1e1e1;
      margin-left: -25px;
      margin-right: -25px;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 15px;
      margin-bottom: 20px;
      span {
        font-family: $font-regular;
      }
      .stepLine {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        li.stepline-listing {
          margin-bottom: -12px;
          position: relative;
          .tick {
            margin-left: -6px;
          }
          .fa.fa-check.fc-green {
            position: absolute;
            left: 5px;
            top: -14px;
          }
        }
      }
    }
    .actions-wrp { 
      button.btn-link {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-medium;
        color: $primary-blue; 
        font-size: 16px;
        text-decoration: none !important;
        i {
          margin-right: 12px;
          font-size: 16px;
        }
        &.gray-btn {
          color: #768091; 
          font-size: 16px; 
          font-family: $font-regular;
        }

      }
    }
    .details-list {
      padding-left: 30px;
      position: relative;
      .btn-link {
        padding: 0;
        margin: 0;
        text-decoration: none !important;
      }
      &:before {
        content: ''; 
        position: absolute;
        left: 14px;
        top: 6px;
        height: 100%;
        width: 2px;
        background-color: #DAE2EB;
      }
      .each-detail {
        .btn-toolbar {
          button {
            font-size: 16px;
            font-family: $font-medium;
            color: #fff;
            padding: 6px 15px; 
            line-height: 1 !important;
            border-radius: 4px; 
            margin-right: 10px;
            border:0 !important;
          }
        }
        .sop-step-name {
          position: relative;
          &:before {
            content: ''; 
            left: -21px;
            top: 50%;
            transform: translateY(-50%); 
            width: 12px;
            height: 12px; 
            border-radius: 50%; 
            background-color: #88C04E;
            position: absolute;
          }
        }
        &.calling-enabled {
          .sop-step-name {
            &:before {
              background-color: $primary-blue;
            }
          }
        }
      }
    }
    .table.custom-styled {
      tr {
        border-bottom: 1px solid #E9EFF5;; 
        td {
          border-top: 0 !important; 
          font-size: 14px;
          font-family: $font-regular;
          color: #374050;
        }
      }
    }
  }

}