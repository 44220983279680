.custom-react-select {
  .custom__control {
    border: 1px solid #ccd6e2;
    border-radius: 4px;
    min-height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: none !important;
    min-width: 150px;


    .custom__value-container {
      width: calc(100% - 20px);

      .custom__input,
      .custom__single-value {
        line-height: 1 !important;
        font-size: 16px !important;
        font-family: $font-regular  !important;
        color: #374050;
      }

      .custom__single-value {
        position: relative;
        transform: none;
        white-space: normal;
        padding-top: 3px;
        padding-bottom: 3px;
      }

    }

    .custom__indicators {
      .custom__indicator-separator {
        display: none;
      }

      .custom__indicator {
        padding: 3px;
        width: 20px;
      }
    }

    &.custom__control--is-focused,
    &:hover {
      border-color: #dae2eb;
    }

    .custom__value-container.custom__value-container--has-value {
      .custom__single-value+div .custom__input {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .custom__value-container.custom__value-container--is-multi {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 4px 0;

      .custom__multi-value {
        background: $dark-blue;
        border-radius: 4px;
        margin-right: 4px;
        margin-bottom: 2px;
        margin-top: 2px;
        overflow: hidden;

        .custom__multi-value__label {
          font-size: 14px;
          font-family: $font-regular;
          color: #fff;
          padding: 2px 3px 4px 5px;
          line-height: 1.2;
        }

        .custom__multi-value__remove {
          padding-right: 5px;
          cursor: pointer;
          color: #fff;
          background-color: $dark-blue;
        }
      }

      &.custom__value-container--has-value {
        >div {
          width: auto !important;
        }
      }

    }
  }

  &.is-red-bordered {
    .custom__control {
      border: 1px solid #ff4e59;
    }
  }

  &.is-blue-bordered {
    .custom__control {
      border: 1px solid #0094f3;

      .custom__single-value {
        color: $secondary-blue  !important;
      }

      .custom__indicators .custom__indicator {
        svg {
          color: $dark-blue;
        }
      }
    }
  }

  &.without-border {
    .custom__control {
      border: 0;
      padding: 0;
      min-width: 80px;

      .custom__value-container .custom__single-value {
        color: $dark-blue  !important;
        font-family: $font-medium !important;
        font-size: 16px !important;;
      }

      .custom__indicators .custom__indicator {
        color: $dark-blue  !important;
        width: 22px;
      }
    }
  }

  &.blue-placeholder {
    .custom__placeholder {
      color: $secondary-blue  !important;
    }
  }

  &.is-one-red {
    .custom__value-container.custom__value-container--is-multi.custom__value-container--has-value {
      >div:first-child {
        .custom__multi-value {
          background-color: #e2534b;

          .custom__multi-value__remove {
            background-color: transparent !important;
          }
        }
      }
    }
  }



  &.is-big {
    .custom__control {
      min-height: 48px;
      border: 1px solid #E0E8F0;
      border-radius: 4px;
    }
  }

  &.fz-12 {
    .custom__control {
      .custom__value-container {
        font-size: 14px;
      }
    }
  }

  &.is-border-radius8 {
    .custom__control {
      border-radius: 8px;
    }
  }

  &.is-light-border {
    .custom__control {
      border: 1px solid #CCD6E2;
    }
  }
  &.is-height36  {
    .custom__control {
      height: 36px;
    }
  }
}

.second-bd {
  .multi-select {
    .dropdown {

      .dropdown-heading,
      .dropdown-content {
        border: 1px solid #e9eff5 !important;
      }
    }
  }

  .custom-react-select {
    .custom__control {
      border: 1px solid #e9eff5;
    }
  }
}

.multi-select {
  .item-renderer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.custom__menu {
  z-index: 99999;
  position: absolute;
  min-width: 80px;
  overflow: auto;
  padding-top: 8px;
  padding-bottom: 8px;

  .custom__menu-list {
    position: relative;
    height: 100%;

    .custom__option {
      padding: 5px 15px;
      font-size: 14px;
    }
  }
}

#cop-ct-columns-overflow-wrp {
  overflow-y: auto;

  >div {
    >.custom__menu {
      .custom__menu-list {
        position: relative;
        height: 100%;
        overflow-y: scroll;

        // max-height: 75px !important;
        .custom__option {
          padding: 3px 15px;
          font-size: 14px;
        }
      }
    }
  }
}