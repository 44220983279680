.es-listing-sidebar {
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;

  .days-filters-wrp {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    >div {
      background: #F6F9FC;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 8px;
      margin-bottom: 8px;
      padding: 4px 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h4 {
        margin-bottom: 0 !important;
        transition: all ease 0.3s;
        font-family: $font-regular;
        color: #576071;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:after {
        content: '';
        opacity: 0;
        transition: all ease 0.3s;
      }
    }

    >div.active {
      background: $secondary-blue;

      h4 {
        color: #fff;
        font-family: $font-medium;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        height: 2px;
        width: 24px;
        opacity: 1;
        background-color: #47D078;
      }
    }
  }
}