.app {

  nav :global(.fa) {
    font-size: 1em;
    line-height: 20px;
  }

  :global(.navbar-fixed-top) {
    :global(.navbar-brand){
      height: 80px;
    }
    :global(.navbar-nav) {
      padding: 15px;
    }
  }
}

